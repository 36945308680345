// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Link, useNavigate } from "react-router-dom"; 
// import MetaTags from "./MetaTags";
// import LandingHeader from "./LandingHeader";
// import LandingFooter from "./LandingFooter";
// import HelpFAQ from "./HelpFAQ";
// import img from "../../../assets/img/pages/header-light.png";

// const HelpCenter = () => {
//   const [faqDetails, setFaqDetails] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [searchResults, setSearchResults] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");
//   const navigate = useNavigate(); 

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);


//   const handleSearchChange = async (e) => {
//     const inputValue = e.target.value;
//     setSearchQuery(inputValue);

//     if (!inputValue) {
//       setSearchResults([]);
//       return;
//     }

//     try {
//       const response = await axios.post(
//         "https://ghanish.in/api/website/search_faq",
//         { keyword: inputValue },
//         { headers: { "Content-Type": "application/json" } }
//       );

//       if (response.data.st === 1 && response.data.data.length > 0) {
        
//         setSearchResults(response.data.data);
//       } else {
//         setSearchResults([]);
//       }
//     } catch (error) {
//       console.error("Error fetching FAQ options:", error);
  
//       setSearchResults([]);
//     }
//   };


//   const handleSelect = (faqId) => {
  
//     navigate(`/faq/${faqId}`);
//   };

//   return (
//     <div>
//       <MetaTags />
//       <LandingHeader />

//       <section className="section-py first-section-pt help-center-header position-relative overflow-hidden">
        
//         <img
//           className="banner-bg-img position-absolute top-0 start-0 w-100 h-100"
//           src={img}
//           alt="Help center header"
//           style={{ objectFit: "cover", zIndex: -1 }}
//         />

     
//         <div className="text-center my-5 mt-12 position-relative z-1 ">
//           <h4 className="text-primary mb-3 mt-5">Hello, how can we help?</h4>
//           <div className=" rounded-pill input-group input-group-merge mx-auto w-50 bg-white">
//                         <span
//                           className="input-group-text"
//                           id="basic-addon-search31"
//                         >
//                           <i className="ri-search-line lh-1 ri-20px"></i>
//                         </span>
//                         <input
//                           type="text"
//                           className="form-control chat-search-input ps-8"
//                           placeholder="Search "
//                           value={searchQuery}
//                           onChange={handleSearchChange}
//                           aria-label="Search..."
//                           aria-describedby="basic-addon-search31"
//                         />
//                       </div>

       
//           {searchResults.length > 0 && (
//             <ul
//               className="list-group position-absolute top-100 start-50 translate-middle-x mt-2 shadow-sm"
//               style={{
//                 zIndex: 1050,
//                 maxHeight: "200px",
//                 overflowY: "auto",
//                 width: "600px",
//                 backgroundColor: "#fff",
//                 borderRadius: "0.25rem",
//               }}
//             >
//               {searchResults.map((item) => (
//                 <li
//                   key={item.faq_id}
//                   className="list-group-item list-group-item-action"
//                   onClick={() => handleSelect(item.faq_id)} 
//                 >
//                   {item.question}
//                 </li>
//               ))}
//             </ul>
//           )}

       
//           <p className="text-center px-4 mb-0 text-muted  ">
//             Common troubleshooting topics: eCommerce, Blogging to payment
//           </p>
//         </div>
//       </section>


//       <HelpFAQ />
//       <LandingFooter />
//     </div>
//   );
// };

// export default HelpCenter;


import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; 
import MetaTags from "./MetaTags";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";
import HelpFAQ from "./HelpFAQ";
import img from "../../../assets/img/pages/header-light.png";
import { AutoComplete } from 'primereact/autocomplete'; // Import AutoComplete component
import './HelpCenter.css';
const HelpCenter = () => {
  const [faqDetails, setFaqDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate(); 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSearchChange = async (e) => {
    const inputValue = e.query; // Use e.query instead of e.target.value
    setSearchQuery(inputValue);
  
    if (!inputValue) {
      setSearchResults([]);
      return;
    }
  
    try {
      const response = await axios.post(
        "https://ghanish.in/api/website/search_faq",
        { keyword: inputValue },
        { headers: { "Content-Type": "application/json" } }
      );
  
      if (response.data.st === 1 && response.data.data.length > 0) {
        setSearchResults(response.data.data);
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.error("Error fetching FAQ options:", error);
      setSearchResults([]);
    }
  };
  

  const handleSelect = (faqId) => {
    navigate(`/faq/${faqId}`);
    setSearchQuery("");  // Clear search query
    setSearchResults([]); // Clear search results
  };

  const itemTemplate = (item) => {
    return <div>{item.question}</div>; // Render the question in the dropdown
  };

  return (
    <div>
      <MetaTags />
      <LandingHeader />

      <section className="section-py first-section-pt help-center-header position-relative overflow-hidden">
  <img
    className="banner-bg-img position-absolute top-0 start-0 w-100 h-100"
    src={img}
    alt="Help center header"
    style={{ objectFit: "cover", zIndex: -1 }}
  />

  <div className="text-center my-5 mt-12 position-relative z-1">
    <h4 className="text-primary mb-3 mt-5">Hello, how can we help?</h4>

    {/* Wrapping the AutoComplete in a centered container */}
    <div style={{ width: '50%', margin: '0 auto', textAlign: 'center' }}>
      <div className="card p-fluid">
        <AutoComplete
          value={searchQuery}
          suggestions={searchResults}
          completeMethod={handleSearchChange}
          field="question"
          onChange={(e) => setSearchQuery(e.value)}
          onSelect={(e) => handleSelect(e.value.faq_id)}
          itemTemplate={itemTemplate}
          placeholder="Search"
          style={{ width: '100%' }} // Ensures the input is full-width within its container
          panelStyle={{ width: '100%' }} // Ensures dropdown matches the input width
        />
      </div>
    </div>

    <p className="text-center px-4 mb-0 mt-2 text-muted">
      Common troubleshooting topics: eCommerce, Blogging to payment
    </p>
  </div>
</section>


      <HelpFAQ />
      <LandingFooter />
    </div>
  );
};

export default HelpCenter;

import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

import StudentHeader from "./StudentHeader";
import Footer from "../../../component/Footer"
import SubHeaderS from "./SubHeaderS";
import { Link, useNavigate, useParams } from "react-router-dom";

import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Tooltip } from "primereact/tooltip";
import axios from "axios";
import { Toast } from "primereact/toast";
import config from "../../../component/config";

const StudentOrderDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useRef(null);
  const [backClicked, setBackClicked] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const wsRef = useRef(null);
  
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const userId = localStorage.getItem("userId");

  const fetchOrderDetails = async () => {
    const authToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
    if (!authToken || !userId) {
      navigate("/login");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${config.apiDomain}/api/common/order_details`,
        {
          user_id: userId,
          uniqueorderid: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data.st === 1 && response.data.data) {
        const orderDetails = response.data.data;
        setData([orderDetails]); 
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      console.clear(); 
     
      if (error.response && error.response.status === 401) {
       
        navigate("/login"); 
      } else {
        const errorMsg = error.response
          ? error.response.data.msg || "Failed to fetch data"
          : error.message || "Failed to fetch data";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 2000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    const authToken = localStorage.getItem("authToken");
  
    if (!userId) {
      setError(new Error("User ID not found"));
      setLoading(false);
      return;
    }
  
    setLoading(true);
    setIsRefreshing(true);
  
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/common/order_details`,
        { user_id: userId, uniqueorderid: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      const { st, msg, data: orderDetails, broker } = response.data;
  
      // Handle success case (`st === 1`)
      if (st === 1 && orderDetails) {
        setData(orderDetails); // Set the single order details object directly
        const symbolTokens = [orderDetails.symboltoken]; // Wrap symbol token in an array
  
        if (symbolTokens.length > 0) {
          connectWebSocket(symbolTokens, broker);
        } else {
          console.log("No tokens found for WebSocket connection");
        }
  
      } else {
        // Handle other `st` values for warnings and errors
        switch (st) {
          case 2:
            setError(new Error(msg || "Warning"));
            window.showToast("warning", msg || "Warning: Please check the details.");
            break;
          case 3:
          case 4:
            setError(new Error(msg || "Server error occurred"));
            window.showToast("error", msg || "An error occurred. Please try again.");
            break;
          default:
            setError(new Error("Failed to fetch data"));
            window.showToast("error", msg || "Something went wrong. Contact Support.");
        }
      }
    } catch (error) {
      console.clear();
  
      if (error.response && error.response.status === 401) {
        navigate("/login"); // Redirect on 401 Unauthorized
      } else {
        const errorMsg = error.response?.data?.msg || error.message || "Something went wrong. Contact Support.";
        setError(new Error(errorMsg));
        window.showToast("error", errorMsg);
      }
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };
  const connectWebSocket = (tokens, broker) => {
    let wsUrl;
  
    // Set the WebSocket URL based on the broker
    if (broker === 'angle_one') {
      wsUrl = 'wss://ghanish.in/ws/angelone/live_market_data/';
    } else if (broker === 'dhan') {
      wsUrl = 'wss://ghanish.in/ws/dhan/live_market_data/';
    } else {
      console.log('Unsupported broker:', broker);
      return;
    }
  
    if (isMarketOpen()) {
      wsRef.current = new WebSocket(wsUrl);
  
      wsRef.current.onopen = () => {
        console.log('WebSocket connected');
        const teacherId = userId;
        const requestData = {
          teacher_id: teacherId,
          tokens: tokens, // Send symboltoken values in the request
        };
        wsRef.current.send(JSON.stringify(requestData));
      };
  
      wsRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('Live market data received:', data);
      
        if (!data || !data.data) { // Check if data exists and has the expected structure
          console.log('Received invalid data, disconnecting WebSocket');
          disconnectWebSocket();
        } else {
          updateLtp(data.data.token, data.data.ltp); // Update the LTP in the UI
        }
      };
      
  
      wsRef.current.onerror = (error) => {
        console.log('WebSocket error:', error);
      };
  
      wsRef.current.onclose = () => {
        console.log('WebSocket disconnected');
      };
    } else {
      console.log('Market is closed. WebSocket connection not established.');
    }
  };
  
  
    const disconnectWebSocket = () => {
      if (wsRef.current) {
        wsRef.current.close(); // Close the WebSocket connection
        wsRef.current = null; // Clear the reference
      }
    };
    
   
  
    const updateLtp = (token, newLtp) => {
      const normalizedToken = token.toString();
      const normalizedLtp = parseFloat(newLtp);
    
      setData((prevData) => {
        // Ensure that prevData is an object and not an array
        if (prevData.symboltoken === normalizedToken) {
          const oldLtp = parseFloat(prevData.ltp) || 0;
          const ltpDifference = normalizedLtp - oldLtp;
    
          let ltpClass = 'text-secondary';
          if (ltpDifference > 0) {
            ltpClass = 'text-success';
          } else if (ltpDifference < 0) {
            ltpClass = 'text-danger';
          }
    
          // Return updated data with the new LTP
          return {
            ...prevData,
            ltp: normalizedLtp.toFixed(2),
            ltpDifference: ltpDifference.toFixed(2),
            ltpClass,
          };
        }
        return prevData; // If the token doesn't match, return the unchanged data
      });
    };
    
    
    
    
    
    
    useEffect(() => {
      return () => {
        disconnectWebSocket(); // Disconnect when component unmounts
      };
    }, []);

    const isMarketOpen = () => {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinute = currentTime.getMinutes();
      const currentDay = currentTime.getDay();
    
      const marketOpenHour = 9;
      const marketOpenMinute = 15;
      const marketCloseHour = 15;
      const marketCloseMinute = 30;
    
      // If it's Sunday (0) or Saturday (6), the market is closed
      if (currentDay === 0 || currentDay === 6) {
        return false;
      }
    
      // Check if the current time is within market hours (9:15 AM to 3:30 PM)
      if (
        (currentHour > marketOpenHour ||
          (currentHour === marketOpenHour && currentMinute >= marketOpenMinute)) &&
        (currentHour < marketCloseHour ||
          (currentHour === marketCloseHour && currentMinute <= marketCloseMinute))
      ) {
        return true;
      } else {
        return false;
      }
    };

  useEffect(() => {
    fetchOrderDetails();
  }, [id]); 

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  const renderTransactionType = (rowData) => {
    const type = rowData.transactiontype || rowData.transactionType; // Check both fields
    const style = {
      color: type === "BUY" ? "green" : "orange",
    };
    return <span style={style}>{type}</span>;
  };
  
  const formatExpiryDate = (dateString) => {
    // Month mapping
    const monthMap = {
      JAN: 'Jan',
      FEB: 'Feb',
      MAR: 'Mar',
      APR: 'Apr',
      MAY: 'May',
      JUN: 'Jun',
      JUL: 'Jul',
      AUG: 'Aug',
      SEP: 'Sep',
      OCT: 'Oct',
      NOV: 'Nov',
      DEC: 'Dec',
    };
  
    // Ensure dateString is a string
    if (typeof dateString !== 'string') return dateString;
  
    
  
    // Extract parts of the date
    const day = dateString.slice(8, 10); // Assuming format "YYYY-MM-DD"
    const month = dateString.slice(5, 7); // Getting month as MM
    const year = dateString.slice(0, 4); // Getting year as YYYY
  
    // Convert month from MM to month abbreviation
    const monthAbbr = new Date(`${year}-${month}-01`).toLocaleString('default', { month: 'short' }).toUpperCase();
  
    // Return formatted date if valid
    if (day && monthAbbr && year) {
      return `${day} ${monthMap[monthAbbr]} ${year}`;
    }
  
    // Optionally, log a warning for unexpected format
    console.warn(`Invalid date format: ${dateString}`);
    return dateString; // Return the original string if formatting fails
  };
  
  // Render function to get the formatted expiry date
  const renderExpiryDate = (rowData) => {
    const expiryDate = rowData.expirydate ;
    return formatExpiryDate(expiryDate);
  };
  const renderOrderStatus = (rowData) => {
    const status = rowData?.orderstatus || "";     const style = {
      color: status && status.toLowerCase() === "complete" ? "green" : "orange",
    };
  
    const formattedStatus =
      status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  
    return <span style={style}>{formattedStatus}</span>;
  };
  
  return (
    <>
      <Toast ref={toast} />
      <StudentHeader />
      <SubHeaderS />

      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/student/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/app2/order_book" className="text-black">
                Order Book
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary" aria-current="page">
              Order Book Details
            </li>
          </ol>
        </nav>

        <div className="card p-5">
          <div className="d-flex justify-content-between align-items-center mb-5">
            <button onClick={handleBack} className="btn rounded-pill btn-outline-secondary btn-xs">
              <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
            </button>

            <h5 className="mb-0 mx-auto">Order Book Details</h5>
            <div></div>
          </div>
          <div className="d-flex justify-content-start mb-3">
          
            <IconField iconPosition="left">
              <InputIcon className="ri ri-search-line"></InputIcon>
              <InputText
                type="search"
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="rounded"
              />
            </IconField>
            {loading ? (
            <div className="d-flex align-items-center">
            <i
              className={`ri-refresh-line ri-lg ms-3 p-text-secondary ${
                isRefreshing ? "rotate" : ""
              }`}
              role="status"
            ></i>
          </div>
        ) : (
          <div className="mt-3">
            <Tooltip target=".custom-target-icon" />
            <i
              className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
              role="button"
              data-pr-tooltip="Reload"
              onClick={handleRefresh}
              data-pr-position="top"
            ></i>
          </div> 
          )}
          </div>
          <DataTable
            className="custom-column-border text-center "
            value={data ? [data] : []} 
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
          
          >
                          {data.ltp && ( // Conditionally render the LTP column
    <Column
      align="center"
      className="custom-column-border"
      field="ltp"
      header="LTP"
      body={(rowData) => (
        <span className={rowData.ltpClass}>
          {rowData.ltp ? rowData.ltp : ''}
        </span>
      )}
    ></Column>
  )}
            <Column
              align="center"
              className="custom-column-border"
              field="ordertype"
            

              header="Order Type"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="producttype"
             

              header="Product Type"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="quantity"
              header="Quantity"
            ></Column>

           
            <Column
              align="center"
              className="custom-column-border"
              field="tradingsymbol"

              header="Symbol"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="transactiontype"
              header="Transaction Type"
              body={renderTransactionType}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="exchange"

              header="Exchange"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="instrumenttype"
              header="Instrument Type"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="strikeprice"
              header="Strike Price"

            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="optiontype"
              header="Option Type"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="expirydate"
              header="Expiry Date"
              body={renderExpiryDate}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="orderstatus"
              header="Order Status"
              body={renderOrderStatus}
            ></Column>
          </DataTable>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default StudentOrderDetails;

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import Footer from "../../../component/Footer";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import config from "../../../component/config";
import { Tooltip } from "primereact/tooltip";
import CardLinks from "../../../component/CardLinks";
import { Toast } from "primereact/toast";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
const StudentReport = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [backClicked, setBackClicked] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const authToken = localStorage.getItem("authToken");
  const [isRefreshing, setIsRefreshing] = useState(true);
  const toast = useRef(null);
  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");

      if (!authToken || !userId) {
        navigate("/login");
        return;
      }
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/month_wise_list`,
        { teacher_id: userId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data && response.data.st === 1) {
        setData(response.data.data);
      } else {
        setError(new Error(response.data.message || "No data found"));
      }
    } catch (error) {
      console.clear(); 
      if (error.response && error.response.status === 401) {
       
        navigate("/login"); 
      } else {
        setError(new Error(error.message || "Failed to fetch data"));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success";
    } else if (hours === 15) {
      return "danger";
    }
    return "secondary";
  };

  const getColorModalClass = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9 || hours === 15) {
      return hours === 9 ? "modal-green" : "modal-red";
    }
    return "";
  };

  const getModalTitle = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "Market is Open!";
    } else if (hours === 15) {
      return "Market is Closed!";
    }
    return "";
  };

  const getModalBody = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "Market is currently open. Take necessary actions.";
    } else if (hours === 15) {
      return "Market is currently closed. Come back tomorrow.";
    }
    return "";
  };

  const handleRefresh = async () => {
    setLoading(true);
    setError(null);
    setIsRefreshing(true);
  
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/month_wise_list`,
        { teacher_id: userId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      const { st, message, data } = response.data;
  
      if (st === 1) {
        setData(data);
        // window.showToast("success", message || "Data fetched successfully");
      } else {
        // Handle different `st` values with custom toasts for error, warning, or info
        const errorMessage = message || "No data found";
        setError(new Error(errorMessage));
  
        switch (st) {
          case 2:
            window.showToast("danger", errorMessage || "Critical error fetching data.");
            break;
          case 3:
            window.showToast("warning", errorMessage || "Warning: No data available.");
            break;
          case 4:
            window.showToast("info", errorMessage || "Info: Additional information provided.");
            break;
          default:
            window.showToast("error", "Something went wrong. Contact Support.");
        }
      }
    } catch (error) {
      console.clear();
      if (error.response && error.response.status === 401) {
        navigate("/login");
      } else {
        const errorMessage = error.response?.data?.message || error.message || "Something went wrong. Contact Support.";
        setError(new Error(errorMessage));
        window.showToast("error", errorMessage);
      }
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };
  
  
  return (
    <>
      <Toast ref={toast} />
      <Header />
      <SubHeader />
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>

            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Student Report
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col-3 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 text-center mb-5">
              <h5 className="mb-0">Student Report</h5>
            </div>
            <div className="col-3"></div>
          </div>
          <div className="d-flex justify-content-start mb-3">
            <InputText
              type="search"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
              className="rounded"
            />
            {loading ? (
              <div className="d-flex align-items-center">
                <i
                  className={`ri-refresh-line ri-lg ms-3 p-text-secondary ${
                    isRefreshing ? "rotate" : ""
                  }`}
                  role="status"
                ></i>
              </div>
            ) : (
              <div className="mt-3">
                <Tooltip target=".custom-target-icon" />
                <i
                  className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                  role="button"
                  data-pr-tooltip="Reload"
                  onClick={handleRefresh}
                  data-pr-position="top"
                ></i>
              </div>
            )}
          </div>
          <DataTable
            value={data}
            paginator
            rows={20}
            loading={loading}
            className="custom-column-border"
            globalFilter={globalFilter}
          >
            <Column
              field="month_name"
              header="Month"
              className="custom-column-border"
            />
            <Column
              field="trades_count"
              header="Total Trades"
              className="custom-column-border"
            />
            <Column
              field="profitable_trades"
              header="Profitable Trades"
              className="custom-column-border"
            />
            <Column
              field="losing_trades"
              header="Losing Trades"
              className="custom-column-border"
            />
            <Column
              field="commission"
              header="Commission"
              className="custom-column-border"
              body={(rowData) => <>{rowData.commission} Rs.</>}
            />
            <Column
              header="Actions"
              className="custom-column-border"
              body={(rowData) => (
                <Link
                  to={`/teacher/student_report_list/${userId}/${rowData.month_name}`}
                >
                  <Button
                    className="btn btn-info  custom-btn-action1 custom-target-icon"
                    data-pr-tooltip="View Record"
                    data-pr-position="top"
                  >
                    <i className="ri-gallery-view-2"></i>
                  </Button>{" "}
                </Link>
              )}
            />
          </DataTable>
        </div>
        <CardLinks />
      </div>
      <Footer />
    </>
  );
};

export default StudentReport;

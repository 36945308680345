
const config = {
    apiDomain: 'https://ghanish.in',
  };
  
  export default config;






  
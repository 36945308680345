// src/context/NetworkStatusContext.js
import React, { createContext } from 'react';
import useNetworkStatus from '../hooks/useNetworkStatus';

export const NetworkStatusContext = createContext();

export const NetworkStatusProvider = ({ children }) => {
  const isOnline = useNetworkStatus();

  return (
    <NetworkStatusContext.Provider value={isOnline}>
      {children}
    </NetworkStatusContext.Provider>
  );
};

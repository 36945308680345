import React,{useEffect} from "react";
import LandingFooter from "./LandingFooter";
import LandingHeader from "./LandingHeader";
import logo from "../../../assets/img/products/logo (2) (1).png";
import group from "../../../assets/img/products/11-removebg-preview.png"
import {Link} from "react-router-dom"
function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>

      <LandingHeader />
      <div className="container-xxl mt-10 pt-10  bg-white">
      
      
      <section className="comp-section shortcode-section text-left fluid-height mt-10 mb-5">
      <div className="container-xxl">
        <div className="row shortcode-row justify-content-between align-items-center">
          {/* Left Column (Text Section) */}
          <div className="col-12 col-md-6 mb-4 mb-md-0" >
          <h4 className="text-start  mb-2"><span className=" fw-semibold badge rounded-pill bg-label-primary"> <i className="ri-emoji-sticker-line me-3"></i>Pricing Plan</span> </h4>

            <small className="comp-pre-title overline-14 sd-omit">About Us</small>
            <h2 className="comp-title shortcode-title heading-48 fw-bold">
              Helping businesses deliver
              <span className="green-doodle"> exceptional</span> buyer experiences.
            </h2>
            <p>
              Vidyard is the leading video messaging and asynchronous communications platform for go-to-market teams. Millions of sales professionals and more than 250,000 go-to-market teams use Vidyard’s AI-powered video messaging, video hosting, and digital sales rooms to connect with more prospects and generate more revenue.
            </p>
            <Link
              to="/signup"
              className="btn btn-primary shortcode-sign-up-button btn-indigo-400 sign-up sd-omit mb-4"
            >
              Sign Up for Free
            </Link>
          </div>

          {/* Right Column (Image Section) */}
          <div className="col-12 col-md-6 d-flex justify-content-center" >
            <div className="comp-resp-img wrapper shortcode-resp-image" style={{ position: 'relative', maxWidth: '500px' }}>
              <picture>
                <img
                  decoding="async"
                  loading="lazy"
                  src={group}
                  alt="About Us"
                  className="comp-resp-img img-fluid"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </section>


    
      </div>

     
      <section className="comp-section shortcode-section text-left fluid-height py-5 ">
        <div className="container-xxl py-5">
          <div className="row">
            <div className="col-12 col-md-6">
              <h2 className="comp-title shortcode-title heading-48 fw-bold">
                Built for go-to-market teams, powered by AI
              </h2>
            </div>
            <div className="col-12 col-md-6">
              <p>
                We believe in the power of video to foster human connections and empower go-to-market professionals to deliver trusted and productive buyer experiences.
              </p>
              <p>
                We also believe in the power of AI to help sales and marketing teams unlock their creativity and connect with more prospects than ever before.
              </p>
              <p>
                We’re committed to helping our customers grow their revenue faster by unleashing the potential of video and AI.
              </p>
            </div>
          </div>


          <div className="row text-center mt-6">
            <div className="col-12 col-md-4">
            <div className="border p-4 bg-white">
              <h1 className="fw-bold">300+</h1>
              <p>Since 2010, Vidyard has grown from a team of 2 to over 300.</p>
              </div>
            </div>
            <div className="col-12 col-md-4">
            <div className="border p-4 bg-white">
              <h1 className="fw-bold">12 million+</h1>
              <p>Over 12 million people use Vidyard to create and share </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
            <div className="border p-4 bg-white">
              <h1 className="fw-bold">160,000</h1>
              <p>160,000 companies choose Vidyard.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
   
   
      <LandingFooter />
    </>
  );
}

export default About;

import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import AdminHeader from "./AdminHeader";
import AdminSubHeader from "./AdminSubHeader";
import Footer from "../../../component/Footer";

import { Toast } from "primereact/toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import config from "../../../component/config";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";

// Add these styles to your CSS file or as a styled-component
const editorStyles = `
  .quill-editor-container {
    display: flex;
    flex-direction: column;
    min-height: 300px;
  }

  .quill-editor {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .ql-container {
    flex: 1;
    min-height: 200px;
    font-family: inherit !important;
    font-size: 14px !important;
  }

  .ql-editor {
    min-height: 200px !important;
    max-height: 500px;
    overflow-y: auto !important;
  }

  .ql-toolbar {
    border: 1px solid #ced4da !important;
    border-radius: 4px 4px 0 0 !important;
    background: #fff;
  }

  .ql-container {
    border: 1px solid #ced4da !important;
    border-top: none !important;
    border-radius: 0 0 4px 4px !important;
  }

  .ql-editor p {
    margin-bottom: 1em;
  }

  /* Error state styling */
  .is-invalid .ql-toolbar,
  .is-invalid .ql-container {
    border-color: #dc3545 !important;
  }
`;

const CreateFaq = () => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [backClicked, setBackClicked] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [categories, setCategories] = useState([]);
  const [faqCategories, setFaqCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [newCategoryName, setNewCategoryName] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const authToken = localStorage.getItem("authToken");

  const [errors, setErrors] = useState({
    category: "",
    question: "",
    answer: "",
  });
  useEffect(() => {
    fetchFaqCategories();
  }, []);

  const fetchFaqCategories = async () => {
    setLoading(true);
    const authToken = localStorage.getItem("authToken");

    try {
      const response = await axios.get(
        "https://ghanish.in/api/admin/list_view_faq_category",
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.st === 1) {
        setFaqCategories(response.data.data);
      } else if (response.data.st === 401) {
        navigate("/login"); // Redirect to common login on 401 status
      } else {
       
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/login"); // Redirect to common login on 401 status
      } else {
        
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (faq_category_id) => {
    const authToken = localStorage.getItem("authToken");
  
    try {
      const response = await axios.delete(
        "https://ghanish.in/api/admin/delete_faq_category",
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          data: {
            faq_category_id: faq_category_id,  // This should be in the data key of the config object
          },
        }
      );
  
      if (response.data.st === 1) {
        
        fetchFaqCategories(); // Refresh the categories list after deletion
      } else if (response.data.st === 401) {
        navigate("/login"); // Redirect to common login on 401 status
      } else {
        
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/login"); // Redirect to common login on 401 status
      } else {
       
      }
    }
  };
  

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${config.apiDomain}/api/admin/get_faq_category`, {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        });
        if (response.data.st === 1) {
          setCategories(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [authToken]);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    if (e.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, category: "" }));
    }
  };

  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
    if (e.target.value.trim()) {
      setErrors((prevErrors) => ({ ...prevErrors, question: "" }));
    }
  };

  const handleAnswerChange = (value) => {
    setAnswer(value);
    if (value.trim()) {
      setErrors((prevErrors) => ({ ...prevErrors, answer: "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Reset errors
    setErrors({ category: "", question: "", answer: "" });

    let hasError = false;
    const newErrors = {};

    if (!selectedCategory) {
      newErrors.category = "Please select a category";
      hasError = true;
    }

    if (!question.trim()) {
      newErrors.question = "Please enter a question";
      hasError = true;
    } else if (question.trim().length < 5) {
      newErrors.question = "Question must be at least 5 characters long";
      hasError = true;
    }

    const plainAnswer = stripHtmlTags(answer);
    if (!plainAnswer.trim()) {
      newErrors.answer = "Please enter an answer";
      hasError = true;
    } else if (plainAnswer.trim().length < 5) {
      newErrors.answer = "Answer must be at least 5 characters long";
      hasError = true;
    }

    if (hasError) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }

    const data = {
      faq_category_id: selectedCategory,
      question,
      answer,
    };

    const authToken = localStorage.getItem("authToken");

    try {
      const response = await axios.post(
        "https://ghanish.in/api/admin/create_faq",
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
    
      setLoading(false);
    
      // Assuming `st` values are returned in response for different statuses
      const { st, msg } = response.data;
    
      switch (st) {
        case 1:
          window.showToast("success", msg || "FAQ created successfully");
          setTimeout(() => {
            navigate("/admin/manage_faq");
          }, 1000);
          break;
        case 2:
          window.showToast("danger", msg || "An error occurred while creating FAQ.");
          break;
        case 3:
          window.showToast("warning", msg || "Warning: Check the FAQ details.");
          break;
        case 4:
          window.showToast("info", msg || "Information: FAQ created with additional notes.");
          break;
        default:
          window.showToast("error", "Something went wrong. Contact Support.");
      }
    } catch (error) {
      setLoading(false);
    
      if (error.response && error.response.status === 401) {
        navigate("/login");
      } else {
        console.error("Error creating FAQ:", error);
        const errorMsg = error.response?.data?.msg || "Something went wrong. Contact Support.";
        window.showToast("error", errorMsg);
      }
    }
  };    
  const actionTemplate = (rowData) => {
    return (
      <button
        className="btn btn-danger active text-align custom-btn-action1"
        onClick={() => handleDelete(rowData.faq_category_id)}
      >
        <i className="ri-delete-bin-line ri-lg"></i>
      </button>
    );
  };

  const handleCreateCategory = async () => {
    if (!newCategoryName.trim()) {
        setCategoryError("Please enter a category name");
        return;
    }

    setLoading(true);
    setCategoryError("");

    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
       if (!authToken || !userId) {
      navigate("/login");
        return;
      }
        const response = await axios.post(
            "https://ghanish.in/api/admin/create_faq_category",
            { category_name: newCategoryName },
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            }
        );

        if (response.data.st === 1) {
            toast.current.show({
                severity: "success",
                summary: "Success",
                detail: "FAQ category created successfully",
                life: 3000,
            });
            setNewCategoryName("");
            fetchFaqCategories(); // Refresh the categories list

            // Close the Create Category modal
            document.getElementById('createCategoryModal').classList.remove('show');
            document.getElementById('createCategoryModal').style.display = 'none';

            // Reopen the Manage FAQ Categories modal
            document.getElementById('addNewCCModal').classList.add('show');
            document.getElementById('addNewCCModal').style.display = 'block';
        } else {
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Failed to create category",
                life: 2000,
            });
        }
    } catch (error) {
        console.error("Error creating category:", error);
        toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "An error occurred while creating the category",
            life: 3000,
        });
    } finally {
        setLoading(false);
    }
};
const stripHtmlTags = (html) => {
  const tmp = document.createElement("div");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

  // Define Quill modules and formats
  const quillModules = {
    toolbar: {
      container: [
        [{ font: [] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ header: '1' }, { header: '2' }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean']
      ]
    },
    clipboard: {
      matchVisual: false
    }
  };

  const quillFormats = [
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'color',
    'background',
    'script',
    'header',
    'blockquote',
    'code-block',
    'list',
    'bullet',
    'link',
    'image'
  ];

  return (
    <div>
      <AdminHeader />
      <AdminSubHeader />
      <div className="container-xxl flex-grow-1 container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
        <ol className="breadcrumb breadcrumb-style1 text-black mb-3">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/manage_faq" className="text-black">
                 FAQ
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Create FAQ
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col-5 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 text-start mb-5">
              <h5 className="mb-0">Create FAQ</h5>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            
          <div className="row mb-3">
  {/* Label Section */}
  <div className="col-6 col-md-3 col-lg-3 text-start">
    <label htmlFor="category_name" className="form-label text-capitalize">
      <span className="text-danger">*</span>Category Name
    </label>
  </div>

  {/* Manage FAQ Link */}
  <div className="col-6 col-lg-3 col-md-3 text-end">
    <div
      className="text-primary"
      data-bs-toggle="modal"
      data-bs-target="#addNewCCModal"
    >
      FAQ category
    </div>
  </div>
</div>

<div className="row mb-3">
  {/* Select Input Section */}
  <div className="col-12 col-md-6 col-lg-6">
  <div class="form-floating form-floating-outline mb-6">
    <select
      id="category_name"
      value={selectedCategory}
      onChange={handleCategoryChange}
      className={`form-select text-capitalize ${
        errors.category ? "is-invalid" : ""
      }`}
    >
      <option value="">Select a category</option>
      {categories.map((category) => (
        <option
          key={category.faq_category_id}
          value={category.faq_category_id}
          className="text-capitalize"
        >
          {category.category_name}
        </option>
      ))}
    </select>
    {errors.category && (
      <small className="text-danger">{errors.category}</small>
    )}
  </div>
  </div>
</div>

            
            <div className="mb-3">
              <div className="form-floating form-floating-outline">
                <input
                  type="text"
                  className={`form-control ${
                    errors.question ? "is-invalid" : ""
                  }`}
                  id="question"
                  value={question}
                  onChange={handleQuestionChange}
                  placeholder="Question"
                />
                <label htmlFor="question">
                  <span className="text-danger">*</span> Question
                </label>
                {errors.question && (
                  <small className="text-danger">{errors.question}</small>
                )}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="answer" className="form-label">
                <span className="text-danger">*</span> Answer
              </label>
              <div className={`quill-editor-container ${errors.answer ? 'is-invalid' : ''}`}>
                <ReactQuill
                  theme="snow"
                  value={answer}
                  onChange={handleAnswerChange}
                  modules={quillModules}
                  formats={quillFormats}
                  className="quill-editor"
                  placeholder="Write the answer here..."
                />
              </div>
              {errors.answer && (
                <small className="text-danger">{errors.answer}</small>
              )}
            </div>

            <div className=" text-end mb-5 mt-6">
              <button
                type="submit"
                className="btn rounded-pill btn-success btn-sm"
                disabled={loading}
              >
                {loading ? (
                <div className="spinner-border me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
                ) : (
                  <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                )}{" "}
                {loading ? "Saving Data" : "Save Data"}
              </button>
            </div>
          </form>
        </div>
        <CardLinks></CardLinks>
      </div>
      <div
  className="modal fade"
  id="addNewCCModal"
  tabIndex="-1"
  aria-hidden="true"
>
  <div className="modal-dialog modal-dialog-centered modal-simple modal-add-new-cc">
    <div className="modal-content">
    <div className="modal-body p-0">
    <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>

        <div className="row align-items-center">
          <div className="col-5 text-start">
            <h5 className="mb-0">FAQ Categories</h5>
          </div>
          <div className="col-7 text-end">
            <button
              className="btn btn-success"
              data-bs-toggle="modal"
              data-bs-target="#createCategoryModal"
              onClick={() => {
                // Hide the Manage FAQ Categories modal before showing the Create Category modal
                document.getElementById('addNewCCModal').classList.remove('show');
                document.getElementById('addNewCCModal').style.display = 'none';
              }}
            >
              <i className="ri-add-circle-line ri-lg me-1"></i>
              <span>Create</span>
            </button>
          </div>
        </div>

        <div className="mt-4" style={{ maxHeight: "400px", overflowY: "auto" }}>
          <DataTable
            value={faqCategories}
            loading={loading}
            responsiveLayout="scroll"

          >
            <Column
              field="category_name"
              header="Category Name"
              align="left"
              className="border border-1 text-capitalize"
            ></Column>
            <Column
              header="Actions"
              body={actionTemplate}
              align="center"
              className="border border-1"
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  className="modal fade"
  id="createCategoryModal"
  tabIndex="-1"
  aria-labelledby="createCategoryModalLabel"
  aria-hidden="true"
  // Prevent closing the modal by clicking outside
>
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="createCategoryModalLabel">
          Create FAQ Category
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            // When closing Create Category Modal, reopen the Manage FAQ Categories modal
            document.getElementById('addNewCCModal').classList.add('show');
            document.getElementById('addNewCCModal').style.display = 'block';
          }}
        ></button>
      </div>
      <div className="modal-body">
        <div className="form-group">
          <label htmlFor="newCategoryName">Category Name</label>
          <input
            type="text"
            className="form-control"
            id="newCategoryName"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
          />
          {categoryError && (
            <div className="error text-danger">{categoryError}</div>
          )}
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-success"
          onClick={handleCreateCategory}
        >
          {loading ? "Saving..." : "Create Category"}
        </button>
      </div>
    </div>
  </div>
</div>

      <Toast ref={toast} position="top-right" />
      <Footer />
      <style>{editorStyles}</style>
    </div>
  );
};

export default CreateFaq;




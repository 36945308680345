// // src/assets/custom-toast/toast.js

// function showToast(type, message) {
//     console.log("showToast triggered with type:", type, "and message:", message);
  
//     let toast = document.getElementById("toast");
  
//     if (!toast) {
//       toast = document.createElement("div");
//       toast.id = "toast";
//       toast.className = "toast";
//       document.body.appendChild(toast);
//       console.log("Toast element created and added to DOM");
//     }
  
//     // Set content and classes
//     toast.className = `toast ${type}`; // Assign type class for styling
//     toast.innerHTML = `<div class="toast-content">
//                          <span class="toast-message">${message}</span>
//                        </div>`;
  
//     // Show the toast
//     toast.classList.add("show");
//     console.log("Toast should now be visible with message:", message);
  
//     // Hide the toast after 3 seconds
//     setTimeout(() => {
//       toast.classList.remove("show");
//       console.log("Toast should now be hidden");
//     }, 3000);
//   }
  
//   // Attach showToast to window
//   window.showToast = showToast;
//   console.log("showToast function is now attached to window.");
  


function showToast(type, message) {
  let toast = document.getElementById("toast");

  if (!toast) {
    toast = document.createElement("div");
    toast.id = "toast";
    toast.className = "toast";
    document.body.appendChild(toast);
  }

  // Set icon based on the type of toast
  let iconClass;
  let title;
  switch (type) {
    case "success":
      iconClass = "ri-check-line";
      title = "Success";
      break;
    case "error":
      iconClass = "ri-close-circle-line";
      title = "Error";
      break;
    case "info":
      iconClass = "ri-information-line";
      title = "Info";
      break;
    case "warning":
      iconClass = "ri-alert-line";
      title = "Warning";
      break;
    default:
      iconClass = "";
      title = "Notification";
  }

  // Set content and classes
  toast.className = `toast ${type}`;
  toast.innerHTML = `
    <div class="toast-content">
      <i class="toast-icon ${iconClass}"></i>
      <div class="message">
        <span class="text-title">${title}</span>
        <span class="text-body">${message}</span>
      </div>
    </div>
     <i class="close ri-close-line" onclick="window.hideToast()"></i>

    <div class="progress-bar"></div>`;

  // Show the toast
  toast.classList.add("show");

  // Hide the toast after 3 seconds
  setTimeout(() => {
    toast.classList.remove("show");
  }, 3000);
}

function hideToast() {
  const toast = document.getElementById("toast");
  if (toast) {
    toast.classList.remove("show");
  }
}

// Attach showToast and hideToast to window
window.showToast = showToast;
window.hideToast = hideToast; // <-- Add this line to make hideToast global

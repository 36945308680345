

import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import autoprofito_logo from "../../../assets/img/products/company/autoprofito_logo.png";
//  import "../../../assets/vendor/css/rtl/theme.css"; 
// import "../../../assets/vendor/css/rtl/core.css";
const AdminHeader = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [dropdownOpen, setDropdownOpen] = useState(false);
const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const userRole = localStorage.getItem("userRole");
  const userTradeBook = JSON.parse(localStorage.getItem("userTradeBook"));
  const userOrderBook = JSON.parse(localStorage.getItem("userOrderBook"));
  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => setDropdownOpen(false);

  const formatTime = (date) => {
    const optionsDate = { weekday: "long", day: "numeric" };
    const optionsTime = { hour: "2-digit", minute: "2-digit", hour12: true };

    const day = date.toLocaleDateString("en-US", { day: "numeric" });
    const monthAbbreviations = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthAbbreviations[date.getMonth()];
    const weekday = date.toLocaleDateString("en-US", { weekday: "long" });

    const formattedDate = `${weekday}, ${day} ${month}`;
    const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

    return { formattedDate, formattedTime };
  };

  const { formattedDate, formattedTime } = formatTime(currentTime);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.clear();
    navigate("/login");
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleConfirmLogout = () => {
    handleLogout();
    handleCloseModal();
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


let themeSwitched = false; 

const applyTheme = async (newTheme = null) => {
  const rootElement = document.documentElement;
  const currentTheme = localStorage.getItem("theme") || "light"; 


  if (newTheme && currentTheme !== newTheme) {
    localStorage.setItem("theme", newTheme); 
    themeSwitched = true; 
  }

  const finalTheme = newTheme || currentTheme; 
  if (finalTheme === "dark") {
    rootElement.classList.add("dark-mode");
    rootElement.classList.remove("light-mode");
    await import("../../../assets/vendor/css/rtl/core-dark.css");
    await import("../../../assets/vendor/css/rtl/theme-dark.css");
  } else {
    rootElement.classList.add("light-mode");
    rootElement.classList.remove("dark-mode");
    await import("../../../assets/vendor/css/rtl/core.css");
    await import("../../../assets/vendor/css/rtl/theme.css");
  }

 
  if (themeSwitched) {
    window.location.reload();
  }
};

applyTheme();









const toggleTheme = () => {
  const newTheme = theme === "light" ? "dark" : "light";
  applyTheme(newTheme);
  setTheme(newTheme);
};

useEffect(() => {
  applyTheme(theme);
}, [theme]);
  return (
    <div>
      <nav
        className="layout-navbar navbar navbar-expand-xl align-items-center border border-bottom"
        id="layout-navbar"
      >
        <div className="container-xxl d-flex justify-content-between align-items-center">
          <div className="navbar-brand app-brand demo d-none d-xl-flex py-0 me-6">
            <div className="app-brand-logo demo">
              <img
                src={autoprofito_logo}
                alt="AutoProfito Logo"
                className="w-px-40 h-auto rounded-circle"
              />
            </div>
            <span className="app-brand-text demo menu-text fw-semibold ms-1">
              AutoProfito
            </span>
          </div>

          <div className="layout-menu-toggle navbar-nav align-items-xl-center me-4 me-xl-0 d-xl-none">
            <button
              className="nav-item nav-link px-0 me-xl-6 btn text-secondary"
              onClick={toggleSidebar}
            >
              <i className="ri-menu-line ri-xl"></i>
            </button>
          </div>

          <div className=" d-xl-block">
            <span className="text-secondary">{formattedDate}</span>
            <br />
            <strong className="fs-4">{formattedTime}</strong>
          </div>

          <div className="d-flex align-items-center">
            <div className="text-end me-3 me-sm-2 d-none  d-xl-block me-md-3">
              <h6 className="mb-1 fs-5 semi-bold text-uppercase">
                <strong>{toTitleCase(userName)}</strong>
              </h6>
              <Link to="/admin/user_profile">
                <span className="btn btn-outline-secondary rounded-pill btn-xs">
                  {toTitleCase(userRole)}
                </span>
              </Link>
            </div>
            <div className="align-items-xl-center me-4 me-xl-0">
  <ul className="navbar-nav flex-row align-items-center ms-auto">
    <li className="nav-item navbar-dropdown dropdown-user dropdown" ref={dropdownRef}>
      <div className="nav-link dropdown-toggle hide-arrow p-0" onClick={toggleDropdown}>
        <div className="w-px-40 h-auto rounded-circle position-relative">
          <div className="d-flex justify-content-center align-items-center border border-success border-3 rounded-circle bg-label-success avatar-lg">
            <span className="avatar-initial rounded-circle bg-label-success fs-2">
              {userName && userName.charAt(0).toUpperCase()}
            </span>
          </div>
        </div>
      </div>
      {dropdownOpen && (
        <ul
          className="dropdown-menu dropdown-menu-end mt-3 py-2 show me-3 dropdown-menu-mobile"
          style={{ right: "0", left: "auto" ,width:"300px"}}
        >
                           <li>
  <div className="d-flex justify-content-start align-items-center ms-4">
    {/* Avatar */}
    <div className="d-flex justify-content-center align-items-center border border-success border-3 rounded-circle bg-label-success avatar-lg me-3">
      <span className="avatar-initial rounded-circle bg-label-success fs-2">
        {userName && userName.charAt(0).toUpperCase()}
      </span>
    </div>

    {/* User Info */}
    <div className="text-start">
      <h6 className="fs-6 mb-0 bold text-uppercase">
        {toTitleCase(userName)}
      </h6>
      <div className="position-relative d-inline-block my-2">
        <Link to="/teacher/user_profile">
          <span className="btn btn-outline-secondary rounded-pill btn-xs">
            {toTitleCase(userRole)}
          </span>
        </Link>
      </div>
    </div>
  </div>
</li>
<hr className="mb-0 mt-0"></hr>
<li className="dropdown-item">
      <div className="d-flex align-items-center justify-content-between">
        <span className="switch-label ">
          {theme === "light" ? (
            <>
               <i className="ri-sun-line ri-22px me-2"></i>Light
            </>
          ) : (
            <>
             
              <i className="ri-moon-line ri-22px me-2"></i>Dark 
            </>
          )}
        </span>

        <label className="switch switch-square me-12">
          <input
            type="checkbox"
            className="switch-input"
            checked={theme === "dark"}
            onChange={toggleTheme} // Toggle the theme when the switch is changed
          />
          <span className="switch-toggle-slider">
            <span className="switch-on"></span>
            <span className="switch-off"></span>
          </span>
        </label>
      </div>
    </li>
          <li>
            <Link to="/admin/profile" className="dropdown-item" onClick={toggleDropdown}>
              <i className="ri-user-line ri-22px me-2"></i>
              <span className="align-middle">Profile</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/my_report" className="dropdown-item" onClick={toggleDropdown}>
              <i className="ri-bar-chart-box-line ri-22px me-2"></i>
              <span className="align-middle">My Reports</span>
            </Link>
          </li>
          
          <li>
            <div className="d-grid px-4 pt-2 pb-1">
              <div onClick={handleShowModal} className="dropdown-item btn btn-danger d-flex align-items-center">
                <i className="ri-logout-box-r-line ri-lg me-1"></i>
                <span className="align-middle">Logout</span>
              </div>
            </div>
          </li>


        </ul>
      )}
    </li>
  </ul>
</div>

          </div>
        </div>
      </nav>

      <div className={`sidebar ${isSidebarOpen ? "active" : ""}`}>
        <div className="sidebar-content">
          <div className="sidebar-header d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div className="app-brand-logo demo">
                <img
                  src={autoprofito_logo}
                  alt="AutoProfito Logo"
                  className="w-px-40 h-auto rounded-circle"
                />
              </div>
              <span className="app-brand-text demo menu-text fw-semibold ms-2">
                AutoProfito
              </span>
            </div>
            <span className="close-icon text-end" onClick={toggleSidebar}>
              <i className="ri ri-close-line ri-lg ms-3"></i>
            </span>
          </div>
          <hr />

          <ul className="sidebar-nav mt-4">
  <li>
    <Link
      to="/admin/dashboard"
      onClick={() => {
        toggleSidebar();
        navigate("/admin/dashboard");
      }}
    >
      <i className="ri ri-home-7-line ri-lg me-3"></i> Home
      <i className="ri ri-arrow-right-s-line sidebar-arrow"></i>
    </Link>
  </li>
  {userTradeBook && userOrderBook && (
  <li className="text-muted">-------------- Trade --------------</li>
)}

  {userTradeBook && (
    <li>
      <Link
        to="/admin/trade_book"
        onClick={() => {
          toggleSidebar();
          navigate("/admin/trade_book");
        }}
      >
        <i className="ri ri-swap-2-line ri-lg me-3"></i> Trade Book
        <i className="ri ri-arrow-right-s-line sidebar-arrow"></i>
      </Link>
    </li>
  )}
  {userOrderBook && (
    <li>
      <Link
        to="/admin/order_book"
        onClick={() => {
          toggleSidebar();
          navigate("/admin/order_book");
        }}
      >
        <i className="ri ri-book-marked-line ri-lg me-3"></i> Order Book
        <i className="ri ri-arrow-right-s-line sidebar-arrow"></i>
      </Link>
    </li>
  )}
  <li className="text-muted">-------------- Manage --------------</li>
  <li>
    <Link
      to="/admin/manage_teacher"
      onClick={() => {
        toggleSidebar();
        navigate("/admin/manage_teacher");
      }}
    >
      <i className="ri ri-shield-user-line  ri-lg me-3"></i> Teachers
      <i className="ri ri-arrow-right-s-line sidebar-arrow"></i>
    </Link>
  </li>
  <li>
    <Link
      to="/admin/manage_faq"
      onClick={() => {
        toggleSidebar();
        navigate("/admin/manage_faq");
      }}
    >
      <i className="ri ri-question-answer-line ri-lg me-3"></i> FAQ
      <i className="ri ri-arrow-right-s-line sidebar-arrow"></i>
    </Link>
  </li>
  <li>
    <Link
      to="/admin/manage_subscription"
      onClick={() => {
        toggleSidebar();
        navigate("/admin/manage_subscription");
      }}
    >
      <i className="ri ri-medal-2-fill ri-lg me-3"></i> Subscription
      <i className="ri ri-arrow-right-s-line sidebar-arrow"></i>
    </Link>
  </li>
  <li>
    <Link
      to="/admin/feature_request"
      onClick={() => {
        toggleSidebar();
        navigate("/admin/feature_request");
      }}
    >
      <i className="ri ri-sparkling-line ri-lg me-3"></i> Feature Request
      <i className="ri ri-arrow-right-s-line sidebar-arrow"></i>
    </Link>
  </li>
  <li>
    <Link
      to="/admin/manage_telegram_subscription"
      onClick={() => {
        toggleSidebar();
        navigate("/admin/manage_telegram_subscription");
      }}
    >
      <i className="ri ri-telegram-line ri-lg me-3"></i> Telegram Subscription
      <i className="ri ri-arrow-right-s-line sidebar-arrow"></i>
    </Link>
  </li>
</ul>

        </div>
      </div>

      {isSidebarOpen && <div className="overlay" onClick={toggleSidebar}></div>}

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between w-100 ms-0 me-0">
            <button
              onClick={handleCloseModal}
              className="btn btn-outline-secondary rounded-pill btn-sm "
            >
              <i className="ri-close-large-line me-2"></i>
              <span>Cancel</span>
            </button>
            <button
              onClick={handleConfirmLogout}
              className="btn btn-danger rounded-pill btn-sm"
            >
              <i className="ri-logout-box-r-line  me-2"></i>
              Logout
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminHeader;


// import React, { useState, useEffect, useRef } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { Modal } from "react-bootstrap";
// import autoprofito_logo from "../../../assets/img/products/company/autoprofito_logo.png";

// const AdminHeader = () => {
//   const [currentTime, setCurrentTime] = useState(new Date());
//   const [dropdownOpen, setDropdownOpen] = useState(false);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//   const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
//   const [showModal, setShowModal] = useState(false);
//   const navigate = useNavigate();
//   const dropdownRef = useRef(null);
//   const userName = localStorage.getItem("userName");
//   const userRole = localStorage.getItem("userRole");

//   useEffect(() => {
//     const timerId = setInterval(() => {
//       setCurrentTime(new Date());
//     }, 1000);
//     return () => clearInterval(timerId);
//   }, []);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setDropdownOpen(false);
//       }
//     };
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => document.removeEventListener("mousedown", handleClickOutside);
//   }, []);

//   const toggleDropdown = () => {
//     setDropdownOpen(!dropdownOpen);
//   };

//   const closeDropdown = () => setDropdownOpen(false);

//   const formatTime = (date) => {
//     const optionsTime = { hour: "2-digit", minute: "2-digit", hour12: true };
//     const day = date.toLocaleDateString("en-US", { day: "numeric" });
//     const monthAbbreviations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
//     const month = monthAbbreviations[date.getMonth()];
//     const weekday = date.toLocaleDateString("en-US", { weekday: "long" });
//     const formattedDate = `${weekday}, ${day} ${month}`;
//     const formattedTime = date.toLocaleTimeString("en-US", optionsTime);
//     return { formattedDate, formattedTime };
//   };

//   const { formattedDate, formattedTime } = formatTime(currentTime);

//   const handleLogout = () => {
//     localStorage.clear();
//     navigate("/login");
//   };

//   const handleShowModal = () => setShowModal(true);
//   const handleCloseModal = () => setShowModal(false);
//   const handleConfirmLogout = () => {
//     handleLogout();
//     handleCloseModal();
//   };

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };

//   let themeSwitched = false; 

//   const applyTheme = async (newTheme = null) => {
//     const rootElement = document.documentElement;
//     const currentTheme = localStorage.getItem("theme") || "light"; 
  
  
//     if (newTheme && currentTheme !== newTheme) {
//       localStorage.setItem("theme", newTheme); 
//       themeSwitched = true; 
//     }
  
//     const finalTheme = newTheme || currentTheme; 
//     if (finalTheme === "dark") {
//       rootElement.classList.add("dark-mode");
//       rootElement.classList.remove("light-mode");
//       await import("../../../assets/vendor/css/rtl/core-dark.css");
//       await import("../../../assets/vendor/css/rtl/theme-dark.css");
//     } else {
//       rootElement.classList.add("light-mode");
//       rootElement.classList.remove("dark-mode");
//       await import("../../../assets/vendor/css/rtl/core.css");
//       await import("../../../assets/vendor/css/rtl/theme.css");
//     }
  
   
//     if (themeSwitched) {
//       window.location.reload();
//     }
//   };
  
//   applyTheme();
  

  
 

  
 
  
  
//   const toggleTheme = () => {
//     const newTheme = theme === "light" ? "dark" : "light";
//     applyTheme(newTheme);
//     setTheme(newTheme);
//   };

//   useEffect(() => {
//     applyTheme(theme);
//   }, [theme]);

//   return (
//     <div>
//       <nav className="layout-navbar navbar navbar-expand-xl align-items-center border border-bottom">
//         <div className="container-xxl d-flex justify-content-between align-items-center">
//           <div className="navbar-brand app-brand demo d-none d-xl-flex py-0 me-6">
//             <div className="app-brand-logo demo">
//               <img src={autoprofito_logo} alt="AutoProfito Logo" className="w-px-40 h-auto rounded-circle" />
//             </div>
//             <span className="app-brand-text demo menu-text fw-semibold ms-1">AutoProfito</span>
//           </div>
//           <div className="d-xl-block">
//             <span className="text-secondary">{formattedDate}</span>
//             <br />
//             <strong className="fs-4">{formattedTime}</strong>
//           </div>
//           <div className="d-flex align-items-center">
//             <div className="text-end me-3 me-sm-2 d-none  d-xl-block me-md-3">
//               <h6 className="mb-1 fs-5 semi-bold text-uppercase">
//                 <strong>{userName && userName.charAt(0).toUpperCase() + userName.slice(1)}</strong>
//               </h6>
//               <Link to="/admin/user_profile">
//                 <span className="btn btn-outline-secondary rounded-pill btn-xs">{userRole}</span>
//               </Link>
//             </div>
//             <div className="align-items-xl-center me-4 me-xl-0">
//               <ul className="navbar-nav flex-row align-items-center ms-auto">
//                 <li className="nav-item navbar-dropdown dropdown-user dropdown" ref={dropdownRef}>
//                   <div className="nav-link dropdown-toggle hide-arrow p-0" onClick={toggleDropdown}>
//                     <div className="w-px-40 h-auto rounded-circle position-relative">
//                       <div className="d-flex justify-content-center align-items-center border border-success border-3 rounded-circle bg-label-success avatar-lg">
//                         <span className="avatar-initial rounded-circle bg-label-success fs-2">
//                           {userName && userName.charAt(0).toUpperCase()}
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                   {dropdownOpen && (
//                     <ul className="dropdown-menu dropdown-menu-end mt-3 py-2 show me-3 dropdown-menu-mobile" style={{ right: "0", left: "auto", width: "300px" }}>
//       <li className="dropdown-item">
//       <div className="d-flex align-items-center justify-content-between">
//         <span className="switch-label ">
//           {theme === "light" ? (
//             <>
//                <i className="ri-sun-line ri-22px me-2"></i>Light
//             </>
//           ) : (
//             <>
             
//               <i className="ri-moon-line ri-22px me-2"></i>Dark 
//             </>
//           )}
//         </span>

//         <label className="switch switch-square me-12">
//           <input
//             type="checkbox"
//             className="switch-input"
//             checked={theme === "dark"}
//             onChange={toggleTheme} // Toggle the theme when the switch is changed
//           />
//           <span className="switch-toggle-slider">
//             <span className="switch-on"></span>
//             <span className="switch-off"></span>
//           </span>
//         </label>
//       </div>
//     </li>
//                       <li>
//                         <div className="d-grid px-4 pt-2 pb-1">
//                           <div onClick={handleShowModal} className="dropdown-item btn btn-danger d-flex align-items-center">
//                             <i className="ri-logout-box-r-line ri-lg me-1"></i>
//                             <span className="align-middle">Logout</span>
//                           </div>
//                         </div>
//                       </li>
//                     </ul>
//                   )}
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </nav>
//       {isSidebarOpen && <div className="overlay" onClick={toggleSidebar}></div>}
//       <Modal show={showModal} onHide={handleCloseModal} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Are you sure?</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>Are you sure?</Modal.Body>
//         <Modal.Footer>
//           <div className="d-flex justify-content-between w-100 ms-0 me-0">
//             <button onClick={handleCloseModal} className="btn btn-outline-secondary rounded-pill btn-sm ">
//               <i className="ri-close-large-line me-2"></i>
//               <span>Cancel</span>
//             </button>
//             <button onClick={handleConfirmLogout} className="btn btn-danger rounded-pill btn-sm">
//               <i className="ri-logout-box-r-line  me-2"></i>
//               Logout
//             </button>
//           </div>
//        </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// export default AdminHeader;

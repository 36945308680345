import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import StudentMyReport from "./src/app2/Student/StudentMyReport";
import StudentMyReportView from "./src/app2/Student/StudentMyReportView";
import StudentProfile from "./src/app2/Student/Student_profile";
import Dashboard from "./src/app2/Student/Dashboard";
import StudentOrderBook from "./src/app2/Student/StudentOrderBook";
import StudentTradeBook from "./src/app2/Student/StudentTradeBook";

import AdminDashboard from "./src/app3/Admin/AdminDashboard";
import ManageTeacher from "./src/app3/Admin/ManageTeacher";
import CreateTeacher from "./src/app3/Admin/CreateTeacher";
import UpdateTeacher from "./src/app3/Admin/UpdateTeacher";
import ViewTeacher from "./src/app3/Admin/ViewTeacher";
import AdminProfile from "./src/app3/Admin/AdminProfile";
import AdminMyReport from "./src/app3/Admin/AdminMyReport";
import AdminMyReportDetails from "./src/app3/Admin/AdminMyReportDetails";
import TeacherList from "./src/app3/Admin/TeacherList";
import TeacherReport from "./src/app3/Admin/TeacherReport";
import TeacherReportDetails from "./src/app3/Admin/TeacherReportDetails";

import Home from "../src/src/app1/screen/Home";
import Basket from "../src/src/app1/screen/Basket";

import TradeBook from "../src/src/app1/screen/Tradebook";
import OrderBook from "../src/src/app1/screen/OrderBook";

import StudentReport from "../src/src/app1/screen/StudentReport";
import MyReport from "../src/src/app1/screen/MyReport";
import MyReportView from "../src/src/app1/screen/MyReportView";
import StudReportList from "../src/src/app1/screen/StudReportList";

import TimeLine from "../src/src/app1/screen/TimeLine";
import Position from "../src/src/app1/screen/Position";
import ManageStudent from "../src/src/app1/screen/ManageStudent";
import CreateStudent from "../src/src/app1/screen/CreateStudent";
import UpdateStudent from "../src/src/app1/screen/UpdateStudent";
import StudentDetails from "../src/src/app1/screen/StudentDetails";
import Reports from "./src/app3/Admin/Reports";
import ProfileReports from "./src/app2/Student/ProfileReport";
import TeacherProfile from "./src/app1/screen/Profile";
import TeacherProfileReport from "./src/app1/screen/TeacherProfileRrport";
import StudentReportDetails from "../src/src/app1/screen/StudentDetails";
import ViewStudent from "./src/app1/screen/ViewStudent";

import OrderbookDetails from "./src/app1/screen/OrderbookDetails";
import StudentOrderDetails from "./src/app2/Student/StudentOrderDetails";
import AdminOrderBook from "./src/app3/Admin/AdminOrderBook";
import AdminOrderBookDetails from "./src/app3/Admin/AdminOrderBookDetails";
import AdminTradeBook from "./src/app3/Admin/AdminTradeBook";
import Signup from "./src/app1/screen/Signup";

import CommonLogin from "./component/CommonLogin";

import ActivityLog from "./src/app1/screen/ActivityLog";
import AdminLoginActivityLog from "./src/app3/Admin/AdminLoginActivityLog";
import LoginActivityLog from "./src/app2/Student/LoginActivityLog";

import SubscriptionTeacher from "./src/app1/screen/SubscriptionTeacher";

import Billing from "./src/app1/screen/Billing";
import ManageFaq from "./src/app3/Admin/ManageFaq";
import CreateFaq from "./src/app3/Admin/CreateFaq";
import ViewFaq from "./src/app3/Admin/ViewFaq";
import UpdateFaq from "./src/app3/Admin/UpdateFaq";

import PaymentHistory from "./src/app1/screen/PaymentHistory";

import ManageSubscription from "./src/app3/Admin/ManageSubscription";
import CreateSubscription from "./src/app3/Admin/CreateSubscription";
import UpdateSubscription from "./src/app3/Admin/UpdateSubscription";
import ViewSubscription from "./src/app3/Admin/ViewSubscription";
import FeatureRequest from "./src/app1/screen/FeatureRequest";
import FeatureRequestStudent from "./src/app2/Student/FeatureRequestStudent";
import AdminFeatureRequest from "./src/app3/Admin/AdminFeatureRequest";
import ViewFeatureRequest from "./src/app3/Admin/ViewFeatureRequest";
import PaymentGateway from "./src/app1/screen/PaymentGateway";
import TelegramBroadcast from "./src/app1/screen/TelegramBroadcast";
import SubscriptionPlan from "./src/app1/screen/SubscriptionPlan";
import PaymentSuccessful from "./src/app1/screen/PaymentSuccessful";
import PaymentFailed from "./src/app1/screen/PaymentFailed";

import LandingHome from "./src/app2/landing/LandingHome";
import About from "./src/app2/landing/About";
import CancellationPolicy from "./src/app2/landing/CancellationPolicy";
import FAQs from "./src/app2/landing/FAQs";
import Contactus from "./src/app2/landing/Contactus";
import PrivacyPolicy from "./src/app2/landing/PrivacyPolicy";
import CookiePolicy from "./src/app2/landing/CookiePolicy";

import Refund from "./src/app2/landing/Refund";
import TermsCondition from "./src/app2/landing/TermsCondition";
import Subscription from "./src/app2/landing/Subscription";

import HelpCenter from "./src/app2/landing/HelpCenter";

import Pricing from "./src/app2/landing/Pricing";

import FaqDetails from "./src/app2/landing/FaqDetails";

import LoginLog from "./src/app1/screen/LoginLog";
import AdminActivityLog from "./src/app3/Admin/AdminActivityLog";
import WatchList from "./src/app1/screen/WatchList";
import Chat from "./src/app1/screen/Chat";
import AdminMyReportDatewise from "./src/app3/Admin/AdminMyReportDatewise";
import MyReportDatewise from "./src/app1/screen/MyReportDatewise";
import StudentDatewiseReport from "./src/app1/screen/StudentDatewiseReport";
import ManageTelegramSubscription from "./src/app3/Admin/ManageTelegramSubscription";
import CreateTelegramSubscription from "./src/app3/Admin/CreateTelegramSubscription";
import ViewTelegramSubscription from "./src/app3/Admin/ViewTelegramSubscription";
import UpdateTelegramSubscription from "./src/app3/Admin/UpdateTelegramSubscription";
import StudentChat from "./src/app2/Student/StudentChat";
import TeacherReportDatewise from "./src/app3/Admin/TeacherReportDatewise";

import Circular from "./src/app2/landing/Circular";
import StudentActivitylog from "./src/app2/Student/StudentActivitylog";
import AlgoTrading from "./src/app2/landing/AlgoTrading";
import { NetworkStatusProvider } from "./src/context/NetworkStatusContext";


function App() {
  return (
    <NetworkStatusProvider>
    <Routes>
      {/* <Route path="/" element={<CommonLogin />} /> */}

      <Route
        path="/student/login_activity_log"
        element={<LoginActivityLog />}
      />
      <Route
        path="/student/activity_log"
        element={<StudentActivitylog />}
      />
      <Route
        path="/admin/login_activity_log"
        element={<AdminLoginActivityLog />}
      />
      <Route path="/admin/activity_log" element={<AdminActivityLog />} />
      <Route path="/student/chat" element={<StudentChat />} />

      <Route path="/student/dashboard" element={<Dashboard />} />
      <Route path="/admin/dashboard" element={<AdminDashboard />} />
      <Route path="/admin/manage_teacher" element={<ManageTeacher />} />
      <Route
        path="/admin/manage_teacher/create_teacher"
        element={<CreateTeacher />}
      />

      <Route
        path="/admin/manage_teacher/view_teacher/:id"
        element={<ViewTeacher />}
      />
      <Route path="/admin/profile" element={<AdminProfile />} />
      <Route path="/admin/report" element={<Reports />} />
      <Route path="/admin/my_report" element={<AdminMyReport />} />

      <Route path="/admin/order_book" element={<AdminOrderBook />} />
      <Route
        path="/admin/order_book/order_details/:id"
        element={<AdminOrderBookDetails />}
      />
      <Route path="/admin/trade_book" element={<AdminTradeBook />} />
      <Route path="/admin/feature_request" element={<AdminFeatureRequest />} />
      <Route
        path="/admin/feature_request/Feature_view/:id"
        element={<ViewFeatureRequest />}
      />

      <Route path="/admin/manage_faq/create_faq" element={<CreateFaq />} />

      <Route
        path="/admin/manage_teacher/update_teacher/:id"
        element={<UpdateTeacher />}
      />
      <Route
        path="/admin/report_datewise"
        element={<AdminMyReportDatewise />}
      />

      <Route path="/admin/report_details" element={<AdminMyReportDetails />} />
      <Route path="/admin/teacher_list" element={<TeacherList />} />
      <Route
        path="/admin/teacher_report/:teacherId"
        element={<TeacherReport />}
      />
      <Route
        path="/admin/manage_subscription"
        element={<ManageSubscription />}
      />
      <Route
        path="/admin/manage_subscription/create_subscription"
        element={<CreateSubscription />}
      />
      <Route
        path="/admin/manage_subscription/update_subscription"
        element={<UpdateSubscription />}
      />
      <Route
        path="/admin/manage_subscription/view_subscription"
        element={<ViewSubscription />}
      />

      <Route
        path="/admin/manage_subscription/view_subscription/:subscription_id"
        element={<ViewSubscription />}
      />
      <Route
        path="/admin/manage_subscription/update_subscription/:subscription_id"
        element={<UpdateSubscription />}
      />

      <Route path="/admin/manage_faq" element={<ManageFaq />} />
      <Route path="/admin/manage_faq/view_faq/:faqId" element={<ViewFaq />} />

      <Route
        path="/admin/manage_faq/update_faq/:faqId"
        element={<UpdateFaq />}
      />

      <Route
        path="/admin/teacher_report_details/:teacherId/:sellDate"
        element={<TeacherReportDetails />}
      />
      <Route
        path="/admin/teacher_report_datewise/:teacherId/:monthName"
        element={<TeacherReportDatewise />}
      />
      <Route
        path="/admin/manage_telegram_subscription"
        element={<ManageTelegramSubscription />}
      />
      <Route
        path="/admin/manage_telegram_subscription/create"
        element={<CreateTelegramSubscription />}
      />
      <Route
        path="/admin/manage_telegram_subscription/view/:subscription_id"
        element={<ViewTelegramSubscription />}
      />
      <Route
        path="/admin/manage_telegram_subscription/update/:subscription_id"
        element={<UpdateTelegramSubscription />}
      />
      <Route path="/app2/student_my_report" element={<StudentMyReport />} />
      <Route path="/app2/student_profile" element={<StudentProfile />} />

      <Route
        path="/student/feature_request"
        element={<FeatureRequestStudent />}
      />

      <Route
        path="/app2/student_report_view"
        element={<StudentMyReportView />}
      />
      <Route path="/app2/order_book" element={<StudentOrderBook />} />
      <Route path="/app2/student_trade_book" element={<StudentTradeBook />} />
      <Route path="/student/report" element={<ProfileReports />} />
      <Route
        path="/app2/student_order_details/:id"
        element={<StudentOrderDetails />}
      />
      {/* Teacher dashboard */}
      <Route path="/teacher/dashboard" element={<Home />} />
      <Route path="/home" element={<Home />} />
      {/* <Route path="/user_profile" element={<Profile />} /> */}
      <Route path="/teacher/user_profile" element={<TeacherProfile />} />
      <Route
        path="/teacher/user_profile_report"
        element={<TeacherProfileReport />}
      />
      <Route path="/teacher/subscription" element={<Billing />} />
      <Route
        path="/teacher/subscription_plan/:subscriptionId/:subscriptionType"
        element={<SubscriptionPlan />}
      />

      <Route
        path="/teacher/payment_gateway/:subscriptionId/:subscriptionPlanId/:subscriptionType"
        element={<PaymentGateway />}
      />
      <Route path="/teacher/payment_check" element={<PaymentSuccessful />} />

      <Route path="/user_basket" element={<Basket />} />
      <Route path="/trade_book" element={<TradeBook />} />
      <Route path="/trade_position" element={<Position />} />
      <Route path="/order_book" element={<OrderBook />} />
      <Route path="/teacher/feature_request" element={<FeatureRequest />} />

      <Route path="/teacher/subscription" element={<SubscriptionTeacher />} />

      <Route
  path="/order_book/orderbook_details/:id"
  element={<OrderbookDetails />}
/>


      <Route path="/teacher/payment_history" element={<PaymentHistory />} />
      <Route
        path="/teacher/telegram_broadcast"
        element={<TelegramBroadcast />}
      />

      <Route path="/teacher/my_report" element={<MyReport />} />
      <Route path="/teacher/my_report_view" element={<MyReportView />} />
      <Route
        path="/teacher/my_report_Datewise"
        element={<MyReportDatewise />}
      />
      <Route path="/teacher/student_report" element={<StudentReport />} />
      {/* <Route path="/teacher/student_report_list" element={<StudReportList />} /> */}
      <Route
        path="/teacher/student_report_list/:userId/:month_name"
        element={<StudReportList />}
      />
      <Route
        path="/teacher/student_report_datewise/:userId/:month_name"
        element={<StudentDatewiseReport />}
      />
      <Route
        path="/teacher/student_report_details/:userId/:sell_date"
        element={<StudentReportDetails />}
      />
      <Route
        path="/teacher/student_report_details/:userId"
        element={<StudentReportDetails />}
      />

      <Route path="/teacher/user_profile_Timeline" element={<TimeLine />} />
      <Route path="/teacher/manage_student" element={<ManageStudent />} />
      <Route
        path="/teacher/manage_student/create_student"
        element={<CreateStudent />}
      />
      <Route
        path="/teacher/manage_student/update_student/:id"
        element={<UpdateStudent />}
      />
      <Route path="/teacher/student_details" element={<StudentDetails />} />

      <Route
        path="/teacher/manage_student/view_student/:id"
        element={<ViewStudent />}
      />

      <Route
        path="/teacher/user_profile_activity_log"
        element={<ActivityLog />}
      ></Route>
      <Route
        path="/teacher/user_profile_login_log"
        element={<LoginLog />}
      ></Route>
      <Route path="/signup" element={<Signup />} />

      <Route path="/login" element={<CommonLogin />} />

      <Route
        path="/teacher/payment_successful"
        element={<PaymentSuccessful />}
      />
      <Route path="/teacher/payment_failed" element={<PaymentFailed />} />

      <Route path="/teacher/watch_list" element={<WatchList />} />

      <Route path="/teacher/chat" element={<Chat />} />

      <Route path="/" element={<LandingHome />} />

      <Route path="/about" element={<About />} />
      <Route path="/cancellation_policy" element={<CancellationPolicy />} />
      <Route path="/faq" element={<FAQs />} />
      <Route path="/contact" element={<Contactus />} />
      <Route path="/privacy_policy" element={<PrivacyPolicy />} />
      <Route path="/cookies_policy" element={<CookiePolicy />} />
      <Route path="/refund_policy" element={<Refund />} />
      <Route path="/terms_condition" element={<TermsCondition />} />
      <Route path="/landing_subscription" element={<Subscription />} />
      <Route path="/help_center" element={<HelpCenter />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/sebi-circular" element={<Circular />} />

      <Route path="/faq/:faqId" element={<FaqDetails />} />

     
      <Route path="/algo_trading" element={<AlgoTrading />} />
    </Routes>
    </NetworkStatusProvider>
  );
}

export default App;

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import Footer from "../../../component/Footer";
import { Modal, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import config from "../../../component/config";

import SubHeader from "../component/SubHeader";
import Header from "../component/Header";
import SubscriptionBadge from "../../../component/SubscriptionBadge";

const TeacherProfileReport = () => {
  const [userData, setUserData] = useState(null);
  
  const navigate = useNavigate();
  const [brokerData, setBrokerData] = useState({});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const toast = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");

        if (!authToken || !userId) {
          setLoading(false);
          navigate("/login");
          return;
        }

        const response = await axios.post(
          `${config.apiDomain}/api/common/get_profile_details`,
          {
            user_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.st === 1) {
          setUserData(response.data.user_details);
          setBrokerData(response.data.broker_details);
          setFormData({
            ...response.data.user_details,
            ...response.data.broker_details,
          });
        } else {
          
        }
      } catch (error) {
        let errorMsg = "Failed to fetch data";
        if (error.response) {
          errorMsg = error.response.data.msg
            ? formatMessage(error.response.data.msg)
            : "An error occurred";
        } else if (error.message) {
          errorMsg = error.message;
        }
       
        if (error.response && error.response.status === 401) {
          navigate("/login");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleConnectionStatus = (status) => {
    
  };

  const formatMessage = (msg) => {
    if (typeof msg === "object") {
      return Object.values(msg).flat().join(", ");
    }
    return String(msg);
  };

  const [showPopup, setShowPopup] = useState(false); 

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      
      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); // Every minute

 
    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  
  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success"; 
    } else if (hours === 15) {
      return "danger"; 
    }
    return "secondary"; 
  };

  return (
    <>
      <Header></Header>
      <SubHeader></SubHeader>

      <Toast ref={toast} position="top-right" />

      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className=" layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container flex-grow-1 container-p-y">
              <nav aria-label="breadcrumb">
              <div className="d-flex justify-content-between align-items-center">
                <ol className="breadcrumb breadcrumb-style1 text-black">
                  <li className="breadcrumb-item">
                    <Link to="/teacher/dashboard" className="text-black">
                      <i className="ri-home-7-line ri-lg"></i>
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active text-secondary"
                    aria-current="page"
                  >
                    Profile
                  </li>
                </ol>
                <SubscriptionBadge></SubscriptionBadge>
                </div>
              </nav>

              <div className="row">
                {userData && (
                  <div className="row ">
                    <div className="col-md-4">
                      <div className="card ">
                        <div className="card-body pt-0">
                          <div className="user-info  mt-3 text-center">
                            <h5>{capitalizeFirstLetter(userData.name)}</h5>
                            <span className="badge bg-label-primary rounded-pill">
                              {" "}
                              {capitalizeFirstLetter(userData.role)}
                            </span>
                          </div>

                          <ul className="list-unstyled my-3 py-1">
                            <li className="d-flex justify-content-between align-items-center mb-4">
                              <strong>Broker Connection:</strong>
                              <span className="text-success ml-auto">
                                <div className="ms-auto">
                                  <div
                                    className={`text-success ml-auto ${
                                      userData.broker_conn_status
                                        ? "text-success"
                                        : "text-danger"
                                    }`}
                                    onClick={() =>
                                      handleConnectionStatus(
                                        !userData.broker_conn_status
                                      )
                                    }
                                  >
                                    {userData.broker_conn_status ? (
                                      <>
                                        <i className="ri-shield-check-line"></i>{" "}
                                        Connected
                                      </>
                                    ) : (
                                      <>
                                        <i className="ri-close-large-line"></i>{" "}
                                        Disconnected
                                      </>
                                    )}
                                  </div>
                                </div>
                              </span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center mb-4">
                              <strong>Broker Acc. Balance:</strong>
                              <span className="ml-auto">
                                {(userData.amount || 0).toFixed(2)} Rs.
                              </span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center mb-4">
                              <strong>Commission:</strong>
                              <span className="ml-auto">
                                {userData.commission}%
                              </span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center mb-7">
                              <strong>Lot Size Limit:</strong>
                              <span className="ml-auto ">
                                {userData.lot_size_limit} Lot
                              </span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center  ">
                              <strong>Segments</strong>
                              <span className="ml-auto ">
                                {userData.exchangas}
                              </span>
                            </li>
                          </ul>
                          <hr />
                          <ul className="list-unstyled my-3 py-1">
                            <li className="d-flex flex-column align-items-start mb-4">
                              <span className="fw-medium fs-5">Contacts</span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center mb-4">
                              <strong>Email:</strong>
                              <span className="ml-auto">{userData.email}</span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center mb-4">
                              <strong>Mobile:</strong>
                              <span className="ml-auto">{userData.mobile}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="row">
                        <div className="card-body pt-0">
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title mb-5">
                                    My Reports
                                  </h5>

                                  <p className="mb-3">Profit This Month</p>
                                  <h4 className="text-light mb-0">2,2199</h4>
                                  <Link
                                    to="/teacher/my_report"
                                    className="btn btn-sm btn-primary"
                                  >
                                    <i className="ri-user-follow-fill ri-md me-2">
                                      {" "}
                                    </i>{" "}
                                    My Reports
                                  </Link>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title mb-5">
                                    Student Report
                                  </h5>

                                  <p className="mb-3">Profit This Month</p>
                                  <h4 className="text-light mb-0">2,2199</h4>
                                  <Link
                                    to="/teacher/student_report"
                                    className="btn btn-sm btn-primary"
                                  >
                                    <i className="ri-user-follow-fill ri-md me-2">
                                      {" "}
                                    </i>{" "}
                                    Student Report
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <Footer></Footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherProfileReport;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; 
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};

import React, { useEffect, useState, useRef } from "react";
import Footer from "../../../component/Footer";
import { Toast } from "primereact/toast";
import { Link, useNavigate } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import { Modal, Button } from "react-bootstrap";
import config from "../../../component/config";
import axios from "axios";
import AdminHeader from "./AdminHeader";
import AdminSubHeader from "./AdminSubHeader";
import CardLinks from "../../../component/CardLinks";
import dhanLogo from "../../../assets/logo/dhanlogo.jpeg";
import angeloneLogo from "../../../assets/logo/angelonelogo.png";
import Skeleton from "react-loading-skeleton";
const AdminProfile = () => {
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();
  const [isTradingPowerEditable, setIsTradingPowerEditable] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(userData);
  const [formErrors, setFormErrors] = useState({});
  const [brokerData, setBrokerData] = useState({});
  const [selectedBroker, setSelectedBroker] = useState(null);

  const handleBrokerChange = (broker) => {
    setSelectedBroker(broker);
  };
  const toast = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");

        if (!authToken || !userId) {
          setLoading(false);
          navigate("/commonlogin");
          return;
        }

        const response = await axios.post(
          `${config.apiDomain}/api/common/get_profile_details`,
          {
            user_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.st === 1) {
          setUserData(response.data.user_details);
          setBrokerData(response.data.broker_details);
          setFormData({
            ...response.data.user_details,
            ...response.data.broker_details,
          });
          setSelectedBroker(
            response.data.broker_details?.broker || "angle_one"
          );
         
        }
         else {
        
        }
      } catch (error) {
        let errorMsg = "Failed to fetch data";
        if (error.response) {
          errorMsg = error.response.data.msg
            ? formatMessage(error.response.data.msg)
            : "An error occurred";
        } else if (error.message) {
          errorMsg = error.message;
        }
       
       
        if (error.response && error.response.status === 401) {
          navigate("/commonlogin");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const handleConnectionStatus = (status) => {};
  const toLowerCase = (string) => {
    return string.toLowerCase();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "lot_size_limit") {
      if (/^\d*$/.test(value)) {
        // Only allow digits
        if (parseInt(value, 10) > 5000) {
          setError("Maximum limit is 5000");
          setFormData((prevData) => ({
            ...prevData,
            [name]: 5000,
          }));
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "Maximum limit is 5000",
          }));
        } else {
          setError("");
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
          }));
        }
      } else {
        setError("Only digits are allowed");
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Only digits are allowed",
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));

      // Clear field-specific errors on correct input
      if (
        name === "name" &&
        !/\d/.test(value) &&
        !/[^a-zA-Z\s]/.test(value) &&
        value.length >= 3 &&
        value.length <= 50
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          name: "",
        }));
      } else if (
        name === "email" &&
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      } else if (name === "mobile" && /^\d{10}$/.test(value)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          mobile: "",
        }));
      } else if (
        name === "commission" &&
        /^[0-9%]+$/.test(value) &&
        !(value.includes("%") && value.length === 1)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          commission: "",
        }));
      }

      if (name === "tradingPower") {
        setIsTradingPowerEditable(true);
      }
    }
  };

  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/g;

    if (!formData.name) {
      tempErrors.name = "Name is required";
      isValid = false;
    } else if (/\d/.test(formData.name)) {
      tempErrors.name = "Name cannot contain digits";
      isValid = false;
    } else if (/[^a-zA-Z\s]/.test(formData.name)) {
      tempErrors.name = "Name cannot contain special characters";
      isValid = false;
    } else if (formData.name.length < 3) {
      tempErrors.name = "Name must be at least 3 characters";
      isValid = false;
    } else if (formData.name.length > 50) {
      tempErrors.name = "Name must be at most 50 characters";
      isValid = false;
    }

    if (!formData.email) {
      tempErrors.email = "Email is required";
      isValid = false;
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
    ) {
      tempErrors.email = "Email is invalid";
      isValid = false;
    }

    if (!formData.mobile) {
      tempErrors.mobile = "Mobile number is required";
      isValid = false;
    } else if (!/^\d+$/.test(formData.mobile)) {
      tempErrors.mobile = "Mobile number must contain only digits";
      isValid = false;
    } else if (formData.mobile.length !== 10) {
      tempErrors.mobile = "Mobile number must be exactly 10 digits";
      isValid = false;
    }

    if (formData.lot_size_limit === "") {
      tempErrors.lot_size_limit = "Lot size limit is required";
      isValid = false;
    } else if (isNaN(formData.lot_size_limit)) {
      tempErrors.lot_size_limit = "Lot size limit must be a number";
      isValid = false;
    } else {
      const lotSizeLimit = parseInt(formData.lot_size_limit, 10);
      if (lotSizeLimit < 1 || lotSizeLimit > 5000) {
        tempErrors.lot_size_limit = "Lot size limit must be between 1 and 5000";
        isValid = false;
      }
    }

    if (!formData.commission) {
      tempErrors.commission = "Commission is required";
      isValid = false;
    } else if (!/^[0-9%]+$/.test(formData.commission)) {
      tempErrors.commission =
        "Commission must only contain digits and the '%' character";
      isValid = false;
    } else if (
      formData.commission.includes("%") &&
      formData.commission.length === 1
    ) {
      tempErrors.commission = "Commission cannot be just '%'";
      isValid = false;
    }

    setFormErrors(tempErrors);
    return isValid;
  };

  const formatMessage = (msg) => {
    if (typeof msg === "object") {
      return Object.values(msg).flat().join(", ");
    }
    return String(msg);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setLoadingInfo(true);
  
    try {
      const authToken = localStorage.getItem("authToken");
  
      if (!authToken) {
        console.error("Access token not found in localStorage");
        setLoadingInfo(false);
        navigate("/commonlogin");
        return;
      }
  
      const response = await axios.put(
        `${config.apiDomain}/api/common/save_profile_details`,
        {
          user_id: localStorage.getItem("userId"),
          email: formData.email,
          mobile: formData.mobile,
          name: formData.name,
          lot_size_limit: formData.lot_size_limit,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      const { st, msg } = response.data;
  
      // Display toast based on `st` status
      switch (st) {
        case 1:
          setUserData(formData); // Update userData with the formData
          window.showToast("success", msg || "Profile details saved successfully");
          break;
        case 2:
          window.showToast("danger", msg || "An error occurred. Please check your details.");
          break;
        case 3:
          window.showToast("warning", msg || "Warning: Please check the information provided.");
          break;
        case 4:
          window.showToast("info", msg || "Info: Profile updated with additional notes.");
          break;
        default:
          window.showToast("error", "Something went wrong. Contact Support.");
      }
    } catch (error) {
      let errorMsg = "Failed to fetch data";
  
      if (error.response) {
        errorMsg = error.response.data.msg ? formatMessage(error.response.data.msg) : "An error occurred";
      } else if (error.message) {
        errorMsg = error.message;
      }
  
      window.showToast("error", "Something went wrong. Contact Support.");
  
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access, redirecting to common login screen");
        navigate("/commonlogin");
      }
    } finally {
      setLoadingInfo(false);
    }
  };
  

  const handleBrokerInformation = async (e) => {
    e.preventDefault();
  
    if (!validateForm()) {
      return;
    }
  
    setIsLoading(true);
  
    try {
      const authToken = localStorage.getItem("authToken");
  
      if (!authToken) {
        setIsLoading(false);
        navigate("/commonlogin");
        return;
      }
  
      let payload;
      if (selectedBroker === "angle_one") {
        payload = {
          user_id: localStorage.getItem("userId"),
          client_id: formData.client_id,
          password: formData.password,
          qr_totp_token: formData.qr_totp_token,
          api_key: formData.api_key,
          broker: "angle_one",
        };
      } else if (selectedBroker === "dhan") {
        payload = {
          user_id: localStorage.getItem("userId"),
          dhan_client_id: formData.dhan_client_id,
          access_token: formData.access_token,
          broker: "dhan",
        };
      }
  
      const response = await axios.put(
        `${config.apiDomain}/api/common/save_broker_details`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      const { st, msg, details } = response.data;
  
      // Display toast based on `st` status
      if (st === 1) {
        setUserData(formData);
        window.showToast("success", msg || "Broker details saved successfully.");
      } else {
        let errorMsg = msg || "An error occurred";
        if (st === 2) {
          errorMsg = `Error: ${msg}. ${formatMessage(details)}`;
        }
  
        if (errorMsg === "Token expired") {
          errorMsg = "Session expired. Please log in again.";
          navigate("/commonlogin");
        }
  
        window.showToast("error", errorMsg);
      }
    } catch (error) {
      let errorMsg = "Error updating user profile";
  
      if (error.response) {
        if (error.response.status === 401) {
          errorMsg = "Session expired. Please log in again.";
          navigate("/login");
        } else {
          errorMsg = formatMessage(error.response.data.msg || error.response.data) || "Something went wrong. Contact Support.";
        }
      } else if (error.message) {
        errorMsg = error.message;
      }
  
      window.showToast("error", errorMsg);
    } finally {
      setIsLoading(false);
    }
  };
  

  const toTitleCase = (str) => {
    if (!str) return "";
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 15)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success";
    } else if (hours === 15) {
      return "danger";
    }
    return "secondary";
  };
  const formatExchanges = (exchanges) => {
    if (!exchanges) return [];
    const formattedExchanges = [];
    for (const [key, value] of Object.entries(exchanges)) {
      if (Array.isArray(value)) {
        formattedExchanges.push(`${key}: ${value.join(", ")}`);
      } else {
        formattedExchanges.push(`${key}: ${value}`);
      }
    }
    return formattedExchanges;
  };

  const formattedExchanges =
    userData && userData.broker_account_exchanges
      ? formatExchanges(userData.broker_account_exchanges)
      : [];

  const brokerNameMap = {
    angle_one: "AngelOne",
    dhan: "Dhan",
  };

  const getBrokerDisplayName = (brokerName) => {
    return brokerNameMap[brokerName] || brokerName;
  };
  return (
    <div>
      <AdminHeader></AdminHeader>
      <AdminSubHeader></AdminSubHeader>
      <Toast ref={toast} position="top-right" />

      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className=" layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container-xxl  flex-grow-1 container-p-y">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 text-black">
                  <li className="breadcrumb-item">
                    <Link to="/Admin/dashboard" className="text-black">
                      <i className="ri-home-7-line ri-lg"></i>
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active text-secondary"
                    aria-current="page"
                  >
                    Profile
                  </li>
                </ol>
              </nav>

              <div className="row">
                <div className="col-md-4 me-0">
                <div className="card">
  <div className="card-body pt-0">
   
        <div className="w-px-40 h-auto mx-auto mt-3 rounded-circle">
        {loading ? (
    <Skeleton circle={true} height={80} width={80} />
  ) : (
          <div className="d-flex justify-content-center align-items-center border border-success border-3 rounded-circle bg-label-success avatar-xl">
            <span className="avatar-initial rounded-circle bg-label-success">
              {userData.name &&
                userData.name
                  .split(" ")
                  .filter((word, index) => index === 0 || index === userData.name.split(" ").length - 1)
                  .map((word) => word.charAt(0).toUpperCase())
                  .join("")}
            </span>
          </div>
  )}
        </div>
        {loading ? (
          <Skeleton count={1} height={30} className="mt-3" />
        ) : (
          <>
        <div className="user-info mt-3 text-center">
   
          <h5 className="text-capitalize">{userData.name}</h5>
          <span className="btn btn-outline-secondary rounded-pill btn-xs">
            {userData.role}
          </span>
        </div>
        </>)}

        <ul className="list-unstyled my-3 py-1">
        {loading ? (
    <Skeleton count={1} height={30} className="" />
  ) : (
    <>
        {brokerData && (
          <li className="d-flex justify-content-between align-items-center mb-4">
            <strong>Broker</strong>
            <span className="ml-auto d-flex align-items-center">
              {selectedBroker === "angle_one" && (
                <img src={angeloneLogo} alt="AngleOne" style={{ height: "18px" }} className="me-1 border border-1 rounded-pill" />
              )}
              {selectedBroker === "dhan" && (
                <img src={dhanLogo} alt="Dhan" style={{ height: "18px" }} className="me-1 border border-1 rounded-pill" />
              )}
              {getBrokerDisplayName(selectedBroker)}
            </span>
          </li>
        )}
        {brokerData && (
           <li className="d-flex justify-content-between align-items-center mb-4">
            <strong>Broker Acc. Balance:</strong>
            <span className="ml-auto">
          {`${userData.broker_account_fund} Rs.`}
            </span>
          </li>
        )}
       
          <li className="d-flex justify-content-between align-items-center mb-4">
            <strong>Commission:</strong>
            <span className="ml-auto">{`${userData.commission}%`}</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <strong>Lot Size Limit:</strong>
            <span className="ml-auto">{`${userData.lot_size_limit} Lot`}</span>
          </li>

          {formattedExchanges.length > 0 && (
                            <li className="mt-4">
                              <strong>Segments:</strong>
                              <span className="ml-auto">
                                <ul className="list-unstyled text-end">
                                  {" "}
                                  {/* New <ul> for the exchanges */}
                                  {formattedExchanges.map((exchange, index) => (
                                    <li key={index}>{exchange}</li>
                                  ))}
                                </ul>
                              </span>
                            </li>
                          )}
                          </>)}
        </ul>

        <hr />
        <ul className="list-unstyled my-3">
  {loading ? (
    <Skeleton count={1} height={30} className="" />
  ) : (
    <>
      <li className="d-flex justify-content-between align-items-center mb-4">
        <strong>Email:</strong>
        <span className="ml-auto">{userData.email}</span>
      </li>
      <li className="d-flex justify-content-between align-items-center">
        <strong>Mobile:</strong>
        <span className="ml-auto">{userData.mobile}</span>
      </li>
    </>
  )}
</ul>
    
  </div>
</div>

                </div>

                <div className="col-md-8">
                  <div className="row">
       
                    <div className="col-md-8 col-sm-7 mt-3 mt-md-0 mt-lg-0">
      <div className="nav-align-top">
        <ul className="nav flex-row flex-wrap mb-3 justify-content-start justify-content-md-start justify-content-center">
          {/* {loading ? (
            <>
              <li className="nav-item me-1_5">
                <Skeleton height={40} width={100} />
              </li>
              <li className="nav-item me-1_5">
                <Skeleton height={40} width={100} />
              </li>
              <li className="nav-item">
                <Skeleton height={40} width={100} />
              </li>
            </>
          ) : (
            <> */}
              <li className="nav-item active">
                <Link to="/admin/profile" className="nav-link active btn btn-primary">
                  <i className="ri-user-3-line me-1_5"></i> Profile
                </Link>
              </li>
              <li className="nav-item active">
                <Link to="/admin/login_activity_log" className="nav-link">
                  <i className="ri-news-line me-1_5"></i> Login Log
                </Link>
              </li>
              <li className="nav-item active">
                <Link to="/admin/activity_log" className="nav-link">
                  <i className="ri-file-list-2-line me-1_5"></i> Activity Log
                </Link>
              </li>
            {/* </>
          )} */}
        </ul>
      </div>
    </div>
    
                    <div className="container-xxl p-md-0 p-lg-0">
                         <div className="card">
      
      <div className="card-body pt-0">
       
      {loading ? (
        <Skeleton height={250} className="mt-5" /> // Adjust height and width as needed
      ) : (
      

          <form id="formAccountSettings" method="POST">
                          <div className="row ">
                            <div className="d-flex justify-content-between align-items-center">
                            <span className="fw-medium mt-3 fs-5 text-start mb-5 text-black">
                                <i className="ri-user-line ri-ms me-1"></i>
                                Personal Information
                              </span>

                             
                            </div>

                            <div className="col-md-4 mt-5">
                              <div className="input-group input-group-merge">
                                <div className="form-floating form-floating-outline">
                                  <input
                                    className={`form-control ${
                                      formErrors.name ? "is-invalid" : ""
                                    }`}
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name || ""}
                                    placeholder="Name"
                                    onChange={handleChange}
                                  />
                                  <label htmlFor="name">
                                    {" "}
                                    <span className="text-danger">
                                      *{" "}
                                    </span> Name{" "}
                                  </label>
                                  {formErrors.name && (
                                    <div className="invalid-feedback">
                                      {formErrors.name}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 mt-5">
                              <div className="input-group input-group-merge">
                                <div className="form-floating form-floating-outline">
                                  <input
                                    className={`form-control ${
                                      formErrors.email ? "is-invalid" : ""
                                    }`}
                                    type="text"
                                    id="email"
                                    name="email"
                                    value={formData.email || ""}
                                    placeholder="E-mail"
                                    required
                                    onChange={handleChange}
                                  />
                                  <label htmlFor="email">
                                    {" "}
                                    <span className="text-danger">* </span>
                                    E-mail{" "}
                                  </label>
                                  {formErrors.email && (
                                    <div className="invalid-feedback">
                                      {formErrors.email}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 mt-5">
                              <div className="input-group input-group-merge">
                                <div className="form-floating form-floating-outline">
                                  <input
                                    type="text"
                                    id="mobile"
                                    name="mobile"
                                    className={`form-control ${
                                      formErrors.mobile ? "is-invalid" : ""
                                    }`}
                                    value={formData.mobile || ""}
                                    placeholder="Mobile Number"
                                    onChange={handleChange}
                                    maxLength="10"
                                  />
                                  <label htmlFor="mobile">
                                    <span className="text-danger">* </span>
                                    Mobile Number{" "}
                                  </label>
                                  {formErrors.mobile && (
                                    <div className="invalid-feedback">
                                      {formErrors.mobile}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 mt-5">
                              <div className="input-group input-group-merge">
                                <div className="form-floating form-floating-outline">
                                  <select
                                    className={`form-control ${
                                      formErrors.lot_size_limit
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="lot_size_limit"
                                    name="lot_size_limit"
                                    value={formData.lot_size_limit || ""}
                                    required
                                    onChange={handleChange}
                                  >
                                    {/* Dropdown options for 1 to 5 */}
                                    <option value="">
                                      Select Lot Size Limit
                                    </option>{" "}
                                    {/* Placeholder option */}
                                    {[1, 2, 3, 4, 5].map((value) => (
                                      <option key={value} value={value}>
                                        {value} Lot
                                      </option>
                                    ))}
                                  </select>
                                  <label htmlFor="lot_size_limit">
                                    <span className="text-danger">* </span>
                                    Lot Size Limit
                                  </label>
                                  {formErrors.lot_size_limit && (
                                    <div className="invalid-feedback">
                                      {formErrors.lot_size_limit}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className=" mt-3 mt-md-0 text-end">
                              <button
                                onClick={handleSubmit}
                                className="btn btn-success rounded-pill  text-end"
                                disabled={loading}
                              >
                                {loading ? (
                                  <div
                                    className="spinner-border me-2"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                ) : (
                                  <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                                )}{" "}
                                {loading ? "Saving Data" : "Save Data"}
                              </button>
                            </div>
                          </div>
                        </form>
                          )}
      </div>
    
    </div>


                      <div className="card mt-3">
                        <div className=" card-body pt-0">

                        {loading ? (
        <Skeleton height={250} className="mt-5" /> // Adjust height and width as needed
      ) : (
        <>
                          <h5 className="text-start mt-3">
                            {" "}
                            <i className="ri-group-line ri-ms me-2"></i>
                            Broker Information
                          </h5>
                          <div className="nav-align-top">
                            <ul className="nav nav-tabs" role="tablist">
                              <li className="nav-item me-5">
                                <div
                                  className={` ${
                                    selectedBroker === "angle_one"
                                      ? "border-bottom  border-primary text-primary pb-2 border-4"
                                      : "text-black"
                                  }`}
                                  onClick={() =>
                                    handleBrokerChange("angle_one")
                                  }
                                >
                                  {selectedBroker === "angle_one" && (
                                    <img
                                      src={angeloneLogo}
                                      alt="AngleOne"
                                      style={{ height: "18px" }}
                                      className="me-1 border border-1 rounded-pill"
                                    />
                                  )}{" "}
                                  AngleOne
                                </div>
                              </li>

                              <li className="nav-item">
                                <div
                                  className={` ${
                                    selectedBroker === "dhan"
                                      ? "border-bottom border-primary text-primary pb-2 border-4"
                                      : "text-black"
                                  }`}
                                  onClick={() => handleBrokerChange("dhan")}
                                >
                                  {selectedBroker === "dhan" && (
                                    <img
                                      src={dhanLogo}
                                      alt="Dhan"
                                      style={{ height: "18px" }}
                                      className="me-1 border border-1 rounded-pill"
                                    />
                                  )}{" "}
                                  Dhan
                                </div>
                              </li>
                            </ul>

                            <div className="row mt-5">
                              {selectedBroker === "angle_one" && (
                                <>
                                  <div className="col-md-4 ">
                                    <div className="input-group input-group-merge">
                                      <div className="form-floating form-floating-outline">
                                        <input
                                          type="text"
                                          className={`form-control ${
                                            formErrors.brokerClientId
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          id="client_id"
                                          name="client_id"
                                          placeholder="Broker Client ID"
                                          value={formData.client_id || ""}
                                          onChange={handleChange}
                                        />
                                        <label htmlFor="client_id">
                                          {" "}
                                          <span className="text-danger">
                                            *{" "}
                                          </span>
                                          Broker Client ID
                                        </label>
                                        {formErrors.brokerClientId && (
                                          <div className="invalid-feedback">
                                            {formErrors.brokerClientId}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 mt-5 mt-md-0">
                                    <div className="input-group input-group-merge">
                                      <div className="form-floating form-floating-outline">
                                        <input
                                          type="text"
                                          className={`form-control ${
                                            formErrors.brokerPassword
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          id="password"
                                          name="password"
                                          placeholder="Broker Password"
                                          value={formData.password || ""}
                                          onChange={handleChange}
                                        />
                                        <label htmlFor="password">
                                          <span className="text-danger">
                                            *{" "}
                                          </span>
                                          Broker Password
                                        </label>
                                        {formErrors.brokerPassword && (
                                          <div className="invalid-feedback">
                                            {formErrors.brokerPassword}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 mt-5 mt-md-0 ">
                                    <div className="input-group input-group-merge">
                                      <div className="form-floating form-floating-outline">
                                        <input
                                          type="text"
                                          className={`form-control ${
                                            formErrors.brokerQrTotpToken
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          id="qr_totp_token"
                                          name="qr_totp_token"
                                          value={formData.qr_totp_token || ""}
                                          placeholder="Broker QR TOTP Token"
                                          onChange={handleChange}
                                        />
                                        <label htmlFor="qr_totp_token">
                                          <span className="text-danger">
                                            *{" "}
                                          </span>
                                          Broker QR TOTP Token
                                        </label>
                                        {formErrors.brokerQrTotpToken && (
                                          <div className="invalid-feedback">
                                            {formErrors.brokerQrTotpToken}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 mt-5">
                                    <div className="input-group input-group-merge">
                                      <div className="form-floating form-floating-outline">
                                        <input
                                          type="text"
                                          className={`form-control ${
                                            formErrors.brokerApiKey
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          name="api_key"
                                          placeholder="Broker API Key"
                                          value={formData.api_key || ""}
                                          onChange={handleChange}
                                          autoComplete="api_key"
                                        />
                                        <label htmlFor="api_key">
                                          <span className="text-danger">
                                            *{" "}
                                          </span>
                                          Broker API Key
                                        </label>
                                        {formErrors.brokerApiKey && (
                                          <div className="invalid-feedback">
                                            {formErrors.brokerApiKey}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {selectedBroker === "dhan" && (
                                <>
                                  <div className="col-md-4  ">
                                    <div className="input-group input-group-merge">
                                      <div className="form-floating form-floating-outline">
                                        <input
                                          type="text"
                                          className="form-control "
                                          id="dhan_client_id"
                                          name="dhan_client_id"
                                          placeholder="Broker Client ID"
                                          value={formData.dhan_client_id || ""}
                                          onChange={handleChange}
                                        />
                                        <label htmlFor="dhan_client_id">
                                          {" "}
                                          <span className="text-danger">
                                            *{" "}
                                          </span>
                                          Broker Client ID
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6 mt-5 mt-md-0">
                                    <div className="input-group input-group-merge">
                                      <div className="form-floating form-floating-outline">
                                        <textarea
                                          className="form-control h-px-75 "
                                          id="access_token"
                                          name="access_token"
                                          placeholder="Access Token"
                                          value={formData.access_token || ""}
                                          onChange={handleChange}
                                        />
                                        <label htmlFor="access_token">
                                          <span className="text-danger">
                                            *{" "}
                                          </span>
                                          Broker Access Token
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>

                          <div className="mb-3 mt-3 mt-md-0 text-end">
                            <button
                              onClick={handleBrokerInformation}
                              className="btn btn-success rounded-pill  text-end"
                              disabled={IsLoading}
                            >
                              {IsLoading ? (
                                <div
                                  className="spinner-border me-2"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                              )}{" "}
                              {IsLoading ? "Saving Data" : "Save Data"}
                            </button>
                          </div>
                          </> )}
                        </div>
                           
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <CardLinks></CardLinks>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default AdminProfile;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red";
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};

import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer";
import AdminSubHeader from "./AdminSubHeader";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import config from "../../../component/config";
import { Toast } from "primereact/toast";
import HeatmapCalendar from "./HeatmapCalender";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";

const AdminMyReportDatewise = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [backClicked, setBackClicked] = useState(false);
  const toast = useRef(null);
  const [isRefreshing,setIsRefreshing] =useState(true);

  const [summary, setSummary] = useState({
    total_trades_count: 0,
    total_profitable_trades: 0,
    total_losing_trades: 0,
    total_commission: 0.0,
  });
  const [month, setMonth] = useState(""); // State for month

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
       if (!authToken || !userId) {
      navigate("/login");
        return;
      }

      const response = await axios.post(
        `${config.apiDomain}/api/common/datewise_report`,
        {
          user_id: userId,
          month_name: month, // Pass month_name to the API
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data && response.data.st === 1) {
        setData(response.data.completed_trades_per_date);
        setSummary(response.data.completed_trades);
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      setError(new Error(error.message || "Failed to fetch data"));
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setLoading(true);
    setError(null);
    setIsRefreshing(true);
  
    try {
      const userId = localStorage.getItem("userId");
      const authToken = localStorage.getItem("authToken");
  
      if (!userId || !authToken) {
        window.showToast("error", "User ID or auth token not found");
        setLoading(false);
        return;
      }
  
      const response = await axios.post(
        `${config.apiDomain}/api/common/datewise_report`,
        { user_id: userId, month_name: month },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      const { st, msg, completed_trades_per_date, completed_trades } = response.data;
  
      // Handle different `st` values with custom toasts
      switch (st) {
        case 1:
          setData(completed_trades_per_date);
          setSummary(completed_trades);
          break;
        case 2:
          setError(new Error(msg || "Warning"));
          window.showToast("warning", msg || "Warning: Please check the details.");
          break;
        case 3:
        case 4:
          setError(new Error(msg || "Danger: Server Error"));
          window.showToast("error", msg || "An error occurred. Please try again.");
          break;
        default:
          setError(new Error("Failed to fetch data"));
          window.showToast("error", "Something went wrong. Contact Support.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
  
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access, redirecting to common login screen");
        navigate("/login"); // Redirect on 401 Unauthorized
      } else {
        const errorMsg = error.response?.data?.msg || error.message || "Something went wrong. Contact Support.";
        setError(new Error(errorMsg));
        window.showToast("error", errorMsg);
      }
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };
  

  useEffect(() => {
    fetchData(); // This should ideally be called once when the component mounts
  }, [month]); // Dependency array to ensure it runs when the month changes

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const CommissionBodyTemplate = (rowData) => {
    return ` ${rowData.commission} Rs.`;
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleViewReport = (rowData) => {
    navigate(`/admin/report_details`, { state: { userId, month: rowData.sell_date } });
  };

  return (
    <>
      <Toast ref={toast} />
      <AdminHeader />
      <AdminSubHeader />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
        <ol className="breadcrumb breadcrumb-style1 text-black mb-3">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
           
            <li className="breadcrumb-item active text-secondary" aria-current="page">
              My Report
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>

        {/* <HeatmapCalendar /> */}

        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col-auto text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col text-center mb-5">
              <h5 className="mb-0">My Report</h5>
            </div>
            <div className="col-auto text-end mb-5">
              <Link to="/admin/teacher_list">
                <button className="btn rounded-pill btn-outline-secondary btn-xs">
                  <span className="text-black">
                    <i className="ri-group-line me-1 ri-lg"></i>{" "}
                    <span>Teacher Report</span>
                  </span>
                </button>
              </Link>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-3">
              <h4 className="mb-0">{summary.total_trades_count || 0}</h4>
              <p className="mt-0">Total Trades</p>
            </div>
            <div className="col-md-3">
              <h4 className="mb-0">{summary.total_profitable_trades || 0}</h4>
              <p className="mt-0">Profitable Trades</p>
            </div>
            <div className="col-md-3">
              <h4 className="mb-0">{summary.total_losing_trades || 0}</h4>
              <p className="mt-0">Losing Trades</p>
            </div>
            <div className="col-md-3">
              <h4 className="mb-0">{summary.total_commission || 0}</h4>
              <p className="mt-0">Total Commission</p>
            </div>
          </div>

          <div className="row mb-5 mt-5">
            <div className="col-lg-3 col-md-4 col-12">
              <div className="d-flex align-items-center">
                <InputText
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  placeholder="Enter month"
                />
                <Button
                  label="Reload"
                  className="p-button-primary ms-3"
                  onClick={handleRefresh}
                />
              </div>
            </div>
          </div>

          {loading ? (
            <div className="text-center">
              <ProgressSpinner />
            </div>
          ) : error ? (
            <div className="alert alert-danger">{error.message}</div>
          ) : (
            <DataTable value={data} paginator rows={20} globalFilter={globalFilter}  loading={loading}>
              <Column field="sell_date" header=" Exit Date" />
              <Column field="trades_count" header="Trades Count" />
              <Column field="profit" header="Profit" />
              <Column field="commission" header="Commission" body={CommissionBodyTemplate} />
              <Column body={(rowData) => (
                <Button label="View Report"  data-pr-tooltip="View Record"
                data-pr-position="top"  className="custom-target-icon"onClick={() => handleViewReport(rowData)} />
              )} />
            </DataTable>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminMyReportDatewise;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, useNavigate } from "react-router-dom";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer";
import AdminSubHeader from "./AdminSubHeader";
import config from "../../../component/config";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import CardLinks from "../../../component/CardLinks";
import Modal from "react-bootstrap/Modal";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
import { Skeleton } from 'primereact/skeleton';
const AdminFeatureRequest = () => {
  const [loading, setLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [backClicked, setBackClicked] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [featureData, setFeatureData] = useState([]);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false); 
  const [selectedFeature, setSelectedFeature] = useState(null); 
  const [deleteLoading, setDeleteLoading] = useState(false);
  const toast = useRef(null);
 
  useEffect(() => {
   
    const fetchFeatureRequests = async () => {
      setLoading(true);
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
      if (!authToken || !userId) {
        navigate("/login");
        return;
      }

      try {
        const response = await axios.get(`${config.apiDomain}/api/admin/feature_request/list`, {
          headers: {
            'Authorization': `Bearer ${authToken}`
          },
        });

        if (response.data.st === 1) {
          setFeatureData(response.data.data);
        } else {
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login'); 
        } else {
        }
      } finally {
        setLoading(false);
      }
    };

    fetchFeatureRequests();
  }, []);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleView = (id) => {
    navigate(`/admin/feature_request/Feature_view/${id}`);
  };

  const handleRefresh = async () => {
    setLoading(true);
    setIsRefreshing(true);
    const authToken = localStorage.getItem("authToken");
  
    try {
      const response = await axios.get(`${config.apiDomain}/api/admin/feature_request/list`, {
        headers: {
          'Authorization': `Bearer ${authToken}`
        },
      });
  
      const { st, msg, data } = response.data;
  
      // Display toast based on `st` status
      switch (st) {
        case 1:
          setFeatureData(data);
          window.showToast("success", msg || "Data fetched successfully");
          break;
        case 2:
          window.showToast("danger", msg || "An error occurred. Please try again.");
          break;
        case 3:
          window.showToast("warning", msg || "Warning! Please check the details.");
          break;
        case 4:
          window.showToast("info", msg || "Information for your reference.");
          break;
        default:
          window.showToast(
            "danger",
            "Something went wrong. Contact Support. "
          );
      }
    } catch (error) {
      // Display an error toast if there is a request failure
      if (error.response && error.response.status === 401) {
        navigate('/login'); // Navigate to login page on 401 Unauthorized
      } else {
        window.showToast("error", error.response?.data?.msg || "Something went wrong. Contact Support. ");
      }
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };
  

  const handleDelete = async () => {
    setDeleteLoading(true);
    const authToken = localStorage.getItem("authToken");

    try {
      const response = await axios.delete(`${config.apiDomain}/api/admin/feature_request/delete`, {
        headers: { Authorization: `Bearer ${authToken}` },
        data: { feature_request_id: selectedFeature.feature_request_id },
      });

      if (response.data.st === 1) {
        setFeatureData((prevData) => prevData.filter((item) => item.feature_request_id !== selectedFeature.feature_request_id));
      } else {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.msg || 'Failed to delete feature request', life: 2000 });
      }
    } catch (error) {
      console.error("Error deleting feature request:", error);
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while deleting the feature request.', life: 2000 });
    } finally {
      setDeleteLoading(false);
      setShowModal(false);
    }
  };

  const openDeleteModal = (rowData) => {
    setSelectedFeature(rowData);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedFeature(null);
  };
  return (
    <div>
      <AdminHeader />
      <AdminSubHeader />
      <div className="container-xxl container-p-y" align="center">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
        <ol className="breadcrumb breadcrumb-style1 text-black mb-3">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Feature Request
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center mb-5">
            <div className="col-4 text-start">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 col-md-4 col-lg-4  text-center">
              <h5 className="mb-0">Feature Request</h5>
            </div>
            <div className="col-2"></div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex align-items-center">
              <IconField iconPosition="left">
                <InputIcon className="ri ri-search-line"></InputIcon>
                <InputText
                  type="search"
                  placeholder="Search"
                  value={globalFilter}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                  className="rounded"
                />
              </IconField>
              {loading ? (
              <div className="d-flex align-items-center">
                <i
                  className={`ri-refresh-line ri-lg ms-3 p-text-secondary ${
                    isRefreshing ? "rotate" : ""
                  }`}
                  role="status"
                ></i>
              </div>
            ) : (
              <div className="">
                <Tooltip target=".custom-target-icon" />
                <i
                  className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                  role="button"
                  data-pr-tooltip="Reload"
                  onClick={handleRefresh}
                  data-pr-position="top"
                ></i>
              </div>
            )}
            </div>
          </div>

          

          <DataTable
            value={featureData}
            rows={20}
            paginator
            showGridlines
            globalFilter={globalFilter}
            emptyMessage="No records found"
            className="p-datatable-rounded border border-1 text-center"
            loading={loading}
          >
            <Column
              field="name"
              align={"center"}
              header="Name"
              className="border border-1 text-capitalize"
              body={(rowData) => (
                loading ? <Skeleton width="80%" /> : rowData.name
              )}
            ></Column>


            <Column
              field="product"
              align={"center"}
              className="border border-1 text-capitalize"
              header="Product"
              body={(rowData) => (
                loading ? <Skeleton width="80%" /> : rowData.product
              )}
            ></Column>
           
            <Column
              align={"center"}
              className="border border-1 w-25"
              header="Actions"
              // style={{ width: "150px" }} 
              body={(rowData) => (
                loading ? (
                  <div className="d-flex justify-content-center">
                    <Skeleton shape="circle" size="2rem" className="me-2" />
                    <Skeleton shape="circle" size="2rem" />
                  </div>
                ) : (
                <div className="d-flex flex-nowrap justify-content-center align-items-center">


                  <button
                    className="btn btn-info me-3 custom-btn-action1 custom-target-icon"
                    data-pr-tooltip="View Record"
                    data-pr-position="top"
                    onClick={() => handleView(rowData.feature_request_id)}
                  >
                    <i className="ri-gallery-view-2 ri-lg"></i>
                  </button>

                  <button
                    className="btn btn-danger active text-align custom-btn-action1 custom-target-icon "
                    data-pr-tooltip="Delete Record"
                    data-pr-position="top"
                    onClick={() => openDeleteModal(rowData)}

                  >
                    <i className="ri-delete-bin-line ri-lg"></i>
                  </button>
     
                </div>
                ) 
              )}
            ></Column>
          </DataTable>
        </div>
        <Modal show={showModal} onHide={closeModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure?
          </Modal.Body>
          <Modal.Footer>
           
            <div className="d-flex justify-content-between w-100 ms-0 me-0">
                          <button
                            onClick={closeModal}
                            className="btn btn-outline-secondary rounded-pill btn-sm"
                          >
                            <i className="ri-close-large-line me-2"></i>
                            <span>Cancel</span>
                          </button>
                          <button
                            onClick={handleDelete}
                            disabled={deleteLoading}
                            className="btn btn-danger rounded-pill btn-sm "
                           
                          >
                           <i className="ri-delete-bin-line me-2"></i>
                            {deleteLoading ? "Deleting" : "Delete "}
                          </button>
                        </div>
          </Modal.Footer>
        </Modal>

        <CardLinks></CardLinks>
      </div>
      <Footer />
      <Toast ref={toast} />
      <ConfirmDialog />
    </div>
  );
};

export default AdminFeatureRequest;

import React ,{useEffect}from "react";
import FAQs from "./FAQs";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";
import MetaTags from "./MetaTags";

function CookiePolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MetaTags />
      <LandingHeader />
      <section
        id="landingPricing"
        className="container-p-y bg-body landing-pricing pt-10 mt-10"
      >
        
        <div className="container-xxl bg-icon-left position-relative ">
        <h4 className="text-center mt-10 mb-5"><span className=" fw-semibold badge rounded-pill bg-label-primary"> <i className="ri-emoji-sticker-line me-3"></i>Cookie Policy</span> </h4>
          <p>
            Our Privacy Policy forms part of, dan must be read in conjunction
            with, website Terms and Conditions. We reserve the right to change
            this Privacy Policy at any time. We respect the privacy of our users
            and every person who visits our site quantbotsecurities.com. Here,
            (“we”, “us”, or “our”). We are committed to protecting your personal
            information and your right to privacy in accordance with the General
            Data Protection Regulation (GDPR). If you have any questions or
            concerns about our policy or our practices with regards to your
            personal information, please contact us at
            admin@quantbotsecurities.com. When you visit our website
            quantbotsecurities.com (“Site”), and use our services, you trust us
            with your personal information. We take your privacy very seriously.
            In this privacy notice, we describe our privacy policy. We seek to
            explain to you in the clearest way possible what information we
            collect, how we use it and what rights you have in relation to it.
            We hope you take some time to read through it carefully, as it is
            important. If there are any terms in this privacy policy that you do
            not agree with, please discontinue the use of our site and our
            services. This privacy policy applies to all information collected
            through our website, and/or any related services, sales, marketing
            or events (we refer to them collectively in this privacy policy as
            the “Site“).
          </p>

          <h5 className="h5 mt-3">WHAT INFORMATION DO WE COLLECT?</h5>
          <p>
            The Individual Data you uncover to us We gather individual data that
            you intentionally give to us while communicating an interest in
            getting data about us or our items and administrations, while taking
            part in exercises on the Site or in any case reaching us. The
            individual data that we gather relies upon the setting of your
            connections with us and the Site, the decisions you make and the
            items and elements you use. The individual data we gather can
            incorporate the accompanying: Name and Contact Information. We
            gather your first and last name, email address, postal location,
            telephone number and other comparable contact information. Data
            consequently gathered We naturally gather specific data when you
            visit, utilize or explore the Site. This data doesn't uncover your
            particular personality (like your name or contact data) yet may
            incorporate gadget and utilization data, for example, your IP
            address, program and gadget attributes, working framework, language
            inclinations, alluding URLs, gadget name, country, area, data
            concerning how and when you utilize our Site and other specialized
            data. If you access our site with your cell phone, we may
            consequently gather gadget data (like your cell phone ID, model and
            producer), working framework, adaptation data and IP address. This
            data is basically expected to keep up with the security and activity
            of our Site, and for our inside investigation and detailing
            purposes. In the same way as other organizations, we additionally
            gather data through treats and comparative advancements. You can
            discover more with regards to this in our Treat Strategy. Data
            gathered from different Sources We might get data about you from
            different sources, like public data sets, joint promoting
            accomplices, web-based media stages (like Facebook), just as from
            other outsiders. If you have decided to prefer our pamphlet, your
            first name, last name and email address will be imparted to our
            bulletin supplier. This is to save you refreshed with data and
            offers for showcasing purposes.
          </p>



         

          <h5 className="h5 mt-3"> DO WE COLLECT INFORMATION FROM MINORS?</h5>
          <p>
            We do not knowingly solicit data from or market to children under 16
            years of age. By using the Site, you represent that you are at least
            16 or that you are the parent or guardian of such a minor and
            consent to such minor dependent’s use of the Site. If we learn that
            personal information from users less than 16 years of age has been
            collected, we will deactivate the account and take reasonable
            measures to promptly delete such data from our records. If you
            become aware of any data we have collected from children under age
            16, please contact us at admin@quantbotsecurities.com.
          </p>

         
          
        </div>
      </section>

      
      <LandingFooter />
    </div>
  );
}

export default CookiePolicy;

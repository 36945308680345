import React, { useState, useEffect } from "react";
import { ref, onValue, set, remove } from "firebase/database";
import { realtimeDb } from "../../firebase"; // Import Firebase Realtime Database setup
import SubHeaderS from "./SubHeaderS";
import StudentHeader from "./StudentHeader";
import { useNavigate } from "react-router-dom";

const StudentChat = () => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [teacher, setTeacher] = useState(null); // For storing teacher info
  const userName = localStorage.getItem("userName");
const navigate=useNavigate();
  const authToken = localStorage.getItem("authToken");
  const [teacherId, setTeacherId] = useState(null); // Store teacher ID from API response
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchTeacherDetails = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");
         if (!authToken || !userId) {
        navigate("/login");
          return;
        }
        const response = await fetch(
          `https://ghanish.in/api/common/get_teacher_details`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`, // Add JWT token in the header
            },
            body: JSON.stringify({
              user_id: userId, // Request body with student ID
            }),
          }
        );

        const data = await response.json();
        if (data.st === 1) {
          setTeacher(data.teacher_details); // Use teacher_details from API response
          setTeacherId(data.teacher_details.id); // Set the teacher ID
        } else {
          console.error("Failed to fetch teacher details", data.message);
        }
      } catch (error) {
        console.error("Error fetching teacher details:", error);
      }
    };

    if (userId && authToken) {
      fetchTeacherDetails();
    }
  }, [userId, authToken]);

  useEffect(() => {
    if (!teacherId || !userId) return;

    const chatPath = `chats/${userId}_${teacherId}`;
    const messagesRef = ref(realtimeDb, chatPath);
    const unsubscribe = onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setMessages(Object.values(data));
      } else {
        setMessages([]);
      }
    });

    return () => unsubscribe();
  }, [teacherId, userId]);

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (message.trim() && teacherId) {
      const timestamp = Date.now();
      const newMessage = {
        sender: userId,
        message,
        timestamp,
      };

      try {
        await set(
          ref(realtimeDb, `chats/${userId}_${teacherId}/${timestamp}`),
          newMessage
        );
        await set(
          ref(realtimeDb, `chats/${teacherId}_${userId}/${timestamp}`),
          {
            ...newMessage,
            sender: teacherId,
          }
        );

        setMessage(""); // Clear the input field after sending
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };
  const sortedMessages = messages.sort((a, b) => a.timestamp - b.timestamp);



  return (
    <div>
      <StudentHeader />
      <SubHeaderS />

      <div className="container-xxl flex-grow-1 container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <a href="/student/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </a>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Chat
            </li>
          </ol>
        </nav>

        <div className="card app-chat overflow-hidden">
          <div className="row g-0">
            <div className="col app-chat-history bg-body">
              <div className="chat-history-wrapper">
                <div className="chat-history-header border-bottom bg-white">
                  <div className="d-flex justify-content-between align-items-center">
                    {teacher ? (
                      <div className="d-flex align-items-center">
                        <div className="avatar ">
                          <span className="avatar-initial rounded-circle bg-label-primary">
                            {teacher.name.charAt(0).toUpperCase()}
                          </span>
                        </div>
                        <div className="chat-contact-info ms-4">
                          <h6 className="m-0 fw-normal">{teacher.name}</h6>
                          <small className="user-status text-body">
                            {teacher.mobile}
                          </small>
                        </div>
                      </div>
                    ) : (
                      <p>Loading teacher info...</p>
                    )}
                   
                  </div>
                </div>

                <div
                  className="chat-history-body"
                  style={{ overflowY: "auto" }}
                >
                  <ul className="list-unstyled chat-history">
                    {messages.map((msg, index) => (
                      <li
                        key={index}
                        className={`chat-message ${
                          msg.sender === userId
                            ? "chat-message-right"
                            : "chat-message-left"
                        }`}
                      >
                         <div className={`d-flex ${msg.sender === userId ? "justify-content-end" : "justify-content-start"} overflow-hidden`}>
            {msg.sender !== userId && (
              <div className="flex-shrink-0 avatar me-3">
                <span className="avatar-initial rounded-circle bg-label-primary">
                  {teacher.name.charAt(0).toUpperCase()}
                </span>
              </div>
            )}
            <div className="chat-message-wrapper flex-grow-1">
              <div
                className={`chat-message-text ${
                  msg.sender === userId
                    ? "bg-primary text-white"
                    : "bg-white text-dark"
                }`}
              >
                <p className="mb-0">{msg.message}</p>
              </div>
              <div className="text-end text-muted mt-1">
                <small>
                  {new Date(msg.timestamp).toLocaleTimeString()}
                </small>
              </div>
            </div>
            {msg.sender === userId && (
              <div className="flex-shrink-0 avatar ms-3">
                <span className="avatar-initial rounded-circle bg-label-primary">
                  {userName.charAt(0).toUpperCase()}
                </span>
              </div>
            )}
          </div>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="chat-history-footer bg-white border border-1">
                  <form
                    className="form-send-message d-flex justify-content-between align-items-center mb-0"
                    onSubmit={handleSendMessage}
                  >
                    <input
                      type="text"
                      className="form-control message-input me-4 shadow-none"
                      placeholder="Type your message here..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <button type="submit" className="btn btn-primary d-flex">
                      <span className="align-middle">Send</span>
                      <i className="ri-send-plane-line ri-16px ms-md-1_5 ms-0"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentChat;

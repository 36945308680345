import React from "react";
import faq from "../../../assets/img/products/sitting-girl-with-laptop.png";


import MetaTags from "./MetaTags";
const Features = () => {
  return (
    <div>
      <MetaTags />
  
      <section
        id="landingReviews"
        className=" bg-body landing-reviews pt-10 mt-5"
      >
        <div className="container-xxl">
        
        </div>
      </section>
  
     
    

      <section id="landingFAQ" class=" bg-body landing-faq">
    <div class="container-xxl bg-icon-right">
    <h4 class="text-center  mb-2"><span class=" badge fw-semibold rounded-pill bg-label-primary"> <i className="ri-emoji-sticker-line me-3"></i>FAQ</span> </h4>

      <h5 class="text-center mb-2">Frequently asked<span class="h4 fw-bold"> questions</span></h5>
      <p class="text-center fw-medium mb-4 mb-md-12 pb-4">
        Browse through these FAQs to find answers to commonly asked questions.
      </p>
      <div class="row gy-5">
        <div class="col-lg-5">
          <div class="text-center">
            <img src={faq}
           alt="sitting girl with laptop" class="faq-image scaleX-n1-rtl" />
          </div>
        </div>
        <div class="col-lg-7">
          <div class="accordion" id="accordionFront">
            <div class="accordion-item">
              <h2 class="accordion-header" id="head-One">
                <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionOne" aria-expanded="true" aria-controls="accordionOne">
                  Do you charge for each upgrade?
                </button>
              </h2>

              <div id="accordionOne" class="accordion-collapse collapse" data-bs-parent="#accordionFront" aria-labelledby="accordionOne">
                <div class="accordion-body">
                  Lemon drops chocolate cake gummies carrot cake chupa chups muffin topping. Sesame snaps icing
                  marzipan gummi bears macaroon dragée danish caramels powder. Bear claw dragée pastry topping
                  soufflé. Wafer gummi bears marshmallow pastry pie.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="head-Two">
                <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionTwo" aria-expanded="false" aria-controls="accordionTwo">
                  Do I need to purchase a license for each website?
                </button>
              </h2>
              <div id="accordionTwo" class="accordion-collapse collapse" aria-labelledby="accordionTwo" data-bs-parent="#accordionFront">
                <div class="accordion-body">
                  Dessert ice cream donut oat cake jelly-o pie sugar plum cheesecake. Bear claw dragée oat cake
                  dragée ice cream halvah tootsie roll. Danish cake oat cake pie macaroon tart donut gummies. Jelly
                  beans candy canes carrot cake. Fruitcake chocolate chupa chups.
                </div>
              </div>
            </div>
            <div class="accordion-item active">
              <h2 class="accordion-header" id="head-Three">
                <button type="button" class="accordion-button" data-bs-toggle="collapse" data-bs-target="#accordionThree" aria-expanded="true" aria-controls="accordionThree">
                  What is regular license?
                </button>
              </h2>
              <div id="accordionThree" class="accordion-collapse collapse show" aria-labelledby="accordionThree" data-bs-parent="#accordionFront">
                <div class="accordion-body">
                  Regular license can be used for end products that do not charge users for access or service(access
                  is free and there will be no monthly subscription fee). Single regular license can be used for
                  single end product and end product can be used by you or your client. If you want to sell end
                  product to multiple clients then you will need to purchase separate license for each client. The
                  same rule applies if you want to use the same end product on multiple domains(unique setup). For
                  more info on regular license you can check official description.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="head-Four">
                <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionFour" aria-expanded="false" aria-controls="accordionFour">
                  What is extended license?
                </button>
              </h2>
              <div id="accordionFour" class="accordion-collapse collapse" aria-labelledby="accordionFour" data-bs-parent="#accordionFront">
                <div class="accordion-body">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis et aliquid quaerat possimus maxime!
                  Mollitia reprehenderit neque repellat deleniti delectus architecto dolorum maxime, blanditiis
                  earum ea, incidunt quam possimus cumque.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="head-Five">
                <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionFive" aria-expanded="false" aria-controls="accordionFive">
                  Which license is applicable for SASS application?
                </button>
              </h2>
              <div id="accordionFive" class="accordion-collapse collapse" aria-labelledby="accordionFive" data-bs-parent="#accordionFront">
                <div class="accordion-body">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi molestias exercitationem ab cum
                  nemo facere voluptates veritatis quia, eveniet veniam at et repudiandae mollitia ipsam quasi
                  labore enim architecto non!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
 
      <div className="bg-white">
       
        <section
          id="landingReviews"
          className=" bg-body landing-reviews pt-10 mt-5"
        >
          <div className="container-xxl"></div>
        </section>

        <section id="landingFunFacts" className="section-py landing-fun-facts py-12 my-4">
        <div className="container-xxl">
  <div className="row gx-0 gy-5 gx-sm-6">
    <div className="col-6 col-md-3 col-lg-3 text-center">
      <span className="badge rounded-pill bg-label-hover-primary">
        <i className="ri ri-cloud-line fs-2"></i>
      </span>
      <h2 className="fw-bold mb-0 fun-facts-text">137+</h2>
      <h6 className="mb-0 text-body">Completed Sites</h6>
    </div>
    <div className="col-6 col-md-3 col-lg-3 text-center">
      <span className="badge rounded-pill bg-label-hover-success">
        <i className="ri ri-time-line fs-2"></i>
      </span>
      <h2 className="fw-bold mb-0 fun-facts-text">1,100+</h2>
      <h6 className="mb-0 text-body">Working Hours</h6>
    </div>
    <div className="col-6 col-md-3 col-lg-3 text-center">
      <span className="badge rounded-pill bg-label-hover-warning">
        <i className="ri ri-user-smile-line fs-2"></i>
      </span>
      <h2 className="fw-bold mb-0 fun-facts-text">137+</h2>
      <h6 className="mb-0 text-body">Happy Customers</h6>
    </div>
    <div className="col-6 col-md-3 col-lg-3 text-center">
      <span className="badge rounded-pill bg-label-hover-info">
        <i className="ri ri-award-line fs-2"></i>
      </span>
      <h2 className="fw-bold mb-0 fun-facts-text">23+</h2>
      <h6 className="mb-0 text-body">Awards Winning</h6>
    </div>
  </div>
</div>


  </section>
       
      </div>
    </div>
  );
};

export default Features;

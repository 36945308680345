// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database"; // For real-time database

const firebaseConfig = {
    apiKey: "AIzaSyDqxWPPaL4G5ceLkht2NrpBbqjrCbeB8pE",
    authDomain: "chat-application-f4a6f.firebaseapp.com",
    databaseURL: "https://chat-application-f4a6f-default-rtdb.firebaseio.com",
    projectId: "chat-application-f4a6f",
    storageBucket: "chat-application-f4a6f.appspot.com",
    messagingSenderId: "122176702310",
    appId: "1:122176702310:web:f8236835077c8243b12aa7",
    measurementId: "G-251RE6J9QV"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const realtimeDb = getDatabase(app); // For real-time chat

export { db, auth, realtimeDb };

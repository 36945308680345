import React, { useEffect, useState,useRef} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer"
import AdminSubHeader from "./AdminSubHeader";
import { Link, useNavigate,useParams } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import CardLinks from "../../../component/CardLinks";
import axios from "axios";
import config from "../../../component/config";
import { Toast } from "primereact/toast";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
const TeacherReport = () => {
  const { teacherId } = useParams();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [backClicked, setBackClicked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isRefreshing,setIsRefreshing] = useState(true);
  const [error, setError] = useState(null);
  const toast = useRef(null);
  const [summary, setSummary] = useState({
    total_trades_count: 0,
    total_profitable_trades: 0,
    total_losing_trades: 0,
    total_commission: 0.0,
  });

  const fetchData = async () => {
    setLoading(true);
    setError(null);
  
    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
       if (!authToken || !userId) {
      navigate("/login");
        return;
      }
  
      const response = await axios.post(
        `${config.apiDomain}/api/admin/month_wise_teacher_report`,
        {
          teacher_id: teacherId,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
          },
        }
      );
  
      if (response.data && response.data.st === 1) {
        setData(response.data.completed_trades_per_month);
        setSummary(response.data.completed_trades);
      } else {
        setError(new Error("No data found"));
      }
        }catch (error) {
      console.error('Error fetching data:', error);
      // Check for 401 Unauthorized error
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized access, redirecting to common login screen');
        navigate('/login'); // Navigate to common login screen on 401 error
      } else {
        const errorMsg = error.response ? error.response.data.msg || 'Failed to fetch data' : error.message || 'Failed to fetch data';
        setError(new Error(errorMsg));
       
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setLoading(true);
    setError(null);
    setIsRefreshing(true);
  
    try {
      const authToken = localStorage.getItem("authToken");
    
      if (!authToken) {
        throw new Error("Auth token not found");
      }
  
      const response = await axios.post(
        `${config.apiDomain}/api/admin/month_wise_teacher_report`,
        {
          teacher_id: teacherId,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      const { st, msg, completed_trades_per_month, completed_trades } = response.data;
  
      if (st === 1) {
        setData(completed_trades_per_month);
        setSummary(completed_trades);
        // window.showToast("success", msg || "Data fetched successfully.");
      } else {
        // Handle other `st` values with custom toasts
        switch (st) {
          case 2:
            setError(new Error(msg || "Error: Critical issue encountered"));
            window.showToast("danger", msg || "An error occurred. Please check.");
            break;
          case 3:
            setError(new Error(msg || "Warning: Potential issue"));
            window.showToast("warning", msg || "Warning: Please review the details.");
            break;
          case 4:
            setError(new Error(msg || "Info: Additional information"));
            window.showToast("info", msg || "Info: Check the provided information.");
            break;
          default:
            const errorMsg = msg || "Something went wrong. Contact Support.";
            setError(new Error(errorMsg));
            window.showToast("error", errorMsg);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
  
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access, redirecting to common login screen");
        navigate("/login");
      } else {
        const errorMsg = error.response?.data?.msg || error.message || "Something went wrong. Contact Support.";
        setError(new Error(errorMsg));
        window.showToast("error", errorMsg);
      }
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };
  

  
  useEffect(() => {
    fetchData();
  }, []); // Fetch data on component mount
  

  const CommissionBodyTemplate = (rowData) => {
    return ` ${rowData.commission} Rs.`;
  };
  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  return (
    <>
      <Toast ref={toast} />
      <AdminHeader />
      <AdminSubHeader />

      <div className="container-xxl container-p-y">
      <nav aria-label="breadcrumb">
      <div className="d-flex justify-content-between align-items-center">
      <ol className="breadcrumb breadcrumb-style1 text-black mb-3">
    <li className="breadcrumb-item">
      <Link to="/admin/dashboard" className="text-black">
      <i className="ri-home-7-line ri-lg"></i>
      </Link>
    </li>
    
    <li className="breadcrumb-item">
      <Link to="/admin/teacher_list" className="text-black">
      Teacher Report
      </Link>
  
    </li>
  
    <li className="breadcrumb-item active text-secondary" aria-current="page">
   Monthwise  
    </li>
  </ol>
  <SubscriptionBadge></SubscriptionBadge>
  </div>
</nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col-3 text-start mb-5 ">
            <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-7 text-center mb-5">
              <h5 className="mb-0">Monthwise  </h5>
            </div>
            <div className="col-2"></div>
          </div>

          <div className="d-flex justify-content-start mb-3">
           
            <IconField iconPosition="left">
              <InputIcon className="ri ri-search-line"> </InputIcon>
              <InputText
                type="search"
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="rounded"
              />
            </IconField>
            {loading ? (
              <div className="d-flex align-items-center">
                <i
                  className={`ri-refresh-line ri-lg ms-3 p-text-secondary ${
                    isRefreshing ? "rotate" : ""
                  }`}
                  role="status"
                ></i>
              </div>
            ) : (
              <div className="mt-3">
                <Tooltip target=".custom-target-icon" />
                <i
                  className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                  role="button"
                  data-pr-tooltip="Reload"
                  onClick={handleRefresh}
                  data-pr-position="top"
                ></i>
              </div>
            )}
          </div>
          <DataTable
                         className="custom-column-border"

            align="center"
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
          >
            <Column
              align="center"
              className="custom-column-border"
              field="month_name"
              header="Month"
              
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="trades_count"
              header="Total Trades"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="profitable_trades"
              header="Profitable Trades"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="losing_trades"
              header="Losing Trades"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="commission"
              header="Commission"
              body={CommissionBodyTemplate}
            ></Column>

            <Column
              align="center"
              className="custom-column-border"
              header="Actions"
              body={(rowData) => (
                <Link
                  to={`/admin/teacher_report_datewise/${teacherId}/${rowData.month_name}`}
                >
                  <button className="btn btn-info custom-btn-action1 custom-target-icon"  data-pr-tooltip="View Record"
                    data-pr-position="top">
                    <i className="ri-gallery-view-2"></i>
                  </button>
                </Link>
              )}
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>

      <Footer />
    </>
  );
};

export default TeacherReport;


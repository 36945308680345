// import React, { useEffect, useState, lazy, Suspense } from "react";
// import axios from "axios";
// import Header from "../component/Header";
// import Footer from "../../../component/Footer";
// import SubHeader from "../component/SubHeader";
// import config from "../../../component/config";
// import { Modal, Button } from "react-bootstrap";
// import { useNavigate,Link } from "react-router-dom";
// import { Tooltip } from "primereact/tooltip";
// import CardLinks from "../../../component/CardLinks";
// import SubscriptionBadge from "../../../component/SubscriptionBadge";
// import daisy from "../../../assets/img/illustrations/illustration-john.png"

// const Home = () => {
//   const [data, setData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [showPopup, setShowPopup] = useState(false);
//   const [studentCount, setStudentCount] = useState({
//     total_student_count: 0,
//     active_student_count: 0,
//     inactive_student_count: 0,
//   });
//   const navigate = useNavigate();
  // useEffect(() => {
  //   const fetchTeacherHomeData = async () => {
  //     try {
  //       const authToken = localStorage.getItem("authToken");
  //       const userId = localStorage.getItem("userId");

  //       if (!authToken || !userId) {
  //         navigate("/login");
  //         return;
  //       }

  //       const response = await axios.post(
  //         `${config.apiDomain}/api/teacher/teacher_home`,
  //         {
  //           teacher_id: userId,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${authToken}`,
  //           },
  //         }
  //       );

  //       if (response.data.st === 1) {
  //         setStudentCount(response.data.student_count);
  //       } else {
  //         console.error(
  //           "Failed to fetch teacher home data:",
  //           response.data.msg
  //         );
  //       }
  //     } catch (error) {
  //       if (error.response && error.response.status === 401) {
         
  //         navigate("/login");
  //       } else {
  //       }
  //       console.clear(); 
  //     }
  //   };

  //   fetchTeacherHomeData();
  // }, []);

//   useEffect(() => {
//     const checkTime = () => {
//       const now = new Date();
//       const hours = now.getHours();
//       const minutes = now.getMinutes();

//       if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
//         setShowPopup(true);
//       }
//     };

//     const interval = setInterval(() => {
//       checkTime();
//     }, 60000);

//     return () => clearInterval(interval);
//   }, []);

//   const handleClosePopup = () => {
//     setShowPopup(false);
//   };

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

  // const getButtonVariant = () => {
  //   const now = new Date();
  //   const hours = now.getHours();

  //   if (hours === 9) {
  //     return "success";
  //   } else if (hours === 15) {
  //     return "danger";
  //   }
  //   return "secondary";
  // };

//   const shouldShowCongratulationsCard =
//     studentCount.total_student_count === 0 &&
//     studentCount.active_student_count === 0 &&
//     studentCount.inactive_student_count === 0;
//   return (
//     <div>
//       <Header />
//       <SubHeader />
     
//       <Modal
//         show={showPopup}
//         onHide={handleClosePopup}
//         dialogClassName={getColorModalClass()}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>{getModalTitle()}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p>{getModalBody()}</p>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant={getButtonVariant()} onClick={handleClosePopup}>
//             Ok
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
//   <div className="layout-container">
//     <div className="container-xxl flex-grow-1 container-p-y">
//       <div className="row g-6">
//         {studentCount.total_student_count > 0 && (
//           <div className="col-6 col-sm-6 col-lg-2">
//             <Link to="/teacher/manage_student">
//             <div className="card card-border-shadow-primary h-100">
//               <div className="card-body">
//                 <div className="d-flex align-items-center mb-2">
//                   <div className="avatar me-4">
//                     <span className="avatar-initial rounded bg-label-primary">
//                       <i className="ri-group-3-line ri-xl"></i>
//                     </span>
//                   </div>
//                   <h4 className="mb-0 fs-3">
//                     {studentCount.total_student_count}
//                   </h4>
//                 </div>
//                 <h6 className="mb-0 fw-normal"> Total Students</h6>
//               </div>
//             </div>
//             </Link>
//           </div>
//         )}
//         {studentCount.active_student_count > 0 && (
//           <div className="col-6 col-sm-6 col-lg-2">
//             <Link to="/teacher/manage_student">
//             <div className="card card-border-shadow-primary h-100">
//               <div className="card-body">
//                 <div className="d-flex align-items-center mb-2">
//                   <div className="avatar me-4">
//                     <span className="avatar-initial rounded bg-label-primary">
//                       <i className="ri-group-3-line ri-xl"></i>
//                     </span>
//                   </div>
//                   <h4 className="mb-0 fs-3">
//                     {studentCount.active_student_count}
//                   </h4>
//                 </div>
//                 <h6 className="mb-0 fw-normal">Total Active Students</h6>
//               </div>
//             </div>
//             </Link>
//           </div>
//         )}
//         {studentCount.inactive_student_count > 0 && (
//           <div className="col-6 col-sm-6 col-lg-2">
//             <Link to="/teacher/manage_student">
//             <div className="card card-border-shadow-primary h-100">
//               <div className="card-body">
//                 <div className="d-flex align-items-center mb-2">
//                   <div className="avatar me-4">
//                     <span className="avatar-initial rounded bg-label-primary">
//                       <i className="ri-group-3-line ri-xl"></i>
//                     </span>
//                   </div>
//                   <h4 className="mb-0 fs-3">
//                     {studentCount.inactive_student_count}
//                   </h4>
//                 </div>
//                 <h6 className="mb-0 fw-normal">
//                   Total Inactive Students
//                 </h6>
//               </div>
//             </div>
//             </Link>
//           </div>
//         )}
      
//       {shouldShowCongratulationsCard && (
//                 <div className="col-lg-12 col-12">
//                   <div className="card mt-6">
//                     <div className="row">
//                       <div className="col-md-6">
//                         <div className="card-body">
//                           <h4 className="card-title text-truncate mb-9">
//                             Congratulations Daisy!🎉
//                           </h4>
//                           <p className="mb-0">You have done 84% 😍 more tasks today.</p>
//                           <p className="mb-0">Check your new badge in your profile.</p>
//                         </div>
//                       </div>
//                       <div className="col-md-6 position-relative text-center">
//                         <img
//                           src={daisy}
//                           className="card-img-position bottom-0 w-auto end-0 scaleX-n1-rtl"
//                           alt="View Profile"
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               )}
//            <CardLinks></CardLinks>
//       </div>
//     </div>
//   </div>

// </div>



   
//         <Footer />
  
//     </div>
//   );
// };

// export default Home;

// const getColorModalClass = () => {
//   const now = new Date();
//   const hours = now.getHours();

//   if (hours === 9 || hours === 15) {
//     return hours === 9 ? "modal-green" : "modal-red";
//   }
//   return "";
// };

// const getModalTitle = () => {
//   const now = new Date();
//   const hours = now.getHours();

//   if (hours === 9) {
//     return "Market is Open!";
//   } else if (hours === 15) {
//     return "Market is Closed!";
//   }
//   return "";
// };

// const getModalBody = () => {
//   const now = new Date();
//   const hours = now.getHours();

//   if (hours === 9) {
//     return "Market is currently open. Take necessary actions.";
//   } else if (hours === 15) {
//     return "Market is currently closed. Come back tomorrow.";
//   }
//   return "";
// };

import introJs from 'intro.js';
import 'intro.js/introjs.css'; 

import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../component/Header";
import Footer from "../../../component/Footer";
import SubHeader from "../component/SubHeader";
import config from "../../../component/config";
import { useNavigate, Link } from "react-router-dom";
import CardLinks from "../../../component/CardLinks";
import daisy from "../../../assets/img/illustrations/illustration-john.png";

import Skeleton from "react-loading-skeleton";
const Home = () => {
  const [studentCount, setStudentCount] = useState({
    total_student_count: 0,
    active_student_count: 0,
    inactive_student_count: 0,
  });



  const userName = localStorage.getItem("userName");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchTeacherHomeData = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");

        if (!authToken || !userId) {
          navigate("/login");
   
          return;
        }

        const response = await axios.post(
          `${config.apiDomain}/api/teacher/teacher_home`,
          { teacher_id: userId },
          { headers: { Authorization: `Bearer ${authToken}` } }
        );

        if (response.data.st === 1) {
          setStudentCount(response.data.student_count);
        } else {
          console.error("Failed to fetch teacher home data:", response.data.msg);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/login");
        } else {
          console.clear();
        }
      } finally {
        setLoading(false);
      }
    };
    fetchTeacherHomeData();
  }, [navigate]);


  const newLogin = localStorage.getItem("newLogin");


  // Function to dynamically generate Intro.js steps based on card visibility
  const getIntroSteps = () => {
    const steps = [];

    steps.push({
      element: '.tour-button',
      intro: 'Welcome to the Teacher Dashboard! ',
    });
    
    
    steps.push({
      element: '.content-wrapper',
      intro: 'Welcome to the Teacher Dashboard! This is the header where you can navigate through the app.',
    });
    steps.push({
      element: '.user-intro',
      intro: 'Welcome to the Teacher Dashboard! This is the header where you can navigate through the app.',
    });
   
    if (studentCount.total_student_count > 0) {
      steps.push({
        element: '.student-count-section',
        intro: 'Here, you can see the total number of students.',
      });
    }

  
    if (studentCount.active_student_count > 0) {
      steps.push({
        element: '.student-count-section1',
        intro: 'Here, you can see the number of active students.',
      });
    }

   
    if (studentCount.inactive_student_count > 0) {
      steps.push({
        element: '.student-count-section2',
        intro: 'Here, you can see the number of inactive students.',
      });
    }
    if (shouldShowCongratulationsCard) {
      steps.push({
        element: '.empty-card-home',
        intro: 'Here, please click and create a student.',
      });
    }
    // Add step for the footer
    steps.push({
      element: '.footer',
      intro: 'This is the footer, where you can find additional information and links.',
    });

    return steps;
  };

  
  const startTour = () => {
    const intro = introJs();
    intro.setOptions({
      steps: getIntroSteps(),  
      showProgress: true,      
      showBullets: true,       
    });

    intro.start();  
  };

  const shouldShowCongratulationsCard =
    studentCount.total_student_count === 0 &&
    studentCount.active_student_count === 0 &&
    studentCount.inactive_student_count === 0;

  return (
    <div>
      <Header className="user-intro profile-name-display" />
      <SubHeader className=" content-wrapper" />

     

      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row g-6">
            {loading ? (
        // Skeletons while loading
        <>
          <div className="col-6 col-sm-6 col-lg-2">
            <Skeleton height={150} />
          </div>
          <div className="col-6 col-sm-6 col-lg-2">
            <Skeleton height={150} />
          </div>
          <div className="col-6 col-sm-6 col-lg-2">
            <Skeleton height={150} />
          </div>
        </>
      ) : (
        <>
              {studentCount.total_student_count > 0 && (
                <div className="col-6 col-sm-6 col-lg-2 student-count-section">
                  <Link to="/teacher/manage_student">
                    <div className="card card-border-shadow-primary h-100">
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-2">
                          <div className="avatar me-4">
                            <span className="avatar-initial rounded bg-label-primary">
                              <i className="ri-group-3-line ri-xl"></i>
                            </span>
                          </div>
                          <h4 className="mb-0 fs-3">
                            {studentCount.total_student_count}
                          </h4>
                        </div>
                        <h6 className="mb-0 fw-normal"> Total Students</h6>
                      </div>
                    </div>
                  </Link>
                </div>
              )}
              {studentCount.active_student_count > 0 && (
                <div className="col-6 col-sm-6 col-lg-2 student-count-section1">
                  <Link to="/teacher/manage_student">
                    <div className="card card-border-shadow-primary h-100">
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-2">
                          <div className="avatar me-4">
                            <span className="avatar-initial rounded bg-label-primary">
                              <i className="ri-group-3-line ri-xl"></i>
                            </span>
                          </div>
                          <h4 className="mb-0 fs-3">
                            {studentCount.active_student_count}
                          </h4>
                        </div>
                        <h6 className="mb-0 fw-normal">Total Active Students</h6>
                      </div>
                    </div>
                  </Link>
                </div>
              )}
              {studentCount.inactive_student_count > 0 && (
                <div className="col-6 col-sm-6 col-lg-2 student-count-section2">
                  <Link to="/teacher/manage_student">
                    <div className="card card-border-shadow-primary h-100">
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-2">
                          <div className="avatar me-4">
                            <span className="avatar-initial rounded bg-label-primary">
                              <i className="ri-group-3-line ri-xl"></i>
                            </span>
                          </div>
                          <h4 className="mb-0 fs-3">
                            {studentCount.inactive_student_count}
                          </h4>
                        </div>
                        <h6 className="mb-0 fw-normal">Total Inactive Students</h6>
                      </div>
                    </div>
                  </Link>
                </div>
              )}
             
      
           

            {/* Congratulations card */}
            {shouldShowCongratulationsCard && (
              <div className="col-lg-12 col-12 empty-card-home">
                <div className="card mt-6">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card-body">
                        <h4 className="card-title text-truncate mb-9 text-capitalize">
                          Congratulations {userName}!🎉
                        </h4>
                        <p className="mb-0">You have done 84% 😍 more tasks today.</p>
                        <p className="mb-0">Check your new badge in your profile.</p>
                      </div>
                    </div>
                    <div className="col-md-6 position-relative text-center">
                      <img
                        src={daisy}
                        className="card-img-position bottom-0 w-auto end-0 scaleX-n1-rtl"
                        alt="View Profile"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            </>
          )}
           </div>

           <div className="row my-2">
  <div className="col-12 d-flex justify-content-between align-items-center flex-nowrap">
    {/* Left Section: Intro and Feature Request */}
    <div className="d-flex align-items-center flex-shrink-1">
      <div onClick={startTour} className="text-secondary tour-button me-3" role="button">
        <i className="ri-information-2-line"></i> Intro
      </div>
      <a href="/teacher/feature_request" target="_blank" className="text-secondary">
        <i className="ri-sparkling-line"></i> Feature Request
      </a>
    </div>

    {/* Right Section: Support and Help Center */}
    <div className="d-flex align-items-center flex-shrink-1">
      <a href="/contact" target="_blank" className="text-secondary me-3">
        <i className="ri-customer-service-2-line"></i> Support
      </a>
      <a href="/help_center" target="_blank" className="text-secondary">
        <i className="ri-questionnaire-line"></i> Help Center
      </a>
    </div>
  </div>
</div>



          </div>
        </div>
      </div>

      <Footer className="footer" />

     
     
    </div>
  );
};

export default Home;




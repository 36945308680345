import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import zerodhaImg from "../../../assets/img/products/company/zerodha.png";
import dhanImg from "../../../assets/img/products/company/dhan.png";
import upStockImg from "../../../assets/img/products/company/upstock.png";
import angleOneImg from "../../../assets/img/products/company/angle_one.png";
import heroElement from "../../../assets/img/products/3d-business-man.png";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";
import ServicesOffered from "./ServicesOffered";
import Features from "./Features";
import MetaTags from "./MetaTags";
import PricingPlanComponent from "./PricingPlanComponent";
import Marquee from 'react-fast-marquee';

// Marquee configuration object
const marqueeConfig = {
  // Animation settings
  speed: 80,                    // Scroll speed (lower = slower)
  gap: 60,                      // Gap between logos in pixels
  gradientWidth: 150,           // Width of fade effect on edges
  logoHeight: 35,               // Height of logos
  backgroundColor: '#f4f5fa',   // Background color
  
  // Gradient settings
  gradient: {
    start: '#f4f5fa',
    stops: {
      '20%': 'rgba(244, 245, 250, 0.9)',
      '40%': 'rgba(244, 245, 250, 0.7)',
      '80%': 'rgba(244, 245, 250, 0.4)',
      '100%': 'rgba(244, 245, 250, 0)',
    }
  }
};

function LandingHome() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MetaTags />
      <LandingHeader />
      <div data-bs-spy="scroll" className=" scrollspy-example pt-10 mt-5 ">
        <section id="landingHero" className="position-relative bg-white ">
          
          <div className="container-xxl">
            <div className="row align-items-center">
              <div className="col-lg-6 ">
                <div className="text-center text-lg-start">
                  <h2 className="fs-1 hero-title mb-4 poppins-bold text-start pt-5">
                    A Digital Agency <br />
                    Specialized in AI and <br />
                    WEB 3.0
                  </h2>
                  <div className="fs-5 mb-8 lh-md ">
                    <p className="pb-0 mb-0 text-start ">
                      We work in a customized way of devising and handling
                      entirely online projects with long-term partnerships.
                    </p>
                  </div>
                  <div className="">
                    <Link to="/signup">
                    <button
                      type="button"
                      className="btn rounded-pill btn-outline-info waves-effect mx-2"
                    >
                      Get Started
                    </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex justify-content-center justify-content-lg-end">
                <img
                  src={heroElement}
                  alt="hero elements"
                  className="img-fluid"
                  style={{
                   
                    width: "auto",
                    height: "auto", 
                    maxWidth: "100%", 
                  }}
                />
              </div>
            </div>
          </div>
        </section>
<div className="d-flex justify-content-center">
  <div className="marquee-container" style={{ 
    position: 'relative',
    padding: '30px 0',
    background: marqueeConfig.backgroundColor,
    overflow: 'hidden',
    width: '100%',
    margin: '0 auto'
  }}>
    {/* Left gradient overlay */}
    <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: `${marqueeConfig.gradientWidth}px`,
      height: '100%',
      background: `linear-gradient(to right, 
        ${marqueeConfig.gradient.start}, 
        ${marqueeConfig.gradient.stops['20%']} 20%, 
        ${marqueeConfig.gradient.stops['40%']} 40%, 
        ${marqueeConfig.gradient.stops['80%']} 80%, 
        ${marqueeConfig.gradient.stops['100%']})`,
      zIndex: 2
    }}></div>
    
    {/* Right gradient overlay */}
    <div style={{
      position: 'absolute',
      top: 0,
      right: 0,
      width: `${marqueeConfig.gradientWidth}px`,
      height: '100%',
      background: `linear-gradient(to left, 
        ${marqueeConfig.gradient.start}, 
        ${marqueeConfig.gradient.stops['20%']} 20%, 
        ${marqueeConfig.gradient.stops['40%']} 40%, 
        ${marqueeConfig.gradient.stops['80%']} 80%, 
        ${marqueeConfig.gradient.stops['100%']})`,
      zIndex: 2
    }}></div>

    <Marquee
      gradient={false}
      speed={marqueeConfig.speed}
      pauseOnHover={true}
      direction="left"
    >
      {/* First set of logos */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img 
          src={angleOneImg} 
          style={{ 
            height: `${marqueeConfig.logoHeight}px`, 
            width: "auto",
            marginRight: `${marqueeConfig.gap}px` 
          }} 
          alt="AngleOne" 
        />
        <img 
          src={zerodhaImg} 
          style={{ 
            height: `${marqueeConfig.logoHeight}px`, 
            width: "auto",
            marginRight: `${marqueeConfig.gap}px` 
          }} 
          alt="Zerodha" 
        />
        <img 
          src={dhanImg} 
          style={{ 
            height: `${marqueeConfig.logoHeight}px`, 
            width: "auto",
            marginRight: `${marqueeConfig.gap}px` 
          }} 
          alt="Dhan" 
        />
        <img 
          src={upStockImg} 
          style={{ 
            height: `${marqueeConfig.logoHeight}px`, 
            width: "auto",
            marginRight: `${marqueeConfig.gap}px` 
          }} 
          alt="UpStock" 
        />
      </div>
      
      {/* Duplicate set for smooth loop */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img 
          src={angleOneImg} 
          style={{ 
            height: `${marqueeConfig.logoHeight}px`, 
            width: "auto",
            marginRight: `${marqueeConfig.gap}px` 
          }} 
          alt="AngleOne" 
        />
        <img 
          src={zerodhaImg} 
          style={{ 
            height: `${marqueeConfig.logoHeight}px`, 
            width: "auto",
            marginRight: `${marqueeConfig.gap}px` 
          }} 
          alt="Zerodha" 
        />
        <img 
          src={dhanImg} 
          style={{ 
            height: `${marqueeConfig.logoHeight}px`, 
            width: "auto",
            marginRight: `${marqueeConfig.gap}px` 
          }} 
          alt="Dhan" 
        />
        <img 
          src={upStockImg} 
          style={{ 
            height: `${marqueeConfig.logoHeight}px`, 
            width: "auto",
            marginRight: `${marqueeConfig.gap}px` 
          }} 
          alt="UpStock" 
        />
      </div>
      
      {/* Duplicate set for smooth loop */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img 
          src={angleOneImg} 
          style={{ 
            height: `${marqueeConfig.logoHeight}px`, 
            width: "auto",
            marginRight: `${marqueeConfig.gap}px` 
          }} 
          alt="AngleOne" 
        />
        <img 
          src={zerodhaImg} 
          style={{ 
            height: `${marqueeConfig.logoHeight}px`, 
            width: "auto",
            marginRight: `${marqueeConfig.gap}px` 
          }} 
          alt="Zerodha" 
        />
        <img 
          src={dhanImg} 
          style={{ 
            height: `${marqueeConfig.logoHeight}px`, 
            width: "auto",
            marginRight: `${marqueeConfig.gap}px` 
          }} 
          alt="Dhan" 
        />
        <img 
          src={upStockImg} 
          style={{ 
            height: `${marqueeConfig.logoHeight}px`, 
            width: "auto",
            marginRight: `${marqueeConfig.gap}px` 
          }} 
          alt="UpStock" 
        />
      </div>
    </Marquee>
  </div>
</div>

  {/* <div className="container-xxl mt-5  bg-white mb-5"> */}
        
         <section id="landingFeatures" class="landing-features bg-white mt-5 pb-5"> 
       
    
      <h4 class="text-center   mb-2"><span class=" badge rounded-pill bg-label-primary fw-semibold "> <i className="ri-emoji-sticker-line me-3"></i>Fetures</span> </h4>
      <p class="text-center fw-medium mb-4 mb-md-12">
        Not just a set of tools, the package includes ready-to-deploy conceptual application.
      </p>
      <div class="features-icon-wrapper row gx-0 gy-12 gx-sm-6">
        <div class="col-lg-4 col-sm-6 text-center features-icon-box">
          <div class="features-icon mb-4">
          <i className="ri ri-cloud-line text-primary fs-1 mb-3"></i>
          </div>
          <h5 class="mb-2">Quality Code</h5>
          <p class="features-icon-description">
            Code structure that all developers will easily understand and fall in love with.
          </p>
        </div>
        <div class="col-lg-4 col-sm-6 text-center features-icon-box">
          <div class="features-icon mb-4">
          <i className="ri ri-cloud-line text-primary fs-1 mb-3"></i>
          </div>
          <h5 class="mb-2">Continuous Updates</h5>
          <p class="features-icon-description">
            Free updates for the next 12 months, including new demos and features.
          </p>
        </div>
        <div class="col-lg-4 col-sm-6 text-center features-icon-box">
          <div class="features-icon mb-4">
          <i className="ri ri-cloud-line text-primary fs-1 mb-3"></i>
          </div>
          <h5 class="mb-2">Stater-Kit</h5>
          <p class="features-icon-description">
            Start your project quickly without having to remove unnecessary features.
          </p>
        </div>
        <div class="col-lg-4 col-sm-6 text-center features-icon-box">
          <div class="features-icon mb-4">
          <i className="ri ri-cloud-line text-primary fs-1 mb-3"></i>
          </div>
          <h5 class="mb-2">API Ready</h5>
          <p class="features-icon-description">
            Just change the endpoint and see your own data loaded within seconds.
          </p>
        </div>
        <div class="col-lg-4 col-sm-6 text-center features-icon-box">
          <div class="features-icon mb-4">
          <i className="ri ri-cloud-line text-primary fs-1 mb-3"></i>
          </div>
          <h5 class="mb-2">Excellent Support</h5>
          <p class="features-icon-description">An easy-to-follow doc with lots of references and code examples.</p>
        </div>
        <div class="col-lg-4 col-sm-6 text-center features-icon-box">
          <div class="features-icon mb-4">
          <i className="ri ri-cloud-line text-primary fs-1 mb-3"></i>
          </div>
          <h5 class="mb-2">Well Documented</h5>
          <p class="features-icon-description">An easy-to-follow doc with lots of references and code examples.</p>
        </div>
      </div>
   
  </section>

        {/* </div> */}
        
        {/* SKILLS / BROKER  */}
        <div className="bg-white mt-10 pb-5">
        
           <div className="container-xxl  pt-4 mb-5">
        <div className="text-center mb-5 mt-8">
        <h4 class="text-center  mb-2"><span class="  badge rounded-pill bg-label-primary fw-semibold"> <i className="ri-emoji-sticker-line me-3"></i>Brokers</span> </h4>

          <p className="mb-1">
          Our UX and UI design proficiency ensures seamless
          </p>
          <p className="mt-1 mb-10">interaction
          in the digital realm.</p>
        </div>
      </div>

          <div className="container-xxl my-4">
            <div className="row">
              <div className="col-lg-6 col-md-12 mb-4">
                <div className="card d-flex flex-column shadow-none bg-transparent border border-secondary h-100">
                  <div className="card-body d-flex flex-column">
                    <div className="row align-items-center flex-grow-1">
                      <div className="col-6 d-flex justify-content-start">
                        <div
                          className=" text-start text-dark poppins-medium"
                          style={{ fontSize: "32px" }}
                        >
                          AngelOne
                        </div>
                      </div>
                      <div className="col-6 d-flex">
                        <img
                          src={angleOneImg}
                          alt="AngelOne"
                          className="img-fluid"
                          style={{ height: "30px", width: "150px" }}
                        />
                      </div>
                    </div>
                    <div className="row align-items-center mt-3">
                      <div className="col-6 d-flex align-items-center justify-content-center">
                        <p className="lh-base fs-6">
                          Bootstrap is a front-end framework that makes it easy
                          to create responsive and mobile-friendly websites.
                        </p>
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-center">
                        <div
                          className="progress"
                          style={{ height: "12px", width: "100%" }}
                        >
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            100%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12 mb-4">
                <div className="card d-flex flex-column shadow-none bg-transparent border border-secondary h-100">
                  <div className="card-body d-flex flex-column">
                    <div className="row align-items-center flex-grow-1">
                      <div className="col-6 d-flex justify-content-start">
                        <div
                          className=" text-start text-dark poppins-medium"
                          style={{ fontSize: "32px" }}
                        >
                          Zerodha
                        </div>
                      </div>
                      <div className="col-6 d-flex ">
                        <img
                          src={zerodhaImg}
                          alt="Zerodha"
                          className="img-fluid"
                          style={{ height: "30px", maxWidthwidth: "150px" }}
                        />
                      </div>
                    </div>
                    <div className="row align-items-center mt-3">
                      <div className="col-6 d-flex align-items-center justify-content-center">
                        <p className="lh-base fs-6">
                          Bootstrap is a front-end framework that makes it easy
                          to create responsive and mobile-friendly websites.
                        </p>
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-center">
                        <div
                          className="progress bg-light"
                          style={{
                            height: "12px",
                            width: "100%",
                          }}
                        >
                          <div
                            className="progress-bar bg-info"
                            role="progressbar"
                            style={{ width: "75%" }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                          75%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12 mb-4">
                <div className="card d-flex flex-column shadow-none bg-transparent border border-secondary h-100">
                  <div className="card-body d-flex flex-column">
                    <div className="row align-items-center flex-grow-1">
                      <div className="col-6 d-flex justify-content-start">
                        <div
                          className=" text-start text-dark poppins-medium"
                          style={{ fontSize: "32px" }}
                        >
                          Dhan
                        </div>
                      </div>
                      <div className="col-6 d-flex">
                        <img
                          src={dhanImg}
                          alt="Dhan"
                          className="img-fluid"
                          style={{ height: "30px", maxWidthwidth: "150px" }}
                        />
                      </div>
                    </div>
                    <div className="row align-items-center mt-3">
                      <div className="col-6 d-flex align-items-center justify-content-center">
                        <p className="lh-base fs-6">
                          Bootstrap is a front-end framework that makes it easy
                          to create responsive and mobile-friendly websites .
                        </p>
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-center">
                        <div
                          className="progress bg-light"
                          style={{ height: "12px", width: "100%" }}
                        >
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            100%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12 mb-4">
                <div className="card d-flex flex-column shadow-none bg-transparent border border-secondary h-100">
                  <div className="card-body d-flex flex-column">
                    <div className="row align-items-center flex-grow-1">
                      <div className="col-6 d-flex justify-content-start">
                        <div
                          className=" text-start text-dark poppins-medium"
                          style={{ fontSize: "32px" }}
                        >
                          Upstock
                        </div>
                      </div>
                      <div className="col-6 d-flex">
                        <img
                          src={upStockImg}
                          alt="UpStock"
                          className="img-fluid"
                          style={{ height: "30px", maxWidthwidth: "150px" }}
                        />
                      </div>
                    </div>
                    <div className="row align-items-center mt-3">
                      <div className="col-6 d-flex align-items-center justify-content-center">
                        <p className="lh-base fs-6">
                          Bootstrap is a front-end framework that makes it easy
                          to create responsive and mobile-friendly websites.
                        </p>
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-center">
                        <div
                          className="progress bg-light"
                          style={{ height: "12px", width: "100%" }}
                        >
                          <div
                            className="progress-bar bg-info"
                            role="progressbar"
                            style={{ width: "50%" }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            50%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

       
      </div>
      <Features />
      <ServicesOffered />
      <PricingPlanComponent></PricingPlanComponent>
      <LandingFooter />
    </>
  );
}

export default LandingHome;

import React, { useState, useEffect, useRef } from "react";
import AdminSubHeader from "./AdminSubHeader";
import Footer from "../../../component/Footer";
import config from "../../../component/config";
import AdminHeader from "./AdminHeader";
import { Toast } from 'primereact/toast';
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
const AdminDashboard = () => {
  const [message, setMessage] = useState("");
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [teacherCount, setTeacherCount] = useState({
    total_teacher_count: 0,
    active_teacher_count: 0,
    inactive_teacher_count: 0,
  });
  
  const [studentCount, setStudentCount] = useState({
    total_student_count: 0,
    active_student_count: 0,
    inactive_student_count: 0,
  });
  const navigate = useNavigate();
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");
        if (!authToken || !userId) {
          navigate("/login");
          return;
        }

        const response = await fetch(
          `${config.apiDomain}/api/admin/admin_home`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${authToken}`,
            },
          }
        );

        if (!response.ok) {
          if (response.status === 401) {
            navigate("/login");
            return;
          }
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setMessage(data.msg);
        setTeacherCount(data.teacher_count);
        setStudentCount(data.student_count);
      } catch (error) {
        console.error("Error fetching data:", error);
        const errorMsg = error.response ? error.response.data.msg || 'Failed to fetch data' : error.message || 'Failed to fetch data';
        setError(new Error(errorMsg));
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: errorMsg,
          life: 2000,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  return (
    <>
      <Toast ref={toast} />
      <AdminHeader />
      <AdminSubHeader />
      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row g-6">
            {loading ? (
        // Skeletons while loading
        <>
          <div className="col-6 col-sm-6 col-lg-2">
            <Skeleton height={150} />
          </div>
          <div className="col-6 col-sm-6 col-lg-2">
            <Skeleton height={150} />
          </div>
          <div className="col-6 col-sm-6 col-lg-2">
            <Skeleton height={150} />
          </div>
        </>
      ) : (
        <>
              {teacherCount.total_teacher_count > 0 && (
                <div className="col-6 col-sm-6 col-lg-2">
                     <Link to="/admin/manage_teacher">
                  <div className="card card-border-shadow-primary h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-2">
                        <div className="avatar me-4">
                          <span className="avatar-initial rounded bg-label-primary">
                            <i className="ri-shield-user-line ri-xl"></i>
                          </span>
                        </div>
                        <h4 className="mb-0 fs-3">{teacherCount.total_teacher_count}</h4>
                      </div>
                      <h6 className="mb-0 fw-normal">Total Teachers</h6>
                    </div>
                  </div>
                  </Link>
                </div>
              )}
              {teacherCount.active_teacher_count > 0 && (
                <div className="col-6 col-sm-6 col-lg-2">
                   <Link to="/admin/manage_teacher">
                  <div className="card card-border-shadow-primary h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-2">
                        <div className="avatar me-4">
                          <span className="avatar-initial rounded bg-label-primary">
                            <i className="ri-shield-user-line ri-xl"></i>
                          </span>
                        </div>
                        <h4 className="mb-0 fs-3">{teacherCount.active_teacher_count}</h4>
                      </div>
                      <h6 className="mb-0 fw-normal">Active Teachers</h6>
                    </div>
                  </div>
                  </Link>
                </div>
              )}
              {teacherCount.inactive_teacher_count > 0 && (
                <div className="col-6 col-sm-6 col-lg-2">
                  <Link to="/admin/manage_teacher">
                  <div className="card card-border-shadow-primary h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-2">
                        <div className="avatar me-4">
                          <span className="avatar-initial rounded bg-label-primary">
                            <i className="ri-shield-user-line ri-xl"></i>
                          </span>
                        </div>
                        <h4 className="mb-0 fs-3">{teacherCount.inactive_teacher_count}</h4>
                      </div>
                      <h6 className="mb-0 fw-normal">Inactive Teachers</h6>
                    </div>
                  </div>
                  </Link>
                </div>
              )}
              </>
      )}
            </div>
            <div className="row g-6 mt-1">
            {loading ? (
        // Skeletons while loading
        <>
          <div className="col-6 col-sm-6 col-lg-2">
            <Skeleton height={150} />
          </div>
          <div className="col-6 col-sm-6 col-lg-2">
            <Skeleton height={150} />
          </div>
          <div className="col-6 col-sm-6 col-lg-2">
            <Skeleton height={150} />
          </div>
        </>
      ) : (
        <>
              {studentCount.total_student_count > 0 && (
                <div className="col-6 col-sm-6 col-lg-2">
              
                  <div className="card card-border-shadow-primary h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-2">
                        <div className="avatar me-4">
                          <span className="avatar-initial rounded bg-label-primary">
                            <i className="ri-group-3-line ri-xl"></i>
                          </span>
                        </div>
                        <h4 className="mb-0 fs-3">{studentCount.total_student_count}</h4>
                      </div>
                      <h6 className="mb-0 fw-normal">Total Students</h6>
                    </div>
                  </div>
                </div>
              )}
              {studentCount.active_student_count > 0 && (
                <div className="col-6 col-sm-6 col-lg-2">
                  <div className="card card-border-shadow-primary h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-2">
                        <div className="avatar me-4">
                          <span className="avatar-initial rounded bg-label-primary">
                            <i className="ri-group-3-line ri-xl"></i>
                          </span>
                        </div>
                        <h4 className="mb-0 fs-3">{studentCount.active_student_count}</h4>
                      </div>
                      <h6 className="mb-0 fw-normal">Active Students</h6>
                    </div>
                  </div>
                </div>
              )}
              {studentCount.inactive_student_count > 0 && (
                <div className="col-6 col-sm-6 col-lg-2">
                  <div className="card card-border-shadow-primary h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-2">
                        <div className="avatar me-4">
                          <span className="avatar-initial rounded bg-label-primary">
                            <i className="ri-group-3-line ri-xl"></i>
                          </span>
                        </div>
                        <h4 className="mb-0 fs-3">{studentCount.inactive_student_count}</h4>
                      </div>
                      <h6 className="mb-0 fw-normal">Inactive Students</h6>
                    </div>
                  </div>
                </div>
              )}
              </>
      )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminDashboard;

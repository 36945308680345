import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import LandingFooter from "./LandingFooter";
import LandingHeader from "./LandingHeader";
import AlgoTradingStrategy from './AlgoTradingStrategy';
import AlgoTradingInfo from './AlgoTradingInfo';
import AlgoTradingRequirement from './AlgoTradingRequirement';
import AlgoTradingTimeScale from './AlgoTradingTImeScale';
import AlgoTradingConclusion from './AlgoTradingConclusion';
import group from "../../../assets/img/products/11-removebg-preview.png"
const AlgoTrading = () => {
  return (
    <>   <LandingHeader></LandingHeader>
    <div className="container-xxl mt-10 pt-10 mb-5">
    <div className="text-center mb-5 mt-10">
        </div>
      <Row>
      
        <Col lg={6} md={6} className="d-flex align-items-center">
          <div>
            <h2 className=" fw-bold">Algo Trading</h2>
            <p className="mt-4 me-0 me-md-12 me-lg-12"  style={{  lineHeight: "1.8" ,textAlign: "justify" }}>
              Algo trading harnesses advanced algorithms to execute trading strategies with unparalleled precision,
              catering to traders seeking to customize their approach for a tailored trading experience.
              This innovative trading form allows individuals to leverage broker-provided API keys,
              granting access to automated operations that transform complex strategies into seamless actions.
              <br /><br />
              Algo trading, gaining momentum for its arility to simplify trading through technology,
              enables even those without programming skills to participate effectively. Traders can back-test strategies confidently,
              ensuring their moves are both smart and strategic. As algo trading becomes increasingly popular, 
              it represents the future of trading, offering a platform where strategies are not just executed but optimized,
              marking a significant shift in how trading success is achieved and redefining opportunities in the financial markets.
            </p>
         
          </div>
        </Col>

        {/* Right Side: Form and Features */}
        <div
          className="col-lg-6 col-md-6 col-12 bg-primary text-white p-12 mb-0 pb-0"
          style={{ borderRadius: "0 0 0 80px", }} // Making it fully rounded at the bottom left
        >
          <h3 className="mb-12 mt-8 text-white text-center">
            Open an Account & Start Algo Trading Today!
          </h3>

          

          {/* Features List */}
          <ul className="list-unstyled mt-12 d-flex flex-wrap mb-0">
            <li className="col-6 mb-6">
              <i className="ri ri-check-line me-2"></i>Flat Rs 18 brokerage
            </li>
            <li className="col-6 mb-6">
              <i className="ri ri-check-line me-2"></i>No Cost Algo Trading
            </li>
            <li className="col-6 mb-6">
              <i className="ri ri-check-line me-2"></i>30+ Indicators
            </li>
            <li className="col-6 mb-6">
              <i className="ri ri-check-line me-2"></i>Trade from Chart
            </li>
            <li className="col-6 mb-6">
              <i className="ri ri-check-line me-2"></i>Stock SIP
            </li>
            <li className="col-6 mb-6">
              <i className="ri ri-check-line me-2"></i>Bigul Option Chain
            </li>
            <li className="col-6 mb-6">
              <i className="ri ri-check-line me-2"></i>300+ Pre Defined Market Watch
            </li>
            <li className="col-6 mb-6">
              <i className="ri ri-check-line me-2"></i>Basket Order
            </li>
          </ul>
        </div>
      
      </Row>


    </div>

    <section id="landingFeatures" class="landing-features bg-white mt-10 pt-10 mb-5"> 
        <div className='container-xxl'>
 
      <div className="row align-items-center">
        {/* Left Section: Text Content */}
        <div className="col-lg-6 col-md-6 col-12">
          <h2 className="fw-bold">Purpose of Algo Trading</h2>
          <p className="mt-4" style={{ textAlign: "justify", lineHeight: "1.8" }}>
            The purpose of algo trading is to elevate trading efficiency and precision by leveraging advanced algorithms
            to automate and optimise trading strategies. This technology enables fast execution of orders, surpassing human
            capabilities and allowing for high-frequency trading at an unprecedented scale. It significantly reduces the risk
            of emotional decision-making and human errors, integrating sophisticated risk management tools for smarter, more
            strategic trades.
            <br />
            <br />
            Algo trading democratizes access to the financial markets by simplifying complex strategies, making it accessible
            even to those with minimal programming knowledge. Ultimately, it aims to reduce operational costs while providing
            the flexibility to adapt strategies in real-time, ensuring traders can maximize their market potential and
            investment returns efficiently.
          </p>
        </div>

       
        <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-end">
  {/* <div className="video-wrapper "  >
    <iframe
      width="100%"
      height="315"
      src="https://www.youtube.com/embed/3UQdwRy0Eow"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
     
    ></iframe>
  </div> */}

<picture>
                <img
                  decoding="async"
                  loading="lazy"
                  src={group}
                  alt="About Us"
                  className="comp-resp-img img-fluid"
                />
              </picture>
</div>
</div>


<h2 className="fw-bold mt-12">Benefits of Algo Trading</h2>
      <div className="row" style={{ textAlign: "justify", lineHeight: "1.8" }}>
        
      <div className="col-lg-6 col-md-6 mb-4">
  <div className="advantage-card p-3 d-flex flex-column flex-md-row align-items-center align-items-md-start">
    {/* Icon Section */}
    <div className="icon-wrapper mb-3 mb-md-0 me-md-3 text-center">
      <span className="badge bg-label-hover-primary rounded-pill">
        <i className="ri ri-cloud-line fs-1"></i>
      </span>
    </div>

    {/* Text Section */}
    <div>
      <h4 className="fw-bold mb-0">Fast Execution of Orders</h4>
      <p>
        Supercharge your trading! Algorithmic trading, driven by
        lightning-fast computers, slashes order execution time. Experience
        the pinnacle with high-frequency trading, enabling traders to make
        tens of thousands of trades per second.
      </p>
    </div>
  </div>
</div>



        {/* Automation */}
        <div className="col-lg-6 col-md-6 mb-4">
        <div className="advantage-card p-3 d-flex flex-column flex-md-row align-items-center align-items-md-start">
        {/* Icon Section */}
    <div className="icon-wrapper me-6">
      <span className="badge   bg-label-hover-danger rounded-pill">
        <i className="ri ri-cloud-line fs-1"></i>
      </span>
    </div>
    <div>
            <h4 className="fw-bold mb-0">Automation</h4>
            <p>
              Unlock automated trading magic! Pre-programmed algorithms crafted
              by skilled programmers oversee operations seamlessly—from
              monitoring to trade execution, eliminating human intervention.
            </p>
          </div>
        </div>
        </div>
        {/* Risk Management */}
        <div className="col-lg-6 col-md-6 mb-4">
        <div className="advantage-card p-3 d-flex flex-column flex-md-row align-items-center align-items-md-start">
        {/* Icon Section */}
    <div className="icon-wrapper me-6">
      <span className="badge   bg-label-hover-success rounded-pill">
        <i className="ri ri-cloud-line fs-1"></i>
      </span>
    </div>
    <div>
            <h4 className="fw-bold mb-0">Risk Management</h4>
            <p>
              Level up from emotional decisions! Classic traders retain manual
              control, risking emotional choices and potential losses. Algo
              trading integrates risk management for strategic precision.
            </p>
          </div>
        </div>
        </div>

        <div className="col-lg-6 col-md-6 mb-4">
        <div className="advantage-card p-3 d-flex flex-column flex-md-row align-items-center align-items-md-start">
        {/* Icon Section */}
    <div className="icon-wrapper me-6">
      <span className="badge   bg-label-hover-info rounded-pill">
        <i className="ri ri-cloud-line fs-1"></i>
      </span>
    </div>
    <div>
            <h4 className="fw-bold mb-0">Cost Reduction</h4>
            <p>
              Invest wisely! While the initial setup of algo trading may be
              costly, its long-term efficiency slashes operational expenses,
              minimizing transaction costs and ensuring a cost-effective trading
              journey.
            </p>
          </div>
        </div>
</div>
        {/* Human Error */}
        <div className="col-lg-6 col-md-6 mb-4">
        <div className="advantage-card p-3 d-flex flex-column flex-md-row align-items-center align-items-md-start">
        {/* Icon Section */}
    <div className="icon-wrapper me-6">
      <span className="badge   bg-label-hover-warning rounded-pill">
        <i className="ri ri-cloud-line fs-1"></i>
      </span>
    </div>
    <div>
            <h4 className="fw-bold mb-0">Human Error</h4>
            <p>
              Most human errors! Algo trading eradicates mistakes like wrong
              inputs, forgotten stop-loss, and emotional decisions. It ensures
              flawless execution, freeing you from pitfalls and enhancing
              overall performance.
            </p>
          </div>
        </div>
</div>
        {/* Free Algo Trading Software */}
        <div className="col-lg-6 col-md-6 mb-4">
        <div className="advantage-card p-3 d-flex flex-column flex-md-row align-items-center align-items-md-start">
        {/* Icon Section */}
    <div className="icon-wrapper me-6">
      <span className="badge   bg-label-hover-secondary rounded-pill">
        <i className="ri ri-cloud-line fs-1"></i>
      </span>
    </div>
    <div>
            <h4 className="fw-bold mb-0">Free Algo Trading Software</h4>
            <p>
              Code your success! Algorithms crafted by programmers need timely
              updates to align with market shifts. Enjoy the freedom to modify
              and thrive!
            </p>
          </div>
        </div>
        </div>
      </div>
      </div>
    </section>
    <AlgoTradingStrategy></AlgoTradingStrategy>
    <AlgoTradingInfo></AlgoTradingInfo>
    <AlgoTradingRequirement></AlgoTradingRequirement>
    <AlgoTradingTimeScale></AlgoTradingTimeScale>
    <AlgoTradingConclusion></AlgoTradingConclusion>
    <LandingFooter></LandingFooter>
    </>
  );
};

export default AlgoTrading;

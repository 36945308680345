import config from "../../../component/config";

import AdminHeader from "./AdminHeader";
import { Toast } from "primereact/toast";
import Footer from "../../../component/Footer";
import AdminSubHeader from "./AdminSubHeader";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import CardLinks from "../../../component/CardLinks";
import { Tooltip } from "primereact/tooltip";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
import Skeleton from "react-loading-skeleton";
const ViewTeacher = () => {
  const [backClicked, setBackClicked] = useState(false);
  const toast = useRef(null);
  const navigate = useNavigate();
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const location = useLocation();

  const teacher_id = location.state ? location.state.teacher_id : null;
  const { teacherId } = location.state;
  const [teacherData, setTeacherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [error, setError] = useState(null);

  const handleToggleTrade = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
  
      if (!authToken) {
        throw new Error("Auth token not found");
      }
  
      const response = await axios.post(
        `${config.apiDomain}/api/admin/teacher_trade_switch`,
        { teacher_id: teacherId },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
  
      const { st, msg } = response.data;
  
      if (st === 1) {
        setTeacherData((prevData) => ({
          ...prevData,
          trade_status: !prevData.trade_status,
        }));
        window.showToast("success", msg || "Trade status updated successfully!");
      } else {
        switch (st) {
          case 2:
            window.showToast("danger", msg || "Error updating trade status.");
            break;
          case 3:
            window.showToast("warning", msg || "Warning: Review trade status update.");
            break;
          case 4:
            window.showToast("info", msg || "Info: Trade status update with additional notes.");
            break;
          default:
            window.showToast("error", "Something went wrong. Contact Support.");
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("Unauthorized access detected. Redirecting to login...");
        navigate("/login");
      } else {
        const errorMsg = error.response?.data?.msg || "Something went wrong. Contact Support.";
        window.showToast("error", errorMsg);
      }
    }
  };

  const handleToggle = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
  
      if (!authToken) {
        throw new Error("Auth token not found");
      }
  
      const response = await fetch(
        `${config.apiDomain}/api/admin/teacher_active_switch`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ teacher_id: teacherId }),
        }
      );
  
      const responseData = await response.json();
      const { st, msg } = responseData;
  
      if (st === 1) {
        setTeacherData((prevData) => ({
          ...prevData,
          active_status: !prevData.active_status,
        }));
        window.showToast("success", msg || "Active status updated successfully!");
      } else {
        switch (st) {
          case 2:
            window.showToast("danger", msg || "Error updating active status.");
            break;
          case 3:
            window.showToast("warning", msg || "Warning: Review active status update.");
            break;
          case 4:
            window.showToast("info", msg || "Info: Active status update with additional notes.");
            break;
          default:
            window.showToast("error", "Something went wrong. Contact Support.");
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access, redirecting to common login screen");
        navigate("/login");
      } else {
        const errorMsg = error.response?.data?.msg || error.message || "Something went wrong. Contact Support.";
        window.showToast("error", errorMsg);
      }
    }
  };
  
  

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");
        if (!authToken || !userId) {
          navigate("/login");
          return;
        }

        const response = await axios.post(
          `${config.apiDomain}/api/admin/manage_teachers/view`,
          { teacher_id: teacherId, admin_id: userId },
          { headers: { Authorization: `Bearer ${authToken}` } }
        );

        if (response.data && response.data.st === 1) {
          setTeacherData(response.data.data);
        } else {
          setError(new Error(response.data.msg || "Failed to fetch data"));
        }
      } catch (error) {
       
        if (error.response && error.response.status === 401) {
          console.error(
            "Unauthorized access, redirecting to common login screen"
          );
          navigate("/login");
        } else {
          const errorMsg = error.response
            ? error.response.data.msg || "Failed to fetch data"
            : error.message || "Failed to fetch data";
          setError(new Error(errorMsg));
         
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [teacherId]);

  const toLowerCase = (string) => {
    return string.toLowerCase();
  };
  const formatExchanges = (exchanges) => {
    if (!exchanges) return [];
    const formattedExchanges = [];
    for (const [key, value] of Object.entries(exchanges)) {
      if (Array.isArray(value)) {
        formattedExchanges.push(`${key}: ${value.join(", ")}`);
      } else {
        formattedExchanges.push(`${key}: ${value}`);
      }
    }
    return formattedExchanges;
  };

  const formattedExchanges =
    teacherData && teacherData.broker_account_exchanges
      ? formatExchanges(teacherData.broker_account_exchanges)
      : [];

  const handleRefresh = async () => {
    setLoading(true);
    setError(null);
    setIsRefreshing(true);

    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
      if (!authToken) {
        throw new Error("Auth token not found");
      }

      const response = await axios.post(
        `${config.apiDomain}/api/admin/manage_teachers/view`,
        { teacher_id: teacherId, admin_id: userId },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      if (response.data && response.data.st === 1) {
        setTeacherData(response.data.data);
      } else {
        setError(new Error(response.data.msg || "Failed to fetch data"));
      }
    } catch (error) {
   
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access, redirecting to common login screen"
        );
        navigate("/login");
      } else {
        const errorMsg = error.response
          ? error.response.data.msg || "Failed to fetch data"
          : error.message || "Failed to fetch data";
        setError(new Error(errorMsg));
       
      }
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };
  const maskString = (str) => {
    if (!str || str.length < 5) return str; 
    const firstPart = str.slice(0, 2).replace(/./g, '*'); 
    const middlePart = str.slice(2, -3); 
    const lastPart = str.slice(-3).replace(/./g, '*'); 
    return `${firstPart}${middlePart}${lastPart}`;
  };
  
  
  return (
    <>
      <Toast ref={toast} />
      <AdminHeader />
      <AdminSubHeader />

      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
        <ol className="breadcrumb breadcrumb-style1 text-black mb-3">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>{" "}
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/manage_teacher" className="text-black">
                Teacher
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary text-capitalize"
              aria-current="page"
            >
              {teacherData ? teacherData.name : 'Loading...'}
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>
        <div className="card p-5">
        {loading ? (
        <Skeleton height={400} /> // Adjust the height as needed
      ) : (
        <>
        <div className="row align-items-center mb-5">
  
  <div className="col-3  d-flex align-items-center justify-content-start">
    <button
      onClick={handleBack}
      className="btn rounded-pill btn-outline-secondary btn-xs"
    >
      <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
    </button>
  </div>

 
  <div className="col-6 text-center">
    <h5 className="mb-0 text-capitalize">{teacherData ? teacherData.name : 'Loading...'}</h5>
  </div>

  <div className="col-12 col-md-3 col-lg-3 mt-6 mt-md-0 d-flex justify-content-end flex-wrap">
    <div className="d-flex flex-row align-items-center">
      {teacherData && teacherData.broker_status && (
        <div className="d-flex flex-column align-items-center me-3">
         
          <label
            className={`switch switch-square ${
              teacherData.trade_status ? "bg-success" : ""
            }`}
          >
            <input
              type="checkbox"
              className="switch-input"
              checked={teacherData.trade_status}
              onChange={() => handleToggleTrade(teacherData.teacher_id)}
            />
            <span
              className={`switch-toggle-slider ${
                teacherData.trade_status ? "bg-success" : ""
              }`}
            >
              <span className="switch-on"></span>
              <span className="switch-off"></span>
            </span>
          </label>
          <div className="ms-2 mt-2 d-flex align-items-center">
            <i className="ri-arrow-left-right-fill ri-ms me-1"></i>
            <span className="text-black">Trade Status</span>
          </div>
        </div>
      )}

      {teacherData && (
        <div className="d-flex flex-column align-items-center me-3">
          <label
            className={`switch switch-square ${
              teacherData.active_status ? "bg-success" : ""
            }`}
          >
            <input
              type="checkbox"
              className="switch-input"
              checked={teacherData.active_status}
              onChange={() => handleToggle(teacherData.teacher_id)}
            />
            <span
              className={`switch-toggle-slider ${
                teacherData.active_status ? "bg-success" : ""
              }`}
            >
              <span className="switch-on"></span>
              <span className="switch-off"></span>
            </span>
          </label>
          <div className="ms-2 mt-2 d-flex align-items-center">
            <i className="ri-user-3-line ri-ms me-1"></i>
            <span className="text-black">Account Status</span>
          </div>
        </div>
      )}

{loading ? (
              <div className="d-flex align-items-center">
                <i
                  className={`ri-refresh-line ri-lg ms-3 p-text-secondary ${
                    isRefreshing ? "rotate" : ""
                  }`}
                  role="status"
                ></i>
              </div>
            ) : (
              <div className="">
                <Tooltip target=".custom-target-icon" />
                <i
                  className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                  role="button"
                  data-pr-tooltip="Reload"
                  onClick={handleRefresh}
                  data-pr-position="top"
                ></i>
              </div>
            )}
    </div>
  </div>
</div>


          {teacherData && (
            <div className="row">
              <div className="col-12">
                <div className="row mt-1">
                  <h4>
                    {" "}
                    <i className="ri-user-line ri-ms me-1 "></i>
                    Personal Information
                  </h4>
                  <div className="col-6 col-md-3 col-lg-3">
                    <span className="text-black">
                      <strong>{toTitleCase(teacherData.name)}</strong>
                    </span>
                    <div>Name</div>
                  </div>
                  <div className="col-6 col-md-3 col-lg-3">
                    <span className="text-black">
                      <strong>{teacherData.mobile}</strong>
                    </span>
                    <div>Mobile</div>
                  </div>
                  {teacherData.email && (
  <div className="col-12 col-md-3 col-lg-3 mt-5 mt-md-0">
    <span className="text-black">
      <strong>{teacherData.email.toLowerCase()}</strong>
    </span>
    <div>Email</div>
  </div>
)}

                  <div className="col-12 col-md-3 col-lg-3 mt-5 mt-md-0">
                    <div
                      className={` ${
                        teacherData.broker_status
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {teacherData.broker_status ? (
                        <div className="d-flex justify-content-space align-items-center">
                          <i className="ri-shield-check-line me-1"></i>
                          <strong>Connected</strong>
                          {teacherData.broker_status &&
                            teacherData.broker_account_fund != null && (
                              <div className="text-muted ms-2">
                                {teacherData.broker_account_fund} Rs
                              </div>
                            )}
                        </div>
                      ) : (
                        <>
                          <i className="ri-close-large-line me-1"></i>
                          <strong>Not Connected</strong>
                        </>
                      )}
                    </div>

                    <div>Broker</div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-5 mb-5">
                <div className="row mt-1">
                  <div className="col-6 col-md-3 col-lg-3">
                    <span className="text-black">
                      <strong>{teacherData.lot_size_limit} Lot</strong>
                    </span>
                    <div>Lot Size Limit</div>
                  </div>

                  <div className="col-6 col-md-3 col-lg-3">
                    <span className="text-black">
                      <strong>{teacherData.commission}%</strong>
                    </span>
                    <div>Commission</div>
                  </div>
                  {formattedExchanges.length > 0 && (
                    <div className="col-12 mt-5 mt-md-0 col-md-3 col-lg-3">
                      <span className="text-black">
                        <strong>
                          {" "}
                          {formattedExchanges.map((exchange, index) => (
                            <li key={index}>{exchange}</li>
                          ))}
                        </strong>
                      </span>
                      <div>Segments</div>
                    </div>
                  )}
                </div>
              </div>

              <hr />
              {teacherData.active_subscription && (
                <>
                  <h4>
                    <i className="ri-medal-2-line ri-ms me-2"></i>
                    Subscription Information
                  </h4>
                  <div className="col-12  mb-5">
                    <div className="row mt-1">
                      <div className="col-6 col-md-3 col-lg-3">
                        <span className="text-black text-capitalize">
                          <strong>
                            {teacherData.active_subscription.subscription_name}
                          </strong>
                        </span>
                        <div>Subscription Name</div>
                      </div>
                      <div className="col-6  col-md-3 col-lg-3">
                        <span className="text-black text-capitalize">
                          <strong>
                            {teacherData.active_subscription.plan_name}
                          </strong>
                        </span>
                        <div>Plan Name</div>
                      </div>
                      <div className="col-6 col-md-3 col-lg-3 mt-5 mt-md-0">
                        <span className="text-black">
                          <strong>
                            {teacherData.active_subscription.completed_days}{" "}
                            Days
                          </strong>
                        </span>
                        <div>Completed Days</div>
                      </div>
                      <div className="col-6 col-md-3 col-lg-3 mt-5 mt-md-0">
                        <span className="text-black">
                          <strong>
                            {teacherData.active_subscription.remaining_days}{" "}
                            Days
                          </strong>
                        </span>
                        <div>Remaining Days</div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <hr></hr>
              {teacherData.broker_status && (
  <>
    <h4>
      <i className="ri-group-line ri-ms me-2"></i>
      Broker Information
    </h4>

    {teacherData.broker === "dhan" && teacherData.broker_details && (
      <div className="col-12 mb-5">
        <div className="row mt-1">
          <div className="col-12 col-md-3 col-lg-3">
            <span className="text-black">
              <strong>{teacherData.broker_details.dhan_client_id}</strong>
            </span>
            <div>Dhan Client ID</div>
          </div>

          <div className="col-12 mt-5 mt-md-0 col-md-9 col-lg-9">
  <span className="text-black">
    <strong>{maskString(teacherData.broker_details.access_token)}</strong>
  </span>
  <div>Dhan Access Token</div>
</div>
        </div>
      </div>
    )}

    {teacherData.broker === "angle_one" && teacherData.broker_details && (
      <div className="col-12 mb-5">
        <div className="row mt-1">
        <div className="col-6 col-md-3 col-lg-3">
  <span className="text-black">
    <strong>{maskString(teacherData.broker_details.api_key)}</strong>
  </span>
  <div>Broker API Key</div>
</div>

          <div className="col-6 col-md-3 col-lg-3">
            <span className="text-black">
              <strong>{teacherData.broker_details.client_id}</strong>
            </span>
            <div>Broker Client ID</div>
          </div>

        

          <div className="col-12 mt-5 mt-md-0 col-md-3 col-lg-3">
  <span className="text-black">
    <strong>{maskString(teacherData.broker_details.qr_totp_token)}</strong>
  </span>
  <div>Broker QR TOTP Token</div>
</div>
        </div>
      </div>
    )}

    <hr />
  </>
)}

              {teacherData.student_count && (
                <div className="row mt-5">
                  <div className="col-4 text-center">
                    <span className="text-black">
                      <h1>
                        <strong>
                          {teacherData.student_count.total_student_count || 0}
                        </strong>
                      </h1>
                    </span>
                    <div>Total Students</div>
                  </div>
                  <div className="col-4 text-center">
                    <span className="text-black">
                      <h1>
                        <strong>
                          {teacherData.student_count.active_student_count || 0}
                        </strong>
                      </h1>
                    </span>
                    <div>Total Active Students</div>
                  </div>
                  <div className="col-4 text-center">
                    <span className="text-black">
                      <h1>
                        <strong>
                          {teacherData.student_count.inactive_student_count ||
                            0}
                        </strong>
                      </h1>
                    </span>
                    <div>Total Inactive Students</div>
                  </div>
                </div>
              )}
            </div>
          )}
</>)}

        </div>
        <CardLinks></CardLinks>
      </div>

      <Footer />
    </>
  );
};

export default ViewTeacher;

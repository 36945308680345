import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import AdminHeader from './AdminHeader';
import AdminSubHeader from './AdminSubHeader';
import Footer from '../../../component/Footer';
import config from '../../../component/config';
import CardLinks from '../../../component/CardLinks';
import { Tooltip } from "primereact/tooltip";
import SubscriptionBadge from '../../../component/SubscriptionBadge';
import Skeleton from "react-loading-skeleton";
const ViewFeatureRequest = () => {
  const { id } = useParams();  
  const [loading, setLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const [featureRequest, setFeatureRequest] = useState({});
  const toast = useRef(null);
  const navigate = useNavigate();
  const [backClicked, setBackClicked] = useState(false);

  useEffect(() => {
    const fetchFeatureRequestDetails = async () => {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
       if (!authToken || !userId) {
      navigate("/login");
        return;
      }

      try {
        const response = await axios.post(`${config.apiDomain}/api/admin/feature_request/detail`, 
          { feature_request_id: id }, 
          {
            headers: {
              'Authorization': `Bearer ${authToken}`
            },
          }
        );

        if (response.data.st === 1) {
          setFeatureRequest(response.data.data);
        } else {
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login'); // Navigate to login page on 401 Unauthorized
        } else {
        }
      } finally {
        setLoading(false);
      }
    };

    fetchFeatureRequestDetails();
  }, [id]);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  const handleDownload = () => {
    if (featureRequest.example_file) {
      const link = document.createElement('a');
      link.href = featureRequest.example_file;
      link.setAttribute('download', 'attachment.jpg'); // Set the desired file name and force download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the link element
    } else {
    }
  };
  

 
    const handleRefresh = async () => {
      const authToken = localStorage.getItem("authToken");
      setLoading(true);
      setIsRefreshing(true);
      try {
        const response = await axios.post(`${config.apiDomain}/api/admin/feature_request/detail`, 
          { feature_request_id: id }, 
          {
            headers: {
              'Authorization': `Bearer ${authToken}`
            },
          }
        );

        if (response.data.st === 1) {
          setFeatureRequest(response.data.data);
        } else {
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login'); // Navigate to login page on 401 Unauthorized
        } else {
        }
      } finally {
        setLoading(false);
        setIsRefreshing(false);
      }
    };

  
  return (
    <div>
      <AdminHeader />
      <AdminSubHeader />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
        <ol className="breadcrumb breadcrumb-style1 text-black mb-3">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/feature_request" className="text-black">
                Feature Request
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary text-capitalize" aria-current="page">
            {featureRequest ? featureRequest.user_name : 'Loading...'}
               
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>
        <div className="card p-5">
        {loading ? (
        <Skeleton height={200} /> // Adjust the height as needed
      ) : (
        <>
          <div className="row align-items-center mb-5">
            <div className="col-3 text-start">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 text-center">
              <h5 className="mb-0 text-capitalize"> {featureRequest ? featureRequest.user_name : 'Loading...'}</h5>
            </div>
            <div className="col-3 text-end">
  {loading ? (
    <div className="d-flex justify-content-end align-items-center"> {/* Make sure the alignment remains consistent */}
      <i
        className={`ri-refresh-line ri-lg ms-3 p-text-secondary ${
          isRefreshing ? "rotate" : ""
        }`}
        role="status"
      ></i>
    </div>
  ) : (
    <div className="d-flex justify-content-end align-items-center"> {/* Consistent alignment for normal icon */}
      <Tooltip target=".custom-target-icon" />
      <i
        className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
        role="button"
        data-pr-tooltip="Reload"
        onClick={handleRefresh}
        data-pr-position="top"
      ></i>
    </div>
  )}
</div>

          </div>

        
            <div className="col-12 mt-4">
              <div className="row mb-3">
               
              <div className="col-4">
                    <span className="text-black">
                      <strong className='text-capitalize'>{featureRequest.user_name}</strong>
                    </span>
                    <div> Name</div>
                  </div>
                
                  <div className="col-8">
                    <span className="text-black">
                      <strong className='text-capitalize'>{featureRequest.product}</strong>
                    </span>
                    <div> Product</div>
                  </div>
             
              
              <div className="row mt-6">
              <div className="col-12 mt-3">
              <div> Feature</div>
                    <span className="text-black">
                      <strong className='text-capitalize'>{featureRequest.feature}</strong>
                    </span>
                    
                  </div>
              </div>
              <div className="row mt-6">
              <div className="col-12 mt-3">
              <div> Explanation</div>
                    <span className="text-black">
                      <strong className='text-capitalize'>{featureRequest.explanation}</strong>
                    </span>
                    
                  </div>
              </div>
        
            </div>
            <div className="d-flex justify-content-between align-items-center mt-12">
  {/* Display the Download button on the left */}
  {featureRequest.example_file ? (
    <button
      type="button"
      className="btn rounded-pill btn-outline-secondary me-3"
      onClick={handleDownload}
    >
      <i className="ri-download-2-line me-2"></i> Download Attachment
    </button>
  ) : null}

  {/* Display the Created At date on the right */}
  <small className="text-end">{featureRequest.created_at}</small>
</div>

            </div>
         </>
      )}
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer />
      <Toast ref={toast} />
    </div>
  );
};

export default ViewFeatureRequest;

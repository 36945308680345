import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";
import Footer from "../../../component/Footer";
import Header from "../component/Header";

import SubHeader from "../component/SubHeader";
import { Link, useNavigate } from "react-router-dom";

import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import config from "../../../component/config";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
const OrderBook = () => {
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useRef(null);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const userId = localStorage.getItem("userId"); 
  const [backClicked, setBackClicked] = useState(false);
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const wsRef = useRef(null);

  const fetchData = async () => {
    const authToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");

    if (!authToken || !userId) {
      navigate("/login");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${config.apiDomain}/api/common/order_book`,
        {
          user_id: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data && Array.isArray(response.data.data)) {
        setData(response.data.data);  // Set the array directly as data
        const symbolTokens = response.data.data.map((item) => item.symboltoken);
        const broker = response.data.broker;
  
        if (symbolTokens.length > 0) {
          connectWebSocket(symbolTokens, broker);
        } else {
          console.log("No tokens found for WebSocket connection");
        }
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      console.clear(); 
    
      if (error.response && error.response.status === 401) {
       
        navigate("/login"); 
      } else {
        const errorMsg = error.response
          ? error.response.data.msg || "Failed to fetch data"
          : error.message || "Failed to fetch data";
      }
    } finally {
      setLoading(false);
    }
  };

  const connectWebSocket = (tokens, broker) => {
    let wsUrl;
  
    // Set the WebSocket URL based on the broker
    if (broker === 'angle_one') {
      wsUrl = 'wss://ghanish.in/ws/angelone/live_market_data/';
    } else if (broker === 'dhan') {
      wsUrl = 'wss://ghanish.in/ws/dhan/live_market_data/';
    } else {
      console.log('Unsupported broker:', broker);
      return;
    }
  
    if (isMarketOpen()) {
      wsRef.current = new WebSocket(wsUrl);
  
      wsRef.current.onopen = () => {
        console.log('WebSocket connected');
        const teacherId = userId;
        const requestData = {
          teacher_id: teacherId,
          tokens: tokens, // Send symboltoken values in the request
        };
        wsRef.current.send(JSON.stringify(requestData));
      };
  
      wsRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('Live market data received:', data);
      
        if (!data || !data.data) { // Check if data exists and has the expected structure
          console.log('Received invalid data, disconnecting WebSocket');
          disconnectWebSocket();
        } else {
          updateLtp(data.data.token, data.data.ltp); // Update the LTP in the UI
        }
      };
      
  
      wsRef.current.onerror = (error) => {
        console.log('WebSocket error:', error);
      };
  
      wsRef.current.onclose = () => {
        console.log('WebSocket disconnected');
      };
    } else {
      console.log('Market is closed. WebSocket connection not established.');
    }
  };
  
  
    const disconnectWebSocket = () => {
      if (wsRef.current) {
        wsRef.current.close(); // Close the WebSocket connection
        wsRef.current = null; // Clear the reference
      }
    };
    
   
  
    const updateLtp = (token, newLtp) => {
      const normalizedToken = token.toString();
      const normalizedLtp = parseFloat(newLtp);
    
      setData((prevData) => prevData.map((instrument) => {
        if (instrument.symboltoken === normalizedToken) {
          const oldLtp = parseFloat(instrument.ltp) || 0;
          const ltpDifference = normalizedLtp - oldLtp;
    
          let ltpClass = 'text-secondary';
          if (ltpDifference > 0) {
            ltpClass = 'text-success';
          } else if (ltpDifference < 0) {
            ltpClass = 'text-danger';
          }
    
          return {
            ...instrument,
            ltp: normalizedLtp.toFixed(2),
            ltpDifference: ltpDifference.toFixed(2),
            ltpClass,
          };
        }
        return instrument;
      }));
    };
    
    
    
    
    
    useEffect(() => {
      return () => {
        disconnectWebSocket(); // Disconnect when component unmounts
      };
    }, []);

    const isMarketOpen = () => {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinute = currentTime.getMinutes();
      const currentDay = currentTime.getDay();
    
      const marketOpenHour = 9;
      const marketOpenMinute = 15;
      const marketCloseHour = 15;
      const marketCloseMinute = 30;
    
      // If it's Sunday (0) or Saturday (6), the market is closed
      if (currentDay === 0 || currentDay === 6) {
        return false;
      }
    
      // Check if the current time is within market hours (9:15 AM to 3:30 PM)
      if (
        (currentHour > marketOpenHour ||
          (currentHour === marketOpenHour && currentMinute >= marketOpenMinute)) &&
        (currentHour < marketCloseHour ||
          (currentHour === marketCloseHour && currentMinute <= marketCloseMinute))
      ) {
        return true;
      } else {
        return false;
      }
    };



    const handleRefresh = async () => {
      const userId = localStorage.getItem("userId");
      const authToken = localStorage.getItem("authToken");
    
      if (!userId) {
        setError(new Error("User ID not found"));
        setLoading(false);
        return;
      }
    
      if (!authToken) {
        setError(new Error("Auth token not found"));
        setLoading(false);
        return;
      }
    
      setLoading(true);
      setIsRefreshing(true);
    
      try {
        const response = await axios.post(
          `${config.apiDomain}/api/common/order_book`,
          { user_id: userId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
    
        const { st, msg, data, broker } = response.data;
    
        if (data && Array.isArray(data)) {
          setData(data);  // Set the array directly as data
          const symbolTokens = data.map((item) => item.symboltoken);
    
          if (symbolTokens.length > 0) {
            connectWebSocket(symbolTokens, broker);
          } else {
            console.log("No tokens found for WebSocket connection");
          }
    
        } else {
          // Handle different `st` values with custom toasts
          switch (st) {
            case 2:
              setError(new Error(msg || "Warning"));
              window.showToast("warning", msg || "Warning: Please check the details.");
              break;
            case 3:
            case 4:
              setError(new Error(msg || "Server error occurred"));
              window.showToast("error", msg || "An error occurred. Please try again.");
              break;
            default:
              setError(new Error("Failed to fetch data"));
              window.showToast("error", msg || "Something went wrong. Contact Support.");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
    
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized access, redirecting to common login screen");
          navigate("/login"); // Redirect on 401 Unauthorized
        } else {
          const errorMsg = error.response?.data?.msg || error.message || "Something went wrong. Contact Support.";
          setError(new Error(errorMsg));
          window.showToast("error", errorMsg);
        }
      } finally {
        setLoading(false);
        setIsRefreshing(false);
      }
    };
  useEffect(() => {
    fetchData();
  }, []);

  const rowClassName = (rowData, rowIndex) => {
    return rowIndex % 2 === 0 ? "even-row" : "odd-row";
  };

  const renderTransactionType = (rowData) => {
    const style = {
      color: rowData.transactiontype === "BUY" || rowData.transactionType === "BUY" ? "green" : "orange",
    };
    const transactionType = rowData.transactiontype ;
    return <span style={style}>{transactionType}</span>;
  };
  
  const renderOrderStatus = (rowData) => {
    const status = rowData?.orderstatus || ""; 
    const style = {
      color: status.toLowerCase() === "complete" ? "green" : "orange",
    };
    const formattedStatus = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    return <span style={style}>{formattedStatus}</span>;
  };
  
  const renderStrikePrice = (rowData) => {
    const strikePrice = rowData.strikeprice;
    return <span>{strikePrice}</span>;
  };
  

  const [showPopup, setShowPopup] = useState(false); 

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

    
      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); 

   
    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success"; 
    } else if (hours === 15) {
      return "danger"; 
    }
    return "secondary";
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  const hasLtpData = data.some(item => item.ltp !== null && item.ltp !== undefined); // Check if any item has LTP

  return (
    <>
      {" "}
      <Toast ref={toast} />
      <Header />
      <SubHeader />
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <div className="d-flex justify-content-between align-items-center">
            <ol className="breadcrumb breadcrumb-style1 text-black">
              <li className="breadcrumb-item">
                <Link to="/teacher/dashboard" className="text-black">
                  <i className="ri-home-7-line ri-lg"></i>
                </Link>
              </li>
              <li
                className="breadcrumb-item active text-secondary"
                aria-current="page"
              >
                Order Book
              </li>
            </ol>
            <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>

        <div className="card p-5">
          <div className="d-flex justify-content-between align-items-center mb-5">
            <button
              onClick={handleBack}
              className="btn rounded-pill btn-outline-secondary btn-xs"
            >
              <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
            </button>

            <h5 className="mb-0 mx-auto">Order Book</h5>
            <div></div>
          </div>
          <div className="d-flex justify-content-start mb-3">
            <IconField iconPosition="left">
              <InputIcon className="ri ri-search-line"></InputIcon>
              <InputText
                type="search"
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="rounded"
              />
            </IconField>
            {loading ? (
              <div className="d-flex align-items-center">
                <i
                  className={`ri-refresh-line ri-lg ms-3 p-text-secondary ${
                    isRefreshing ? "rotate" : ""
                  }`}
                  role="status"
                ></i>
              </div>
            ) : (
              <div className="mt-3">
                <Tooltip target=".custom-target-icon" />
                <i
                  className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                  role="button"
                  data-pr-tooltip="Reload"
                  onClick={handleRefresh}
                  data-pr-position="top"
                ></i>
              </div>
            )}
          </div>
          <DataTable
            className="custom-column-border text-center"
            value={data || []} 
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
            rowClassName={rowClassName}
          >
  {hasLtpData && ( // Conditionally render the LTP column
    <Column
      align="center"
      className="custom-column-border"
      field="ltp"
      header="LTP"
      body={(rowData) => (
        <span className={rowData.ltpClass}>
          {rowData.ltp ? rowData.ltp : ''}
        </span>
      )}
    ></Column>
  )}
            <Column
    align="center"
    className="custom-column-border"
    field="tradingsymbol"
    header="Symbols"
  ></Column>
  <Column
    align="center"
    className="custom-column-border"
    field="transactiontype"
    header="Transaction Type"
    body={renderTransactionType}
  ></Column>
  <Column
    align="center"
    className="custom-column-border"
    field="exchange"
    header="Exchange"
   
  ></Column>
  
  <Column
    align="center"
    className="custom-column-border"
    field="strikeprice"
    header="Strike Price"
    body={renderStrikePrice}
  ></Column>
  <Column
    align="center"
    className="custom-column-border"
    field="optiontype"
    header="Option Type"
  ></Column>
  <Column
    align="center"
    className="custom-column-border"
    field="orderstatus"
    header="Order Status"
    body={renderOrderStatus}
  ></Column>
            <Column
              align="center"
              className="custom-column-border "
              header="Actions"
              body={(rowData) => (
                <Link to={`/order_book/orderbook_details/${rowData.uniqueorderid || rowData.orderId}`}>

                  <button
                    className="btn btn-info  custom-btn-action1 custom-target-icon"
                    data-pr-tooltip="View Record"
                    data-pr-position="top"
                  >
                    <i className="ri-gallery-view-2"></i>
                  </button>
                </Link>
              )}
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer />
    </>
  );
};

export default OrderBook;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; 
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};

import React from "react";

import offer from "../../../assets/img/products/what-we-offer-image.png"
const AlgoTradingInfo = () => {
  return (
    <section id="landingFeatures" class="landing-features bg-white pt-10 mb-5"> 
    <div className="container-xxl  ">
      {/* What We Offer Section */}
      <div className="row align-items-center mb-5 p-4 bg-body rounded">
        <div className="col-lg-7">
          <h2 className="fw-bold">What We Offer</h2>
          <ul className="list-unstyled">
          <div className="row">
  <div className="col-sm-6">
    <li className="mb-2">
      <i className="ri ri-gamepad-line text-success me-2"></i>
      Virtual Trading
    </li>
    <li className="mb-2">
      <i className="ri ri-bar-chart-box-line text-success me-2"></i>
      Backtesting
    </li>
    <li className="mb-2">
      <i className="ri ri-settings-3-line text-success me-2"></i>
      Strategy Builder
    </li>
  </div>
  <div className="col-sm-6">
    <li className="mb-2">
      <i className="ri ri-robot-line text-success me-2"></i>
      Execution Algo
    </li>
    <li className="mb-2">
      <i className="ri ri-layout-grid-line text-success me-2"></i>
      Multiple Segment
    </li>
    <li className="mb-2">
      <i className="ri ri-stack-line text-success me-2"></i>
      Prebuilt Trading Strategies
    </li>
  </div>
</div>

          </ul>
        </div>
        <div className="col-lg-5 text-center">
          <img
            src={offer}
            alt="Trading Robot"
            className="img-fluid"
          />
        </div>
      </div>

      {/* How Algo Trading Works Section */}
      <div className="row mb-5">
        <div className="col-lg-12">
          <h2 className="fw-bold">How Algo Trading Works?</h2>
          <p className="mt-3">
            Algo Trading completely works on the conditions and parameters designed and coded by the user. It is a process of executing orders utilizing automated and preprogrammed trading instructions to account for variables such as price, timing, and volume.
          </p>
          <p>
            Let’s understand it with an example: Suppose a trader wants to deploy an option selling strategy; firstly, they will design the strategy after writing down all the possible conditions of the strategy; later, it will be fed to the program for the best possible result. Let’s say straddle but with some adjustments by us – one of the most popular option selling strategies, the possible conditions that could be fed to the program for the best possible result.
          </p>
          <ul className="list-unstyled">
            <li className="mb-2">
              <i className="ri ri-check-line text-success me-2"></i>Sell 10 lots of Call and Put of ATM premium of NIFTY50 index on expiry at 9:30 AM.
            </li>
            <li className="mb-2">
              <i className="ri ri-check-line text-success me-2"></i>Stop loss will be placed on the 50% increase in the premium value or at the break-even points.
            </li>
            <li className="mb-2">
              <i className="ri ri-check-line text-success me-2"></i>Stop loss can be trailed on every 25% – 30% Decay in premium value.
            </li>
            <li className="mb-2">
              <i className="ri ri-check-line text-success me-2"></i>Target will be booked as soon as the premium value is not more than 10% of the selling value of the premium.
            </li>
          </ul>
          <p>
            These instructions will be coded into one of the following languages: Python, Java, C++, R. Using these given instructions, a computer program will automatically monitor the spot as well as the premium price and place the buy and sell orders when the defined conditions are met. The trader no longer needs to monitor live prices and graphs or put in the orders manually. The algorithmic trading system does this automatically by correctly identifying the trading opportunity. The core mechanism of algo trading is nothing, but the program will do trading on your behalf.
          </p>
        </div>
      </div>
    </div>
    </section>
  );
};

export default AlgoTradingInfo;

import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect,useRef } from "react";

const AdminSubHeader = () => {
  const [activeItem, setActiveItem] = useState("");
  const [hoverItem, setHoverItem] = useState("");
  const userTradeBook = JSON.parse(localStorage.getItem("userTradeBook"));
  const userOrderBook = JSON.parse(localStorage.getItem("userOrderBook"));
 
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSetActive = (item) => {
    if (activeItem !== item) {
      setActiveItem(item);
    }
  };

  const handleMouseEnter = (item) => {
    setHoverItem(item);
  };

  const handleMouseLeave = () => {
    setHoverItem("");
  };

  const getMenuItemStyle = (item) => {
    const isActive = activeItem === item;
    const isHovered = hoverItem === item;
    const baseStyle = {
      color: "black",
      backgroundColor: isHovered ? "#8c57ff" : "transparent",
    };
    if (isActive) {
      baseStyle.color = "#8c57ff";
    }
    if (isHovered) {
      baseStyle.color = "white";
    }
    return baseStyle;
  };

  useEffect(() => {
    switch (true) {
      case location.pathname.startsWith("/admin/dashboard"):
        setActiveItem("student_home");
        break;
      case location.pathname.startsWith("/admin/manage_teacher"):
        setActiveItem("manage_teacher");
        break;
     
      case location.pathname.startsWith("/admin/trade_book"):
        setActiveItem("trade_book");
        break;
      case location.pathname.startsWith("/admin/order_book"):
        setActiveItem("order_book");
        break;
      case location.pathname.startsWith("/admin/manage_faq"):
        setActiveItem("manage_faq");
        break;
        case location.pathname.startsWith("/admin/manage_subscription"):
          setActiveItem("manage_subscription");
          break;
          case location.pathname.startsWith("/admin/feature_request"):
            setActiveItem("feature_request");
            break;
            case location.pathname.startsWith("/admin/manage_telegram_subscription"):
              setActiveItem("manage_telegram_subscription");
              break;
            
      default:
        setActiveItem("");
        break;
    }
  }, [location.pathname]);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const closeDropdown = () => setDropdownOpen(false);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div>
      <div className="layout-page">
        <div className="content-wrapper">
          <aside
            id="layout-menu"
            className="layout-menu-horizontal menu-horizontal menu bg-menu-theme flex-grow-0"
          >
            <div className="container-xxl d-flex h-100">
              <ul className="menu-inner">
                <li
                  className={`menu-item ${
                    activeItem === "student_home" ? "active" : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter("student_home")}
                  onMouseLeave={handleMouseLeave}
                  style={getMenuItemStyle("student_home")}
                >
                  <Link
                    to="/admin/dashboard"
                    className="menu-link"
                    onClick={() => handleSetActive("student_home")}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <i className="menu-icon tf-icons ri-home-7-line"></i>
                    Home
                  </Link>
                </li>
                <li
                  className={`menu-item ${
                    location.pathname.startsWith("/admin/manage_teacher") ? "active" : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter("manage_teacher")}
                  onMouseLeave={handleMouseLeave}
                  style={getMenuItemStyle("manage_teacher")}
                >
                  <Link
                    to="/admin/manage_teacher"
                    className="menu-link"
                    onClick={() => handleSetActive("manage_teacher")}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <i className="menu-icon tf-icons ri-shield-user-line "></i>
                    <div> Teachers</div>
                  </Link>
                </li>
               
                    {userTradeBook && (
                  <li
                    className={`menu-item ${
                      activeItem === "trade_book" ? "active" : ""
                    }`}
                    onMouseEnter={() => handleMouseEnter("trade_book")}
                    onMouseLeave={handleMouseLeave}
                    style={getMenuItemStyle("trade_book")}
                  >
                    <Link
                      to="/admin/trade_book"
                      className="menu-link"
                      onClick={() => handleSetActive("trade_book")}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <i className="menu-icon tf-icons ri-swap-2-line"></i>
                      <div>Trade Book</div>
                    </Link>
                  </li>
                )}
                    {userOrderBook && (
                  <li
                    className={`menu-item ${
                      activeItem === "order_book" ? "active" : ""
                    }`}
                    onMouseEnter={() => handleMouseEnter("order_book")}
                    onMouseLeave={handleMouseLeave}
                    style={getMenuItemStyle("order_book")}
                  >
                    <Link
                      to="/admin/order_book"
                      className="menu-link"
                      onClick={() => handleSetActive("order_book")}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <i className="menu-icon tf-icons ri-book-marked-line"></i>
                      <div>Order Book</div>
                    </Link>
                  </li>
                )}
                <li
                  className={`menu-item ${
                    activeItem === "manage_faq" ? "active" : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter("manage_faq")}
                  onMouseLeave={handleMouseLeave}
                  style={getMenuItemStyle("manage_faq")}
                >
                  <Link
                    to="/admin/manage_faq"
                    className="menu-link"
                    onClick={() => handleSetActive("manage_faq")}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <i className="menu-icon tf-icons ri-question-answer-line"></i>
                    <div> FAQ</div>
                  </Link>
                </li>

                <li
                  className={`menu-item ${
                    activeItem === "manage_subscription" ? "active" : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter("manage_subscription")}
                  onMouseLeave={handleMouseLeave}
                  style={getMenuItemStyle("manage_subscription")}
                >
                  <Link
                    to="/admin/manage_subscription"
                    className="menu-link"
                    onClick={() => handleSetActive("manage_subscription")}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <i className="menu-icon tf-icons ri-medal-2-fill"></i>
                    <div> Manage Subscription</div>
                  </Link>
                </li>

               

                <li
                  className={`menu-item ${
                    activeItem === "feature_request" ? "active" : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter("feature_request")}
                  onMouseLeave={handleMouseLeave}
                  style={getMenuItemStyle("feature_request")}
                >
                  <Link
                    to="/admin/feature_request"
                    className="menu-link"
                    onClick={() => handleSetActive("feature_request")}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <i className="menu-icon tf-icons ri-sparkling-line"></i>
                    <div> Feature Request</div>
                  </Link>
                </li>
                <li
                  className={`menu-item ${
                    activeItem === "manage_telegram_subscription" ? "active" : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter("manage_telegram_subscription")}
                  onMouseLeave={handleMouseLeave}
                  style={getMenuItemStyle("manage_telegram_subscription")}
                >
                  <Link
                    to="/admin/manage_telegram_subscription"
                    className="menu-link"
                    onClick={() => handleSetActive("manage_telegram_subscription")}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <i className="menu-icon tf-icons ri-telegram-line"></i>
                    <div> Telegram Subscription</div>
                  </Link>
                </li>
            
            </ul>
            
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default AdminSubHeader;


  
{/* <li className="nav-item navbar-dropdown dropdown-user dropdown" ref={dropdownRef}>
<a
  className="nav-link dropdown-toggle hide-arrow p-0"
  href="#!"
  onClick={toggleDropdown}
>
 <div> Subscription</div>
</a>

{dropdownOpen && (
  <ul className="dropdown-menu dropdown-menu-end mt-3 py-2 show" >
    <li>
      <Link to="/admin/profile" className="dropdown-item" onClick={toggleDropdown}>
        <i className="ri-user-line ri-22px me-2"></i>
        <span className="align-middle">Profile</span>
      </Link>
    </li>
    <li>
      <Link to="/admin/my_report" className="dropdown-item" onClick={toggleDropdown}>
        <i className="ri-bar-chart-box-line ri-22px me-2"></i>
        <span className="align-middle">My Reports</span>
      </Link>
    </li>
    
  </ul>
)}
</li> */}
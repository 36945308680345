// import React from 'react';
// import CalendarHeatmap from 'react-calendar-heatmap';
// import 'react-calendar-heatmap/dist/styles.css';


// const HeatmapCalendar = () => {
//   const data = [
//     { date: '2022-01-01', count: 1 },
//     { date: '2022-01-02', count: 4 },
//     { date: '2022-01-03', count: 2 },
//     { date: '2022-01-04', count: 1 },
//     { date: '2022-01-05', count: 4 },
//     { date: '2022-01-06', count: 2 },
//     { date: '2022-01-01', count: 1 },
//     { date: '2022-01-07', count: 4 },
//     { date: '2022-01-08', count: 2 },
//     { date: '2022-01-08', count: 1 },
//     { date: '2022-01-10', count: 4 },
//     { date: '2022-01-13', count: 2 },
//     { date: '2022-02-01', count: 1 },
//     { date: '2022-03-02', count: 4 },
//     { date: '2022-04-03', count: 2 },
//     { date: '2022-06-04', count: 1 },
//     { date: '2022-06-05', count: 4 },
//     { date: '2022-06-06', count: 2 },
//     { date: '2022-06-01', count: 1 },
//     { date: '2022-06-07', count: 4 },
//     { date: '2022-10-08', count: 2 },
//     { date: '2022-11-08', count: 1 },
//     { date: '2022-11-10', count: 4 },
//     { date: '2022-11-13', count: 2 },
//   ];

//   return (
//     <div>
//       <CalendarHeatmap
//         startDate={new Date('2022-01-01')}
//         endDate={new Date('2022-12-31')}
//         values={data}
//         classForValue={(value) => {
//           if (!value) {
//             return 'color-empty';
//           }
//           return `color-github-${value.count}`;
//         }}
//         tooltipDataAttrs={(value) => {
//           return {
//             'data-tip': value.date ? `${value.date}: ${value.count}` : 'No data',
//           };
//         }}
//         showWeekdayLabels={true}
//       />
//     </div>
//   );
// };

// export default HeatmapCalendar;



import React from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';


const HeatmapCalendar = () => {
    const data = [
        { date: '2022-01-01', count: 1 },
        { date: '2022-01-02', count: 4 },
        { date: '2022-01-03', count: 2 },
        { date: '2022-01-04', count: 1 },
        { date: '2022-01-05', count: 4 },
        { date: '2022-01-06', count: 2 },
        { date: '2022-01-01', count: 1 },
        { date: '2022-01-07', count: 4 },
        { date: '2022-01-08', count: 2 },
        { date: '2022-01-08', count: 1 },
        { date: '2022-01-10', count: 4 },
        { date: '2022-01-13', count: 2 },
        { date: '2022-02-01', count: 1 },
        { date: '2022-03-02', count: 4 },
        { date: '2022-04-03', count: 2 },
        { date: '2022-06-04', count: 1 },
        { date: '2022-06-05', count: 4 },
        { date: '2022-06-06', count: 2 },
        { date: '2022-06-01', count: 1 },
        { date: '2022-06-07', count: 4 },
        { date: '2022-10-08', count: 2 },
        { date: '2022-11-08', count: 1 },
        { date: '2022-11-10', count: 4 },
        { date: '2022-11-13', count: 2 },
      ];

  return (
    <div className="calendar-heatmap">
      <CalendarHeatmap
        startDate={new Date('2022-01-01')}
        endDate={new Date('2022-12-31')}
        values={data}
        classForValue={(value) => {
          if (!value) {
            return 'color-empty';
          }
          return `color-github-${value.count}`;
        }}
        tooltipDataAttrs={(value) => {
          return {
            'data-tip': value.date ? `${value.date}: ${value.count}` : 'No data',
          };
        }}
        showWeekdayLabels={true}
        weekdayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']} // Display all weekdays
      />
    </div>
  );
};

export default HeatmapCalendar;







import React, { useState, useRef } from "react";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer";
import AdminSubHeader from "./AdminSubHeader";
import { Link, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import config from "../../../component/config";
import { Toast } from "primereact/toast";
import CardLinks from "../../../component/CardLinks";
const CreateTeacher = () => {
  const navigate = useNavigate();
  const [backClicked, setBackClicked] = useState(false);
  const toast = useRef(null);
  const [selectedBroker, setSelectedBroker] = useState("angle_one");

  const handleBrokerChange = (broker) => {
    setSelectedBroker(broker);
  };
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    commission: "10",
    trading_power: "",
    lot_size_limit: "1",
    api_key: "",
    client_id: "",
    password: "",
    qr_totp_token: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [withBrokerDetails, setWithBrokerDetails] = useState(false);

  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;

    // Validation logic
    if (!formData.name) {
      tempErrors.name = "Name is required";
      isValid = false;
    } else if (/\d/.test(formData.name)) {
      tempErrors.name = "Name cannot contain digits";
      isValid = false;
    } else if (/[^a-zA-Z\s]/.test(formData.name)) {
      tempErrors.name = "Name cannot contain special characters";
      isValid = false;
    } else if (formData.name.length < 3) {
      tempErrors.name = "Name must be at least 3 characters";
      isValid = false;
    } else if (formData.name.length > 50) {
      tempErrors.name = "Name must be at most 50 characters";
      isValid = false;
    }

    if (!formData.email) {
      tempErrors.email = "Email is required";
      isValid = false;
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
    ) {
      tempErrors.email = "Email is invalid";
      isValid = false;
    }

    if (!formData.mobile) {
      tempErrors.mobile = "Mobile number is required";
      isValid = false;
    } else if (!/^\d+$/.test(formData.mobile)) {
      tempErrors.mobile = "Mobile number must contain only digits";
      isValid = false;
    } else if (formData.mobile.length !== 10) {
      tempErrors.mobile = "Mobile number must be exactly 10 digits";
      isValid = false;
    }

    if (formData.lot_size_limit === "") {
      tempErrors.lot_size_limit = "Lot size limit is required";
      isValid = false;
    } else if (isNaN(formData.lot_size_limit)) {
      tempErrors.lot_size_limit = "Lot size limit must be a number";
      isValid = false;
    } else {
      const lotSizeLimit = parseInt(formData.lot_size_limit, 10);
      if (lotSizeLimit < 1 || lotSizeLimit > 5000) {
        tempErrors.lot_size_limit = "Lot size limit must be between 1 and 5000";
        isValid = false;
      }
    }

    if (!formData.commission) {
      tempErrors.commission = "Commission is required";
      isValid = false;
    } else if (
      formData.commission.includes("%") &&
      formData.commission.length === 1
    ) {
      tempErrors.commission = "Commission cannot be just '%'";
      isValid = false;
    }

   

    setErrors(tempErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Immediate validation for the changed field
    if (name === "mobile") {
      if (value.length !== 10) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Mobile number must be exactly 10 digits",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    } else {
      if (value.trim() !== "" && errors[name]) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    setErrors({});

    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
       if (!authToken || !userId) {
      navigate("/login");
        return;
      }

      const requestData = {
        name: formData.name,
        email: formData.email,
        mobile: formData.mobile,
        commission: formData.commission,
        lot_size_limit: formData.lot_size_limit,
      };

      if (withBrokerDetails) {
        requestData.broker = selectedBroker;
        requestData.broker_details = {
          api_key: formData.api_key,
          client_id: formData.client_id,
          password: formData.password,
          qr_totp_token: formData.qr_totp_token,
        };
      }

      const response = await axios.post(
        `https://ghanish.in/api/admin/manage_teachers/create?with_broker_details=${withBrokerDetails}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data && response.data.st === 1) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: formatMessage(response.data.msg) || "Success!",
          className: "text-start",
          life: 2000,
        });

        setTimeout(() => {
          navigate("/admin/manage_teacher");
        }, 1000);
      } else if (response.data && response.data.st === 2) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail:
            formatMessage(response.data.msg) ||
            "Warning: Please check the input.",
          life: 2000,
        });
      } else if (
        response.data &&
        (response.data.st === 3 || response.data.st === 4)
      ) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: formatMessage(response.data.msg) || "An error occurred.",
          life: 2000,
        });
      } else {
        setErrors({ global: "Unexpected response status" });
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Unexpected response status",
          life: 2000,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      } else {
        const errorMsg = error.response?.data?.msg
          ? formatMessage(error.response.data.msg)
          : String(error.message || "Failed to fetch data");

        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 2000,
          className: "text-start",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  

  const formatMessage = (msg) => {
    if (typeof msg === "object") {
      return Object.values(msg).flat().join(", ");
    }
    return String(msg);
  };

  return (
    <>
      <AdminHeader />
      <AdminSubHeader />

      <div className="container-xxl flex-grow-1 container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/manage_teacher" className="text-black">
                Manage Teacher
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Create Teacher
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col-5 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 text-start mb-5">
              <h5 className="mb-0">Create Teacher</h5>
            </div>
          </div>
          <div className="row">
            <h5 className="text-start">
              {" "}
              <i className="ri-user-line ri-ms me-2"></i>
              Personal Information
            </h5>
            <div className="col-12">
              <form onSubmit={handleSubmit}>
                <div className="row mt-1">
                  <div className="col-3">
                    <div className="form-floating form-floating-outline">
                      <input
                        className={`form-control ${
                          errors.name ? "is-invalid" : ""
                        }`}
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Name"
                      
                      />
                      <label htmlFor="name">
                        {" "}
                        <span className="text-danger">*</span> Name{" "}
                      </label>
                      {errors.name && (
                        <p className="error">{errors.name}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-floating form-floating-outline">
                      <input
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        type="text"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="E-mail"
                       
                      />
                      <label htmlFor="email">
                        {" "}
                        <span className="text-danger">*</span> E-mail{" "}
                      </label>
                      {errors.email && (
                        <p className="error">{errors.email}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-floating form-floating-outline">
                      <input
                        className={`form-control ${
                          errors.mobile ? "is-invalid" : ""
                        }`}
                        type="text"
                        id="mobile"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        placeholder="Mobile Number"
                        maxLength="10"
                      />
                      <label htmlFor="mobile">
                        <span className="text-danger">*</span> Mobile Number
                      </label>
                      {errors.mobile && (
                        <p className="error">{errors.mobile}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-1">
                    <div className="form-floating form-floating-outline">
                      <input
                        className={`form-control ${
                          errors.lot_size_limit ? "is-invalid" : ""
                        }`}
                        type="number"
                        id="lot_size_limit"
                        name="lot_size_limit"
                        value={formData.lot_size_limit}
                        onChange={handleChange}
                        placeholder="Lot Size Limit"
                      
                        maxLength="5000"
                      />
                      <label htmlFor="lot_size_limit">
                        <span className="text-danger">*</span> Lot Size Limit{" "}
                      </label>
                      {errors.lot_size_limit && (
                        <p className="error">{errors.lot_size_limit}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-1">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="number"
                        className={`form-control ${
                          errors.commission ? "is-invalid" : ""
                        }`}
                        id="commission"
                        name="commission"
                        value={formData.commission}
                        onChange={handleChange}
                        placeholder="Commission"
                      
                      />
                      <label htmlFor="commission">
                        <span className="text-danger">*</span> Commission
                      </label>
                      {errors.commission && (
                        <p className="error">{errors.commission}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12 mt-3 d-flex justify-content-end">
                  <div className="form-check text-end">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="withBrokerDetails"
                      name="withBrokerDetails"
                      
                      checked={withBrokerDetails}
                      onChange={() => setWithBrokerDetails(!withBrokerDetails)}
                      
                    />
                    <label
                      className="form-check-label"
                      htmlFor="withBrokerDetails"
                    >
                      Include Broker Details
                    </label>
                  </div>
                </div>
                <hr></hr>
                {withBrokerDetails && (
                <div className="row mb-3">
                  <h5 className="text-start">
                    {" "}
                    <i className="ri-group-line ri-ms me-2"></i>
                    Broker Information
                  </h5>
                  <div className="nav-align-top mb-6">
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <button
            type="button"
            className={`nav-link  ${selectedBroker === "angle_one" ? "active-tab" : ""}`}
            role="tab"
            onClick={() => handleBrokerChange("angle_one")}
          >
            AngleOne
          </button>
        </li>
        {/* <li className="nav-item">
          <button
            type="button"
            className={`nav-link ${selectedBroker === "zerodha" ? "active" : ""}`}
            role="tab"
            onClick={() => handleBrokerChange("zerodha")}
          >
            Zerodha
          </button>
        </li>
        <li className="nav-item">
          <button
            type="button"
            className={`nav-link ${selectedBroker === "dhan" ? "active" : ""}`}
            role="tab"
            onClick={() => handleBrokerChange("dhan")}
          >
            Dhan
          </button>
        </li> */}
      </ul>
     
        {/* <div
          className={`tab-pane fade ${selectedBroker === "angle_one" ? "show active" : ""}`}
          id="navs-pills-top-home"
          role="tabpanel"
        > */}
          <div className="row mt-6">
                  <div className="col-3">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        className="form-control" 
                        id="client_id"
                        name="client_id"
                        placeholder="Broker Client ID"
                        required
                        value={formData.client_id}
                        onChange={handleChange}
                      />
                      <label htmlFor="client_id">
                        <span className="text-danger">*</span> Broker Client ID
                      </label>
                     
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        className="form-control" 
                        id="password"
                        name="password"
                        placeholder="Broker Password"
                        required
                        value={formData.password}
                        onChange={handleChange}
                      />
                      <label htmlFor="password">
                        {" "}
                        <span className="text-danger">*</span> Broker Password
                      </label>
                     
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        className="form-control" 
                        id="qr_totp_token"
                        name="qr_totp_token"
                        placeholder="Broker QR TOTP Token"
                        required
                        value={formData.qr_totp_token}
                        onChange={handleChange}
                      />
                      <label htmlFor="qr_totp_token">
                        {" "}
                        <span className="text-danger">*</span> Broker QR TOTP
                        Token
                      </label>
                    
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        className="form-control" 
                        id="api_key"
                        name="api_key"
                        placeholder="Broker API Key"
                        required
                        value={formData.api_key}
                        onChange={handleChange}
                      />
                      <label htmlFor="api_key">
                        {" "}
                        <span className="text-danger">*</span>Broker API Key
                      </label>
                     
                    </div>
                  </div>
                </div>
                {/* </div> */}
                </div>
                <div className="tab-pane fade" id="navs-pills-top-profile" role="tabpanel">
         
        </div>
        <div className="tab-pane fade" id="navs-pills-top-messages" role="tabpanel">
          
        </div>
                </div>
                  )}
              

                <div className="row">
                

                  <div className="col text-end mt-4">
                    <button
                      type="submit"
                      className="btn rounded-pill btn-success btn-sm"
                      disabled={loading}
                      
                    >
                      {loading ? (
                        <i className="ri-loader-line ri-lg me-1"></i>
                      ) : (
                        <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                      )}{" "}
                      {loading ? "Saving Data...." : "Save Data"}
                    </button>
                    <Toast ref={toast} position="top-right" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <CardLinks></CardLinks>
      </div>

      <Footer />
    </>
  );
};

export default CreateTeacher;




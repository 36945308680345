import React, { useState, useEffect, useRef } from "react";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import Footer from "../../../component/Footer";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import config from "../../../component/config";
import { Row, Col, Nav, Button, Card, Modal } from "react-bootstrap";
import { VirtualScroller } from "primereact/virtualscroller";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
import Skeleton from 'react-loading-skeleton';

const Basket = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [baskets, setBaskets] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const authToken = localStorage.getItem("authToken");
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("userId");
  const [showModal, setShowModal] = useState(false);
  const [items, setItems] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [backClicked, setBackClicked] = useState(false);
  const [error, setError] = useState(null);
  const [onlyTeacherExecute, setOnlyTeacherExecute] = useState(false);
  const [search, setSearch] = useState("");
  const [equityData, setEquityData] = useState([]);
  const [commodityData, setCommodityData] = useState([]);
  const [fnoData, setFnoData] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const [selectedExchange, setSelectedExchange] = useState([]);
  const [showNav, setShowNav] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const toast = useRef(null);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [modalSearch, setModalSearch] = useState("");
  const [showDropdownModal, setShowDropdownModal] = useState(false);
  const [modalItems, setModalItems] = useState([]);
  const [modalEquityData, setModalEquityData] = useState([]);
  const [modalCommodityData, setModalCommodityData] = useState([]);
  const [modalFnoData, setModalFnoData] = useState([]);
  const [equityCount, setEquityCount] = useState(0);
  const [commodityCount, setCommodityCount] = useState(0);
  const [fnoCount, setFnoCount] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [basketToDeleteIndex, setBasketToDeleteIndex] = useState(null);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const [brokerConnected, setBrokerConnected] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const wsRef = useRef(null);
  const [basketId, setBasketId] = useState(null); 
  const [deleteBasketInstrumentId, setDeleteBasketInstrumentId] = useState(null);
  const [deleteBasketId, setDeleteBasketId] = useState(null);
  const [showBasketInstrumentDelete, setShowBasketInstrumentDelete] = useState(false);
  const [basketInstrumentId, setBasketInstrumentId] = useState([]);
  const [basketData, setBasketData] = useState([]);
  
  const [errorMessage, setErrorMessage] = useState("");
  const handleShowDeleteModal = (index) => {
    setBasketToDeleteIndex(index);
    setShowDeleteModal(true);
  };

  const titleCase = (str) => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const handleOpenBasketInstrumentDelete = (token) => {
    const instrumentToDelete = basketData.find(item => item.token === token);
    
    if (instrumentToDelete) {
      setDeleteBasketInstrumentId(instrumentToDelete.basket_instrument_id);
      setShowBasketInstrumentDelete(true);
    }
  };
  
  const handleCloseBasketInstrumentDelete = () => {
    setShowBasketInstrumentDelete(false);
  };
  
  const handleDeleteBasketInstrument = async () => {
    try {
      const response = await axios.post(
        "https://ghanish.in/api/teacher/delete_basket_instrument",
        {
          basket_id: basketId,
          basket_instrument_id: deleteBasketInstrumentId
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.data.st === 1) {
        fetchBaskets();
        setShowBasketInstrumentDelete(false);
      }
    } catch (error) {
      console.error("Error deleting instrument:", error);
    }
  };
  

  const handleDeleteRowModal = (index) => {
    const newInstruments = [...currentBasket.instruments];
    newInstruments.splice(index, 1);
    setCurrentBasket((prevBasket) => ({
      ...prevBasket,
      instruments: newInstruments,
    }));
  };

  const [rows, setRows] = useState([]);

  const [currentBasket, setCurrentBasket] = useState({
    name: "",
    instruments: [],
  });

  const [editedBasket, setEditedBasket] = useState({
    name: "",
    instruments: [],
  });

  const fetchBaskets = async () => {
    setLoading(true);
    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");

      const response = await axios.post(
        `${config.apiDomain}/api/teacher/basket_list_view`,
        {
          teacher_id: userId,
        }, 
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.st === 1) {
        setBaskets(response.data.basket_details);
      } else {
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/login");
        // console.clear();
      } else {
      }
    } finally {
      setLoading(false);
    }
  };

  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDeleteBasket = async () => {
    if (basketToDeleteIndex === null) return;

    const basketId = baskets[basketToDeleteIndex].basket_id;

    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        navigate("/login");
        return;
      }

      const response = await fetch(
        `${config.apiDomain}/api/teacher/delete_basket`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ basket_id: basketId }),
        }
      );

      if (response.ok) {
        const newBaskets = [...baskets];
        newBaskets.splice(basketToDeleteIndex, 1);
        setBaskets(newBaskets);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/login");
      } else {
        console.error("Error deleting basket", error);
      }
    } finally {
      setShowDeleteModal(false);
    }
  };

  const createOrUpdateBasket = async (updatedRows) => {
    try {
      const basketData = updatedRows.map((row) => ({
        instrument: row.instrument,
        lot_quantity_buffer: row.lot_quantity_buffer,
        transactionType: row.transactionType,
        orderType: row.orderType,
        productType: row.productType,
      }));
  
      if (basketData.length === 0) {
        return null;
      }
  
      const requestBody = {
        basket_id: basketId || null,
        basket_name: editedBasket.name,
        teacher_id: userId,
        basket_data: basketData,
      };
  
      const response = await axios.post(
        "https://ghanish.in/api/teacher/create_update_basket",
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response && response.data && response.data.st === 1) {
        const basketDataResponse = response.data.basket_data || [];
        
        setBasketId(response.data.basket_id);
        setBasketData(basketDataResponse);

        const broker = response.data.broker;
        const tokens = basketDataResponse.map((row) => String(row.token));
        connectWebSocketForLTP(tokens, broker);
        
        window.showToast("success", response.data.msg || "success.");
        await fetchBaskets();
        
        return response.data;
      } else {
        window.showToast("error", response.data?.msg || "Failed to save the basket");
        return null;
      }
    } catch (error) {
      console.error("Error in createOrUpdateBasket:", error);
      window.showToast("error", "Failed to save the basket");
      return null;
    }
  };
  

  const connectWebSocketForLTP = (tokens, broker) => {
    let wsUrl;

    // Determine the WebSocket URL based on the broker
    if (broker === "angle_one") {
      wsUrl = "wss://ghanish.in/ws/angelone/live_market_data/";
    } else if (broker === "dhan") {
      wsUrl = "wss://ghanish.in/ws/dhan/live_market_data/";
    } else {
      console.log("Unsupported broker:", broker);
      return;
    }

    // Only establish the WebSocket connection if the market is open
    if (isMarketOpen()) {
      // If WebSocket is already open, close it before opening a new one
      if (wsRef.current) {
        wsRef.current.close();
        console.log("Previous WebSocket connection closed.");
      }

      wsRef.current = new WebSocket(wsUrl); // Create new WebSocket connection

      wsRef.current.onopen = () => {
        const requestData = {
          teacher_id: userId, // Assuming you have the userId in your context
          tokens: tokens, // Tokens for instruments to receive LTP updates
        };
        wsRef.current.send(JSON.stringify(requestData)); // Send tokens for LTP updates
        console.log("WebSocket connected");
      };

      wsRef.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          console.log("WebSocket message received:", data);

          if (data && data.data && data.data.token) {
            updateLtpInRows(data.data.token, data.data.ltp); // Ensure token is used for LTP update
          } else {
            console.warn(
              "Invalid WebSocket message structure or missing token:",
              data
            );
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      };

      wsRef.current.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      wsRef.current.onclose = () => {
        console.log("WebSocket disconnected");
      };
    } else {
      console.log("Market is closed. WebSocket connection not established.");
    }
  };

  
  useEffect(() => {
    return () => {
      if (wsRef.current) {
        wsRef.current.close(); // Close the WebSocket connection when unmounting
        console.log("WebSocket disconnected on component unmount");
      }
    };
  }, []); 

  const handleBasketUpdate = (tokens, broker) => {
    connectWebSocketForLTP(tokens, broker); 
  };

  const updateLtpInRows = (token, newLtp) => {
    console.log(`Updating LTP for token: ${token}, LTP: ${newLtp}`); // Debug log

    setRows((prevRows) => {
      const updatedRows = prevRows.map((row) => {
        // Ensure both row.token and WebSocket token are strings for comparison
        if (String(row.token) === String(token)) {
          const oldLtp = parseFloat(row.ltp) || 0; // Previous LTP, or 0 if not available
          const normalizedLtp = !isNaN(parseFloat(newLtp))
            ? parseFloat(newLtp).toFixed(2) // Normalize LTP to 2 decimal places
            : "N/A"; // Default to 'N/A' if LTP is invalid

          // Calculate the difference between the new LTP and the old LTP
          const ltpDifference = parseFloat(normalizedLtp) - oldLtp;

          // Assign color based on the LTP difference
          let ltpColor = "text-secondary"; // Default to secondary (neutral)

          if (ltpDifference > 0) {
            ltpColor = "text-success"; // Positive change, price increased
          } else if (ltpDifference < 0) {
            ltpColor = "text-danger"; // Negative change, price decreased
          }

          // Return the updated row with new LTP and color class
          return {
            ...row,
            ltp: normalizedLtp, // Update the LTP
            ltpColor, // Set the LTP color class for styling
            ltpDifference: ltpDifference.toFixed(2), // Store LTP difference
          };
        }
        return row;
      });

      console.log("Updated rows:", updatedRows); // Debug updated rows
      return updatedRows;
    });
  };

  useEffect(() => {
    // This will clean up the WebSocket connection when the component unmounts
    return () => {
      if (wsRef.current) {
        wsRef.current.close(); // Close the WebSocket connection when unmounting
        console.log("WebSocket disconnected");
      }
    };
  }, []);

  const handleCloseButtonClick = () => {
    setIsTableVisible(false);  // Hide the table
    setRows([]);  // Clear the rows
    setBasketId(null);  // Reset the basketId so that a new basket can be created next time
  
    if (wsRef.current) {
      wsRef.current.close();  // Close the WebSocket when closing the table
      console.log("WebSocket disconnected");
    }
  };
  
  

  const handleSearch = async (e) => {
    let searchValue = e.target.value.toUpperCase();

    if (searchValue.length > 15) {
      searchValue = searchValue.slice(0, 15);
    }

    setSearch(searchValue);

    if (searchValue.trim().length >= 3) {
      try {
        const response = await axios.post(
          `${config.apiDomain}/api/teacher/get_instrument_list_new`,
          {
            search: searchValue,
            teacher_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.st === 1) {
          const equity = response.data.data.equity || [];
          const commodity = response.data.data.commodity || [];
          const fno = response.data.data.fno || [];

          setEquityData(equity);
          setCommodityData(commodity);
          setFnoData(fno);
          setShowDropdown(true);

          const instrumentList = [...equity, ...commodity, ...fno].map(
            (item) => item.symbol
          );
          setItems(instrumentList);

          setEquityCount(equity.length);
          setCommodityCount(commodity.length);
          setFnoCount(fno.length);
        } else {
          setEquityData([]);
          setFnoData([]);
          setCommodityData([]);
          setShowDropdown(false);
          setItems([]);
          setEquityCount(0);
          setCommodityCount(0);
          setFnoCount(0);
          resetSearchData();
        }
      } catch (error) {
        // console.clear();
      }
    } else {
      setEquityData([]);
      setFnoData([]);
      setCommodityData([]);
      setShowDropdown(false);
      setItems([]);
      setEquityCount(0);
      setCommodityCount(0);
      setFnoCount(0);
      resetSearchData();
    }
  };
  const resetSearchData = () => {
    setEquityData([]);
    setCommodityData([]);
    setFnoData([]);
    setShowDropdown(false);
    setItems([]);
    setEquityCount(0);
    setCommodityCount(0);
    setFnoCount(0);
  };
  const handleStockSelect = (stock) => {
    if (rows.length >= 30) {
     
      window.showToast("error", "basket is full" );

      return;
    }
  
    const newInstrument = {
      instrument: stock.symbol,
      lot_quantity_buffer: "1",
      transactionType: "BUY",
      orderType: "MARKET",
      productType: "CARRYFORWARD",
      exch_seg: stock.exch_seg,
      price: "",
      token: String(stock.token), // Normalize token to string
      ltp: "N/A", // Initialize LTP with 'N/A'
      isNew: true,  // Mark as new row for easy filtering
    };
  
    setRows((prevRows) => {
      const updatedRows = [...prevRows, newInstrument];
  
      // Call createOrUpdateBasket to update the basket
      createOrUpdateBasket(updatedRows).then((response) => {
        if (response.st === 1) {
          const broker = response.broker; // Get the broker from the response
          const tokens = updatedRows.map((row) => String(row.token)); // Normalize tokens to strings
  
          // Connect WebSocket for LTP updates with the correct broker
          connectWebSocketForLTP(tokens, broker);
        } else {
          console.error("Error updating basket:", response.msg);
        }
      });
  
      return updatedRows;
    });
  
    setIsTableVisible(true);
    setSearch(""); // Clear search input
  };
  

  const handleExchangeSelect = (exchange) => {
    setSelectedExchange(exchange);
  };

  const filteredStocks = (() => {
    switch (selectedExchange) {
      case "Equity":
        return equityData;
      case "FnO":
        return fnoData;
      case "Commodity":
        return commodityData;
      default:
        return [...equityData, ...fnoData, ...commodityData];
    }
  })();

  const handleRefresh = async () => {
    setLoading(true);
    setIsRefreshing(true);
    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
      if (!authToken) {
        return;
      }

      const response = await axios.post(
        `${config.apiDomain}/api/teacher/basket_list_view`,
        {
          teacher_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.st === 1) {
        setBaskets(response.data.basket_details);
      } else if (response.data.st === 2) {
       
        window.showToast("warning", response.data.msg );

      } else if (response.data.st === 3 || response.data.st === 4) {
        
        window.showToast("error", response.data.msg );

      } else {
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          navigate("/login");
        } else if (error.response.data.st) {
          const { st, msg } = error.response.data;
          if (st === 4) {
           
            window.showToast("error", msg );

          } else {
          
            window.showToast("error", msg );

          }
        } else {
         
          window.showToast("error", "Something went wrong. Contact Support." );

        }
      } else {
        window.showToast("error", "Something went wrong. Contact Support." );

      }
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };

  const handleInputChanges = (index, event) => {
    const { name, value } = event.target;
    const newRows = [...rows];
    newRows[index] = {
      ...newRows[index],
      [name]: value,
    };
    setRows(newRows);
  };

  const filterBaskets = () => {
    if (!globalFilter) {
      return baskets;
    }
    return baskets.filter((basket) =>
      basket.name.toLowerCase().includes(globalFilter.toLowerCase())
    );
  };

  const connectWebSocket = (tokens, broker) => {
    let wsUrl;

    // Check the broker and set the WebSocket URL accordingly
    if (broker === "angle_one") {
      wsUrl = "wss://ghanish.in/ws/angelone/live_market_data/";
    } else if (broker === "dhan") {
      wsUrl = "wss://ghanish.in/ws/dhan/live_market_data/";
    } else {
      console.log("Unsupported broker:", broker);
      return; // Exit the function if the broker is not supported
    }

    // Only establish the WebSocket connection if the market is open
    if (isMarketOpen()) {
      if (wsRef.current) {
        // WebSocket already exists, just send new tokens
        const teacherId = userId;
        const requestData = {
          teacher_id: teacherId,
          tokens: tokens,
        };
        wsRef.current.send(JSON.stringify(requestData)); // Send new tokens to the server
      } else {
        wsRef.current = new WebSocket(wsUrl);

        wsRef.current.onopen = () => {
          console.log("WebSocket connected");
          const teacherId = userId;
          const requestData = {
            teacher_id: teacherId,
            tokens: tokens,
          };
          wsRef.current.send(JSON.stringify(requestData));
        };

        wsRef.current.onmessage = (event) => {
          const data = JSON.parse(event.data);
          console.log("Live market data received:", data);

          if (data.data === null) {
            console.log("Received null data, disconnecting WebSocket");
            disconnectWebSocket();
          } else {
            updateLtp(data.data.token, data.data.ltp); // Update the LTP in the UI
          }
        };

        wsRef.current.onerror = (error) => {
          console.log("WebSocket error:", error);
        };

        wsRef.current.onclose = () => {
          console.log("WebSocket disconnected");
        };
      }
    } else {
      console.log("Market is closed. WebSocket connection not established.");
    }
  };

  const disconnectWebSocket = () => {
    if (wsRef.current) {
      wsRef.current.close(); // Close the WebSocket connection
      wsRef.current = null; // Clear the reference
    }
  };

  const updateLtp = (token, newLtp) => {
    const normalizedToken = token.toString(); // Convert token to string
    const normalizedLtp = parseFloat(newLtp); // Convert LTP to number for calculations

    setCurrentBasket((prevBasket) => ({
      ...prevBasket,
      instruments: prevBasket.instruments.map((instrument) => {
        if (instrument.token === normalizedToken) {
          const oldLtp = parseFloat(instrument.ltp) || 0; // Use previous LTP or 0 if not available
          const ltpDifference = normalizedLtp - oldLtp; // Calculate difference

          // Determine the class based on the LTP difference
          let ltpClass = "text-secondary"; // Default: no change
          if (ltpDifference > 0) {
            ltpClass = "text-success"; // Positive change
          } else if (ltpDifference < 0) {
            ltpClass = "text-danger"; // Negative change
          }

          return {
            ...instrument,
            ltp: normalizedLtp.toFixed(2), // Store new LTP, formatted to 2 decimal places
            ltpDifference: ltpDifference.toFixed(2), // Store the difference with 2 decimals
            ltpClass, // Store the class for styling
          };
        }
        return instrument;
      }),
    }));
  };

  const handleEditBasket = async (index) => {
    try {
      const authToken = localStorage.getItem("authToken");
      const basketId = baskets[index].basket_id;

      const response = await axios.post(
        `${config.apiDomain}/api/teacher/get_basket_details`,
        { basket_id: basketId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const basketData = response.data.basket_data;
      const tokens = basketData.map((item) => item.token); // Extract tokens from the basket data
      const broker = response.data.broker;
      setBrokerConnected(response.data.broker_connection_status);

      setCurrentBasket({
        basket_id: response.data.basket_id, // Ensure basket_id is set
        name: response.data.basket_name,
        instruments: basketData, // Use the fetched instruments
      });

      connectWebSocket(tokens, broker);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/login");
      } else {
        setError(error.message || "Failed to fetch data");
      }
    }
  };

  useEffect(() => {
    return () => {
      disconnectWebSocket(); // Disconnect when component unmounts
    };
  }, []);

  const handleInputChange = (index, event, isModal = false) => {
    const { name, value } = event.target;
    let error = "";

    if (name === "instrument" && value.trim() === "") {
      error = "Instrument is required";
    } else if (name === "lot_quantity_buffer" && !/^\d+$/.test(value)) {
      error = "Lot Quantity Buffer must be a number";
    }

    const updatedErrors = { ...errors };
    updatedErrors[index] = {
      ...updatedErrors[index],
      [name]: error,
    };
    setErrors(updatedErrors);

    const updatedInstruments = isModal
      ? [...editedBasket.instruments]
      : [...currentBasket.instruments];
    updatedInstruments[index] = {
      ...updatedInstruments[index],
      [name]: value,
    };

    if (isModal) {
      setEditedBasket({
        ...editedBasket,
        instruments: updatedInstruments,
      });
    } else {
      setCurrentBasket({
        ...currentBasket,
        instruments: updatedInstruments,
      });
    }
  };

  const handleExecuteAll = async () => {
    const userId = localStorage.getItem("userId");
    const instrumentsErrors = currentBasket.instruments.some(
      (instrument, index) => {
        return (
          !instrument.instrument ||
          instrument.instrument.trim() === "" ||
          errors[index]?.instrument
        );
      }
    );

    setIsLoading(true);
    setErrorMessage("");
    try {
      const response = await axios.post(`https://apbacked.xyz/execute_orders`, {
        teacher_id: userId,
        order_data: currentBasket.instruments.map((instrument) => ({
          instrument: instrument.instrument,
          lot_quantity_buffer: instrument.lot_quantity_buffer,
          transactionType: instrument.transaction_type,

          orderType: instrument.order_type,
          productType: instrument.product_type,
          price: instrument.price,
        })),
        only_teacher_execute: onlyTeacherExecute,
      });

      if (response.data.st === 1) {
        navigate("/trade_position");
        window.location.reload();
      } else {
        // Backend returned an error
        setErrorMessage(
          response.data.msg || "An error occurred during order execution."
        );
      }
    } catch (error) {
      // Handle any request errors
      if (error.response && error.response.data && error.response.data.msg) {
        setErrorMessage(error.response.data.msg); // Show error from backend
      } else {
        setErrorMessage("An unexpected error occurred during order execution.");
      }
    } finally {
      setIsLoading(false); // Stop loading state
    }
  };

  const getFormattedBasketName = (index) => {
    return `Basket ${index + 1}`;
  };

  useEffect(() => {
    fetchBaskets();
  }, [userId]);

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...editedBasket.rows];
    updatedRows[index] = { ...updatedRows[index], [field]: value };
    setEditedBasket({ ...editedBasket, rows: updatedRows });
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    setEditedBasket({ ...editedBasket, name: value });

    if (value.trim() !== "") {
      setValidationErrors({ ...validationErrors, name: "" });
    }
  };

  

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    rows: Array(rows.length).fill({
      instrument: "",
    }),
  });

  const validateFields = () => {
    let isValid = true;
    const errors = { ...validationErrors };

    if (!editedBasket.name.trim()) {
      errors.name = "Basket Name is required";
      isValid = false;
    } else if (editedBasket.name.length < 3) {
      errors.name = "Basket Name must be at least 3 characters";
      isValid = false;
    } else if (editedBasket.name.length > 20) {
      errors.name = "Basket Name must be at most 20 characters";
      isValid = false;
    } else {
      errors.name = "";
    }

    rows.forEach((row, index) => {
      const rowErrors = { ...errors.rows[index] };

      if (!row.instrument.trim()) {
        rowErrors.instrument = "Instrument is required";
        isValid = false;
      } else {
        rowErrors.instrument = "";
      }

      if (!row.lot_quantity_buffer.trim()) {
        rowErrors.lot_quantity_buffer = "Lot QTY Buffer is required";
        isValid = false;
      } else if (!/^[0-9]*$/.test(row.lot_quantity_buffer)) {
        // Allow empty string
        rowErrors.lot_quantity_buffer = "Only positive numbers are allowed";
        isValid = false;
      } else if (parseInt(row.lot_quantity_buffer) < 0) {
        rowErrors.lot_quantity_buffer = "Lot QTY Buffer must be non-negative";
        isValid = false;
      } else {
        rowErrors.lot_quantity_buffer = "";
      }

      errors.rows[index] = rowErrors;
    });

    setValidationErrors(errors);

    return isValid;
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setMessage("");
    setError("");
    disconnectWebSocket();

    setGlobalFilter("");
  };
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const isMarketOpen = () => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    const currentDay = currentTime.getDay();

    const marketOpenHour = 9;
    const marketOpenMinute = 15;
    const marketCloseHour = 15;
    const marketCloseMinute = 30;

    // If it's Sunday (0) or Saturday (6), the market is closed
    if (currentDay === 0 || currentDay === 6) {
      return false;
    }

    // Check if the current time is within market hours (9:15 AM to 3:30 PM)
    if (
      (currentHour > marketOpenHour ||
        (currentHour === marketOpenHour &&
          currentMinute >= marketOpenMinute)) &&
      (currentHour < marketCloseHour ||
        (currentHour === marketCloseHour && currentMinute <= marketCloseMinute))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success";
    } else if (hours === 15) {
      return "danger";
    }
    return "secondary";
  };
  const handleToggleChange = () => {
    setOnlyTeacherExecute(!onlyTeacherExecute);
  };
  const handleModalSearch = async (e) => {
    let searchValue = e.target.value.toUpperCase();

    if (searchValue.length > 15) {
      searchValue = searchValue.slice(0, 15);
    }

    setModalSearch(searchValue);

    if (searchValue.trim().length >= 3) {
      try {
        const response = await axios.post(
          `${config.apiDomain}/api/teacher/get_instrument_list_new`,
          { search: searchValue, teacher_id: userId },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.st === 1) {
          const equity = response.data.data.equity || [];
          const commodity = response.data.data.commodity || [];
          const fno = response.data.data.fno || [];

          setModalEquityData(equity);
          setModalCommodityData(commodity);
          setModalFnoData(fno);
          setShowDropdownModal(true);

          const instrumentList = [...equity, ...commodity, ...fno].map(
            (item) => item.symbol
          );
          setModalItems(instrumentList);

          setEquityCount(equity.length);
          setCommodityCount(commodity.length);
          setFnoCount(fno.length);
        } else {
          resetModalSearchData(); // If no data is found, reset search
        }
      } catch (error) {
        console.clear();
      }
    } else {
      resetModalSearchData();
    }
  };

  const resetModalSearchData = () => {
    setModalEquityData([]);
    setModalFnoData([]);
    setModalCommodityData([]);
    setShowDropdownModal(false);
    setModalItems([]);
    setEquityCount(0);
    setCommodityCount(0);
    setFnoCount(0);
  };

  const handleModalStockSelect = async (stock) => {
    if (currentBasket?.instruments?.length >= 30) {
     

      setShowDropdownModal(false);
      return;
    }

    const newInstrument = {
      instrument: stock.symbol,
      exch_seg: stock.exch_seg || "N/A",
      lot_quantity_buffer: "1",
      transaction_type: "BUY",
      order_type: "MARKET",
      product_type: "INTRADAY",
      price: "",
      token: stock.token, // Assuming stock object contains token
    };

    // Add the new instrument to the basket
    const updatedBasket = {
      ...currentBasket,
      instruments: [...currentBasket.instruments, newInstrument],
    };

    // Update the basket in state
    setCurrentBasket(updatedBasket);

    // Now call the create_update_basket API
    try {
      const authToken = localStorage.getItem("authToken");

      const requestBody = {
        basket_id: currentBasket.basket_id || null, // Use the basket_id from the state, null if new
        basket_name: currentBasket.name || "New Basket",
        teacher_id: userId,
        basket_data: updatedBasket.instruments.map((item) => ({
          instrument: item.instrument,
          exch_seg: item.exch_seg,
          lot_quantity_buffer: item.lot_quantity_buffer,
          transactionType: item.transaction_type,
          orderType: item.order_type,
          productType: item.product_type,
          price: item.price || null,
        })),
      };

      const response = await axios.post(
        `${config.apiDomain}/api/teacher/create_update_basket`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.st === 1) {
        // Update the current basket with the new basket_id and broker
        setCurrentBasket((prevBasket) => ({
          ...prevBasket,
          basket_id: response.data.basket_id,
          broker: response.data.broker, // Capture broker from response
        }));
       
        window.showToast("success", response.data.msg||"Success" );


        // Connect the new tokens to WebSocket for LTP updates using the broker from response
        const tokens = updatedBasket.instruments.map((item) => item.token);
        connectWebSocket(tokens, response.data.broker); // Pass the broker

        await fetchBaskets(); // Refresh basket list if necessary
      } else {
       
        window.showToast("error", response.data.msg);

      }
    } catch (error) {
      console.error("Error in create/update basket", error);
      
      window.showToast("success", "Failed to update the basket" );

    }

    // Reset search
    setModalSearch("");
    setShowDropdownModal(false);
  };

  const filteredModalStocks = (() => {
    switch (selectedExchange) {
      case "Equity":
        return modalEquityData;
      case "FnO":
        return modalFnoData;
      case "Commodity":
        return modalCommodityData;
      default:
        return [...modalEquityData, ...modalFnoData, ...modalCommodityData];
    }
  })();
  const getFormattedDate = () => {
    const today = new Date();
    const options = { weekday: "long", day: "numeric", month: "short" };
    return today.toLocaleDateString("en-GB", options);
  };
  useEffect(() => {
    const defaultName = getFormattedDate();
    setEditedBasket({ name: defaultName });
  }, []);

  return (
    <div>
      <Toast ref={toast} />
      <Header />
      <SubHeader />
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="mt-3 container-xxl">
        {isMarketOpen() ? (
          <div
            className="text-center "
            style={{
              border: "2px solid green",
              padding: "10px",
              color: "green",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            Market is Open
          </div>
        ) : (
          <div
            className="text-center "
            style={{
              border: "2px solid orange",
              padding: "10px",
              color: "orange",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            Market is Closed
          </div>
        )}
      </div>

      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <div className="container-xxl flex-grow-1 container-p-y">
            <nav aria-label="breadcrumb">
              <div className="d-flex justify-content-between align-items-center">
                <ol className="breadcrumb breadcrumb-style1 text-black">
                  <li className="breadcrumb-item">
                    <Link to="/teacher/dashboard" className="text-black">
                      <i className="ri-home-7-line ri-lg"></i>
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active text-secondary"
                    aria-current="page"
                  >
                    Basket
                  </li>
                </ol>
                <SubscriptionBadge></SubscriptionBadge>
              </div>
            </nav>
            <div className="row">
            <div className="col-xl-9 col-lg-9 col-md-7 col-12">
  <div className="card">
    <div className="col-12 d-flex align-items-center justify-content-between p-4">
      <div className="col-2">
        <button
          onClick={handleBack}
          className="btn rounded-pill btn-outline-secondary btn-xs"
        >
          <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
        </button>
      </div>
      <div className="col-8 text-center">
        <strong style={{ fontSize: "1.4rem" }}>Create Basket</strong>
      </div>
      <div className="col-2"></div> {/* Empty div for spacing */}
    </div>
 


                  <div className="column">
                    <div className="col-4 ms-3">
                      <div
                        className={`form-floating form-floating-outline ${
                          validationErrors.name ? "has-error" : ""
                        }`}
                      >
                        <input
                          type="text"
                          className={`form-control form-control-sm me-3 ${
                            validationErrors.name ? "is-invalid" : ""
                          }`}
                          placeholder="Enter basket name"
                          value={editedBasket.name}
                          onChange={handleNameChange}
                        />
                        <label htmlFor="brokerApiKey">
                          {" "}
                          <span className="text-danger">* </span>Basket Name
                        </label>
                        {validationErrors.name && (
                          <span className="text-danger">
                            {validationErrors.name}
                          </span>
                        )}
                      </div>
                    </div>

                    <div
                      className="col-10 col-lg-4 col-md-4  ms-3 mt-3"
                      style={{ position: "relative" }}
                    >
                      <input
                        type="text"
                        placeholder="Search instruments..."
                        value={search.toUpperCase()}
                        onChange={handleSearch}
                        className="bg-white form-control ps-8 mb-3"
                        maxLength={15}
                      />
                      <i className="ri ri-search-line position-absolute top-50 start-0 translate-middle-y ms-3"></i>

                      {showDropdown && search && filteredStocks.length > 0 && (
                        <Card
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: 0,
                            width: "100%",
                            zIndex: 1000,
                          }}
                        >
                          <Nav
                            justify
                            variant="tabs"
                            className="bg-body"
                            style={{ height: "45px", overflowX: "hidden" }}
                          >
                            {equityCount > 0 && (
                              <Nav.Item>
                                <Nav.Link
                                  className={`custom-nav-link mt-1 ${
                                    selectedExchange === "Equity"
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() => handleExchangeSelect("Equity")}
                                >
                                  <i className="ri-cash-line ri-lg me-1"></i>
                                  Equity
                                  <small
                                    className={`ms-1 ${
                                      selectedExchange === "Equity"
                                        ? "active"
                                        : "text-muted "
                                    }`}
                                  >
                                    ({equityCount})
                                  </small>
                                </Nav.Link>
                              </Nav.Item>
                            )}

                            {fnoCount > 0 && (
                              <Nav.Item>
                                <Nav.Link
                                  className={`custom-nav-link mt-1 ${
                                    selectedExchange === "FnO" ? "active" : ""
                                  }`}
                                  onClick={() => handleExchangeSelect("FnO")}
                                >
                                  <i className="ri-bar-chart-2-line ri-lg me-1"></i>
                                  FnO
                                  <small
                                    className={`ms-1 ${
                                      selectedExchange === "FnO"
                                        ? "active"
                                        : "text-muted "
                                    }`}
                                  >
                                    ({fnoCount})
                                  </small>
                                </Nav.Link>
                              </Nav.Item>
                            )}

                            {commodityCount > 0 && (
                              <Nav.Item>
                                <Nav.Link
                                  className={`custom-nav-link mt-1 ${
                                    selectedExchange === "Commodity"
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleExchangeSelect("Commodity")
                                  }
                                >
                                  <i className="ri-vip-diamond-line ri-lg me-1"></i>
                                  Commodity
                                  <small
                                    className={`ms-1 ${
                                      selectedExchange === "Commodity"
                                        ? "active"
                                        : "text-muted "
                                    }`}
                                  >
                                    ({commodityCount})
                                  </small>
                                </Nav.Link>
                              </Nav.Item>
                            )}
                          </Nav>

                          <Card.Body
                            className="bg-white p-0"
                            style={{
                              maxHeight: "250px",
                              overflowY: "auto",
                              overflowX: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Row>
                              {filteredStocks.map((stock, i) => (
                                <Col
                                  key={i}
                                  xs={12}
                                  className={`d-flex align-items-center mb-1 ${
                                    selectedStock === stock
                                      ? "bg-body text-black"
                                      : "hover-bg-secondary"
                                  } custom-col`}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleStockSelect(stock)}
                                >
                                  <div className="d-flex w-100 justify-content-between align-items-center ms-3">
                                    <div className="d-flex align-items-center">
                                      <span className="badge rounded-pill bg-label-secondary me-2">
                                        {stock.exch_seg}
                                      </span>
                                      <strong className="text-start">
                                        {stock.symbol}
                                      </strong>
                                    </div>
                                    {stock.ltp &&
                                      stock.ltp !== "" && ( // Check for ltp existence
                                        <span className="text-end me-3">
                                          ₹{stock.ltp}
                                        </span>
                                      )}
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </Card.Body>
                        </Card>
                      )}
                    </div>
                  </div>

                  {isTableVisible && (
                    <div className="m-3 table-responsive table-bordered">
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th className="text-center">Sr</th>
                            <th className="text-center">Instrument</th>
                            <th className="text-center">Lot Qty Buffer</th>
                            <th className="text-center">Trans. Type</th>
                            <th className="text-center">Order Type</th>
                            <th className="text-center">Product Type</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows.map((row, index) => (
                            <tr key={index}>
                              <td className="w-auto text-center ">
                                {index + 1}
                              </td>
                              <td className="w-25 text-start">
                                <div className="border border-muted py-0 px-2 rounded">
                                  <p className="fs-5 mb-1 display-6">
                                    {row.instrument}
                                  </p>

                                  <p className="small text-muted mb-0">
                                    <span className="me-2">
                                      &bull; {row.exch_seg || "N/A"}
                                    </span>
                                    {row.ltp !== "N/A" &&
                                    row.ltp !== null &&
                                    row.ltp !== "" ? (
                                      <span className={row.ltpColor}>
                                        {" "}
                                        &bull; ₹ {row.ltp}
                                      </span>
                                    ) : null}
                                  </p>
                                </div>
                              </td>
                              <td className="w-auto text-center">
                                <select
                                  className="form-control "
                                  name="lot_quantity_buffer"
                                  value={row.lot_quantity_buffer}
                                  onChange={(event) =>
                                    handleInputChanges(index, event)
                                  }
                                >
                                  <option value="0">None</option>
                                  <option value="1">1</option>
                                </select>
                              </td>
                              <td className="w-25 text-center">
                                <label
                                  className={`switch ${
                                    row.transactionType === "BUY"
                                      ? "switch-success"
                                      : "switch-danger"
                                  }`}
                                >
                                  <input
                                    type="checkbox"
                                    className="switch-input"
                                    checked={row.transactionType === "BUY"}
                                    onChange={() =>
                                      handleInputChanges(index, {
                                        target: {
                                          name: "transactionType",
                                          value:
                                            row.transactionType === "BUY"
                                              ? "SELL"
                                              : "BUY",
                                        },
                                      })
                                    }
                                  />
                                  <span
                                    className={`switch-toggle-slider ${
                                      row.transactionType === "BUY"
                                        ? "bg-success"
                                        : "bg-danger"
                                    }`}
                                  ></span>
                                  <span
                                    className={`switch-label ${
                                      row.transactionType === "SELL"
                                        ? "text-danger"
                                        : "text-success"
                                    }`}
                                  >
                                    {row.transactionType === "BUY"
                                      ? "BUY"
                                      : "SELL"}
                                  </span>
                                </label>
                              </td>
                              <td className="w-25 text-center">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    className="switch-input"
                                    checked={row.orderType === "MARKET"}
                                    onChange={() =>
                                      handleInputChanges(index, {
                                        target: {
                                          name: "orderType",
                                          value:
                                            row.orderType === "MARKET"
                                              ? "LIMIT"
                                              : "MARKET",
                                        },
                                      })
                                    }
                                  />
                                  <span
                                    className={`switch-toggle-slider ${
                                      row.orderType === "LIMIT" ? "bg-info" : ""
                                    }`}
                                  ></span>
                                  <span className="switch-label">
                                    {row.orderType === "MARKET"
                                      ? "MARKET"
                                      : "LIMIT"}
                                  </span>
                                </label>
                                {row.orderType !== "MARKET" && (
                                  <input
                                    type="number"
                                    className="form-control form-control-sm w-75 text-center"
                                    name="price"
                                    placeholder="Enter Price"
                                    value={row.price}
                                    onChange={(event) =>
                                      handleInputChanges(index, event)
                                    }
                                    disabled={row.orderType === "MARKET"}
                                  />
                                )}
                              </td>
                              <td className="w-25 text-center">
                                <select
                                  className="form-control "
                                  name="productType"
                                  value={row.productType}
                                  onChange={(event) =>
                                    handleInputChanges(index, event)
                                  }
                                >
                                  <option value="CARRYFORWARD">
                                    CARRYFORWARD
                                  </option>
                                  <option value="DELIVERY">DELIVERY</option>
                                  <option value="MARGIN">MARGIN</option>
                                  <option value="INTRADAY">INTRADAY</option>
                                  <option value="BO">BO</option>
                                </select>
                              </td>
                              <td className="w-25 text-center">
                                <div
                                  className="badge badge-center badge-lg rounded-pill bg-label-danger custom-target-icon"
                                  onClick={() => handleOpenBasketInstrumentDelete(row.token)}
                                  data-pr-tooltip="Delete Instrument"
                                  data-pr-position="top"
                                  role="button"
                                >
                                  <i className="ri-close-line ri-sm"></i>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <div className="mt-6 text-end mb-5">
                        <button
                          className="btn btn-outline-secondary rounded-pill btn-sm text-end me-3"
                          onClick={handleCloseButtonClick}
                        >
                          <i className="ri-close-large-line me-2"></i>
                          <span>Close</span>
                        </button>
                      </div>
                    </div>
                  )}
                  <Toast
                    ref={toast}
                    position="top-right"
                    className="text-start"
                  />
                </div>
              </div>
              {baskets && baskets.length > 0 && (
                <div className="col-xl-3 col-lg-3 col-md-5 col-12 ">
                  <div className="card mt-5 mt-md-0">
                    <div className="d-flex justify-content-start m-3">
                      <div className="flex-grow-1 input-group input-group-sm input-group-merge rounded-pill">
                        <span
                          className="input-group-text"
                          id="basic-addon-search31"
                        >
                          <i className="ri-search-line lh-1 ri-20px"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control chat-search-input ps-8"
                          placeholder="Search instruments..."
                          value={globalFilter}
                          onChange={(e) => setGlobalFilter(e.target.value)}
                          aria-label="Search..."
                          aria-describedby="basic-addon-search31"
                        />
                      </div>

                      {loading ? (
                        <div className="d-flex align-items-center">
                          <i
                            className={`ri-refresh-line ri-lg ms-3 p-text-secondary ${
                              isRefreshing ? "rotate" : ""
                            }`}
                            role="status"
                          ></i>
                        </div>
                      ) : (
                        <div className="mt-3">
                          <Tooltip target=".custom-target-icon" />
                          <i
                            className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                            role="button"
                            data-pr-tooltip="Reload"
                            onClick={handleRefresh}
                            data-pr-position="top"
                          ></i>
                        </div>
                      )}
                    </div>
                    <hr className="m-0 p-0"></hr>
                    <VirtualScroller
                      items={filterBaskets()}
                      itemSize={70}
                      itemTemplate={(basket, options) => (
                        <div key={basket.basket_id} className=" pt-0">
                          <Tooltip
                            target={`.custom-tooltip-icon-${basket.basket_id}`}
                          >
                            <div>
                              {Array.isArray(basket.instrument_details) &&
                              basket.instrument_details.length > 0 ? (
                                basket.instrument_details.map(
                                  (instrument, index) => (
                                    <div key={index}>
                                      <ul>
                                        <li>
                                          {" "}
                                          {instrument.instrument} -{" "}
                                          {instrument.transaction_type}
                                        </li>
                                      </ul>
                                    </div>
                                  )
                                )
                              ) : (
                                <div>No instruments available</div>
                              )}
                            </div>
                          </Tooltip>

                          <table className="table table-sm">
                            <tbody>
                            {loading ? (
      // Render skeleton rows while loading
      [...Array(2)].map((_, index) => (
        <tr key={index}>
          <td colSpan="3">
            <Skeleton height={20} />
          </td>
        </tr>
      ))
    ) : (
      <>
                              <tr>
                                <td
                                  className="fw-bold"
                                  colSpan="2"
                                  onClick={() =>
                                    handleEditBasket(options.index)
                                  }
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  style={{ border: "none", cursor: "pointer" }}
                                >
                                  {titleCase(
                                    basket.name ||
                                      getFormattedBasketName(options.index)
                                  )}
                                </td>
                                <td
                                  className="text-center"
                                  style={{ border: "none" }}
                                >
                                  <div
                                    className="badge badge-center bg-lg rounded-pill bg-label-danger custom-target-icon"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleShowDeleteModal(options.index);
                                    }}
                                    data-pr-tooltip="Delete Instrument"
                                    data-pr-position="top"
                                    role="button"
                                  >
                                    <i className="ri-delete-bin-line ri-xs"></i>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                onClick={() => handleEditBasket(options.index)}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                <td>
                                  <i
                                    className={`ri ri-information-line me-2 custom-tooltip-icon-${basket.basket_id}`}
                                  ></i>
                                  <small>
                                    Total: {basket.total_instruments_count}/30
                                  </small>
                                </td>
                                <td>
                                  <small>
                                    <span
                                      className={
                                        basket.buy_instruments_count > 0
                                          ? "text-success"
                                          : ""
                                      }
                                    >
                                      <span className="text-secondary">
                                        Buy:
                                      </span>{" "}
                                      {basket.buy_instruments_count}
                                    </span>
                                  </small>
                                </td>
                                <td>
                                  <small>
                                    <span
                                      className={
                                        basket.sell_instruments_count > 0
                                          ? "text-danger"
                                          : ""
                                      }
                                    >
                                      <span className="text-secondary">
                                        Sell:
                                      </span>{" "}
                                      {basket.sell_instruments_count || 0}
                                    </span>
                                  </small>
                                </td>
                              </tr>
                              </>
    )}
                            </tbody>
                          </table>
                        </div>
                      )}
                      delay={250}
                      className="border-1 surface-border border-round"
                      style={{ height: "500px" }}
                    />
                  </div>
                </div>
              )}
              <div
                className="modal fade"
                id="exampleModal"
                // data-bs-backdrop="static"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                onClick={(e) => {
                  if (e.target.id === "exampleModal") {
                    handleCloseModal();
                  }
                }}
              >
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-header d-flex align-items-center">
                      <h5
                        className="modal-title text-center flex-grow-1"
                        id="exLargeModalLabel"
                      >
                        {titleCase(currentBasket?.name)}
                      </h5>
                      <button
                        type="button"
                        className="btn-close ms-3"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCloseModal}
                      ></button>
                    </div>
                    <div
                      className="col-11 col-lg-4 col-md-4 mt-3 ms-3"
                      style={{ position: "relative" }}
                    >
                      <input
                        type="text"
                        placeholder="Search instruments..."
                        value={modalSearch.toUpperCase()}
                        onChange={handleModalSearch}
                        className="bg-white form-control ps-8"
                        maxLength={15}
                      />
                      <i className="ri ri-search-line position-absolute top-50 start-0 translate-middle-y ms-3"></i>

                      {showDropdownModal &&
                        modalSearch &&
                        filteredModalStocks.length > 0 && (
                          <Card
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: 0,
                              width: "100%",
                              zIndex: 1050,
                            }}
                          >
                            <Nav
                              justify
                              variant="tabs"
                              className="bg-body mb-0"
                              style={{ height: "45px", overflowX: "hidden" }}
                            >
                              {equityCount > 0 && (
                                <Nav.Item>
                                  <Nav.Link
                                    className={` custom-nav-link  mt-1 ${
                                      selectedExchange === "Equity"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleExchangeSelect("Equity")
                                    }
                                  >
                                    <i className="ri-cash-line ri-lg me-1"></i>{" "}
                                    Equity
                                    <small
                                      className={`ms-1 ${
                                        selectedExchange === "Equity"
                                          ? "active"
                                          : "text-muted "
                                      }`}
                                    >
                                      ({equityCount})
                                    </small>
                                  </Nav.Link>
                                </Nav.Item>
                              )}
                              {fnoCount > 0 && (
                                <Nav.Item>
                                  <Nav.Link
                                    className={`mt-1 custom-nav-link ${
                                      selectedExchange === "FnO" ? "active" : ""
                                    }`}
                                    onClick={() => handleExchangeSelect("FnO")}
                                  >
                                    <i className="ri-bar-chart-2-line ri-lg me-1"></i>{" "}
                                    FnO
                                    <small
                                      className={`ms-1 ${
                                        selectedExchange === "FnO"
                                          ? "active"
                                          : "text-muted "
                                      }`}
                                    >
                                      ({equityCount})
                                    </small>
                                  </Nav.Link>
                                </Nav.Item>
                              )}
                              {commodityCount > 0 && (
                                <Nav.Item>
                                  <Nav.Link
                                    className={`mt-1 custom-nav-link ${
                                      selectedExchange === "Commodity"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleExchangeSelect("Commodity")
                                    }
                                  >
                                    <i className="ri-vip-diamond-line ri-lg me-1"></i>{" "}
                                    Commodity
                                    <small
                                      className={`ms-1 ${
                                        selectedExchange === "Commodity"
                                          ? "active"
                                          : "text-muted "
                                      }`}
                                    >
                                      ({equityCount})
                                    </small>
                                  </Nav.Link>
                                </Nav.Item>
                              )}
                            </Nav>

                            <Card.Body
                              className="bg-white p-0"
                              style={{
                                maxHeight: "250px",
                                overflowY: "auto",
                                overflowX: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <Row>
                                {filteredModalStocks.map((stock, index) => (
                                  <Col
                                    key={index}
                                    xs={12}
                                    className={`d-flex align-items-center mb-1 ${
                                      selectedStock === stock
                                        ? "bg-body text-black"
                                        : "hover-bg-secondary"
                                    } custom-col`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleModalStockSelect(stock)
                                    }
                                  >
                                    <div className="d-flex w-100 justify-content-between align-items-center ms-3">
                                      <div className="d-flex align-items-center">
                                        <span className="badge rounded-pill bg-label-secondary me-2">
                                          {stock.exch_seg}
                                        </span>
                                        <strong className="text-start">
                                          {stock.symbol}
                                        </strong>
                                      </div>
                                      {stock.ltp !== null &&
                                        stock.ltp !== "" && (
                                          <span className="text-end me-3">
                                            ₹{stock.ltp}
                                          </span>
                                        )}
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            </Card.Body>
                          </Card>
                        )}
                    </div>

                    <div className="modal-body table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center">Sr</th>
                            <th className="text-center">Instrument</th>
                            <th className="text-center">Lot Qty Buffer</th>

                            <th className="text-center">Trans. Type</th>

                            <th className="text-center">Order Type</th>
                            <th className="text-center">Product Type</th>

                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentBasket?.instruments.map((row, index) => (
                            <tr key={index}>
                              <td className="w-auto text-center">
                                {index + 1}
                              </td>
                              <td className="w-25 text-start">
                                <div className="border border-muted py-0 px-2 rounded">
                                  <p className="fs-5 mb-1 display-6">
                                    {row.instrument}
                                  </p>
                                  <p className="small text-muted mb-0">
                                    <span className="me-2">
                                      &bull; {row.exch_seg || row.exchange}
                                    </span>
                                    {row.ltp !== undefined &&
                                      row.ltp !== null && (
                                        <span className={row.ltpClass}>
                                          &bull; ₹
                                          {parseFloat(row.ltp).toFixed(2)}
                                        </span>
                                      )}
                                  </p>
                                </div>
                              </td>

                              <td className="w-auto text-center">
                                <select
                                  className="form-control "
                                  name="lot_quantity_buffer"
                                  value={row.lot_quantity_buffer}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                >
                                  <option value="0">None</option>
                                  <option value="1">1</option>
                                </select>
                              </td>
                              <td className="w-auto text-center">
                                <label
                                  className={`switch ${
                                    row.transaction_type === "BUY"
                                      ? "switch-success"
                                      : "switch-danger"
                                  }`}
                                >
                                  <input
                                    type="checkbox"
                                    className="switch-input"
                                    checked={row.transaction_type === "BUY"}
                                    onChange={() =>
                                      handleInputChange(index, {
                                        target: {
                                          name: "transaction_type",
                                          value:
                                            row.transaction_type === "BUY"
                                              ? "SELL"
                                              : "BUY",
                                        },
                                      })
                                    }
                                  />

                                  <span
                                    className={`switch-toggle-slider ${
                                      row.transaction_type === "BUY"
                                        ? "bg-success"
                                        : "bg-danger"
                                    }`}
                                  ></span>
                                  <span
                                    className={`switch-label ${
                                      row.transaction_type === "SELL"
                                        ? "text-danger"
                                        : "text-success"
                                    }`}
                                  >
                                    {row.transaction_type === "BUY"
                                      ? "BUY"
                                      : "SELL"}
                                  </span>
                                </label>

                                {validationErrors.rows[index]
                                  ?.transaction_type && (
                                  <span className="text-danger">
                                    {
                                      validationErrors.rows[index]
                                        .transaction_type
                                    }
                                  </span>
                                )}
                              </td>

                              <td className="w-auto text-center">
                                <label
                                  className={`switch ${
                                    row.order_type === "LIMIT"
                                      ? "switch-info"
                                      : ""
                                  }`}
                                >
                                  <input
                                    type="checkbox"
                                    className="switch-input"
                                    checked={row.order_type === "MARKET"}
                                    onChange={() =>
                                      handleInputChange(index, {
                                        target: {
                                          name: "order_type",
                                          value:
                                            row.order_type === "MARKET"
                                              ? "LIMIT"
                                              : "MARKET",
                                        },
                                      })
                                    }
                                  />
                                  <span
                                    className={`switch-toggle-slider ${
                                      row.order_type === "LIMIT"
                                        ? "bg-info"
                                        : ""
                                    }`}
                                  ></span>
                                  <span
                                    className={`switch-label ${
                                      row.order_type === "LIMIT" ? "" : ""
                                    }`}
                                  >
                                    {row.order_type === "MARKET"
                                      ? "MARKET"
                                      : "LIMIT"}
                                  </span>
                                </label>
                                {row.order_type !== "MARKET" && (
                                  <div className="d-flex justify-content-center align-items-center mt-2">
                                    <input
                                      type="number"
                                      className="form-control mt-2  form-control-sm w-75"
                                      name="price"
                                      placeholder=" Enter Price"
                                      value={row.price}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      disabled={row.order_type === "MARKET"}
                                    />
                                  </div>
                                )}
                              </td>

                              <td className="w-auto text-center">
                                <select
                                  type="text"
                                  className="form-control "
                                  name="product_type"
                                  value={row.product_type}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                >
                                  {" "}
                                  <option value="CARRYFORWARD">
                                    CARRYFORWARD
                                  </option>
                                  <option value="DELIVERY">DELIVERY</option>
                                  <option value="MARGIN">MARGIN</option>
                                  <option value="INTRADAY">INTRADAY</option>
                                  <option value="BO">BO</option>
                                </select>
                              </td>

                              <td
                                className="w-auto text-center
                              "
                              >
                                <div
                                  className="badge badge-center badge-lg rounded-pill bg-label-danger custom-target-icon"
                                  onClick={() => handleDeleteRowModal(index)}
                                  data-pr-tooltip="Delete Instrument"
                                  data-pr-position="top"
                                  role="button"
                                >
                                  <i className="ri-close-line ri-sm"></i>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="modal-footer">
                      <div className="d-flex align-items-center">
                        <div className="switch-label me-3">
                          Execute Teacher only?
                        </div>
                        <label className="switch me-5">
                          <input
                            type="checkbox"
                            className="switch-input"
                            checked={onlyTeacherExecute}
                            onChange={handleToggleChange}
                          />
                          <span className="switch-toggle-slider">
                            <span className="switch-on"></span>
                            <span className="switch-off"></span>
                          </span>
                          <div className="switch-label"></div>
                        </label>
                        {isLoading && (
                          <div className="d-flex align-items-center ms-6">
                            <i className="ri ri-checkbox-circle-fill text-success me-2 ri-lg"></i>
                            <span className="me-3">
                              {onlyTeacherExecute
                                ? `Executing ${currentBasket.instruments.length} instruments on teacher only`
                                : `Executing ${currentBasket.instruments.length} instruments on teacher & students`}
                            </span>
                          </div>
                        )}
                        <button
                          type="button"
                          className="btn btn-info d-flex align-items-center"
                          onClick={handleExecuteAll}
                          // disabled={isLoading}
                          disabled={isLoading || !brokerConnected}
                        >
                          {isLoading ? (
                            <>
                              <div
                                className="spinner-border me-2"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                              Executing orders
                            </>
                          ) : (
                            <>
                              <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                              Execute All
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                    {!brokerConnected && (
                      <div className="text-danger text-end mb-3 me-6">
                        Broker Not Connected
                      </div>
                    )}
                    {errorMessage && (
                      <div className="text-danger text-end mb-3 me-6">
                        {errorMessage}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <CardLinks></CardLinks>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between w-100 ms-0 me-0">
            <button
              onClick={handleCloseDeleteModal}
              className="btn btn-outline-secondary rounded-pill btn-sm"
            >
              <i className="ri-close-large-line me-2"></i>
              <span>Cancel</span>
            </button>
            <button
              onClick={handleDeleteBasket}
              className="btn btn-danger rounded-pill btn-sm"
            >
              <i className="ri-delete-bin-line me-2"></i>
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={showBasketInstrumentDelete} onHide={handleCloseBasketInstrumentDelete} centered>
  <Modal.Header closeButton>
    <Modal.Title>Are you sure?</Modal.Title>
  </Modal.Header>
  <Modal.Body>Are you sure you want to delete this instrument?</Modal.Body>
  <Modal.Footer>
    <div className="d-flex justify-content-between w-100 ms-0 me-0">
      <button
        onClick={handleCloseBasketInstrumentDelete}
        className="btn btn-outline-secondary rounded-pill btn-sm"
      >
        <i className="ri-close-large-line me-2"></i>
        <span>Cancel</span>
      </button>
      <button
        onClick={handleDeleteBasketInstrument}
        className="btn btn-danger rounded-pill btn-sm"
      >
        <i className="ri-delete-bin-line me-2"></i>
        Delete
      </button>
    </div>
  </Modal.Footer>
</Modal>

    </div>
  );
};

export default Basket;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red";
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};

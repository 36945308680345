import React, { useEffect, useState,useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer"
import AdminSubHeader from "./AdminSubHeader";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import config from "../../../component/config";
import { Toast } from "primereact/toast";
import HeatmapCalendar from "./HeatmapCalender";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
const AdminMyReport = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
 // Fetch the user ID from localStorage
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [isRefreshing,setIsRefreshing] =useState(true);

  const [error, setError] = useState(null);
  const [backClicked, setBackClicked] = useState(false);
  const toast = useRef(null);
  const [summary, setSummary] = useState({
    total_trades_count: 0,
    total_profitable_trades: 0,
    total_losing_trades: 0,
    total_commission: 0.0,
  });
  const [month, setMonth] = useState(""); // State for month

  const fetchData = async () => {
    setLoading(true);
    setError(null);
  
    try {
      const userId = localStorage.getItem("userId"); // Fetch the user ID from local storage
      const authToken = localStorage.getItem("authToken"); // Fetch the auth token from local storage
  
      if (!userId || !authToken) {
        throw new Error("User ID or auth token not found");
      }
  
      const response = await axios.post(
        `${config.apiDomain}/api/common/my_report`,
        {
          user_id: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      if (response.data && response.data.st === 1) {
        setData(response.data.completed_trades_per_month);
        setSummary(response.data.completed_trades);
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      setError(new Error(error.message || "Failed to fetch data"));
    } finally {
      setLoading(false);
    }
  };
  
  
  const handleRefresh = async () => {
    setLoading(true);
    setError(null);
    setIsRefreshing(true);
    
    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
      if (!authToken || !userId) {
        navigate("/login");
        return;
      }
  
      const response = await axios.post(
        `${config.apiDomain}/api/common/my_report`,
        { user_id: userId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      const { st, msg, completed_trades_per_month, completed_trades } = response.data;
  
      // Handle different `st` values to display custom toasts
      switch (st) {
        case 1:
          setData(completed_trades_per_month);
          setSummary(completed_trades);
          window.showToast("success", msg || "Data loaded successfully");
          break;
        case 2:
          setError(new Error(msg || "Warning"));
          window.showToast("warning", msg || "Warning: Please check the details.");
          break;
        case 3:
        case 4:
          setError(new Error(msg || "Danger: Server Error"));
          window.showToast("error", msg || "An error occurred. Please try again.");
          break;
        default:
          setError(new Error("Failed to fetch data"));
          window.showToast("error", "Something went wrong. Contact Support.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
  
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access, redirecting to common login screen");
        navigate("/login"); // Redirect on 401 Unauthorized
      } else {
        const errorMsg = error.response?.data?.msg || error.message || "Something went wrong. Contact Support.";
        setError(new Error(errorMsg));
        window.showToast("error", errorMsg);
      }
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };
  
  
  useEffect(() => {
    fetchData(); // This should ideally be called once when the component mounts
  }, []); // Dependency array to ensure it runs only once
  
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const CommissionBodyTemplate = (rowData) => {
    return ` ${rowData.commission} Rs.`;
  };
  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };


  const handleViewReport = (rowData) => {
    navigate(`/admin/report_datewise`, { state: { userId, month: rowData.month_name } });
  };
  return (
    <>
    <Toast ref={toast} />
      <AdminHeader />
      <AdminSubHeader />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
        <ol className="breadcrumb breadcrumb-style1 text-black mb-3">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
              <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
           
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              My Report
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>



        {/* <HeatmapCalendar />  */}

        <div className="card p-5">
        <div className="row align-items-center">
            <div className="col-3 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 text-center mb-5">
              <h5 className="mb-0">My Report</h5>
            </div>
            <div className="col-12 col-md-3 col-lg-3 text-end mb-5">
              <Link to="/admin/teacher_list">
                <button className="btn rounded-pill btn-outline-secondary btn-xs">
                  <span className="text-black">
                    <i className="ri-group-line me-1 ri-lg"></i>{" "}
                    <span>Teacher Report</span>
                  </span>
                </button>
              </Link>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-3 col-6">
              <h4 className="mb-0">{summary.total_trades_count||0}</h4>
              <p className="mt-0">Total Trades</p>
            </div>
            <div className="col-md-3 col-6">
              <h4 className="mb-0">{summary.total_profitable_trades||0}</h4>
              <p className="mt-0">Profitable Trades</p>
            </div>
            <div className="col-md-3 col-6">
              <h4 className="mb-0">{summary.total_losing_trades||0}</h4>
              <p className="mt-0">Losing Trades</p>
            </div>
            <div className="col-md-3 col-6">
              <h4 className="mb-0">{summary.total_commission||0} Rs.</h4>
              <p className="mt-0">Commission</p>
            </div>
          </div>

          <div className="d-flex justify-content-start mb-3">
          
            <IconField iconPosition="left">
              <InputIcon className="ri ri-search-line"> </InputIcon>
              <InputText
                type="search"
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="rounded"
              />
            </IconField>
            {loading ? (
              <div className="d-flex align-items-center">
                <i
                  className={`ri-refresh-line ri-lg ms-3 p-text-secondary ${
                    isRefreshing ? "rotate" : ""
                  }`}
                  role="status"
                ></i>
              </div>
            ) : (
              <div className="mt-3">
                <Tooltip target=".custom-target-icon" />
                <i
                  className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                  role="button"
                  data-pr-tooltip="Reload"
                  onClick={handleRefresh}
                  data-pr-position="top"
                ></i>
              </div>
            )}
          </div>
          <DataTable
           
            align="center"
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
             className="custom-column-border"
          >
            <Column
              align="center"
              className="custom-column-border"
                            field="month_name"
              header="Month"
             
            ></Column>
<Column
  align="center"
  className="custom-column-border"
  field="trades_count"
  header="Total Trades"
></Column>
            <Column
              align="center"
                className="custom-column-border"
              field="profitable_trades"
              header="Profitable Trades"
            ></Column>
            <Column
              align="center"
               className="custom-column-border"
              field="losing_trades"
              header="Losing Trades"
            ></Column>
            <Column
              align="center"
             className="custom-column-border"
              field="commission2"
              header="Commission"
              body={CommissionBodyTemplate}
            ></Column>
            <Column
              align="center"
               className="custom-column-border"
              header="Actions"
              body={(rowData) => (
                <Button
                  onClick={() => handleViewReport(rowData)}
                  className="btn btn-info custom-btn-action1 custom-target-icon"
                   data-pr-tooltip="View Record"
                    data-pr-position="top"
                >
                  <i className="ri-gallery-view-2"></i>
                </Button>
              )}
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>

      <Footer />
    </>
  );
};

export default AdminMyReport;





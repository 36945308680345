import React from "react";
import { Link } from "react-router-dom";
import autoprofito_logo from "../../../assets/img/products/company/autoprofito_logo.png";
import electricService from "../../../assets/img/products/electric-services-38.png"
import footerimg from "../../../assets/img/products/footerimg.png"
import { useEffect, useState } from "react";
import badgeimg from "../../../assets/img/products/europe-leader.webp"
import axios from "axios";
import { Button, Container, Row, Col, Badge } from 'react-bootstrap';
import FaqSection from "./FaqSection";
import MetaTags from "./MetaTags";


const LandingFooter = () => {

  const cardStyle = {
    background: `
      url("https://img.freepik.com/premium-photo/colorful-graph-with-word-graph-it_1065421-53694.jpg"),
      linear-gradient(90deg, hsla(191, 75%, 60%, 1) 0%, hsla(248, 87%, 36%, 1) 100%)
    `,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
   
  };
  


  
  return (
    <>
      <MetaTags />

      <div data-bs-spy="scroll" className="scrollspy-example ">
      <div className="container-xxl">
       <div className="card rounded my-6 text-break bg-success " style={cardStyle}>
          <div className="card-body p-6">
            <div className="row">
              <div className="col-md-8 text-start">
                <h3 className="card-title text-white fw-bold">
                  Looking to streamline banking <br />
                  operations with automation <br />
                  technology?
                </h3>
              </div>
              <div className="col-md-4 text-end align-self-center">
                <Link
                 to="login"
                className="btn btn-primary btn-xl bg-white text-black border-0 "
                >
                 
                 Login
                </Link>
              </div>
            </div>
          </div>
        </div>
        </div>

        <FaqSection />

        <div className="container-xxl ">

<div className="border p-4 pb-0 rounded bg-white my-6 d-flex align-items-center justify-content-between flex-wrap">
  
  {/* Left Column - Logo (col-2) */}
  <div className="col-12 col-md-1 text-center text-md-start mb-3 mb-md-0">
    <img
      src={autoprofito_logo}
      alt="Logo"
      className="img-fluid"
      style={{ maxHeight: '50px' }} 
    />
  </div>

  {/* Centered Text (col-5) */}
  <div className="col-12 col-md-6 text-center mb-md-0">
    <span className="fw-bold text-black h4">
      Vidyard is rated 4.5 based on 700+ reviews 
    </span>
  </div>

  {/* Right Side - Award Badges (col-5) */}
  <div className="col-12 col-md-5 d-flex flex-wrap justify-content-center">
    <div className="col-6 col-md-3 ">
      <img
        src={badgeimg}
        alt="Award Badge 1"
        className="img-fluid mx-1 border rounded-circle p-2 h-75"
      />
    </div>
    <div className="col-6 col-md-3 ">
      <img
        src={badgeimg}
        alt="Award Badge 2"
        className="img-fluid mx-1 border rounded-circle p-3 h-75"
      />
    </div>
    <div className="col-6 col-md-3 ">
      <img
        src={badgeimg}
        alt="Award Badge 3"
        className="img-fluid mx-1 border rounded-circle p-3 h-75"
      />
    </div>
    <div className="col-6 col-md-3">
      <img
        src={badgeimg}
        alt="Award Badge 4"
        className="img-fluid mx-1 border rounded-circle p-3 h-75"
      />
    </div>
  </div>

</div>
</div>



 

        <div className="container-xxl ">
     
      <div className="row text-center ">
       
        <div className="col-md-3 col-12 mb-4 mb-md-0 ">
          <div className="border p-4 bg-white rounded">
          
                <i className="ri ri-cloud-line text-primary fs-1 "></i>
            <h2 className="fw-bold mb-0 mt-5">25%</h2>
            <p className="mb-0 text-black">increase in close rates</p>
          </div>
        </div>

      
        <div className="col-md-3 col-12 mb-4 mb-md-0 ">
          <div className="border p-4 bg-white rounded">
          <i className="ri ri-cloud-line text-primary fs-1 "></i>
            <h2 className="fw-bold mb-0 mt-5">5x</h2>
            <p className="mb-0 text-black">response rates with video</p>
          </div>
        </div>

        <div className="col-md-3 col-12 mb-4 mb-md-0 ">
          <div className="border p-4 bg-white rounded">
          <i className="ri ri-cloud-line text-primary fs-1 "></i>
            <h2 className="fw-bold mb-0 mt-5">50%</h2>
            <p className="mb-0 text-black">shorter deal cycles</p>
          </div>
        </div>

        {/* Fourth Column */}
        <div className="col-md-3 col-12 ">
          <div className="border p-4 bg-white rounded">
          <i className="ri ri-cloud-line text-primary fs-1 "></i>
            <h2 className="fw-bold mb-0 mt-5">4x</h2>
            <p className="mb-0 text-black">lift to monthly SQOs</p>
          </div>
        </div>
      </div>
    </div>

        <div className="container-xxl ">
      <div className="card gradient_bg1  text-light rounded my-6 ">
        <div className="card-body ">
          <div className="row align-items-center mx-0 my-0  ">
          
            <div className="col-lg-8 col-md-12 mb-4 mb-lg-0 p-6">
             
              <Badge pill bg="dark" className="mb-3 py-2 px-3" style={{ backgroundColor: '#2C3E50' }}>
            <i className="ri-bard-line" style={{ marginRight: '5px' }}></i>
            Explore Algo Trading
          </Badge>
              <h2 className="fw-bold text-white">Generate Videos in Minutes with AI Avatars</h2>
              <p className="mb-4 text-white fw-bold">
                Generate personalized sales videos for every prospect with an AI Avatar that looks and sounds just like you.
              </p>
            
            </div>

           
            <div className="col-lg-4 col-md-12 d-flex flex-column align-items-center">
  <div>
    <Link to="/signup">
      <button className="btn btn-primary me-3 btn-xl">
        Signup
      </button>
    </Link>
  </div>
  
  <div className="mt-3 text-start me-3">
    <Link to="/login">
      <span className="text-white">
        Login
      </span>
    </Link>
  </div>
</div>

          </div>
        </div>
      </div>
    </div>
        <div className="landing-footer">
          <div className="footer-top  bg-black position-relative gradient-bg overflow-hidden mb-0">
            <div className="container-xxl position-relative">
              <div className="row gx-0 gy-0 mb-0 gx-sm-12 gx-lg-12">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="app-brand-link mb-6">
                    <div className="">
                      <img src={autoprofito_logo} alt="Favicon" width="44px" />
                    </div>
                    <span className="app-brand-text demo footer-link fw-semibold ms-1 px-3">
                      AutoProfito
                    </span>
                  </div>
                  <div className="footer-text px-0 footer-logo-description">
                    <p>
                      {" "}
                      AutoProfito (Product of Shekru Labs India Pvt. Ltd.) is a
                      technology provider and not a wealth manager, investment
                      advisor, or portfolio management service. All transactions
                      through AutoProfito are the user's responsibility, and we
                      cannot be held liable for any user actions.
                    </p>
                    <p>
                      We strive for transparency, but the responsibility for any
                      selection or transaction rests with the user.
                    </p>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-6 col-6 mt-0 mt-5 mt-sm-0">
                  <h6 className="footer-title mb-4 mb-lg-6 fs-4">Pages</h6>
                  <ul className="list-unstyled mb-0">
                    <li className="mb-4">
                      <Link to="/" className="footer-link">
                        Home
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link to="/about" className="footer-link">
                        About
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link to="/contact" className="footer-link">
                        Contact
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link to="/help_center" className="footer-link">
                      Help Center
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link to="/pricing" className="footer-link">
                        Pricing
                      </Link>
                      
                    </li>
                    <li className="mb-4">
                      <Link to="/algo_trading" className="footer-link">
                        Algo Trading
                      </Link>
                      
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-6 col-6 mt-0 mt-5 mt-sm-0 ">
                  <h6 className="footer-title mb-4 mb-lg-6 fs-4 ">Legal</h6>
                  <ul className="list-unstyled mb-0">
                    <li className="mb-4">
                      <Link to="/privacy_policy" className="footer-link">
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link to="/cookies_policy" className="footer-link">
                        Cookies Policy
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link to="/cancellation_policy" className="footer-link">
                        Cancellation Policy
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link
                        to="/refund_policy"
                        className="footer-link refundpolicy"
                      >
                        Refund Policy
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link to="/terms_condition" className="footer-link">
                        Terms and Condition
                      </Link>
                    </li>
                    <li>
                      <Link to="/sebi-circular" className="footer-link">
                      SEBI Circular
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12  mt-0 mt-5 mt-sm-0">
                  <h6 className="footer-title mb-4 mb-lg-6 fs-4">Contact</h6>

                  <p className="footer-text px-0 footer-logo-description">
                    <i className="ri-map-pin-line ri-lg text-white"></i> Shekru
                    Labs India Pvt. Ltd., 2nd Floor, Manogat Appt Muktangan
                    English School & Jr College, Sahakar Nagar, Pune, 411009
                  </p>

                  <div className="">
                    <p className="footer-text px-0 footer-logo-description">
                      <i className="ri-mail-line ri-lg text-white"></i>{" "}
                      shekrulabs@gmail.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center col-12  mt-0 mt-5 mt-sm-0">
              <a
                href="https://www.facebook.com/share/ra9cKRDkDpy2W94j/?mibextid=qi2Omg"
                className=" me-4"
                target="_blank"
              >
                <i
                  className="ri-facebook-circle-fill  ri-2x"
                  style={{ color: "#1877F2" }}
                ></i>
              </a>
              <a
                href="https://www.instagram.com/autoprofito/?next=%2F"
                className=" me-4"
                target="_blank"
              >
                <i
                  className="ri-instagram-line  ri-2x"
                  style={{ color: "#de45a8" }}
                ></i>
              </a>
              <a
                href="https://www.youtube.com/channel/UCgfTIIUL16SyHAQzQNmM52A"
                className=" me-4"
                target="_blank"
              >
                <i
                  className="ri-youtube-line  ri-2x"
                  style={{ color: "#FF0000" }}
                ></i>
              </a>
              <a
                href="https://www.linkedin.com/company/104616702/admin/dashboard/"
                className=" me-4"
                target="_blank"
              >
                <i
                  className="ri-linkedin-fill ri-2x"
                  style={{ color: "#0077B5" }}
                ></i>
              </a>
             
              <a
                href="https://www.threads.net/@autoprofito"
                className=" me-4"
                target="_blank"
              >
                <i
                  className="ri-twitter-x-line  ri-2x"
                  style={{ color: "grey" }}
                >
                  {" "}
                </i>
              </a>
              <a
                href="https://t.me/Autoprofito"
                className=" me-4"
                target="_blank"
              >
                <i
                  className="ri-telegram-line  ri-2x"
                  style={{ color: "#0088CC" }}
                >
                  {" "}
                </i>
              </a>
              
            </div>
          </div>

          <div className="footer-bottom bg-body">
            <div className="container-xxl d-flex flex-wrap justify-content-center flex-md-row flex-column text-center text-md-center">
              <div className="text-center mt-2">
                <p className="text-black mb-1 fs-8">
                  Shekru Labs India Pvt. Ltd., 2nd Floor, Manogat Appt.,
                  Muktangan English School & Jr College, Sahakar Nagar, Pune,
                  411009
                </p>
                <p className=" text-black mb-1 fs-8 d-inline-block">
                  Email: shekrulabs@gmail.com
                </p>
                <p className="text-black fs-8 d-inline-block mx-3">|</p>
                <p className="text-black fs-8 d-inline-block">
                  CIN: U74999PN2018PTC174371
                </p>
              </div>
            </div>
          </div>

          <div className="footer-bottom py-5">
            <div className="container-xxl d-flex flex-wrap justify-content-between flex-md-row flex-column text-center ">
              <div className="mb-2 mb-md-0">
                <span className="footer-text">
                  <p> &copy; Copyright All Rights Reserved.</p>
                </span>
              </div>
              <div className="mb-2 mb-md-0">
                <a
                  href="https://www.facebook.com/share/ra9cKRDkDpy2W94j/?mibextid=qi2Omg"
                  className="footer-link me-4"
                  target="_blank"
                >
                  <i className="ri-facebook-circle-fill  ri-xl"></i>
                </a>
                <a
                  href="https://www.instagram.com/autoprofito/?next=%2F"
                  className="footer-link me-4"
                  target="_blank"
                >
                  <i className="ri-instagram-line  ri-xl"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCgfTIIUL16SyHAQzQNmM52A "
                  className="footer-link me-4"
                  target="_blank"
                >
                  <i className="ri-youtube-line  ri-xl"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/104616702/admin/dashboard/"
                  className="footer-link me-4"
                  target="_blank"
                >
                  <i className="ri-linkedin-fill  ri-xl "></i>
                </a>
               
                <a
                  href="https://www.threads.net/@autoprofito"
                  className="footer-link me-4"
                  target="_blank"
                >
                  <i className="ri-twitter-x-line  ri-xl"> </i>
                </a>
                <a
                  href="https://t.me/Autoprofito"
                  className="footer-link me-4"
                  target="_blank"
                >
                  <i className="ri-telegram-line  ri-xl"> </i>
                </a>
               
              </div>

              <div className="mb-2 mb-md-0 text-md-center ">
                <span>
                  <p>
                  <i class="ri-flashlight-fill ri-lg"></i>  Powered by{" "}<br></br>
                    <a href="https://www.shekruweb.com" target="_blank">
                      Shekru Labs India Pvt. Ltd.
                    </a>
                  </p>
                </span>
              </div>
            </div>
          </div>


        </div>
      </div>

  
    </>
  );
};

export default LandingFooter;

import React, { useEffect, useState ,useRef} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import config from "../../../component/config";
import Footer from "../../../component/Footer"
import SubHeaderS from "./SubHeaderS";
import StudentHeader from "./StudentHeader";
import { Toast } from "primereact/toast";
const StudentMyReportView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({});
  const toast = useRef(null);
  const [errors, setErrors] = useState({});
  const [backClicked, setBackClicked] = useState(false);
  const [isRefreshing,setIsRefreshing]=useState(true);
  const authToken = localStorage.getItem("authToken");
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const [summary, setSummary] = useState({
    total_trades_count: 0,
    total_profitable_trades: 0,
    total_losing_trades: 0,
    total_commission: 0.0,
  });

  useEffect(() => {
    const { state } = location;

    // Check if required data is present in location state
    if (!state || !state.userId || !state.month) {
      // Handle the case where location state is not properly set
      // You can navigate back or handle it based on your application flow
      navigate(-1); // Navigate back
      return;
    }

    fetchData(state.userId, state.month);
  }, [location, navigate]);

  const  fetchData = async (userId, month) => {
    setLoading(true);
    setError(null);

    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
       if (!authToken || !userId) {
      navigate("/login");
        return;
      }
      const response = await axios.post(
        `${config.apiDomain}/api/common/trade_details`,
        {
          user_id: userId,
          sell_month: month,
        }
      );

      if (response.data) {
        setData(response.data.trades);
        setSummary(response.data.completed_trades_aggregate);
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      setError(new Error(error.message || "Failed to fetch data"));
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setLoading(true);
    setError(null);
    setIsRefreshing(true);
  
    try {
      const { userId, month } = location.state;
      const response = await axios.post(
        `${config.apiDomain}/api/common/trade_details`,
        {
          user_id: userId,
          sell_date: month,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      const { data } = response;
      const errorMsg = formatMessage(data?.msg) || "An unexpected error occurred";
  
      if (data) {
        setData(data.trades);
        setSummary(data.completed_trades_aggregate);
  
        switch (data.st) {
          case 1:
            // window.showToast("success", data.msg || "Data fetched successfully");
            break;
          case 2:
            window.showToast("warning", errorMsg || "Warning: Check the trade details.");
            break;
          case 3:
          case 4:
            window.showToast("danger", errorMsg || "Error: Unable to fetch trade details.");
            break;
          default:
            setErrors({ global: "Unexpected response status" });
            window.showToast("error", "Something went wrong. Contact Support.");
        }
      } else {
        setErrors({ global: "No data found" });
        window.showToast("error", "No data found");
      }
    } catch (error) {
      const errorMsg = error.response?.data?.msg
        ? formatMessage(error.response.data.msg)
        : String(error.message || "Something went wrong. Contact Support.");
  
      window.showToast("error", errorMsg);
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };


    const handleBack = () => {
      if (!backClicked) {
        setBackClicked(true);
        navigate(-1);
      }
    };
    const formatMessage = (msg) => {
      if (typeof msg === "object") {
        return Object.values(msg).flat().join(", ");
      }
      return String(msg);
    };
    
  const CommissionBodyTemplate = (rowData) => {
    return ` ${rowData.commission} %`;
  };


  const buyPriceTemplate = (rowData) => {
    return ` ${rowData.buy_price} Rs.`;
  };

  const sellPriceBodyTemplate = (rowData) => {
    return ` ${rowData.sell_price} Rs.`;
  };

  const PLTemplate = (rowData) => {
    return ` ${rowData.pandl} Rs.`;
  };

  const PLTotalBodyTemplate = (rowData) => {
    return ` ${rowData.pandl_total} Rs.`;
  };

  const PLPercentTemplate = (rowData) => {
    return ` ${rowData.pandl_percent} %`;
  };

 

  const formatDateTime = (datetime) => {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    return new Date(datetime).toLocaleTimeString('en-US', options);
  };
  
  const formatDateDay = (datetime) => {
    return new Date(datetime).getDate();
  };
  
  const formatDateMonth = (datetime) => {
    return new Date(datetime).toLocaleString('default', { month: 'short' });
  };
  
  const formatDateYear = (datetime) => {
    return new Date(datetime).getFullYear();
  };
  return (
    <>
      <Toast ref={toast} />
  <StudentHeader />
  <SubHeaderS />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/student/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/app2/student_profile" className="text-black">
                Profile
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/app2/student_my_report" className="text-black">
                My Report
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary" aria-current="page">
              My Report Details
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col-3 text-start mb-5 ">
            <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-9 text-center mb-5">
              <h5 className="mb-0">My Report Details</h5>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-3">
              <h4>{summary.total_trades_count}</h4>
              <p>Total Trades</p>
            </div>
            <div className="col-md-3">
              <h4>{summary.total_profitable_trades}</h4>
              <p>Profitable Trades</p>
            </div>
            <div className="col-md-3">
              <h4>{summary.total_losing_trades}</h4>
              <p>Losing Trades</p>
            </div>
            <div className="col-md-3">
              <h4>{summary.total_commission} Rs.</h4>
              <p>Commission</p>
            </div>
          </div>

          <div className="d-flex justify-content-start mb-3">
           
            <IconField iconPosition="left">
              <InputIcon className="ri ri-search-line"></InputIcon>
              <InputText
                type="search"
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="rounded"
              />
            </IconField>
            {loading ? (
                 
                 
            
            <div className="d-flex align-items-center">
            <i
              className={`ri-refresh-line ri-lg ms-3 p-text-secondary ${
                isRefreshing ? "rotate" : ""
              }`}
              role="status"
            ></i>
          </div>
        ) : (
          <div className="">
            <Tooltip target=".custom-target-icon" />
            <i
              className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
              role="button"
              data-pr-tooltip="Reload"
              onClick={() => handleRefresh(location.state.userId, location.state.month)}
              data-pr-position="top"
            ></i>
          </div>
             )}
          </div>
          <DataTable
            className="custom-column-border"
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
          >
            <Column
              align="center"
              className="custom-column-border"
              field="buy_price"
              header="Buy Price"
              body={buyPriceTemplate}
              
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="buy_lotsize"
              header="Buy Lot Size"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="buy_stock_quantity"
              header="Buy Quantity"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="buy_datetime"
              header="Buy Time"
              body={(rowData) => (
                <span>
                  {formatDateTime(rowData.buy_datetime)} {formatDateDay(rowData.buy_datetime)}-{formatDateMonth(rowData.buy_datetime)}-{formatDateYear(rowData.buy_datetime)}
                </span>
              )}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="buy_orderid"
              header="Buy Order ID"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="sell_price"
              header="Sell Price"
              body={sellPriceBodyTemplate}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="sell_lotsize"
              header="Sell Lot Size"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="sell_stock_quantity"
              header="Sell Quantity"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="sell_datetime"
              header="Sell Time"
              body={(rowData) => (
                <span>
                  {formatDateTime(rowData.sell_datetime)} {formatDateDay(rowData.sell_datetime)}-{formatDateMonth(rowData.sell_datetime)}-{formatDateYear(rowData.sell_datetime)}
                </span>
              )}

            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="sell_orderid"
              header="Sell Order ID"
            ></Column>
            <Column
              align="center"
             className="custom-column-border"
              field="pandl"
              header="P&L"
              body={PLTemplate}
            ></Column>
            <Column
              align="center"
             className="custom-column-border"
              field="pandl_total"
              header="P&L Total"
              body={ PLTotalBodyTemplate}
            ></Column>
            <Column
              align="center"
             className="custom-column-border"
              field="pandl_percent"
              header="P&L Percent"
              body={PLPercentTemplate}
            ></Column>
            <Column
              align="center"
             className="custom-column-border"
              field="commission"
              header="Commission"
              body={CommissionBodyTemplate}
            ></Column>
          </DataTable>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default StudentMyReportView;

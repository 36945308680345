import React, { useState, useEffect } from "react";
import axios from "axios";
import { AutoComplete } from "primereact/autocomplete";
import LandingHeader from "./LandingHeader";


import LandingFooter from "./LandingFooter";

import { useNavigate, Link } from "react-router-dom";

import MetaTags from "./MetaTags";
const FAQs = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const navigate = useNavigate(); 

  useEffect(() => {
    // Fetch data from the API
    axios
      .get("https://ghanish.in/api/common/get_faq")
      .then((response) => {
        if (response.data.st === 1) {
          setFaqs(response.data.data);
        } else {
          setFaqs([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching FAQ data:", error);
      });
  }, []);


  const fetchSearchResults = (query) => {
    setLoading(true);
    axios
      .post("https://ghanish.in/api/common/search_faq", { keyword: query })
      .then((response) => {
        if (response.data.st === 1) {
          setSearchResults(response.data.data);
        } else {
          setSearchResults([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching search results:", error);
        setLoading(false);
      });
  };

  
  const handleSearch = (event) => {
    const query = event.query;
    setSearchTerm(query);
    if (query.length >= 3) {
      fetchSearchResults(query);
    } else {
      setSearchResults([]);
    }
  };


  const handleSelect = (e) => {
    const selectedItem = e.value;
    if (selectedItem) {
      navigate(`/landing_faq_details/${selectedItem.id}`);
    }
  };

 
  const itemTemplate = (item) => {
    return <div>{item.question}</div>;
  };

  return (
    <div>
      <MetaTags />
      <LandingHeader />
      <div className="container-xxl">
        <div className=" section-py row gy-4 gy-lg-0 mt-10">
          <div className="col-lg-8">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-2">
                <li className="breadcrumb-item">
                  <a href="javascript:void(0);">Help Centre</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="javascript:void(0);">Buying and item support</a>
                </li>
                <li className="breadcrumb-item active">Template kits</li>
              </ol>
            </nav>
            <h4 className="mb-2">How to add product in cart?</h4>
            <p>1 month ago - Updated</p>
            <hr className="my-6" />
            <p>
              If you’re after only one item, simply choose the ‘Buy Now’ option
              on the item page. This will take you directly to Checkout.
            </p>
            <p className="mb-0">
              If you want several items, use the ‘Add to Cart’ button and then
              choose ‘Keep Browsing’ to continue shopping or ‘Checkout’ to
              finalise your purchase.
            </p>
            <div className="my-6"></div>
            <p className="mb-0">
              You can go back to your cart at any time by clicking on the
              shopping cart icon at the top right side of the page.
            </p>
            <p>
              If you’re after only one item, simply choose the ‘Buy Now’ option
              on the item page. This will take you directly to Checkout.
            </p>
            <p className="mb-0">
              If you want several items, use the ‘Add to Cart’ button and then
              choose ‘Keep Browsing’ to continue shopping or ‘Checkout’ to
              finalise your purchase.
            </p>
            <div className="my-6"></div>
            <p className="mb-0">
              You can go back to your cart at any time by clicking on the
              shopping cart icon at the top right side of the page.
            </p>
            <p>
              If you’re after only one item, simply choose the ‘Buy Now’ option
              on the item page. This will take you directly to Checkout.
            </p>

            <div className="mt-6"></div>
          </div>
          <div className="col-lg-4 bg-white rounded py-5 px-5">
            <div className="bg-light-subtle py-2 px-4 rounded">
              <h5 className="mb-0">Articles in this section</h5>
            </div>

            <div className="input-group input-group-merge mb-6 mt-5">
              <span className="input-group-text" id="article-search">
                <i className="ri-search-line ri-20px" />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                aria-label="Search..."
                aria-describedby="article-search"
              />
            </div>

            <ul className="list-unstyled mt-4 mb-0">
              <li className="mb-4">
                <a
                  href="javascript:void(0)"
                  className="text-heading d-flex justify-content-between align-items-center"
                >
                  <span className="text-truncate me-1 m-2">Template Kits</span>
                  <i className="tf-icons ri-arrow-right-s-line ri-20px scaleX-n1-rtl text-muted" />
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="javascript:void(0)"
                  className="text-heading d-flex justify-content-between align-items-center"
                >
                  <span className="text-truncate me-1 m-2">
                    Envato Elements Template Kits - Importing Issues
                  </span>
                  <i className="tf-icons ri-arrow-right-s-line ri-20px scaleX-n1-rtl text-muted" />
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="javascript:void(0)"
                  className="text-heading d-flex justify-content-between align-items-center"
                >
                  <span className="text-truncate me-1 m-2">
                    Envato Elements Template Kits - Troubleshooting
                  </span>
                  <i className="tf-icons ri-arrow-right-s-line ri-20px scaleX-n1-rtl text-muted" />
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="javascript:void(0)"
                  className="text-heading d-flex justify-content-between align-items-center"
                >
                  <span className="text-truncate me-1 m-2">
                    How to use the template in WordPress
                  </span>
                  <i className="tf-icons ri-arrow-right-s-line ri-20px scaleX-n1-rtl text-muted" />
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  className="text-heading d-flex justify-content-between align-items-center"
                >
                  <span className="text-truncate me-1 m-2">
                    How to use the Template Kit Import plugin
                  </span>
                  <i className="tf-icons ri-arrow-right-s-line ri-20px scaleX-n1-rtl text-muted" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* <div className="container">
        <section className="section-py first-section-pt help-center-header position-relative overflow-hidden">
          <img
            className="banner-bg-img z-n1"
            src={img}
            alt="Help center header"
            data-app-light-img="pages/header-light.png"
            data-app-dark-img="pages/header-dark.png"
          />
          <h4 className="text-center text-primary">Hello, how can we help?</h4>
          <div className="text-center my-4">
            <AutoComplete
              value={searchTerm}
              suggestions={searchResults}
              completeMethod={handleSearch}
              field="question"
              itemTemplate={itemTemplate}
              onSelect={handleSelect}
              placeholder="Search"
              onChange={(e) => setSearchTerm(e.value)}
            />
          </div>
          <p className="text-center px-4 mb-0">
            Common troubleshooting topics: eCommerce, Blogging to payment
          </p>
        </section>

        <div className="row">
          {faqs.map((category) => (
            <div
              className="col-md-4 col-ms-6 mb-6 mt-3 mb-8"
              key={category.category_name}
            >
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="avatar avatar-sm flex-shrink-0 me-2">
                      <span className="avatar-initial bg-label-primary rounded">
                        <i className="tf-icons ri-shopping-cart-line ri-20px"></i>
                      </span>
                    </div>
                    <h5 className="mb-0 ms-1 text-capitalize">
                      {category.category_name}
                    </h5>
                  </div>
                  <ul className="list-unstyled my-6">
                    {category.faq.map((item) => (
                      <li className="mb-2" key={item.faq_id}>
                        <Link
                          to={`/landing_faq_details/${item.faq_id}`}
                          className="text-heading d-flex justify-content-between align-items-center"
                        >
                          <span className="text-truncate me-1">
                            {item.faq_question}
                          </span>
                          <i className="tf-icons ri-arrow-right-s-line ri-20px scaleX-n1-rtl text-muted"></i>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}

      <LandingFooter />
    </div>
  );
};

export default FAQs;

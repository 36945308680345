import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AdminHeader from "./AdminHeader";
import AdminSubHeader from "./AdminSubHeader";
import Footer from "../../../component/Footer";

import { Toast } from "primereact/toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import config from "../../../component/config";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
import Skeleton from "react-loading-skeleton";

// Add these editor styles
const editorStyles = `
  .quill-editor-container {
    display: flex;
    flex-direction: column;
    min-height: 300px;
  }

  .quill-editor {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .ql-container {
    flex: 1;
    min-height: 200px;
    font-family: inherit !important;
    font-size: 14px !important;
  }

  .ql-editor {
    min-height: 200px !important;
    max-height: 500px;
    overflow-y: auto !important;
  }

  .ql-toolbar {
    border: 1px solid #ced4da !important;
    border-radius: 4px 4px 0 0 !important;
    background: #fff;
  }

  .ql-container {
    border: 1px solid #ced4da !important;
    border-top: none !important;
    border-radius: 0 0 4px 4px !important;
  }

  .ql-editor p {
    margin-bottom: 1em;
  }

  /* Firefox-specific fixes */
  @-moz-document url-prefix() {
    .quill-editor-container {
      min-height: 300px;
    }
    
    .ql-container {
      height: auto !important;
    }
    
    .ql-editor {
      min-height: 200px !important;
    }
  }

  /* Error state styling */
  .is-invalid .ql-toolbar,
  .is-invalid .ql-container {
    border-color: #dc3545 !important;
  }
`;

// Update the quillModules configuration
const quillModules = {
  toolbar: {
    container: [
      [{ 'header': '1'}, { 'header': '2'}, { 'font': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      ['link', 'image'],
      ['clean']
    ]
  },
  clipboard: {
    matchVisual: false
  }
};

const quillFormats = [
  'header',
  'font',
  'list',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'color',
  'background',
  'align',
  'link',
  'image'
];

const UpdateFaq = () => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const { faqId } = useParams();
  const [loading, setLoading] = useState(true);
  const [backClicked, setBackClicked] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [errors, setErrors] = useState({
    category: "",
    question: "",
    answer: "",
  });
  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${config.apiDomain}/api/admin/get_faq_category`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.data.st === 1) {
          setCategories(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [authToken]);

  useEffect(() => {
    const fetchFaqDetails = async () => {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");

      if (!authToken || !userId) {
        navigate("/login");
        return;
      }

      setLoading(true);
      try {
        const response = await axios.post(
          `${config.apiDomain}/api/admin/view_faq`,
          { faq_id: faqId },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.st === 1) {
          const { question, answer, category } = response.data.data;
          setQuestion(question);
          setAnswer(answer);

          const selectedCategory = categories.find(
            (cat) => cat.category_name === category
          );
          if (selectedCategory) {
            setSelectedCategory(selectedCategory.faq_category_id);
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/common/login");
        } else {
          console.error("Error fetching FAQ details:", error);
        }
      } finally {
        setLoading(false);
      }
    };

    if (categories.length > 0) {
      fetchFaqDetails();
    }
  }, [faqId, categories, navigate]);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    if (e.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, category: "" }));
    }
  };

  const handleQuestionChange = (e) => {
    const newQuestion = e.target.value;
    setQuestion(newQuestion);
    if (newQuestion.trim().length >= 5) {
      setErrors((prevErrors) => ({ ...prevErrors, question: "" }));
    }
  };

  const cleanHtmlContent = (html) => {
    const text = html.replace(/<\/?[^>]+(>|$)/g, "").trim();
    return text.length > 0 ? text : "";
  };

  const handleAnswerChange = (value) => {
    const cleanedValue = cleanHtmlContent(value);
    setAnswer(value); // Store raw HTML content to keep formatting
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({ category: "", question: "", answer: "" });
  
    let hasError = false;
    const newErrors = {};
  
    if (!selectedCategory) {
      newErrors.category = "Please select a category";
      hasError = true;
    }
  
    if (question.trim().length < 5) {
      newErrors.question = "Question must be at least 5 characters";
      hasError = true;
    }
  
    const cleanedAnswer = cleanHtmlContent(answer);
    if (cleanedAnswer.length === 0) {
      newErrors.answer = "Answer cannot be empty";
      hasError = true;
    }
  
    if (hasError) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }
  
    const data = {
      faq_id: faqId,
      faq_category_id: selectedCategory,
      question,
      answer,
    };
  
    const authToken = localStorage.getItem("authToken");
  
    try {
      const response = await axios.put(`${config.apiDomain}/api/admin/update_faq`, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
  
      const { st, msg } = response.data;
  
      // Handle different `st` values with custom toasts
      switch (st) {
        case 1:
          window.showToast("success", msg || "FAQ updated successfully");
          setTimeout(() => {
            navigate("/admin/manage_faq");
          }, 1000);
          break;
        case 2:
          window.showToast("danger", msg || "Error: FAQ update failed.");
          break;
        case 3:
          window.showToast("warning", msg || "Warning: Check the FAQ details.");
          break;
        case 4:
          window.showToast("info", msg || "Info: FAQ updated with additional notes.");
          break;
        default:
          window.showToast("error", "Something went wrong. Contact Support.");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/login");
      } else {
        console.error("Error updating FAQ:", error);
        const errorMsg = error.response?.data?.msg || "Something went wrong. Contact Support.";
        window.showToast("error", errorMsg);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <AdminHeader />
      <AdminSubHeader />
      <Toast ref={toast} position="top-right" />
      <style>{editorStyles}</style>
      <div className="container-xxl flex-grow-1 container-p-y">
        <nav aria-label="breadcrumb">
          <div className="d-flex justify-content-between align-items-center">
            <ol className="breadcrumb breadcrumb-style1 text-black mb-3">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard" className="text-black">
                  <i className="ri-home-7-line ri-lg"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/admin/manage_faq" className="text-black">
                  FAQ
                </Link>
              </li>
              <li
                className="breadcrumb-item active text-secondary"
                aria-current="page"
              >
                Update FAQ
              </li>
            </ol>
            <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>
        <div className="card p-5">
        {loading ? (
        <Skeleton height={200} /> // Adjust the height as needed
      ) : (
        <>
          <div className="row align-items-center">
            <div className="col-3 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 text-center mb-5">
              <h5 className="mb-0">Update FAQ</h5>
            </div>
            <div className="col-3"></div>
          </div>
          <form onSubmit={handleSubmit}>
            <div class="form-floating form-floating-outline mb-6">
              <select
                id="category_name"
                value={selectedCategory}
                onChange={handleCategoryChange}
                className={`form-select ${errors.category ? "is-invalid" : ""}`}
              >
                <option value="">Select a category</option>
                {categories.map((category) => (
                  <option
                    key={category.faq_category_id}
                    value={category.faq_category_id}
                    className="text-capitalize"
                  >
                    {category.category_name}
                  </option>
                ))}
              </select>
              <label for="exampleFormControlSelect1">
                <span className="text-danger">*</span> Category Name
              </label>

              {errors.category && (
                <small className="text-danger ">{errors.category}</small>
              )}
            </div>

            <div className="mb-3">
              <div className="form-floating form-floating-outline">
                <input
                  type="text"
                  className={`form-control ${
                    errors.question ? "is-invalid" : ""
                  }`}
                  id="question"
                  value={question}
                  onChange={handleQuestionChange}
                  placeholder="Question"
                />
                <label htmlFor="question">
                  <span className="text-danger">*</span> Question
                </label>
                {errors.question && (
                  <small className="text-danger">{errors.question}</small>
                )}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="answer" className="form-label">
                <span className="text-danger">*</span> Answer
              </label>
              <div className={`quill-editor-container ${errors.answer ? 'is-invalid' : ''}`}>
                <ReactQuill
                  theme="snow"
                  value={answer}
                  onChange={handleAnswerChange}
                  modules={quillModules}
                  formats={quillFormats}
                  className="quill-editor"
                  placeholder="Write something..."
                />
              </div>
              {errors.answer && (
                <small className="text-danger">{errors.answer}</small>
              )}
            </div>

            <div className=" text-end mt-4">
              <button
                type="submit"
                className="btn rounded-pill btn-info btn-sm"
                disabled={loading}
              >
                {loading ? (
                  <div className="spinner-border me-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                )}{" "}
                {loading ? "Saving Data" : "Save Data"}
              </button>
            </div>
          </form>
          </>
      )}
        </div>
        <CardLinks></CardLinks>
      </div>

      <Footer />
    </div>
  );
};

export default UpdateFaq;

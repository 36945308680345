import React from "react";
import { Helmet } from "react-helmet";

function MetaTags() {
  return (
    <Helmet>
     <meta name="description" content="AutoProfito - A cutting-edge copy trading platform compatible with top brokers like AngelOne, Dhan, and Zerodha. Trade smarter by copying experienced traders." />
<meta name="keywords" content="AutoProfito, copy trading, AngelOne, Dhan, Zerodha, trading platform, auto trading, investment, stock trading, trade copying, brokers" />

<meta name="author" content="AutoProfito" />
<meta name="distribution" content="global" />
<meta name="rating" content="general" />
<meta name="robots" content="index, follow" />
<meta name="mobile-web-app-capable" content="YES" />
<meta name="HandheldFriendly" content="True" />
<meta name="MobileOptimized" content="320" />
<meta name="identifier-url" content="https://AutoProfito.com/" />

<meta property="og:locale" content="en_US" />
<meta property="og:type" content="website" />
<meta property="og:title" content="AutoProfito | Copy Trading Platform with AngelOne, Dhan & Zerodha" />
<meta property="og:description" content="AutoProfito - The ultimate copy trading platform for trading with top brokers like AngelOne, Dhan, and Zerodha. Empower your trades with proven strategies." />
<meta property="og:url" content="https://AutoProfito.com/" />
<meta property="og:site_name" content="AutoProfito" />
<meta property="og:image" content="https://AutoProfito.com/logo.png" />
<meta property="og:image:alt" content="AutoProfito - Copy Trading Platform" />
<meta property="og:image:width" content="720" />
<meta property="og:image:height" content="602" />

<meta name="twitter:card" content="AutoProfito | Copy Trading Platform" />
<meta name="twitter:title" content="AutoProfito | Copy Trading Platform with AngelOne, Dhan & Zerodha" />
<meta name="twitter:description" content="Join AutoProfito and trade smarter with copy trading solutions for AngelOne, Dhan, and Zerodha. Simplify trading with automated strategies from experienced traders." />
<meta name="twitter:image" content="https://AutoProfito.com/logo.png" />
<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
<meta http-equiv="pragma" content="no-cache" />
<meta http-equiv="expires" content="0" />
<meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
    </Helmet>
  );
}

export default MetaTags;

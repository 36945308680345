import React, { useEffect, useState } from "react";
import axios from "axios";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";
import { Link } from "react-router-dom";
import MetaTags from "./MetaTags";

function Pricing() {
  const [plans, setPlans] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null); // Make sure hoveredIndex is managed

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(
          "https://ghanish.in/api/website/view_all_subscription_plans"
        );
        if (response.data.st === 1) {
          setPlans(response.data.all_subscription_plans_list);
        }
      } catch (error) {
        // Silently handle the error without logging anything
      }
    };

    fetchPlans();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const gifs = [
    require("../../../assets/gif/1.gif"), // First GIF
    require("../../../assets/gif/2.gif"), // Second GIF
    require("../../../assets/gif/3.gif"),
    require("../../../assets/gif/4.gif"),
    require("../../../assets/gif/5.gif"),
  ];
  return (
    <>
      <MetaTags />
      <LandingHeader />
      <div className="container-xxl mt-10 pt-10 mb-5">
        <div className="text-center mb-5 mt-10">
          <h4 className="text-center  mb-2">
            <span className=" fw-semibold badge rounded-pill bg-label-primary ">
              {" "}
              <i className="ri-emoji-sticker-line me-3"></i>Pricing Plan
            </span>{" "}
          </h4>

          <p className="mb-1">
            Choose the perfect plan for your needs. Always flexible to grow.
          </p>
          <p className="mt-1 mb-10">Start with free plan.</p>
        </div>
      </div>
      <div className="container-xxl mb-6">
  <div className="row justify-content-center">
    {plans.map((plan, index) => {
      const isPopular = index === plans.length - 2; // Identifying popular card
      const isFree = plan.pricing === "0"; // Check if the plan is free

      return (
        <div
          key={plan.subscription_plan_id}
          className="col-lg-4 col-md-6 col-sm-12 mb-6 d-flex"
        >
          <div
            className={`card w-100 ${
              isPopular ? "animated-border" : "border-light"
            } d-flex flex-column h-100`}
          >
            <div className="card-body d-flex flex-column position-relative pt-4">
              {isPopular && (
                <div className="position-absolute end-0 me-6 top-0 mt-6">
                  <span className="badge bg-label-primary rounded-pill">
                    Popular
                  </span>
                </div>
              )}

              {/* Badge for Free Plan */}
              {isFree ? (
                <h5 className="card-title text-center text-capitalize mb-1 mt-3">
                  <span className="badge bg-label-success rounded-pill">
                    {plan.plan_name}
                  </span>
                </h5>
              ) : (
                <h4 className="card-title text-center text-capitalize mb-1 mt-3">
                  {plan.plan_name
                    .split(" ")
                    .map(
                      (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                    .join(" ")}
                </h4>
              )}

              <div className="my-5 pt-3 text-center">
                <img
                  src={gifs[index % gifs.length]}
                  alt="Plan Image"
                  height="100"
                />
              </div>
              <div className="text-center">
                <div className="d-flex justify-content-center">
                  <sup className="h6 pricing-currency mt-2 mb-0 me-1 text-body">
                    ₹
                  </sup>
                  <h1 className="price-toggle price-yearly text-primary mb-0">
                    {new Intl.NumberFormat("en-IN").format(plan.pricing)}
                  </h1>
                  <sub className="h6 pricing-duration mt-auto mb-1 text-body ms-2">
                    /{plan.service_period} Days
                  </sub>
                </div>
              </div>

              <p className="text-center text-capitalize my-3 mt-6 mb-6 px-3 lh-base flex-grow-1">
                {plan.plan_description}
              </p>

              <ul className="text-start p-1 ps-4 list-unstyled mt-auto">
                <li className="my-3">
                  <i className="ri-user-3-fill text-primary me-3 fs-5 "></i>
                  Account handled by <span className="fw-bold">1</span> Master Account.
                </li>
                <li className="my-3">
                  <i className="ri-group-line text-primary me-3 fs-5"></i>
                  Copy trades on <span className="fw-bold">{plan.max_user_limit}</span> student accounts.
                </li>
                <li className="my-3">
                  <i className="ri-settings-4-line text-primary me-3 fs-5"></i>
                  <span className="fw-bold">{plan.service_period} Days</span> service.
                </li>
                <li className="my-3">
                  <i className="ri-folder-chart-line text-primary me-3 fs-5"></i>
                  Reporting: Daily, Weekly, Monthly
                </li>
                <li className="my-3">
                <i className="ri-calendar-line text-primary me-3 fs-5"></i> Activity Plan
              
                </li>
              </ul>

              <Link to="/signup">
  <div className="btn btn-primary mt-4 w-100 ">
    <span>Signup</span>
  </div>
</Link>

            </div>
          </div>
        </div>
      );
    })}
  </div>
</div>


      <LandingFooter />
    </>
  );
}

export default Pricing;

import React, { useState, useEffect, useRef } from "react";

import { Row, Col, Nav, Card, Modal, Button } from "react-bootstrap";
import axios from "axios";
import config from "../../../component/config";
import Header from "../component/Header";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../../component/Footer";
import SubHeader from "../component/SubHeader";
import CardLinks from "../../../component/CardLinks";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
import Skeleton from 'react-loading-skeleton';

const WatchList = () => {
  const [search, setSearch] = useState("");
  const [equityData, setEquityData] = useState([]);
  const [commodityData, setCommodityData] = useState([]);
  const [fnoData, setFnoData] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const [selectedExchange, setSelectedExchange] = useState([]);
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const authToken = localStorage.getItem("authToken");
  const [equityCount, setEquityCount] = useState(0);
  const [commodityCount, setCommodityCount] = useState(0);
  const [fnoCount, setFnoCount] = useState(0);
  const toast = useRef(null);
  const [watchList, setWatchList] = useState([]);
  const [loading, setLoading] = useState(true);
  const userId = localStorage.getItem("userId");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);
  const [modalSearch, setModalSearch] = useState("");
  const [showDropdownModal, setShowDropdownModal] = useState(false);
  const [modalItems, setModalItems] = useState([]);
  const [modalEquityData, setModalEquityData] = useState([]);
  const [modalCommodityData, setModalCommodityData] = useState([]);
  const [modalFnoData, setModalFnoData] = useState([]);
  const [instrumentData, setInstrumentData] = useState([]);
  const [brokerConnected, setBrokerConnected] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [broker, setBroker] = useState(null);
  const [selectedOrderData, setSelectedOrderData] = useState({
    symbol: "",
    lotqtybuffer: "",
    transactionType: "",
    orderType: "",
    productType: "",
  });
  const [selectedOrders, setSelectedOrders] = useState([]);
const [isRefreshing,setIsRefreshing] = useState(true);
  const [onlyTeacherExecute, setOnlyTeacherExecute] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const wsRef = useRef(null);
  const watchListWsRef = useRef(null);
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const maxRetries = 5; // Max retry attempts
  const retryDelay = 2000;
  const isMarketOpen = () => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    const currentDay = currentTime.getDay();

    const marketOpenHour = 9;
    const marketOpenMinute = 15;
    const marketCloseHour = 15;
    const marketCloseMinute = 30;

    // If it's Sunday (0) or Saturday (6), the market is closed
    if (currentDay === 0 || currentDay === 6) {
      return false;
    }

    // Check if the current time is within market hours (9:15 AM to 3:30 PM)
    if (
      (currentHour > marketOpenHour ||
        (currentHour === marketOpenHour &&
          currentMinute >= marketOpenMinute)) &&
      (currentHour < marketCloseHour ||
        (currentHour === marketCloseHour && currentMinute <= marketCloseMinute))
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleInputChanges = (index, event) => {
    const { name, value } = event.target;
    const updatedOrders = [...selectedOrders];
    updatedOrders[index] = { ...updatedOrders[index], [name]: value };
    setSelectedOrders(updatedOrders);
  };

  const handleToggleChange = () => {
    setOnlyTeacherExecute(!onlyTeacherExecute);
  };

  const handleCloseOrderModal = () => {
    setShowOrderModal(false);
    setSelectedStock(null);
    setSelectedOrders([]);
    disconnectWebSocket();
  };

  const handleExecuteAll = async () => {
    setIsLoading(true);
    setErrorMessage("");
    const orderData = selectedOrders.map((order) => ({
      instrument: order.symbol,
      lot_quantity_buffer: order.lotqtybuffer,
      transactionType: order.transactionType,
      orderType: order.orderType,
      productType: order.productType,
      price: order.price,
    }));

    const requestData = {
      teacher_id: userId, // Replace with actual teacher ID
      order_data: orderData,
      only_teacher_execute: onlyTeacherExecute,
    };

    try {
      const response = await axios.post(
        "https://apbacked.xyz/execute_orders",
        requestData
      );
      if (response.data.st === 1) {
      } else {
        // Backend returned an error
        setErrorMessage(
          response.data.msg || "An error occurred during order execution."
        );
      }
    } catch (error) {
      // Handle any request errors
      if (error.response && error.response.data && error.response.data.msg) {
        setErrorMessage(error.response.data.msg); // Show error from backend
      } else {
        setErrorMessage("An unexpected error occurred during order execution.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const connectWebSocket = (tokens, broker) => {
    let wsUrl;
  
    // Check the broker and set the WebSocket URL accordingly
    if (broker === "angle_one") {
      wsUrl = "wss://ghanish.in/ws/angelone/live_market_data/";
    } else if (broker === "dhan") {
      wsUrl = "wss://ghanish.in/ws/dhan/live_market_data/";
    } else {
      console.log("Unsupported broker:", broker);
      return; // Exit the function if the broker is not supported
    }
  
    // Only establish the WebSocket connection if the market is open and not already connected
    if (isMarketOpen() && !wsRef.current) {
      wsRef.current = new WebSocket(wsUrl);
  
      wsRef.current.onopen = () => {
        console.log("WebSocket connected");
        const requestData = {
          teacher_id: userId,
          tokens: tokens,
        };
        wsRef.current.send(JSON.stringify(requestData));
      };
  
      wsRef.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        console.log("Live market data received:", message);
  
        const data = message.data;
        if (data && data.token && data.ltp) {
          updateLtp(data.token, data.ltp); // Update the correct order with the LTP
        } else {
          console.log("Received incomplete or null data:", data);
        }
      };
  
      wsRef.current.onerror = (error) => {
        console.log("WebSocket error:", error);
        disconnectWebSocket();  // Disconnect on error
      };
  
      wsRef.current.onclose = () => {
        console.log("WebSocket disconnected");
        wsRef.current = null;  // Clear the reference on close
      };
    } else {
      console.log("Market is closed or WebSocket is already connected.");
    }
  };
  
  const disconnectWebSocket = () => {
    if (wsRef.current) {
      wsRef.current.close(); // Close the WebSocket connection
      wsRef.current = null; // Clear the WebSocket reference
      console.log("WebSocket connection closed.");
    }
  };
  
  
 

  const updateLtp = (token, newLtp) => {
    const normalizedToken = token.toString();
    const cleanedLtp = parseFloat(newLtp).toFixed(2);
  
    setSelectedOrders((prevOrders) =>
      prevOrders.map((order) => {
        if (order.token === normalizedToken) {
          const oldLtp = parseFloat(order.ltp) || 0;
  
          // Determine the class based on the LTP difference
          let ltpClass = "text-secondary"; // Default class for no change
          if (cleanedLtp > oldLtp) {
            ltpClass = "text-success"; // Positive change
          } else if (cleanedLtp < oldLtp) {
            ltpClass = "text-danger"; // Negative change
          }
  
          return { ...order, ltp: cleanedLtp, ltpClass }; // Update LTP and color class
        }
        return order;
      })
    );
  };
  
  

  
  

  useEffect(() => {
    return () => {
      disconnectWebSocket(); // Disconnect when component unmounts
    };
  }, []);

  const handleOpenOrderModal = async (item, transactionType) => {
    setErrorMessage("");
    try {
      const response = await axios.post(
        "https://ghanish.in/api/teacher/watchlist_get_instrument_details",
        {
          teacher_id: userId,
          token: item.token,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      if (response.data.st === 1) {
        const instrumentData = response.data.instrument_data;
        const token = instrumentData.token;
        const broker = response.data.broker;
  
        setBrokerConnected(response.data.broker_connection_status);
        setSelectedOrderData({
          symbol: instrumentData.symbol,
          lotqtybuffer: "1",
          transactionType,
          orderType: "MARKET",
          productType: "CARRYFORWARD",
          exch_seg: instrumentData.exch_seg,
        });
  
        setSelectedOrders((prevOrders) => [
          ...prevOrders,
          {
            symbol: instrumentData.symbol,
            token: instrumentData.token,
            lotqtybuffer: "1",
            transactionType,
            orderType: "MARKET",
            productType: "CARRYFORWARD",
            exch_seg: instrumentData.exch_seg,
          },
        ]);
  
        setShowOrderModal(true);
        
        // Make sure to disconnect any existing WebSocket before establishing a new one
        disconnectWebSocket();
        connectWebSocket([token], broker);
      }
    } catch (error) {
      console.error("Error fetching instrument details:", error);
    }
  };
  

  const updateWatchListLtp = (token, newLtp) => {
    const normalizedToken = token.toString();
    const normalizedLtp = parseFloat(newLtp); // Convert to number for calculations
  
    setWatchList((prevWatchList) =>
      prevWatchList.map((item) => {
        if (item.token === normalizedToken) {
          const oldLtp = parseFloat(item.ltp); // Previous LTP
          const ltpDifference = normalizedLtp - oldLtp; // Calculate difference
  
          // Determine the class based on the LTP difference
          let ltpClass = "text-secondary"; // Default: no change
          if (ltpDifference > 0) {
            ltpClass = "text-success"; // Positive change
          } else if (ltpDifference < 0) {
            ltpClass = "text-danger"; // Negative change
          }
  
          return { ...item, ltp: normalizedLtp, ltpClass };
        }
        return item;
      })
    );
  };
  
  const connectWatchListWebSocket = (tokens, broker) => {
    let wsUrl;
  
    if (broker === "angle_one") {
      wsUrl = "wss://ghanish.in/ws/angelone/live_market_data/";
    } else if (broker === "dhan") {
      wsUrl = "wss://ghanish.in/ws/dhan/live_market_data/";
    } else {
      console.log("Unsupported broker:", broker);
      return;
    }
  
    if (isMarketOpen()) {
      // Ensure there is no existing WebSocket connection before creating a new one
      if (watchListWsRef.current) {
        console.log("WebSocket already open, closing it before creating a new one.");
        disconnectWatchListWebSocket(); // Ensure the previous WebSocket is closed
      }
  
      watchListWsRef.current = new WebSocket(wsUrl);
  
      watchListWsRef.current.onopen = () => {
        console.log("WebSocket connected");
        const teacherId = userId; // Use actual teacher ID
        const requestData = {
          teacher_id: teacherId,
          tokens,
        };
        watchListWsRef.current.send(JSON.stringify(requestData));
      };
  
      watchListWsRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("Live market data received:", data);
  
        if (data.data === null) {
          console.log("Received null data, disconnecting WebSocket");
          disconnectWatchListWebSocket();
        } else {
          updateWatchListLtp(data.data.token, data.data.ltp); // Update LTP in watchlist
        }
      };
  
      watchListWsRef.current.onerror = (error) => {
        console.log("WebSocket error:", error);
      };
  
      watchListWsRef.current.onclose = () => {
        console.log("WebSocket disconnected");
      };
    } else {
      console.log("Market is closed. WebSocket connection not established.");
    }
  };
  
  const disconnectWatchListWebSocket = () => {
    if (watchListWsRef.current && watchListWsRef.current.readyState === WebSocket.OPEN) {
      watchListWsRef.current.close(); // Close the WebSocket connection
      console.log("WebSocket connection closed.");
    }
    watchListWsRef.current = null; // Clear the reference after closing
  };
  
  useEffect(() => {
    if (userId) {
      fetchWatchList();
    }
  
    // Ensure WebSocket is disconnected when component unmounts
    return () => {
      disconnectWatchListWebSocket();
    };
  }, [userId]);
  
  const fetchWatchList = async () => {
    setLoading(true);
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/watchlist_view`,
        { teacher_id: userId },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
  
      if (response.data.st === 1) {
        setWatchList(response.data.watchlist);
        setBroker(response.data.broker);
  
        // Connect WebSocket with tokens from the watchlist
        const tokens = response.data.watchlist.map((item) => item.token);
        connectWatchListWebSocket(tokens, response.data.broker);
      } else {
        console.log("Failed to load watchlist");
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        navigate("/login");
      } else {
        console.log(err);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOrderDelete = () => {
    setSelectedOrderData({
      symbol: "",
      lotqtybuffer: "",

      transactionType: "",
      orderType: "",
      productType: "",
    });
  };

  const handleRefresh = async () => {
    setLoading(true);
    setIsRefreshing(true);
    try {
      const authToken = localStorage.getItem("authToken");
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/watchlist_view`,
        { teacher_id: userId },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );

      if (response.data.st === 1) {
        setWatchList(response.data.watchlist);
      } else {
      }
    } catch (err) {
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };
  const handleDelete = async (token) => {
    try {
      const authToken = localStorage.getItem("authToken");
      const response = await axios.delete(
        `${config.apiDomain}/api/teacher/watchlist_delete`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          data: { teacher_id: userId, token: token },
        }
      );

      if (response.data.st === 1) {
        setWatchList((prevList) =>
          prevList.filter((item) => item.token !== token)
        );
      } else {
      }
    } catch (err) {}
  };
  const openDeleteModal = (token) => {
    setSelectedToken(token);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedToken(null);
  };

  const confirmDelete = () => {
    handleDelete(selectedToken);
    closeDeleteModal();
  };
  const handleSearch = async (e) => {
    let searchValue = e.target.value.toUpperCase();

    if (searchValue.length > 15) {
      searchValue = searchValue.slice(0, 15);
    }

    setSearch(searchValue);

    if (searchValue.trim().length >= 3) {
      try {
        const response = await axios.post(
          `${config.apiDomain}/api/teacher/get_instrument_list_new`,
          {
            search: searchValue,
            teacher_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.st === 1) {
          const equity = response.data.data.equity || [];
          const commodity = response.data.data.commodity || [];
          const fno = response.data.data.fno || [];

          setEquityData(equity);
          setCommodityData(commodity);
          setFnoData(fno);
          setShowDropdown(true);

          const instrumentList = [...equity, ...commodity, ...fno].map(
            (item) => item.symbol
          );
          setItems(instrumentList);

          setEquityCount(equity.length);
          setCommodityCount(commodity.length);
          setFnoCount(fno.length);
        } else {
          resetSearchData();
        }
      } catch (error) {
        console.clear();
      }
    } else {
      resetSearchData();
    }
  };

  const resetSearchData = () => {
    setEquityData([]);
    setCommodityData([]);
    setFnoData([]);
    setShowDropdown(false);
    setItems([]);
    setEquityCount(0);
    setCommodityCount(0);
    setFnoCount(0);
  };

  const handleStockSelect = async (stock) => {
    if (watchList.length >= 30) {
      
      setShowDropdown(false);
      return;
    }
    setSelectedStock(stock);
    setSearch("");
    setShowDropdown(false);

    const watchlistData = {
      teacher_id: userId,
      token: stock.token,
    };

    try {
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/create_watchlist`,
        watchlistData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.st === 1) {
        setWatchList((prevList) => {
          if (!prevList.some((item) => item.token === stock.token)) {
            return [...prevList, stock]; // Add new stock
          }
          return prevList;
        });
        await fetchWatchList();
        setIsTableVisible(true);
      }
    } catch (error) {
      console.clear();
    }
  };

  const handleExchangeSelect = (exchange) => {
    setSelectedExchange(exchange);
  };

  const filteredStocks = (() => {
    switch (selectedExchange) {
      case "Equity":
        return equityData;
      case "FnO":
        return fnoData;
      case "Commodity":
        return commodityData;
      default:
        return [...equityData, ...fnoData, ...commodityData];
    }
  })();

  const handleModalSearch = async (e) => {
    let searchValue = e.target.value.toUpperCase();

    if (searchValue.length > 15) {
      searchValue = searchValue.slice(0, 15);
    }

    setModalSearch(searchValue);

    if (searchValue.trim().length >= 3) {
      try {
        const response = await axios.post(
          `${config.apiDomain}/api/teacher/get_instrument_list_new`,
          {
            search: searchValue,
            teacher_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.st === 1) {
          const equity = response.data.data.equity || [];
          const commodity = response.data.data.commodity || [];
          const fno = response.data.data.fno || [];

          setModalEquityData(equity);
          setModalCommodityData(commodity);
          setModalFnoData(fno);
          setShowDropdownModal(true);

          const instrumentList = [...equity, ...commodity, ...fno].map(
            (item) => item.symbol
          );
          setModalItems(instrumentList);

          setEquityCount(equity.length);
          setCommodityCount(commodity.length);
          setFnoCount(fno.length);
        } else {
          setModalEquityData([]);
          setModalFnoData([]);
          setModalCommodityData([]);
          setShowDropdownModal(false);
          setModalItems([]);
          setEquityCount(0);
          setCommodityCount(0);
          setFnoCount(0);
          // resetSearchData();
        }
      } catch (error) {
        console.clear();
      }
    } else {
      setModalEquityData([]);
      setModalFnoData([]);
      setModalCommodityData([]);
      setShowDropdownModal(false);
      setModalItems([]);
      setEquityCount(0);
      setCommodityCount(0);
      setFnoCount(0);
    }
  };

  const handleModalStockSelect = async (stock) => {
    const newOrder = {
      symbol: stock.symbol,
      exch_seg: stock.exch_seg || "N/A",
      lotqtybuffer: "1",
      transactionType: "BUY",
      orderType: "MARKET",
      productType: "CARRYFORWARD",
      token: stock.token,
      ltp: null,  // Initialize LTP with null
      ltpClass: "text-secondary",  // Default LTP class
    };
  
    try {
      const authToken = localStorage.getItem("authToken");
  
      // API call to create watchlist
      const requestBody = {
        teacher_id: userId,  // Assuming you have the userId available in your context or state
        token: stock.token,  // Assuming stock object contains token
      };
  
      const response = await axios.post(
        "https://ghanish.in/api/teacher/create_watchlist",
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.data.st === 1) {
        // Add the new instrument to the state
        setSelectedOrders((prevOrders) => [...prevOrders, newOrder]);
  
        // Get token and broker from response
        const { token } = response.data.data;
        const broker = response.data.data.broker;  // Assuming broker is provided
  
        // Connect WebSocket for LTP updates
        connectWebSocket([token], broker);
  
        window.showToast("success", "Instrument added to watchlist.");
        await fetchWatchList();
      } else {
        switch (response.data.st) {
          case 2:
            window.showToast("danger", response.data.msg || "Error: Failed to add instrument to watchlist.");
            break;
          case 3:
            window.showToast("warning", response.data.msg || "Warning: Review instrument details.");
            break;
          case 4:
            window.showToast("info", response.data.msg || "Info: Instrument added with additional notes.");
            break;
          default:
            window.showToast("error", "Unexpected response status.");
        }
      }} catch (error) {
        console.error("Error adding instrument to watchlist:", error);
        const errorMsg = "Failed to add instrument to watchlist.";
        window.showToast("error", errorMsg);
      }
  
    // Reset modal search after selection
    setModalSearch("");
  };
  
  
  
  const filteredModalStocks = (() => {
    switch (selectedExchange) {
      case "Equity":
        return modalEquityData;
      case "FnO":
        return modalFnoData;
      case "Commodity":
        return modalCommodityData;
      default:
        return [...modalEquityData, ...modalFnoData, ...modalCommodityData];
    }
  })();

  return (
    <div>
      <Toast ref={toast} />
      <Header></Header>
      <SubHeader></SubHeader>
      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <div className="container-xxl flex-grow-1 container-p-y">
            <nav aria-label="breadcrumb">
              <div className="d-flex justify-content-between align-items-center">
                <ol className="breadcrumb breadcrumb-style1 text-black">
                  <li className="breadcrumb-item">
                    <Link to="/teacher/dashboard" className="text-black">
                      <i className="ri-home-7-line ri-lg"></i>
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active text-secondary"
                    aria-current="page"
                  >
                    Watchlist
                  </li>
                </ol>
                <SubscriptionBadge></SubscriptionBadge>
              </div>
            </nav>
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-7 mt-3 col-12 ">
                <div className="card" style={{ height: "400px" }}>
                  <div className="d-flex justify-content-start ms-4 mb-3 mt-2 me-3"></div>

                  <div
                    className="card-datatable table-responsive pt-0 d-flex justify-content-center align-items-center"
                    style={{ height: "100%" }}
                  >
                    <h3 className="text-center text-muted">
                      Live Market Charts <br></br> Coming Soon
                    </h3>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-5 mt-3 col-12 ">
                <div className="card ">
                  <div className="d-flex justify-content-between mt-3 ms-3 me-3 bg-white ">
                    <div>
                      <span className="text-black">NIFTY 50</span>
                      <span className="text-success ms-2">10120.75</span>
                    </div>
                    <div>
                      <span className="text-black">SENSEX</span>
                      <span className="text-success ms-2">586.90</span>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="ms-1 me-1 " style={{ position: "relative" }}>
                    <div className="d-flex justify-content-start ms-4 mb-3 me-3">
                      <div className="flex-grow-1 input-group input-group-sm input-group-merge rounded-pill">
                        <span
                          className="input-group-text"
                          id="basic-addon-search31"
                        >
                          <i className="ri-search-line lh-1 ri-20px"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control chat-search-input ps-8"
                          placeholder="Search instruments..."
                          value={search.toUpperCase()}
                          onChange={handleSearch}
                          aria-label="Search..."
                          aria-describedby="basic-addon-search31"
                          maxLength={15}
                        />
                      </div>
                      {loading ? (
                        <div className="d-flex align-items-center">
                          <i
                            className={`ri-refresh-line ri-lg ms-3 p-text-secondary ${
                              isRefreshing ? "rotate" : ""
                            }`}
                            role="status"
                          ></i>
                        </div>
                      ) : (
                        <div className="mt-3">
                          <Tooltip target=".custom-target-icon" />
                          <i
                            className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                            role="button"
                            data-pr-tooltip="Reload"
                            onClick={handleRefresh}
                            data-pr-position="top"
                          ></i>
                        </div>
                      )}
                    </div>
                    {showDropdown && search && filteredStocks.length > 0 && (
                      <Card
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: 0,
                          width: "100%",
                          zIndex: 1000,
                        }}
                      >
                        <Nav
                          justify
                          variant="tabs"
                          className="bg-body"
                          style={{ height: "45px", overflowX: "hidden" }}
                        >
                          {equityCount > 0 && (
                            <Nav.Item>
                              <Nav.Link
                                className={`custom-nav-link mt-1 ${
                                  selectedExchange === "Equity" ? "active" : ""
                                }`}
                                onClick={() => handleExchangeSelect("Equity")}
                              >
                                <i className="ri-cash-line ri-lg me-1"></i>
                                Equity
                                <small
                                  className={`ms-1 ${
                                    selectedExchange === "Equity"
                                      ? "active"
                                      : "text-muted "
                                  }`}
                                >
                                  ({equityCount})
                                </small>
                              </Nav.Link>
                            </Nav.Item>
                          )}

                          {fnoCount > 0 && (
                            <Nav.Item>
                              <Nav.Link
                                className={`custom-nav-link mt-1 ${
                                  selectedExchange === "FnO" ? "active" : ""
                                }`}
                                onClick={() => handleExchangeSelect("FnO")}
                              >
                                <i className="ri-bar-chart-2-line ri-lg me-1"></i>
                                FnO
                                <small
                                  className={`ms-1 ${
                                    selectedExchange === "FnO"
                                      ? "active"
                                      : "text-muted "
                                  }`}
                                >
                                  ({fnoCount})
                                </small>
                              </Nav.Link>
                            </Nav.Item>
                          )}

                          {commodityCount > 0 && (
                            <Nav.Item>
                              <Nav.Link
                                className={`custom-nav-link mt-1 ${
                                  selectedExchange === "Commodity"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleExchangeSelect("Commodity")
                                }
                              >
                                <i className="ri-vip-diamond-line ri-lg me-1"></i>
                                Commodity
                                <small
                                  className={`ms-1 ${
                                    selectedExchange === "Commodity"
                                      ? "active"
                                      : "text-muted "
                                  }`}
                                >
                                  ({commodityCount})
                                </small>
                              </Nav.Link>
                            </Nav.Item>
                          )}
                        </Nav>

                        <Card.Body
                          className="bg-white p-0"
                          style={{
                            maxHeight: "250px",
                            overflowY: "auto",
                            overflowX: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Row>
                            {filteredStocks.map((stock, i) => (
                              <Col
                                key={i}
                                xs={12}
                                className={`d-flex align-items-center mb-1 ${
                                  selectedStock === stock
                                    ? "bg-body text-black"
                                    : "hover-bg-secondary"
                                } custom-col`}
                                style={{ cursor: "pointer" }}
                                onClick={() => handleStockSelect(stock)}
                              >
                                <div className="custom-col-content ms-3">
                                  <span className="badge rounded-pill bg-label-secondary me-2 ">
                                    {stock.exch_seg}
                                  </span>
                                  <strong>{stock.symbol}</strong>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </Card.Body>
                      </Card>
                    )}
                    <hr className="pb-0 mb-0"></hr>
                  </div>

                  <div
                    className="card-datatable table-responsive pt-0"
                    style={{
                      height: "300px",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <table className="table table-sm">
                      <tbody>
                        {loading ? (
                          // Render skeleton rows while loading
                          [...Array(5)].map((_, index) => (
                            <tr key={index}>
                              <td colSpan="2">
                                <Skeleton height={40} />
                              </td>
                            </tr>
                          ))
                        ) : watchList.length > 0 ? (
                          watchList.map((item) => (
                            <tr key={item.token}>
                              <td className="text-danger d-flex align-items-center">
                                <span className="badge rounded-pill bg-label-secondary me-1">
                                  {item.exch_seg}
                                </span>
                                <span className="me-3 text-black text-break">
                                  {item.symbol}
                                </span>

                                {item.ltp !== undefined &&
                                  item.ltp !== null &&
                                  typeof item.ltp === "number" && (
                                    <span className={item.ltpClass}>
                                      {item.ltp.toFixed(2)} ₹
                                    </span>
                                  )}
                              </td>

                              <td className="text-end">
                                <div className="btn-group" role="button">
                                  <i
                                    className="ri ri-arrow-line ri-lg rounded-pill dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                    role="button"
                                  ></i>

                                  <ul className="dropdown-menu pt-0 pb-0">
                                    <li>
                                      <button
                                        type="button"
                                        className="btn btn-label-success w-100 border-bottom dropdown-item mt-0"
                                        onClick={() =>
                                          handleOpenOrderModal(item, "BUY")
                                        }
                                      >
                                        <span className="text-start">
                                          <i className="ri-check-line text-success ri-lg me-1"></i>{" "}
                                          Buy
                                        </span>
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        type="button"
                                        className="btn btn-label-danger w-100 border-bottom dropdown-item"
                                        onClick={() =>
                                          handleOpenOrderModal(item, "SELL")
                                        }
                                      >
                                        <span className="text-start">
                                          <i className="ri-telegram-line text-danger ri-lg me-1"></i>{" "}
                                          Sell
                                        </span>
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        type="button"
                                        className="w-100 btn-xs text-center dropdown-item"
                                        onClick={() =>
                                          openDeleteModal(item.token)
                                        }
                                      >
                                        <i className="ri-delete-bin-line ri-md me-1"></i>{" "}
                                        Delete
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3" className="text-center">
                              {loading ? "Loading..." : "No watchlist found."}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <DeleteConfirmationModal
                    show={showDeleteModal}
                    handleClose={closeDeleteModal}
                    handleConfirmDelete={confirmDelete}
                  />
                </div>
              </div>

              <Modal
                show={showOrderModal}
                onHide={handleCloseOrderModal}
                size="xl"
                className="modal fade"
                onClick={(e) => {
                  if (e.target.id === "exampleModal") {
                    handleCloseOrderModal();
                  }
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title size="lg" className="w-75">
                    <div
                      className="w-100 mt-3 ms-3"
                      style={{ position: "relative" }}
                    >
                      <input
                        type="text"
                        placeholder="Search instruments..."
                        value={modalSearch.toUpperCase()}
                        onChange={handleModalSearch}
                        className="bg-white form-control ps-10"
                        maxLength={15}
                      />
                      <i className="ri ri-search-line position-absolute top-50 start-0 translate-middle-y ms-3"></i>
                      {showDropdownModal &&
                        modalSearch &&
                        filteredModalStocks.length > 0 && (
                          <Card
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: 0,
                              width: "100%",
                              zIndex: 1050,
                            }}
                          >
                            <Nav
                              justify
                              variant="tabs"
                              className="bg-body mb-0"
                              style={{ height: "45px", overflowX: "hidden" }}
                            >
                              {equityCount > 0 && (
                                <Nav.Item>
                                  <Nav.Link
                                    className={` custom-nav-link  mt-1 ${
                                      selectedExchange === "Equity"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleExchangeSelect("Equity")
                                    }
                                  >
                                    <i className="ri-cash-line ri-lg me-1"></i>{" "}
                                    Equity
                                    <small
                                      className={`ms-1 ${
                                        selectedExchange === "Equity"
                                          ? "active"
                                          : "text-muted "
                                      }`}
                                    >
                                      ({equityCount})
                                    </small>
                                  </Nav.Link>
                                </Nav.Item>
                              )}
                              {fnoCount > 0 && (
                                <Nav.Item>
                                  <Nav.Link
                                    className={`mt-1 custom-nav-link ${
                                      selectedExchange === "FnO" ? "active" : ""
                                    }`}
                                    onClick={() => handleExchangeSelect("FnO")}
                                  >
                                    <i className="ri-bar-chart-2-line ri-lg me-1"></i>{" "}
                                    FnO
                                    <small
                                      className={`ms-1 ${
                                        selectedExchange === "FnO"
                                          ? "active"
                                          : "text-muted "
                                      }`}
                                    >
                                      ({equityCount})
                                    </small>
                                  </Nav.Link>
                                </Nav.Item>
                              )}
                              {commodityCount > 0 && (
                                <Nav.Item>
                                  <Nav.Link
                                    className={`mt-1 custom-nav-link ${
                                      selectedExchange === "Commodity"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleExchangeSelect("Commodity")
                                    }
                                  >
                                    <i className="ri-vip-diamond-line ri-lg me-1"></i>{" "}
                                    Commodity
                                    <small
                                      className={`ms-1 ${
                                        selectedExchange === "Commodity"
                                          ? "active"
                                          : "text-muted "
                                      }`}
                                    >
                                      ({equityCount})
                                    </small>
                                  </Nav.Link>
                                </Nav.Item>
                              )}
                            </Nav>

                            <Card.Body
                              className="bg-white p-0"
                              style={{
                                maxHeight: "250px",
                                overflowY: "auto",
                                overflowX: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <Row>
                                {filteredModalStocks.map((stock, index) => (
                                  <Col
                                    key={index}
                                    xs={12}
                                    className={`d-flex align-items-center mb-1 ${
                                      selectedStock === stock
                                        ? "bg-body text-black"
                                        : "hover-bg-secondary"
                                    } custom-col`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleModalStockSelect(stock)
                                    }
                                  >
                                    <div className="d-flex w-100 justify-content-between align-items-center ms-3">
                                      <div className="d-flex align-items-center">
                                        <span className="badge rounded-pill bg-label-secondary me-2 h6 fs-6">
                                          {stock.exch_seg}
                                        </span>
                                        <strong className="text-start h6">
                                          {stock.symbol}
                                        </strong>
                                      </div>
                                      {stock.ltp !== null &&
                                        stock.ltp !== "" && (
                                          <span className="text-end me-3 h6">
                                            ₹{stock.ltp}
                                          </span>
                                        )}
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            </Card.Body>
                          </Card>
                        )}
                    </div>
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="table-responsive">
                    <table className="table table-bordered table-xl">
                      <thead>
                        <tr>
                          <th>Instrument</th>
                          <th>Lot Qty Buffer</th>
                          <th>Tran. Type</th>
                          <th>Order Type</th>
                          <th>Product Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedOrders.map((order, index) => (
                          <tr key={index}>
                            <td className="w-25 text-start">
                              <div className="border border-muted py-0 px-2 rounded">
                                <p className="fs-5 mb-1 display-6">
                                  {order.symbol}
                                </p>
                                <p className="small text-muted mb-0">
                                  <span className="me-2">
                                    &bull; {order.exch_seg || order.exchange}
                                  </span>
                                  {order.ltp !== undefined &&
                                    order.ltp !== null && (
                                      <span className={order.ltpClass}>
                                        &bull; ₹
                                        {parseFloat(order.ltp).toFixed(2)}
                                      </span>
                                    )}
                                </p>
                              </div>
                            </td>
                            <td className="w-auto text-center">
                              <select
                                className="form-control"
                                name="lotqtybuffer"
                                value={order.lotqtybuffer}
                                onChange={(event) =>
                                  handleInputChanges(index, event)
                                }
                              >
                                <option value="0">None</option>
                                <option value="1">1</option>
                              </select>
                            </td>

                            <td className="w-auto text-center">
                              <label
                                className={`switch ${
                                  order.transactionType === "BUY"
                                    ? "switch-success"
                                    : "switch-danger"
                                }`}
                              >
                                <input
                                  type="checkbox"
                                  className="switch-input"
                                  checked={order.transactionType === "BUY"}
                                  onChange={() =>
                                    handleInputChanges(index, {
                                      target: {
                                        name: "transactionType",
                                        value:
                                          order.transactionType === "BUY"
                                            ? "SELL"
                                            : "BUY",
                                      },
                                    })
                                  }
                                />
                                <span
                                  className={`switch-toggle-slider ${
                                    order.transactionType === "BUY"
                                      ? "bg-success"
                                      : "bg-danger"
                                  }`}
                                ></span>
                                <span
                                  className={`switch-label ${
                                    order.transactionType === "SELL"
                                      ? "text-danger"
                                      : "text-success"
                                  }`}
                                >
                                  {order.transactionType}
                                </span>
                              </label>
                            </td>

                            <td className="w-auto text-center">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  className="switch-input"
                                  checked={order.orderType === "MARKET"}
                                  onChange={() =>
                                    handleInputChanges(index, {
                                      target: {
                                        name: "orderType",
                                        value:
                                          order.orderType === "MARKET"
                                            ? "LIMIT"
                                            : "MARKET",
                                      },
                                    })
                                  }
                                />
                                <span
                                  className={`switch-toggle-slider ${
                                    order.orderType === "LIMIT" ? "bg-info" : ""
                                  }`}
                                ></span>
                                <span className={`switch-label`}>
                                  {order.orderType === "MARKET"
                                    ? "MARKET"
                                    : "LIMIT"}
                                </span>
                              </label>

                              {order.orderType === "LIMIT" && ( // Show price input only when order type is LIMIT
                                <div className="d-flex justify-content-center align-items-center mt-2">
                                  <input
                                    type="number"
                                    className="form-control form-control-sm w-75 text-center"
                                    name="price"
                                    placeholder="Enter Price"
                                    value={order.price || ""} // Ensure you have a price field in the order object
                                    onChange={(event) =>
                                      handleInputChanges(index, event)
                                    }
                                  />
                                </div>
                              )}
                            </td>

                            <td className="w-auto text-center">
                              <select
                                value={order.productType}
                                name="productType"
                                onChange={(e) => handleInputChanges(index, e)}
                                className="form-select"
                              >
                                <option value="CARRYFORWARD">
                                  CARRYFORWARD
                                </option>
                                <option value="DELIVERY">DELIVERY</option>
                                <option value="MARGIN">MARGIN</option>
                                <option value="INTRADAY">INTRADAY</option>
                                <option value="BO">BO</option>
                              </select>
                            </td>
                            <td className="w-auto text-center">
                              <div
                                className="badge badge-center badge-lg rounded-pill bg-label-danger"
                                onClick={() => {
                                  const updatedOrders = selectedOrders.filter(
                                    (_, i) => i !== index
                                  );
                                  setSelectedOrders(updatedOrders);
                                }}
                              >
                                <i className="ri-delete-bin-line ri-sm"></i>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  <div className="d-flex align-items-center">
                    <div className="switch-label me-3">
                      Execute Teacher only?
                    </div>
                    <label className="switch me-5">
                      <input
                        type="checkbox"
                        className="switch-input"
                        checked={onlyTeacherExecute}
                        onChange={handleToggleChange}
                      />
                      <span className="switch-toggle-slider">
                        <span className="switch-on"></span>
                        <span className="switch-off"></span>
                      </span>
                      <div className="switch-label"></div>
                    </label>
                    {isLoading && (
                      <div className="d-flex align-items-center ms-6">
                        <i className="ri ri-checkbox-circle-fill text-success me-2 ri-lg"></i>
                        <span className="me-3">
                          {onlyTeacherExecute
                            ? `Executing ${selectedOrders.length} instruments on teacher only`
                            : `Executing ${selectedOrders.length} instruments on teacher & students`}
                        </span>
                      </div>
                    )}
                    <button
                      type="button"
                      className="btn btn-info d-flex align-items-center"
                      onClick={handleExecuteAll}
                      // disabled={isLoading}
                      disabled={isLoading || !brokerConnected}
                    >
                      {isLoading ? (
                        <>
                          <div className="spinner-border me-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                          Executing orders
                        </>
                      ) : (
                        <>
                          <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                          Execute All
                        </>
                      )}
                    </button>
                  </div>

                  {/* Positioning the Broker Not Connected message in a new row */}
                </Modal.Footer>
                {!brokerConnected && (
                  <div className="text-danger text-end mb-3 me-6">
                    Broker Not Connected
                  </div>
                )}
                {errorMessage && (
                  <div className="text-danger text-end mb-3 me-6">
                    {errorMessage}
                  </div>
                )}
              </Modal>

              <CardLinks></CardLinks>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default WatchList;

const DeleteConfirmationModal = ({
  show,
  handleClose,
  handleConfirmDelete,
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure?</Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <button
            onClick={handleClose}
            className="btn btn-outline-secondary rounded-pill btn-sm"
          >
            <i className="ri-close-large-line me-2"></i>
            Cancel
          </button>
          <button
            onClick={handleConfirmDelete}
            className="btn btn-danger rounded-pill btn-sm"
          >
            <i className="ri-delete-bin-line me-2"></i>
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

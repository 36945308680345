import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

import { Modal } from "react-bootstrap";
import Footer from "../../../component/Footer";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import SelectChannel from "./SelectChannel";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
const TelegramBroadcast = () => {
  const [loadings, setLoadings] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [viewData, setViewData] = useState([]);
  const [showViewModal, setShowViewModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [messageCount, setMessageCount] = useState(0);
  const toast = useRef(null);
  const navigate = useNavigate();
  const [backClicked, setBackClicked] = useState(false);
const [isRefreshing, setIsRefreshing] = useState(true);
  const [error, setError] = useState("");


const handleSendMessage = async () => {
  if (!validateMessage()) return;
  const userId = localStorage.getItem("userId");
  const authToken = localStorage.getItem("authToken");

  setLoadings(true);
  try {
    const response = await axios.post(
      "https://ghanish.in/api/teacher/telegram/send_message",
      {
        teacher_id: userId,
        message: message,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    const { st, msg } = response.data;

    if (st === 1) {
      window.showToast("success", msg || "Message sent successfully");
      setMessage("");
    } else {
      // Handle different `st` values for errors and warnings
      switch (st) {
        case 2:
          window.showToast("danger", msg || "Error: Failed to send message.");
          break;
        case 3:
          window.showToast("warning", msg || "Warning: Check message details.");
          break;
        case 4:
          window.showToast("info", msg || "Info: Message sent with additional notes.");
          break;
        default:
          window.showToast("error", "Something went wrong. Contact Support.");
      }
    }
  } catch (error) {
    console.clear();
    if (error.response && error.response.status === 401) {
      navigate("/login");
    } else {
      const errorMsg = error.response?.data?.msg || "Something went wrong. Contact Support.";
      window.showToast("error", errorMsg);
    }
  } finally {
    setLoadings(false);
  }
};


  const handleView = async () => {
    const authToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");

    if (!authToken || !userId) {
      navigate("/login");
      return;
    }
    try {
      const response = await axios.post(
        "https://ghanish.in/api/teacher/telegram/message_history",
        {
          teacher_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the JWT token in the headers
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.st === 1) {
        setStartDate(response.data.start_date);
        setEndDate(response.data.end_date);
        setMessageCount(response.data.message_count);
        setViewData(response.data.telegram_message_history);
        setShowViewModal(true);
      } else {
       
      }
    } catch (error) {
      console.clear(); 
      if (error.response && error.response.status === 401) {
       
        navigate("/login"); 
      } else {
        const errorMsg = error.response?.data?.msg || "An error occurred";
      
      }
    }
  };

  const handleRefresh = async () => {
    const userId = localStorage.getItem("userId");
    const authToken = localStorage.getItem("authToken");
    setLoading(true);
    setIsRefreshing(true);
    try {
      const response = await axios.post(
        "https://ghanish.in/api/teacher/telegram/message_history",
        {
          teacher_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      const { st, msg, start_date, end_date, message_count, telegram_message_history } = response.data;
  
      if (st === 1) {
        setStartDate(start_date);
        setEndDate(end_date);
        setMessageCount(message_count);
        setViewData(telegram_message_history);
        setShowViewModal(true);
        window.showToast("success", msg || "Message history fetched successfully");
      } else {
        // Handle different `st` values for errors and warnings
        switch (st) {
          case 2:
            window.showToast("danger", msg || "Error: Failed to fetch message history.");
            break;
          case 3:
            window.showToast("warning", msg || "Warning: Please review the message history.");
            break;
          case 4:
            window.showToast("info", msg || "Info: Additional notes on message history.");
            break;
          default:
            window.showToast("error", "Something went wrong. Contact Support.");
        }
      }
    } catch (error) {
      console.clear();
      if (error.response && error.response.status === 401) {
        navigate("/login");
      } else {
        const errorMsg = error.response?.data?.msg || "Something went wrong. Contact Support.";
        window.showToast("error", errorMsg);
      }
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };
  

  const handleCloseViewModal = () => {
    setShowViewModal(false);
  };

  const rowClassName = (rowData, rowIndex) => {
    return rowIndex % 2 === 0 ? "even-row" : "odd-row";
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  const validateMessage = () => {
    if (message.length < 5) {
      setError("Message must be at least 5 characters long.");
      return false;
    } else if (message.length > 300) {
      setError("Message cannot be more than 300 characters long.");
      return false;
    }
    setError(""); 
    return true;
  };
  const handleMessageChange = (e) => {
    const newMessage = e.target.value;
    setMessage(newMessage);
    validateMessage();
  };
  return (
    <>
      <Toast ref={toast} />
      <Header />
      <SubHeader />

      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>

            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Telegram Broadcasting
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>

        <div className="card p-5">
          <div className="mb-5">
            <div>
              {/* Mobile View */}
              <div className="d-block d-md-none mb-5">
                <div className="position-relative mb-3">
                  <button
                    onClick={handleBack}
                    className="btn rounded-pill btn-outline-secondary btn-xs position-absolute start-0"
                  >
                    <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
                  </button>
                  <h5 className="mb-0 text-center w-100">Telegram Broadcasting</h5>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <button
                    className="btn btn-outline-success btn-sm me-2"
                    onClick={handleView}
                  >
                    <i className="ri-chat-history-line text-success ri-sm"></i>
                  </button>
                  <SelectChannel />
                </div>
              </div>

              {/* Desktop View - Unchanged */}
              <div className="d-none d-md-flex align-items-center justify-content-between mb-5">
                <div className="d-flex align-items-center">
                  <button
                    onClick={handleBack}
                    className="btn rounded-pill btn-outline-secondary btn-xs me-3"
                  >
                    <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
                  </button>
                </div>
                <h5 className="mb-0">Telegram Broadcasting</h5>
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-outline-success btn-sm me-2"
                    onClick={handleView}
                  >
                    <i className="ri-chat-history-line text-success ri-sm"></i>
                  </button>
                  <SelectChannel />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="input-group input-group-merge">
                <div className="form-floating form-floating-outline">
                  <textarea
                    className={`form-control h-px-100 ${
                      error ? "is-invalid" : ""
                    }`}
                    aria-label="With textarea"
                    placeholder="Enter Your Message"
                    value={message}
                    onChange={handleMessageChange}
                  ></textarea>
                  <label>
                    {" "}
                    <span className="text-danger">*</span> Message
                  </label>
                  {error && <div className="invalid-feedback">{error}</div>}
                </div>
              </div>
            </div>

            <div className="text-end mt-3">
              <button
                type="button"
                className="btn rounded-pill btn-info"
                onClick={handleSendMessage}
                disabled={loadings} // Disable button while loading
              >
                {loadings ? (
                  <div className="spinner-border me-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <i className="ri-telegram-line ri-lg me-2"></i>
                )}
                {loadings ? "Sending message" : "Send message"}
              </button>
            </div>
          </div>
        </div>

        <CardLinks></CardLinks>
        <Modal
          show={showViewModal}
          onHide={handleCloseViewModal}
          centered
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title className="d-flex justify-content-center w-100 mb-7">
              Message History
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="w-75 mx-auto mb-3">
              <div className="d-flex justify-content-between">
                <div className="text-center">
                  <strong className="fs-4">{startDate}</strong>
                  <div className="text-center">Start Date</div>
                </div>
                <div className="text-center">
                  <strong className="fs-4">{endDate}</strong>
                  <div className="text-center">End Date</div>
                </div>
                <div className="text-center">
                  <strong className="fs-4">{messageCount}</strong>
                  <div className="text-center">Total Messages Sent</div>
                </div>
              </div>
            </div>
            <hr className="text-secondary mb-10"></hr>
            <div className="d-flex align-items-center mb-3">
              <IconField iconPosition="left">
                <InputIcon className="ri ri-search-line"></InputIcon>
                <InputText
                  type="search"
                  placeholder="Search"
                  value={globalFilter}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                  className="rounded"
                />
              </IconField>
              {loading ? (
                <i className=" custom-target-icon ri-loader-2-line ri-lg mt-3 ms-3 p-text-secondary" />
              ) : (
                <div className="mt-2">
                  <Tooltip target=".custom-target-icon" />
                  <i
                    className=" custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary "
                    data-pr-tooltip="Reload"
                    onClick={handleRefresh}
                    data-pr-position="top"
                  ></i>
                </div>
              )}
            </div>
            {viewData && (
              <DataTable
                className="text-center border border-1"
                value={viewData}
                paginator
                rows={20}
                showGridlines
                loading={loading}
                globalFilter={globalFilter}
                emptyMessage="No records found"
                rowClassName={rowClassName}
              >
                <Column
                  align="center"
                  className="text-center border border-1"
                  field="date_time"
                  header="Date & Time"
                ></Column>
                <Column
                  className=" border border-1 text-capitalize"
                  field="message"
                  header="Message"
                ></Column>
                <Column
                  className=" border border-1 text-capitalize"
                  field="teacher_name"
                  header="Teacher Name"
                ></Column>
                <Column
                  className=" border border-1"
                  field="channel_list"
                  header="Channel List"
                ></Column>
              </DataTable>
            )}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>

      <Footer />
    </>
  );
};

export default TelegramBroadcast;

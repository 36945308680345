import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import MetaTags from "./MetaTags";

const HelpFAQ = () => {
  const [faqs, setFaqs] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await axios.get(
          "https://ghanish.in/api/website/view_all_category_faqs"
        );
        if (response.data.st === 1) {
          setFaqs(response.data.data);
        } else {
          setError("Failed to fetch FAQs");
        }
      } catch (error) {
        console.error("Error fetching FAQs:", error);
        setError("An error occurred while fetching FAQs");
      } finally {
        setLoading(false);
      }
    };

    fetchFaqs();
  }, []);

  if (loading) return <p>Loading..</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <MetaTags />
      <div className="container-xxl bg-white" style={{ borderRadius: "10px" }}>
        <div className="card mt-4 shadow-none">
          <div className="card-body">
            <div className="text-center mb-5">
            <h4 className="text-center  mb-2"><span className=" fw-semibold badge rounded-pill bg-label-primary"> <i className="ri-emoji-sticker-line me-3"></i> FAQ`s</span> </h4>

              <p className="mb-8">
                Let us help answer the most common questions.
              </p>
            </div>
            

            <div className="container-xxl">
              <div className="row d-flex justify-content-center">
                {Object.keys(faqs).map((category) => (
                  <div
                    className="col-lg-4 col-md-8 col-sm-6 mb-6"
                    key={category}
                  >
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="avatar avatar-sm flex-shrink-0 me-2">
                            <span className="avatar-initial bg-label-primary rounded">
                              <i className="ri-drag-move-line fs-4"></i>
                           
                            </span>
                          </div>
                          <h5 className="mb-0 ms-1 text-capitalize">{category}</h5>
                        </div>
                        <ul className="list-unstyled my-6">
                          {faqs[category].map((faq) => (
                            <li className="mb-2" key={faq.faq_id}>
                              <Link
                                to={`/faq/${faq.faq_id}`}
                                className="text-heading d-flex justify-content-between align-items-center"
                              >
                                <span className="text-truncate me-1 text-capitalize">
                                  <i className="ri-arrow-right-line"></i>  {faq.faq_question}
                                </span>
                                <i className="tf-icons ri-arrow-right-s-line ri-20px scaleX-n1-rtl text-muted" />
                              </Link>
                            </li>
                          ))}
                        </ul>
                        <p className="mb-0 fw-medium mt-6">
                          <Link
                            to="/help_center"
                            className="d-flex align-items-center"
                          >
                            {/* <span className="me-2">See all articles</span>
                          <i className="tf-icons ri-arrow-right-line scaleX-n1-rtl" /> */}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="h-40"></div>
      </div>
    </>
  );
};

export default HelpFAQ;

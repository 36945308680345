import React, { useState, useRef } from "react";

import { useDropzone } from 'react-dropzone';
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap'; // Importing Button component from react-bootstrap
import { Toast as PrimeToast } from 'primereact/toast'; // Import PrimeReact Toast component
import axios from 'axios';
import StudentHeader from "./StudentHeader";
import SubHeaderS from "./SubHeaderS";

const FeatureRequestStudent = () => {
  const [backClicked, setBackClicked] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(''); // State for file name
  const [fileError, setFileError] = useState(''); // State for file size error
  const toast = useRef(null); // Create a ref for PrimeReact Toast
  const navigate = useNavigate();

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
    maxSize: 5 * 1024 * 1024, // Set max file size to 5 MB
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        setFileError('File size exceeds 5 MB. Please upload a smaller file.');
        setSelectedFile(null);
        setSelectedFileName('');
      } else {
        setSelectedFile(acceptedFiles[0]);
        setSelectedFileName(acceptedFiles[0].name); // Update the state with the file name
        setFileError(''); // Clear file error if file is valid
      }
    }
  });

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = async () => {
    // if (!captchaValue) {
    //   toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please complete the CAPTCHA' });
    //   return;
    // }
    
    const authToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
    

    if (!authToken || !userId) {
      
     
      navigate("/login");
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('user_id', userId); // Example user ID
      formData.append('product', document.getElementById('productSelect').value);
      formData.append('feature', document.getElementById('featureInput').value);
      formData.append('explanation', document.getElementById('explanationTextarea').value);
      if (selectedFile) {
        formData.append('example_file', selectedFile);
      }

      const response = await axios.post('https://ghanish.in/api/common/feature_request/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${authToken}`
        }
      });

      if (response.data.st === 1) {
        window.showToast("success ", "Feature request submitted successfully!");

        setTimeout(() => {
            navigate("/student/dashboard");
          }, 1000); 
       
      } else {
        window.showToast("error ", "Failed to submit feature request.");

      }
    }  catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login'); // Navigate to login page on 401 Unauthorized
      } else {
        window.showToast("error ", "An error occurred while submitting the request");

      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <StudentHeader></StudentHeader>
      <SubHeaderS></SubHeaderS>
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/Student/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary" aria-current="page">
              Feature Request Form
            </li>
          </ol>
        </nav>
        <div className="card p-6">
          <div className="row align-items-center">
            <div className="col-3 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 text-center mb-5">
              <h5 className="mb-0">Feature Request</h5>
            </div>
            <div className="col-3 text-end"></div>
          </div>
          <div className="row">
           
            <div className="col-md-6">
              <div className="form-floating form-floating-outline">
                <select
                  className="form-select"
                  id="productSelect"
                  aria-label="Default select example"
                >
                  <option value="">Please Select</option>
                  <option value="Product1">Product 1</option>
                  <option value="Product2">Product 2</option>
                  <option value="Product3">Product 3</option>
                </select>
                <label htmlFor="productSelect">  <span className="text-danger">*</span> select the
                product for feature request</label>
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <div className="form-floating form-floating-outline ">
                <input
                  type="text"
                  className="form-control"
                  id="featureInput"
                  placeholder="Enter New Feature"
                />
                <label htmlFor="featureInput">  <span className="text-danger">*</span> What would you like to add as a new feature?</label>
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <div className="form-floating form-floating-outline mb-6">
                <textarea
                  className="form-control h-px-100"
                  id="explanationTextarea"
                  placeholder="Type here..."
                ></textarea>
                <label htmlFor="explanationTextarea">  <span className="text-danger">*</span> Please explain how it would work</label>
              </div>
            </div>
            <div className="col-12">
              <label htmlFor="dropzone-basic">Please Upload example, if there is any</label>
              <div className="dropzone-container bg-body ">
                <div className="dropzone needsclick mt-3" id="dropzone-basic" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dz-message needsclick">
                    <i className="ri-upload-cloud-2-line" style={{ fontSize: '2rem', color: 'blue' }}></i>
                    <div className="text-primary mt-1">Click to upload</div>
                    <div className="">(Max file size: 5 MB)</div>
                  
                    {selectedFileName && (
                      <div className="text-secondary alert" >
                        Selected file: {selectedFileName}
                      </div>
                    )}
                    
                    {fileError && (
                      <div className="text-danger mt-2">
                        {fileError}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
           
            {/* <div className="col-4 mt-4">
              <label className="mb-3">  <span className="text-danger">*</span> Please Verify that you are Human</label>
              <ReCAPTCHA
                sitekey="YOUR_RECAPTCHA_SITE_KEY"
                onChange={handleCaptchaChange}
              />
            </div> */}
            <div className="col-12 mt-4 text-end">
              <button
                type="submit"
                className="btn btn-success rounded-pill px-4 py-2"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <i className="ri-loader-line ri-lg me-1"></i> Submitting....
                  </>
                ) : (
                  <>
                    <i className="ri-checkbox-circle-line ri-lg me-1"></i> Submit
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <PrimeToast ref={toast} />
    </div>
  );
};

export default FeatureRequestStudent;


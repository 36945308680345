import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import autoprofito_logo from "../../../assets/img/products/company/autoprofito_logo.png";
import MetaTags from "./MetaTags";
import { NetworkStatusContext } from "../../context/NetworkStatusContext";

const LandingHeader = () => {
  const location = useLocation();
  // const isOnline = useContext(NetworkStatusContext);
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("/");

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
        {/* {isOnline ? 'You are online!' : 'You are offline. Please check your connection.'} */}

      <MetaTags />

      <nav className="navbar navbar-expand-lg bg-white shadow-sm fixed-top d-lg-none">
        <div className="container-fluid d-flex justify-content-between align-items-center px-3">
          <button
            className="navbar-toggler border-0"
            type="button"
            aria-controls="navbarSupportedContent"
            aria-expanded={isMenuOpen}
            aria-label="Toggle navigation"
            onClick={handleMenuToggle}
          >
            <i className="ri-menu-fill ri-30px align-middle fs-4"></i>
          </button>

          <Link to="/" className="navbar-brand d-flex align-items-center">
            <img src={autoprofito_logo} alt="Favicon" width="44" />
            <span className="ms-2 fw-semibold fs-5">AutoProfito</span>
          </Link>
          <div className="d-flex justify-content-center align-items-center border border-info border-3 rounded-circle bg-label-info avatar-md">

          <div className="avatar-initial rounded-circle bg-label-info  d-lg-none d-flex">
          <Link
            to="/login"
          >
            <i className="ri-user-line ri-lg text-info"></i>
          </Link>
          </div>
          </div>
        </div>
        {isMenuOpen && <div className="overlay" onClick={toggleMenu}></div>}

        <div
          className={`sidebar ${isMenuOpen ? "active" : ""}`}
          id="navbarSupportedContent"
        >
          <div className="d-flex justify-content-between align-items-center ms-5 mt-3">
            <Link
              to="/"
              className="navbar-brand d-flex align-items-center "
              onClick={toggleMenu}
            >
              <img src={autoprofito_logo} alt="Favicon" width="44" />
              <span className="ms-2 fw-semibold fs-5">AutoProfito</span>
            </Link>

            <span className="close-icon text-end me-3"  onClick={toggleMenu}>
        <i className="ri ri-close-line ri-lg ms-3"></i>
      </span>
            

          </div>
       
          <hr className="mt-0"></hr>
          <ul className="sidebar-nav " >
          <li className="ms-3">
              <Link
                className={`nav-link ${
                  activeLink === "/" ? "text-primary fw-bold " : "text-secondary"
                } `}
                to="/"
                onClick={() => {
                  setActiveLink("/");
                  toggleMenu();
                }}
              >
             <i className="ri ri-home-7-line ri-lg me-3"></i> Home
             <i className="ri ri-arrow-right-s-line sidebar-arrow me-3"></i>
              </Link>
            </li>
            <li className="ms-3">
              <Link
                className={`nav-link ${
                  activeLink === "/pricing" ? "text-primary fw-bold " : "text-secondary"
                } `}
                to="/pricing"
                onClick={() => {
                  setActiveLink("/pricing");
                  toggleMenu();
                }}
              >
                <i className="ri ri-price-tag-3-line ri-lg me-3"></i> Pricing
                <i className="ri ri-arrow-right-s-line sidebar-arrow me-3"></i>
              </Link>
            </li>
            <li className="ms-3">
              <Link
                className={`nav-link ${
                  activeLink === "/algo_trading" ? "text-primary fw-bold " : "text-secondary"
                } `}
                to="/algo_trading"
                onClick={() => {
                  setActiveLink("/algo_trading");
                  toggleMenu();
                }}
              >
                 <i className="ri ri-funds-box-line ri-lg me-3"></i> Algo Trading
                 <i className="ri ri-arrow-right-s-line sidebar-arrow me-3"></i>
              </Link>
            </li>
           
            <li className="nav-item mb-12 "></li>
            <li className="m-5 text-center">
  <Link
    className="nav-link btn btn-info waves-effect text-white waves-light rounded-pill w-100 d-flex align-items-center justify-content-center"
    to="/login"
    
  >
    <i className="ri-user-line me-2" /> 
    Login
  </Link>
</li>

           <div className="text center">
              <Link
                  className="text-center"
                  to="/signup"
                >
                  <div className="text-muted mt-5">Signup</div>
                </Link>
                </div>
              </ul>
    
        </div>
   
      </nav>

      {/* Desktop Navbar */}
      <nav className="navbar navbar-expand-lg bg-white shadow-sm fixed-top d-none d-lg-block pb-2 pt-3">
        <div className="container-xxl d-flex justify-content-between align-items-center">
          <Link to="/" className="navbar-brand py-0 d-flex align-items-center">
            <img src={autoprofito_logo} alt="Favicon" width="44" />
            <span className="ms-2 fw-semibold fs-5">AutoProfito</span>
          </Link>
         
          <div
            className={`collapse navbar-collapse justify-content-center ${
              isMenuOpen ? "show" : ""
            }`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav text-center mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className={`nav-link fs-5 ${
                    activeLink === "/"
                      ? "text-primary fw-bold border-bottom border-primary  pb-2 border-4"
                      : "text-black"
                  } px-4`}
                  to="/"
                  onClick={() => setActiveLink("/")}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link fs-5 ${
                    activeLink === "/pricing"
                      ? "text-primary fw-bold border-bottom border-primary  pb-2 border-4"
                      : "text-black"
                  } px-4`}
                  to="/pricing"
                  onClick={() => setActiveLink("/pricing")}
                >
                  Pricing
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link fs-5 ${
                    activeLink === "/algo_trading"
                      ? "text-primary fw-bold border-bottom border-primary  pb-2 border-4"
                      : "text-black"
                  } px-4`}
                  to="/algo_trading"
                  onClick={() => setActiveLink("/algo_trading")}
                >
                  Algo Trading
                </Link>
              </li>
             
            </ul>
          </div>

         
          <div className="navbar-nav ms-auto d-flex align-items-center">
            <Link
              to="/signup"
              className="d-flex justify-content-center align-items-center rounded-pill me-2 text-dark pe-3"
            >
              <div className="text-muted">Signup</div>
            </Link>
            <Link
              to="/login"
              className="btn btn-info rounded-pill btn-outline-secondary waves-effect"
            >
              <i className="ri-user-line me-1" />
              Login
            </Link>
          </div>
        </div>
        
      </nav>
    </>
  );
};

export default LandingHeader;

import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";
import MetaTags from "./MetaTags";
import parse from 'html-react-parser';

const FaqDetails = () => {
  const { faqId } = useParams(); 
  const [loading, setLoading] = useState(true);
  const [faqDetails, setFaqDetails] = useState(null);
  const [error, setError] = useState(null);
  const [questionList, setQuestionList] = useState([]); // Store the list of questions
  const navigate = useNavigate(); 

  const fetchFaqDetails = async (id) => {
    setLoading(true); 
    try {
      const response = await axios.post(
        "https://ghanish.in/api/website/faq_detail",
        { faq_id: id || faqId } // If no id is passed, use faqId from URL params
      );
      if (response.data.st === 1) {
        setFaqDetails(response.data.data);
        setQuestionList(response.data.question_list);
        setError(null);
      } else {
        setFaqDetails(null);
        setError("FAQ not found");
      }
    } catch (error) {
      setError("An error occurred while fetching data.");
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    fetchFaqDetails(faqId); // Fetch FAQ details initially
  }, [faqId]);

  const handleQuestionClick = (id) => {
    fetchFaqDetails(id); // Fetch the new FAQ details when a question is clicked
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MetaTags />
      <LandingHeader />
      <div className="container-xxl mt-10 pt-10 mb-12 bg-white">
        <div className="row mt-10">
          <div className="col-lg-8">
            <div className="mb-3">
              <Link to="/help_center">
                <i className="ri-arrow-left-line me-3"></i>View All FAQs
              </Link>
            </div>
            {loading && <p>Loading...</p>}
            {error && <p>{error}</p>}
            {faqDetails && !loading && !error && (
              <>
                <h5 className="mb-2 text-capitalize">{faqDetails.question}</h5>
                <p className="mb-0 text-capitalize">{faqDetails.category}</p>
                <hr className="my-4" />
                <p className="mb-12">
                  {parse(faqDetails.answer)}
                </p>
              </>
            )}
          </div>

          <div className="col-lg-4 bg-white pt-3 rounded" style={{height: "auto"}}>
            <h6>Related FAQs</h6>
            <hr></hr>
            <ul className="list-unstyled">
              {questionList.map((questionItem) => (
              <li key={questionItem.faq_id} className="mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="text-start mb-3"
                  style={{ textDecoration: "none", color: "inherit", cursor: "pointer" }}
                  onClick={() => handleQuestionClick(questionItem.faq_id)}
                >
                  {questionItem.question.length > 50
                    ? `${questionItem.question.substring(0, 50)}...`
                    : questionItem.question}
                </div>
                <i className="ri-arrow-right-s-line ms-3" style={{ cursor: "pointer" }} onClick={() => handleQuestionClick(questionItem.faq_id)}></i>
              </div>
            </li>
            
              ))}
            </ul>
          </div>
        </div>
      </div>
      <LandingFooter />
    </div>
  );
};

export default FaqDetails;

import React, { useState, useRef } from "react";
import Footer from "../../../component/Footer";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../component/config";
import { Toast } from "primereact/toast";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
const CreateStudent = () => {
  const navigate = useNavigate();
  const [backClicked, setBackClicked] = useState(false);
  const toast = useRef(null);
  const [selectedBroker, setSelectedBroker] = useState("angle_one");
  const userId = localStorage.getItem("userId");

  const handleBrokerChange = (broker) => {
    setSelectedBroker(broker);
  };
  const [formData, setFormData] = useState({
    teacher_id: localStorage.getItem("userId"),

    name: "",
    mobile: "",
    email: "",
    commission: "10",
    trading_power: "",
    lot_size_limit: "1",
    api_key: "",
    client_id: "",
    password: "",
    qr_totp_token: "",
    dhan_client_id:"",
    access_token:"",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [withBrokerDetails, setWithBrokerDetails] = useState(false);

  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;

    if (!formData.name) {
      tempErrors.name = "Name is required";
      isValid = false;
    } else if (/\d/.test(formData.name)) {
      tempErrors.name = "Name cannot contain digits";
      isValid = false;
    } else if (/[^a-zA-Z\s]/.test(formData.name)) {
      tempErrors.name = "Name cannot contain special characters";
      isValid = false;
    } else if (formData.name.length < 3) {
      tempErrors.name = "Name must be at least 3 characters";
      isValid = false;
    } else if (formData.name.length > 50) {
      tempErrors.name = "Name must be at most 50 characters";
      isValid = false;
    }

    if (!formData.email) {
      tempErrors.email = "Email is required";
      isValid = false;
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
    ) {
      tempErrors.email = "Email is invalid";
      isValid = false;
    }

    if (!formData.mobile) {
      tempErrors.mobile = "Mobile number is required";
      isValid = false;
    } else if (!/^\d+$/.test(formData.mobile)) {
      tempErrors.mobile = "Mobile number must contain only digits";
      isValid = false;
    } else if (formData.mobile.length !== 10) {
      tempErrors.mobile = "Mobile number must be exactly 10 digits";
      isValid = false;
    }

    if (formData.lot_size_limit === "") {
      tempErrors.lot_size_limit = "Lot size limit is required";
      isValid = false;
    } else if (isNaN(formData.lot_size_limit)) {
      tempErrors.lot_size_limit = "Lot size limit must be a number";
      isValid = false;
    } else {
      const lotSizeLimit = parseInt(formData.lot_size_limit, 10);
      if (lotSizeLimit < 1 || lotSizeLimit > 5000) {
        tempErrors.lot_size_limit = "Lot size limit must be between 1 and 5000";
        isValid = false;
      }
    }

    if (!formData.commission) {
      tempErrors.commission = "Commission is required";
      isValid = false;
    } else if (
      formData.commission.includes("%") &&
      formData.commission.length === 1
    ) {
      tempErrors.commission = "Commission cannot be just '%'";
      isValid = false;
    }

   

    setErrors(tempErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "mobile") {
      if (value.length !== 10) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Mobile number must be exactly 10 digits",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    } 
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validateForm()) {
      return;
    }
  
    setLoading(true);
    setErrors({});
  
    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
  
      if (!authToken || !userId) {
        navigate("/login");
        return;
      }
  
      const requestData = {
        teacher_id: userId,
        name: formData.name,
        email: formData.email,
        mobile: formData.mobile,
        commission: formData.commission,
        lot_size_limit: formData.lot_size_limit,
        broker: selectedBroker,
      };
  
   
      if (selectedBroker === "angle_one" && withBrokerDetails) {
        requestData.broker_details = {
          api_key: formData.api_key,
          client_id: formData.client_id,
          password: formData.password,
          qr_totp_token: formData.qr_totp_token,
        };
      }
    
      else if (selectedBroker === "dhan" && withBrokerDetails) {
        requestData.broker_details = {
          dhan_client_id: formData.dhan_client_id,
          access_token: formData.access_token, 
        };
      }
  
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/manage_students/create?with_broker_details=${withBrokerDetails}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      if (response.data && response.data.st === 1) {
        window.showToast("success", formatMessage(response.data.msg) || "Success!");
        setTimeout(() => {
          navigate("/teacher/manage_student");
        }, 1000);
      } else if (response.data && response.data.st === 2) {
        window.showToast("warning", formatMessage(response.data.msg) || "Warning: Please check the input.");
      } else if (response.data && (response.data.st === 3 || response.data.st === 4)) {
        window.showToast("danger", formatMessage(response.data.msg) || "An error occurred.");
      } else {
        setErrors({ global: "Unexpected response status" });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/login");
      } else {
        const errorMsg = error.response?.data?.msg
          ? formatMessage(error.response.data.msg)
          : String(error.message || "Something went wrong. Contact Support.");
  
        window.showToast("error", errorMsg);
      }
      console.clear();
    } finally {
      setLoading(false);
    }
  };    
  

  const formatMessage = (msg) => {
    if (typeof msg === "object") {
      return Object.values(msg).flat().join(", ");
    }
    return String(msg);
  };

  return (
    <>
      <Header />
      <SubHeader />

      <div className="container-xxl flex-grow-1 container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/teacher/manage_student" className="text-black">
                 Student
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Create Student
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col-3 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 text-center mb-5">
              <h5 className="mb-0">Create Student</h5>
            </div>
            <div className="col-3"></div>
          </div>
          <div className="row">
            <h5 className="text-start">
              {" "}
              <i className="ri-user-line ri-ms me-2"></i>
              Personal Information
            </h5>
            <div className="col-12">
              <form onSubmit={handleSubmit}>
                <div className="row mt-1">
                  <div className="col-12 col-md-6 col-lg-3 mb-3">
                    <div className="form-floating form-floating-outline">
                      <input
                        className={`form-control ${
                          errors.name ? "is-invalid" : ""
                        }`}
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Name"
                      />
                      <label htmlFor="name">
                        {" "}
                        <span className="text-danger">*</span> Name{" "}
                      </label>
                      {errors.name && <p className="error">{errors.name}</p>}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 mb-3">
                    <div className="form-floating form-floating-outline">
                      <input
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        type="text"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="E-mail"
                      />
                      <label htmlFor="email">
                        {" "}
                        <span className="text-danger">*</span> E-mail{" "}
                      </label>
                      {errors.email && <p className="error">{errors.email}</p>}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 mb-3">
                    <div className="form-floating form-floating-outline">
                      <input
                        className={`form-control ${
                          errors.mobile ? "is-invalid" : ""
                        }`}
                        type="text"
                        id="mobile"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        placeholder="Mobile Number"
                        maxLength="10"
                      />
                      <label htmlFor="mobile">
                        <span className="text-danger">*</span> Mobile Number
                      </label>
                      {errors.mobile && (
                        <p className="error">{errors.mobile}</p>
                      )}
                    </div>
                  </div>

                  <div className=" col-12 col-md-3 col-lg-1 mb-3">
                    <div className="form-floating form-floating-outline">
                      <input
                        className={`form-control ${
                          errors.lot_size_limit ? "is-invalid" : ""
                        }`}
                        type="number"
                        id="lot_size_limit"
                        name="lot_size_limit"
                        value={formData.lot_size_limit}
                        onChange={handleChange}
                        placeholder="Lot Size Limit"
                        maxLength="5000"
                      />
                      <label htmlFor="lot_size_limit">
                        <span className="text-danger">*</span> Lot Size Limit{" "}
                      </label>
                      {errors.lot_size_limit && (
                        <p className="error">{errors.lot_size_limit}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-3 col-lg-1 mb-3">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="number"
                        className={`form-control ${
                          errors.commission ? "is-invalid" : ""
                        }`}
                        id="commission"
                        name="commission"
                        value={formData.commission}
                        onChange={handleChange}
                        placeholder="Commission"
                      />
                      <label htmlFor="commission">
                        <span className="text-danger">*</span> Commission
                      </label>
                      {errors.commission && (
                        <p className="error">{errors.commission}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12 mt-3 d-flex justify-content-end">
                  <div className="form-check text-end">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="withBrokerDetails"
                      name="withBrokerDetails"
                      checked={withBrokerDetails}
                      onChange={() => setWithBrokerDetails(!withBrokerDetails)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="withBrokerDetails"
                    >
                      Include Broker Details
                    </label>
                  </div>
                </div>
                <hr></hr>
                {withBrokerDetails && (
                  <div className="row mb-3">
                    <h5 className="text-start">
                      {" "}
                      <i className="ri-group-line ri-ms me-2"></i>
                      Broker Information
                    </h5>
                    <div className="nav-align-top mb-6">
                    <ul className="nav nav-tabs" role="tablist">
                                  <li className="nav-item me-5">
                                    <div
                                      className={` ${
                                        selectedBroker === "angle_one"
                                          ? "border-bottom  border-primary text-primary pb-2 border-4"
                                          : "text-black"
                                      }`}
                                      onClick={() =>
                                        handleBrokerChange("angle_one")
                                      }
                                    >
                                      AngleOne
                                    </div>
                                  </li>

                                  <li className="nav-item">
                                    <div
                                      className={` ${
                                        selectedBroker === "dhan"
                                          ? "border-bottom border-primary text-primary pb-2 border-4"
                                          : "text-black"
                                      }`}
                                      onClick={() => handleBrokerChange("dhan")}
                                    >
                                      Dhan
                                    </div>
                                  </li>
                                </ul>
                      <div className="row mt-6">
                      {selectedBroker === "angle_one" && (
                                    <>
                        <div className="col-12 col-md-6 col-lg-3 mb-3">
                          <div className="form-floating form-floating-outline">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.client_id ? "is-invalid" : ""
                              }`}
                              id="client_id"
                              name="client_id"
                              placeholder="Broker Client ID"
                              value={formData.client_id}
                              onChange={handleChange}
                            />
                            <label htmlFor="client_id">
                              <span className="text-danger">*</span> Broker
                              Client ID
                            </label>
                            {errors.client_id && (
                              <p className="error">{errors.client_id}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3 mb-3">
                          <div className="form-floating form-floating-outline">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.password ? "is-invalid" : ""
                              }`}
                              id="password"
                              name="password"
                              placeholder="Broker Password"
                              value={formData.password}
                              onChange={handleChange}
                            />
                            <label htmlFor="password">
                              {" "}
                              <span className="text-danger">*</span> Broker
                              Password
                            </label>
                            {errors.password && (
                              <p className="error">{errors.password}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-3">
                          <div className="form-floating form-floating-outline">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.qr_totp_token ? "is-invalid" : ""
                              }`}
                              id="qr_totp_token"
                              name="qr_totp_token"
                              placeholder="Broker QR TOTP Token"
                              value={formData.qr_totp_token}
                              onChange={handleChange}
                            />
                            <label htmlFor="qr_totp_token">
                              {" "}
                              <span className="text-danger">*</span> Broker QR
                              TOTP Token
                            </label>
                            {errors.qr_totp_token && (
                              <p className="error">{errors.qr_totp_token}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-3">
                          <div className="form-floating form-floating-outline">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.api_key ? "is-invalid" : ""
                              }`}
                              id="api_key"
                              name="api_key"
                              placeholder="Broker API Key"
                              value={formData.api_key}
                              onChange={handleChange}
                            />
                            <label htmlFor="api_key">
                              {" "}
                              <span className="text-danger">*</span>Broker API
                              Key
                            </label>
                            {errors.api_key && (
                              <p className="error">{errors.api_key}</p>
                            )}
                          </div>
                        </div>
                        </>
                      )}

{selectedBroker === "dhan" && (
                                    <>
                                     <div className="col-12 col-md-6 col-lg-3 mb-3">
                          <div className="form-floating form-floating-outline">
                            <input
                              type="text"
                              className="form-control "
                              
                              id="dhan_client_id"
                              name="dhan_client_id"
                              placeholder="Broker Client ID"
                              value={formData.dhan_client_id}
                              onChange={handleChange}
                            />
                            <label htmlFor="dhan_client_id">
                              <span className="text-danger">*</span> Broker
                              Client ID
                            </label>
                           
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 mb-3">
                          <div className="form-floating form-floating-outline">
                            <textarea
                                            className="form-control h-px-75 "
                              id="access_token"
                              name="access_token"
                              placeholder="Broker Access Token"
                              value={formData.access_token}
                              onChange={handleChange}
                            />
                            <label htmlFor="access_token">
                              {" "}
                              <span className="text-danger">*</span> Broker
                             Access Token
                            </label>
                           
                          </div>
                        </div>
                                    </>)}
                      </div>
                   
                    </div>
                    
                  </div>
                )}

                <div className="row">
                  <div className="col text-end ">
                    <button
                      type="submit"
                      className="btn rounded-pill btn-success btn-sm"
                      disabled={loading}
                    >
                      {loading ? (
                        <div className="spinner-border me-2" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                      )}{" "}
                      {loading ? "Saving Data" : "Save Data"}
                    </button>
                    <Toast ref={toast} position="top-right" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CreateStudent;

import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import AdminHeader from './AdminHeader';
import AdminSubHeader from './AdminSubHeader';
import Footer from '../../../component/Footer';
import parse from 'html-react-parser'; // Import html-react-parser
import config from '../../../component/config';
import CardLinks from '../../../component/CardLinks';
import { Tooltip } from "primereact/tooltip";
import SubscriptionBadge from '../../../component/SubscriptionBadge';
import Skeleton from "react-loading-skeleton";
const ViewFaq = () => {
  const { faqId } = useParams();
  const [faq, setFaq] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const toast = useRef(null);
  const navigate = useNavigate();
  const [backClicked, setBackClicked] = useState(false);

  useEffect(() => {
    const fetchFaqDetails = async () => {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
  
      if (!authToken || !userId) {
        navigate("/login");
        return;
      }
  
      setLoading(true);
  
      try {
        const response = await axios.post(
          `${config.apiDomain}/api/admin/view_faq`,
          { faq_id: faqId },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
  
        setFaq(response.data.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/login"); // Navigate to login if 401
        } else {
         
        }
      } finally {
        setLoading(false);
      }
    };
  
    fetchFaqDetails();
  }, [faqId, navigate]);
  

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

    const handleRefresh = async () => {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Auth token not found', life: 2000, });
        return;
      }

      setLoading(true);
setIsRefreshing(true);
      try {
        const response = await axios.post(
          `${config.apiDomain}/api/admin/view_faq`,
          { faq_id: faqId },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        setFaq(response.data.data);
        setLoading(false);
        setIsRefreshing(false);
      } catch (error) {
        setLoading(false);
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch FAQ details' , life: 2000,});
      }
    };

  
  return (
    <div>
      <AdminHeader />
      <AdminSubHeader />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
        <ol className="breadcrumb breadcrumb-style1 text-black mb-3">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/manage_faq" className="text-black">
                 FAQ
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary me-6" aria-current="page">
            Detail

            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>
        <div className="card p-5">
        {loading ? (
        <Skeleton height={200} /> // Adjust the height as needed
      ) : (
        <>
        <div className="row align-items-center mb-5">
  <div className="col-3 text-start">
    <button
      onClick={handleBack}
      className="btn rounded-pill btn-outline-secondary btn-xs"
    >
      <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
    </button>
  </div>
  <div className="col-6 text-center">
    <h5 className="mb-0">                
      Detail
    </h5>
  </div>
  <div className="col-3 text-end">
  {loading ? (
    <div className="d-flex justify-content-end align-items-center"> {/* Make sure the alignment remains consistent */}
      <i
        className={`ri-refresh-line ri-lg ms-3 p-text-secondary ${
          isRefreshing ? "rotate" : ""
        }`}
        role="status"
      ></i>
    </div>
  ) : (
    <div className="d-flex justify-content-end align-items-center"> {/* Consistent alignment for normal icon */}
      <Tooltip target=".custom-target-icon" />
      <i
        className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
        role="button"
        data-pr-tooltip="Reload"
        onClick={handleRefresh}
        data-pr-position="top"
      ></i>
    </div>
  )}
</div>

</div>


        
           
              <div>
              <p className='text-capitalize'><strong>Category:</strong> {faq.category}</p>
              <p className='text-capitalize'><strong>Question:</strong> {faq.question}</p>
              
              
              <div className='text-capitalize'>
                <strong>Answer:</strong> {parse(faq.answer)}
              </div>
            </div>
            
          
       </>
      )}
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer />
      <Toast ref={toast} />
    </div>
  );
};

export default ViewFaq;

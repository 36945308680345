import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import AdminHeader from './AdminHeader';
import AdminSubHeader from './AdminSubHeader';
import Footer from '../../../component/Footer';
import config from '../../../component/config';
import CardLinks from '../../../component/CardLinks';
import { Tooltip } from "primereact/tooltip";
import SubscriptionBadge from '../../../component/SubscriptionBadge';
import Skeleton from "react-loading-skeleton";
const ViewSubscription = () => {
  const { subscription_id } = useParams(); // Get subscription_id from URL
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const toast = useRef(null);
  const navigate = useNavigate();
  const [backClicked, setBackClicked] = useState(false);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");
         if (!authToken || !userId) {
        navigate("/login");
          return;
        }
        const response = await axios.post(`${config.apiDomain}/api/admin/subscription_copy_trading/view`, {
          subscription_id: subscription_id
        }, {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        });

        if (response.data.st === 1) {
          setSubscriptionPlan(response.data.subscription_plan_data);
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: response.data.msg,
            life: 2000,
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to fetch subscription data',
            life: 2000,
          });
        }
      } finally {
        setLoading(false);
        setIsRefreshing(false);
      }
    };

    fetchSubscriptionData();
  }, [subscription_id, navigate]);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
 
    const handleRefresh = async () => {
      setLoading(true);
      setIsRefreshing(true);
      try {
        const authToken = localStorage.getItem('authToken');
        const response = await axios.post(`${config.apiDomain}/api/admin/subscription_copy_trading/view`, {
          subscription_id: subscription_id
        }, {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        });

        if (response.data.st === 1) {
          setSubscriptionPlan(response.data.subscription_plan_data);
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: response.data.msg,
            life: 2000,
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to fetch subscription data',
            life: 2000,
          });
        }
      } finally {
        setLoading(false);
        setIsRefreshing(false);
      }
    };

  
  return (
    <div>
      <AdminHeader />
      <AdminSubHeader />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
        <ol className="breadcrumb breadcrumb-style1 text-black mb-3">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/manage_subscription" className="text-black">
                Subscription
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary text-capitalize" aria-current="page">
            {subscriptionPlan ? subscriptionPlan.subsciption_name : 'Loading...'}
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>
        <div className="card p-5">
        {loading ? (
        <Skeleton height={200} /> // Adjust the height as needed
      ) : (
        <>
        <div className="row align-items-center mb-5">
  <div className="col-3 text-start">
    <button
      onClick={handleBack}
      className="btn rounded-pill btn-outline-secondary btn-xs"
    >
      <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
    </button>
  </div>
  <div className="col-6 text-center">
    <h5 className="mb-0 text-capitalize">{subscriptionPlan ? subscriptionPlan.subsciption_name : 'Loading...'}</h5>
  </div>
  <div className="col-3 text-end">
  {loading ? (
    <div className="d-flex justify-content-end align-items-center"> {/* Make sure the alignment remains consistent */}
      <i
        className={`ri-refresh-line ri-lg ms-3 p-text-secondary ${
          isRefreshing ? "rotate" : ""
        }`}
        role="status"
      ></i>
    </div>
  ) : (
    <div className="d-flex justify-content-end align-items-center"> {/* Consistent alignment for normal icon */}
      <Tooltip target=".custom-target-icon" />
      <i
        className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
        role="button"
        data-pr-tooltip="Reload"
        onClick={handleRefresh}
        data-pr-position="top"
      ></i>
    </div>
  )}
</div>

</div>

         
           
              <div>
                <div className="col-12 mt-5 mb-5">
                  <div className='row'>
                    <div className="col-12">
                      <span className="text-black">
                        <strong className='text-capitalize'>{subscriptionPlan.subsciption_name}</strong>
                      </span>
                      <div>Subscription Name</div>
                    </div>
                    <div className='row mt-4'>
                    <div className="col-12">
                      <span className="">
                        <div className=''>Subscription Description</div>
                        <strong className='text-capitalize text-black'>{subscriptionPlan.subsciption_description}</strong>
                      </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                {subscriptionPlan.subscriptions.map((sub, index) => (
                  <div key={index} className="col-12">
                    <div className="mb-3 row">
                      <div className="col-6 col-md-3 col-lg-3">
                        <span className="text-black">
                          <strong className='text-capitalize'>{sub.plan_name}</strong>
                        </span>
                        <div>Plan Name</div>
                      </div>

                     

                      <div className="col-6 col-md-3 col-lg-3">
                        <span className="text-black">
                          <strong className='text-capitalize'>{sub.pricing} Rs.</strong>
                        </span>
                        <div>Pricing</div>
                      </div>
                      <div className="col-6 col-md-3 col-lg-3 mt-4 mt-md-0">
                        <span className="text-black">
                          <strong className='text-capitalize'>{sub.service_period} day</strong>
                        </span>
                        <div>Service Period</div>
                      </div>

                      <div className="col-6 col-md-3 col-lg-3 mt-4 mt-md-0">
                        <span className="text-black">
                          <strong className='text-capitalize'>{sub.max_user_limit} user</strong>
                        </span>
                        <div>Max User Limit</div>
                      </div>
                    </div>
                    <div className="mt-4 row">
                    <div className="col-12">
                        <span className="">
                          <div> Plan Description</div>
                          <span className='text-capitalize text-black'>{sub.plan_description}</span>
                        </span>
                      </div>
                      </div>
                    <hr className='mt-3' />
                  </div>
                ))}
              </div>
           
         </>
      )}
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer />
      <Toast ref={toast} />
    </div>
  );
};

export default ViewSubscription;
